<p>
  Cookies works!
  <br />
  <br />
  <button type="button" id="btn-go" class="btn btn-success " routerLink="/home" [disabled]="cookies === '{}'">
    {{ 'NEXT_STEP' | translate }}
  </button>
  <br />
  ={{ cookies }}=
</p>
