import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ItemActionTypeEnum } from '../../../../shared/enum/item-action.enum';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { ChildComponent } from '../../../../shared/layouts/modals/child-item/child-component';
import { AddItemRequest, CreateCartResponse } from '../../../../shared/models/cart.model';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import { AddItemRequestAction, ResetAddItemErrorAction } from '../../../../shared/store/actions/carts.action';
import { CreateCartState } from '../../../../shared/store/reducers/cart.reducers';
import { selectCreateCartResult } from '../../../../shared/store/selectors/cart.selector';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-duplicate-item',
  templateUrl: './duplicate-item.component.html',
  styleUrls: ['./duplicate-item.component.scss']
})
export class DuplicateItemComponent implements OnInit, ChildComponent, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { barcode: string; qty: string };

  public duplicateItemForm: FormGroup;
  private localStore: Observable<any>;
  public cart$: Observable<CreateCartState>;
  private cart: CreateCartResponse;

  constructor(private readonly store: Store<AppStates>, private readonly formBuilder: FormBuilder) {}

  barcode: string;
  qty: string;

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.cart$ = this.localStore.pipe(select(selectCreateCartResult));
    this.cart$
      .pipe(
        tap(cart => {
          this.cart = cart.result.response;
        })
      )
      .subscribe();
    this.barcode = this.data.barcode;
    this.qty = this.data.qty;
    this.duplicateItemForm = this.formBuilder.group({
      amount: [this.qty, [Validators.required, Validators.min(1), Validators.max(999)]]
    });
  }

  cancel() {
    this.closeModal();
  }

  dispatchUpdateItemAction(barcode: string, qty: string) {
    const addItemRequest: AddItemRequest = {
      barcode: barcode,
      qty: qty,
      action: ItemActionTypeEnum.UPDATE,
      cartId: this.cart.cartId
    };
    this.store.dispatch(new AddItemRequestAction(addItemRequest));
  }

  updateItem(barcode: string) {
    if (this.duplicateItemForm.controls.amount.value) {
      this.dispatchUpdateItemAction(barcode, this.duplicateItemForm.controls.amount.value.toString());
    }
    this.closeModal();
  }

  closeModal() {
    this.notifyParent.emit({
      notificationType: NotificationTypeEnum.FORCE_CLOSE,
      result: null
    });
    this.store.dispatch(new ResetAddItemErrorAction());
  }

  get duplicateItemFormGetter(): {
    [key: string]: AbstractControl;
  } {
    return this.duplicateItemForm.controls;
  }

  ngOnDestroy(): void {}
}
