import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ClientIdTypeEnum } from '../../shared/enum/client-id.enum';
import { ChildItem } from '../../shared/layouts/modals/child-item/child-item';
import { FullModalComponent } from '../../shared/layouts/modals/full-modal/full-modal.component';
import { ItemModalComponent } from '../../shared/models/item-modal.component.model';
import { TdStoreListContent } from '../../shared/models/td-store-list.model';
import { TdStoreSelectObject } from '../../shared/models/td-store-select-object.model';
import { CreateCartRequestAction } from '../../shared/store/actions/carts.action';
import { TdStoreListGetAction } from '../../shared/store/actions/td-store-list.action';
import { ResetTdStoreState, TdStoreGetAction } from '../../shared/store/actions/td-store.action';
import { selectClientIdResult } from '../../shared/store/selectors/client-id.selector';
import { selectTdStoreByUserResult } from '../../shared/store/selectors/td-store-list.selector';
import { selectTdStoreResult } from '../../shared/store/selectors/td-store.selector';
import { selectTenantInfoResult } from '../../shared/store/selectors/tenant-info.selector';
import { AppStates } from '../../shared/store/state/app.states';
import { BaseTdStoreListState } from '../../shared/store/state/td-store-list.state';
import { BaseTdStoreState } from '../../shared/store/state/td-store.state';
import { TenantInfoState } from '../../shared/store/state/tenant-info.state';
import { OrdersComponent } from '../orders/orders.component';

@Component({
  selector: 'app-order-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
  public bsModalRef: BsModalRef;
  public storeList$: Observable<BaseTdStoreListState>;
  public tenantInfo$: Observable<TenantInfoState>;
  public tdStore$: Observable<BaseTdStoreState>;
  public storeList: Array<TdStoreSelectObject>;
  public selectStoreForm: FormGroup;
  private clientId: ClientIdTypeEnum | null;

  private localStore: Observable<any>;

  constructor(
    private readonly store: Store<AppStates>,
    private translate: TranslateService,
    private readonly modalService: BsModalService,
    private readonly formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initState();
  }

  initState() {
    this.storeList$ = this.localStore.pipe(select(selectTdStoreByUserResult));
    this.tenantInfo$ = this.localStore.pipe(select(selectTenantInfoResult));
    this.tdStore$ = this.localStore.pipe(select(selectTdStoreResult));

    this.localStore
      .pipe(
        select(selectClientIdResult),
        tap(clientId => (this.clientId = clientId))
      )
      .subscribe();

    this.tenantInfo$
      .pipe(tap(tenantInfo => this.store.dispatch(new TdStoreListGetAction({ merchantNo: tenantInfo.merchant }))))
      .subscribe();

    this.selectStoreForm = this.formBuilder.group({ store: null });
    this.storeList$
      .pipe(
        map(data => {
          if (data.result.response && data.result.response.content) {
            return data.result.response.content;
          }
        }),
        tap((merchants: TdStoreListContent[]) => {
          if (merchants) {
            this.storeList = new Array<TdStoreSelectObject>();

            if (this.isSpecifiedStoreNo) {
              merchants
                .filter(merchant => merchant.no === this.specifiedStoreNo)
                .forEach(merchant => this.storeList.push({ name: merchant.name, no: merchant.no }));
            } else {
              merchants.forEach(merchant => this.storeList.push({ name: merchant.name, no: merchant.no }));
            }

            if (this.isSingleStore) {
              const storeNo = this.storeList[0].no;

              this.selectStoreForm.get('store').setValue(storeNo);
              this.selectStoreForm.controls.store.disable();
              this.selectedStore();
            }
          }
        })
      )
      .subscribe();
  }

  selectedStore() {
    this.store.dispatch(new ResetTdStoreState());
    this.store.dispatch(new TdStoreGetAction(this.selectStoreForm.get('store').value));
  }

  createCart() {
    const initialState: ItemModalComponent = {
      title: null,
      childItem: new ChildItem(OrdersComponent, {
        storeNo: this.selectStoreForm.get('store').value,
        title: this.translate.instant('ORDER_PROCESS')
      })
    };

    this.store.dispatch(new CreateCartRequestAction({ storeNo: this.selectStoreForm.get('store').value }));

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState: initialState
    });
  }

  get isSpecifiedStoreNo(): boolean {
    return this.specifiedStoreNo !== null;
  }

  get specifiedStoreNo(): string {
    return this.activatedRoute.snapshot.paramMap.get('storeNo');
  }

  get isSingleStore(): boolean {
    return this.storeList.length === 1;
  }

  ngOnDestroy(): void {}
}
