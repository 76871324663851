import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { selectOrderSummaryResult } from '../../../shared/store/selectors/order-summary.selector';
var SearchBarComponent = /** @class */ (function () {
    function SearchBarComponent(formBuilder, store) {
        this.formBuilder = formBuilder;
        this.store = store;
        this.searchCriteria = new EventEmitter();
        this.searchAndFilterForm = this.formBuilder.group({
            searchText: ''
        });
    }
    SearchBarComponent.prototype.ngOnInit = function () {
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.orderSummary$ = this.localStore.pipe(select(selectOrderSummaryResult));
    };
    SearchBarComponent.prototype.handleSearchText = function () {
        this.searchCriteria.emit(this.searchAndFilterForm.controls['searchText'].value);
    };
    SearchBarComponent.prototype.clearSearchText = function () {
        this.searchAndFilterForm.controls['searchText'].reset();
        this.searchCriteria.emit(this.searchAndFilterForm.controls['searchText'].value);
    };
    Object.defineProperty(SearchBarComponent.prototype, "f", {
        get: function () {
            return this.searchAndFilterForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    SearchBarComponent.prototype.ngOnDestroy = function () { };
    return SearchBarComponent;
}());
export { SearchBarComponent };
