import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { ForgotPasswordStepEnum } from '../../../shared/enum/user.enum';
import { EventService } from '../../../shared/services';
import { selectForgotPasswordResult } from '../../../shared/store/selectors/forgot-password.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { BaseForgotPasswordState } from '../../../shared/store/state/forgot-password.state';

@Component({
  selector: 'app-forgot-password-left',
  templateUrl: './forgot-password-left.component.html',
  styleUrls: ['./forgot-password-left.component.scss']
})
export class ForgotPasswordLeftComponent implements OnInit, OnDestroy {
  public forgotPasswordResult$: Observable<BaseForgotPasswordState>;
  public step: ForgotPasswordStepEnum;
  private localStore: Observable<any>;

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppStates>,
    private eventService: EventService
  ) {}

  ngOnInit() {
    this.step = ForgotPasswordStepEnum.REQUEST_OTP;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.forgotPasswordResult$ = this.localStore.pipe(select(selectForgotPasswordResult));
    this.forgotPasswordResult$.subscribe(value => {
      if (value) {
        this.step = value.result.step;
      }
    });
  }

  ngOnDestroy(): void {}

  resendOTP() {
    this.eventService.BroadcastEvent('resendOTP', {});
  }

  onCancel() {
    this.router.navigateByUrl('/login');
  }
}
