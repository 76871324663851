import { createSelector } from '@ngrx/store';
import { ClientIdTypeEnum } from '../../enum/client-id.enum';
import { AppStates } from '../state/app.states';

const selectClientId = (state: AppStates) => state.clientId;

export const selectClientIdResult = createSelector(
  selectClientId,
  (state: ClientIdTypeEnum | null) => state
);
