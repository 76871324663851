import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { BaseTdStoresTreeViewState } from '../state/td-stores-tree-view.state';

const selectTdStoresTreeView = (state: AppStates) => state.tdStoresTreeView;

export const selectTdStoresTreeViewResult = createSelector(
  selectTdStoresTreeView,
  (state: BaseTdStoresTreeViewState) => state.items
);
