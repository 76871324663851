import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { take, tap } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { LogOutAction } from '../store/actions/auth.actions';
import { ResetUserState } from '../store/actions/users.action';
import { selectAuthoritiesResult } from '../store/selectors/auth-user-info.selector';
import { selectClientIdResult } from '../store/selectors/client-id.selector';
import { selectUserInformationResult } from '../store/selectors/user-info.selector';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngrx/store";
import * as i3 from "ngx-cookie-service";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(router, store, cookieService) {
        var _this = this;
        this.router = router;
        this.store = store;
        this.cookieService = cookieService;
        this.store
            .pipe(select(selectClientIdResult), tap(function (clientId) { return (_this.clientId = clientId); }))
            .subscribe();
        this.store
            .pipe(select(selectUserInformationResult), tap(function (userInfo) { return (_this.bypassForceChange = !(userInfo.forceChangePassword && userInfo.forceChangePinCode)); }))
            .subscribe(function (userInfo) {
            _this.authUserInfo = userInfo;
        });
    }
    AuthGuardService.prototype.canActivate = function (route) {
        return this.doAuthenticated(route.data.permissions);
    };
    AuthGuardService.prototype.canActivateChild = function (route) {
        return this.doAuthenticated(route.data.permissions);
    };
    AuthGuardService.prototype.doAuthenticated = function (permissions) {
        var _this = this;
        var canActivate = this.cookieService.check(env.cookies.refreshToken);
        if (!canActivate) {
            this.router.navigate(['/login']);
            return false;
        }
        else if (canActivate && this.bypassForceChange) {
            this.store
                .select(selectAuthoritiesResult)
                .pipe(take(1))
                .subscribe(function (authorities) {
                if (authorities && permissions && !authorities.some(function (val) { return permissions.indexOf(val) > -1; })) {
                    canActivate = false;
                    _this.router.navigateByUrl('/home');
                }
            });
        }
        // else if (canActivate && this.authUserInfo.forceChangePassword) {
        //   // canActivate = canActivate && this.authUserInfo.forceChangePassword;
        //   // canActivate = true;
        //   this.router.navigate(['/force-change-password']);
        //   return false;
        // }
        return canActivate;
    };
    AuthGuardService.prototype.getMenuByPermission = function (userAuthorities) {
        return this.generateMenu(JSON.parse(JSON.stringify(this.router.config)), userAuthorities);
    };
    AuthGuardService.prototype.checkPermission = function (permission) {
        var permissions = Array.isArray(permission) ? permission : [permission];
        var hasPermission = false;
        this.authoritiesSubscription = this.store
            .select(function (state) { return state.userInfo.authorities; })
            .subscribe(function (authorities) {
            if (permissions && authorities) {
                hasPermission = permissions.some(function (val) { return authorities.indexOf(val) > -1; });
            }
        });
        return hasPermission;
    };
    AuthGuardService.prototype.hasOnlyPermission = function (menu, permissionName, userAuthorities) {
        var filteredPermission = userAuthorities.filter(function (permission) {
            return permission.indexOf(menu) > -1;
        });
        return filteredPermission.length === 1 && filteredPermission[0] === menu + "_" + permissionName;
    };
    AuthGuardService.prototype.logOut = function () {
        this.store.dispatch(new LogOutAction());
        this.store.dispatch(new ResetUserState());
    };
    // TODO:: Fix cognitive-complexity
    // tslint:disable-next-line:cognitive-complexity
    AuthGuardService.prototype.generateMenu = function (config, userAuthorities) {
        var _this = this;
        var menus = [];
        var configFiltered = config
            .map(function (route) { return (route.path === '' && route.children && route.children.length ? route.children.shift() : route); })
            .filter(function (route) {
            if (route.children) {
                if (route.data && route.data.permissions && route.data.permissions.length > 0) {
                    var hasPermission = true;
                    hasPermission = route.data.permissions.some(function (val) { return userAuthorities.indexOf(val) > -1; });
                    // Delete children in case parent has permission set
                    route.children = [];
                    return hasPermission && route.data.isShowOnMenu;
                }
                else {
                    route.children = route.children.filter(function (child) {
                        var hasPermission = true;
                        if (child.data && child.data.permissions) {
                            hasPermission = child.data.permissions.some(function (val) { return userAuthorities.indexOf(val) > -1; });
                        }
                        return hasPermission && child.data && child.data.isShowOnMenu;
                    });
                }
            }
            return (route.data && route.data.isShowOnMenu) || (route.children && route.children.length);
        })
            .sort(function (a, b) { return a.data.order - b.data.order; });
        configFiltered.forEach(function (parent) {
            var menu = tslib_1.__assign({ title: parent.data.title, link: parent.path ? '/' + parent.path : '' }, (parent.data.icon && { icon: parent.data.icon }), (parent.data.active && { active: parent.data.active }), (parent.data.badge && { badge: parent.data.badge }), { type: parent.children && parent.children.length ? 'dropdown' : 'simple' });
            if (menu.type === 'dropdown') {
                _this.doDropdownMenu(menu, parent.children);
            }
            menus.push(menu);
        });
        return menus;
    };
    AuthGuardService.prototype.doDropdownMenu = function (menu, children) {
        menu.submenus = [];
        children.forEach(function (child) {
            var subMenu = {
                title: child.data.title ? child.data.title : '',
                link: child.path
            };
            menu.submenus.push(subMenu);
        });
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.CookieService)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
