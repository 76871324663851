<div
  class="wrapper d-flex justify-content-between {{ config.containerClass }}"
  id="c_{{ componentKey }}"
  *ngIf="otpForm?.controls"
  [ngStyle]="config.containerStyles"
>
  <input
    [type]="config.allowNumbersOnly ? 'tel' : 'text'"
    [ngStyle]="config.inputStyles"
    maxlength="1"
    class="flex-item otp-input {{ config.inputClass }}"
    autocomplete="off"
    *ngFor="let item of otpForm?.controls | keys; let i = index"
    [formControl]="otpForm.controls[item]"
    id="otp_{{ i }}_{{ componentKey }}"
    (keyup)="onKeyUp($event, i)"
    appOtpNumber
  />
</div>
