import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MerchantService = /** @class */ (function (_super) {
    tslib_1.__extends(MerchantService, _super);
    function MerchantService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.envService = _this.env.services.merchants;
        _this.headers = new HttpHeaders(_this.envService.headers);
        return _this;
    }
    MerchantService.prototype.getStoresByMerchant = function (urlParams) {
        var params = this.getParams(urlParams);
        var url = this.getFullUrl(this.envService.stores, {
            merchantNo: urlParams.merchantNo
        });
        return this.http.get(url, { headers: this.headers, params: params });
    };
    MerchantService.prototype.getStoresGroupByMerchant = function (urlParams) {
        var url = this.getFullUrl(this.envService.storesGroups, {
            merchantNo: urlParams.merchantNo
        });
        return this.http.get(url, { headers: this.headers });
    };
    MerchantService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MerchantService_Factory() { return new MerchantService(i0.ɵɵinject(i1.HttpClient)); }, token: MerchantService, providedIn: "root" });
    return MerchantService;
}(BaseService));
export { MerchantService };
