import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textExist'
})
export class TextExistPipe implements PipeTransform {
  transform(value: string | null): any {
    return value ? value.trim().length > 0 : false;
  }
}
