import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForceChangePasswordLeftComponent } from './pages/force-change-password/force-change-password-left/force-change-password-left.component';
import { ForceChangePasswordComponent } from './pages/force-change-password/force-change-password.component';
import { ForgotPasswordLeftComponent } from './pages/forgot-password/forgot-password-left/forgot-password-left.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotUsernameLeftComponent } from './pages/forgot-username/forgot-username-left/forgot-username-left.component';
import { ForgotUsernameComponent } from './pages/forgot-username/forgot-username.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginLeftComponent } from './pages/login/login-left/login-left.component';
import { ItemComponent as OrderItemComponent } from './pages/orders/item/item.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { DuplicateItemComponent } from './pages/orders/step1/duplicate-item/duplicate-item.component';
import { PosComponent } from './pages/pos/pos.component';
import { EditComponent as EditStoreComponent } from './pages/stores/edit/edit.component';
import { StoresComponent } from './pages/stores/stores.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { AddComponent as AddUserComponent } from './pages/users/add/add.component';
import { EditComponent as EditUserComponent } from './pages/users/edit/edit.component';
import { UsersComponent } from './pages/users/users.component';
import { MerchantVerificationErrorComponent } from './pages/verification/merchant-verification-error/merchant-verification-error.component';
import { MerchantVerificationInvalidComponent } from './pages/verification/merchant-verification-invalid/merchant-verification-invalid.component';
import { MerchantVerificationSuccessComponent } from './pages/verification/merchant-verification-success/merchant-verification-success.component';
import { MerchantVerificationComponent } from './pages/verification/merchant-verification/merchant-verification.component';
import { AdminLayoutComponent, LoginComponent } from './shared/layouts';
import { LoginLayoutComponent } from './shared/layouts/login-layout/login-layout.component';
import { MobileVerificationLayoutComponent } from './shared/layouts/mobile-verification-layout/mobile-verification-layout.component';
import { AuthGuardService } from './shared/services';
import { MerchantVerifyGuardService } from './shared/services/merchant-verify-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        children: [
          {
            path: '',
            component: LoginComponent
          },
          {
            path: '',
            outlet: 'leftsection',
            component: LoginLeftComponent
          }
        ]
      },
      {
        path: 'forgot-username',
        children: [
          {
            path: '',
            component: ForgotUsernameComponent
          },
          {
            path: '',
            outlet: 'leftsection',
            component: ForgotUsernameLeftComponent
          }
        ]
      },
      {
        path: 'forgot-password',
        children: [
          {
            path: '',
            component: ForgotPasswordComponent
          },
          {
            path: '',
            outlet: 'leftsection',
            component: ForgotPasswordLeftComponent
          }
        ]
      },
      {
        path: 'force-change-password',
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            component: ForceChangePasswordComponent
          },
          {
            path: '',
            outlet: 'leftsection',
            component: ForceChangePasswordLeftComponent
          }
        ]
      }
    ]
  },
  { path: 'pos/stores/:storeNo', component: PosComponent },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'home',
        canActivate: [AuthGuardService],
        component: HomeComponent,
        data: {
          title: 'DASHBOARD',
          icon: 'fa fa-home',
          active: true,
          order: 1,
          isShowOnMenu: true
        }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'order',
        data: {
          title: 'ORDER',
          icon: 'fa fa-shopping-cart',
          active: false,
          order: 2,
          isShowOnMenu: true
        },
        children: [
          {
            path: 'summary',
            canActivate: [AuthGuardService],
            component: SummaryComponent,
            data: {
              title: 'ORDER_PRODUCT',
              isShowOnMenu: true
            }
          },
          {
            path: 'summary/:storeNo',
            canActivate: [AuthGuardService],
            component: SummaryComponent
          },
          {
            path: 'order',
            component: OrdersComponent,
            canActivate: [AuthGuardService],
            children: [
              {
                path: 'steps',
                canActivate: [AuthGuardService],
                component: OrdersComponent,
                children: [
                  {
                    path: 'item',
                    canActivate: [AuthGuardService],
                    component: OrderItemComponent
                  },
                  {
                    path: 'duplicate-item',
                    canActivate: [AuthGuardService],
                    component: DuplicateItemComponent
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'users',
        canActivate: [AuthGuardService],
        component: UsersComponent,
        data: {
          title: 'USER',
          icon: 'fa fa-users',
          active: false,
          order: 3,
          isShowOnMenu: true,
          permissions: ['user_v', 'user_m']
        },
        children: [
          {
            path: 'add',
            canActivate: [AuthGuardService],
            component: AddUserComponent
          },
          {
            path: 'edit',
            canActivate: [AuthGuardService],
            component: EditUserComponent
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'stores',
        canActivate: [AuthGuardService],
        component: StoresComponent,
        data: {
          title: 'STORE',
          icon: 'fa fa-store',
          active: false,
          order: 4,
          isShowOnMenu: true,
          permissions: ['storeprofile_v', 'storeprofile_m']
        },
        children: [
          {
            path: 'edit',
            canActivate: [AuthGuardService],
            component: EditStoreComponent
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: MobileVerificationLayoutComponent,
    children: [
      {
        path: 'mer-verify',
        canActivate: [MerchantVerifyGuardService],
        component: MerchantVerificationComponent
      },
      {
        path: 'mer-verify-success',
        component: MerchantVerificationSuccessComponent
      },
      {
        path: 'mer-verify-invalid',
        component: MerchantVerificationInvalidComponent
      },
      {
        path: 'mer-verify-error',
        component: MerchantVerificationErrorComponent
      }
    ]
  },

  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
