import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { VerificationService } from './verification.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantVerifyGuardService implements CanActivate, CanActivateChild {
  private merchantId: string;

  constructor(
    private verificationService: VerificationService,
    private authService: AuthService,
    private readonly router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> {
    this.merchantId = route.queryParams.m;
    return this.checkMerchantStatus();
  }

  public canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.merchantId = route.params.merchantId;
    return this.checkMerchantStatus();
  }

  private checkMerchantStatus(): Observable<boolean> {
    return this.authService.bypassAuthenToken().pipe(
      map(result => result['access_token']),
      mergeMap(accessToken => this.verificationService.getMerchantStatus(this.merchantId, accessToken)),
      map(response => {
        const status = response['status'];
        if (status === 'WV') {
          return true;
        } else if (['WS', 'D', 'WA', 'C', 'R', 'CR'].includes(status)) {
          this.router.navigateByUrl('/mer-verify-invalid');
          return false;
        } else {
          this.router.navigateByUrl('/mer-verify-error');
          return false;
        }
      }),
      catchError(() => {
        this.router.navigateByUrl('/mer-verify-error');
        return of(false);
      })
    );
  }
}
