import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { filter, map } from 'rxjs/operators';
import { OrderPolicyComponent } from '../../../shared/components/td-store/order-policy/order-policy.component';
import { StoreProfileComponent } from '../../../shared/components/td-store/store-profile/store-profile.component';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { TDPageModes } from '../../../shared/enum/store.enum';
import { UserSelectValueEnum } from '../../../shared/enum/user-select-value.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { TdStore } from '../../../shared/models/td-store.model';
import { ResetTdStoreState, TdStoreEditAction, TdStoreGetAction } from '../../../shared/store/actions/td-store.action';
import { UserSelectValueRequestAction, UserSelectValueResetAction } from '../../../shared/store/actions/user-select-value.actions';
import { selectTdStoreResult } from '../../../shared/store/selectors/td-store.selector';
var EditComponent = /** @class */ (function () {
    function EditComponent(store, formBuilder, modalService, translate, logger) {
        this.store = store;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.translate = translate;
        this.logger = logger;
        this.notifyParent = new EventEmitter();
    }
    EditComponent.prototype.ngOnInit = function () {
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.initState();
        this.initControl();
    };
    EditComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new ResetTdStoreState());
        this.store.dispatch(new UserSelectValueResetAction());
        this.subscriptions.unsubscribe();
    };
    Object.defineProperty(EditComponent.prototype, "pageMode", {
        get: function () {
            return TDPageModes;
        },
        enumerable: true,
        configurable: true
    });
    EditComponent.prototype.initState = function () {
        var _this = this;
        this.store.dispatch(new UserSelectValueRequestAction({
            type: [UserSelectValueEnum.ALL]
        }));
        this.store.dispatch(new TdStoreGetAction(this.data.id));
        this.tdStore$ = this.localStore.pipe(select(selectTdStoreResult));
        this.subscriptions = this.tdStore$.pipe(map(function (action) { return action.result; })).subscribe(function (next) {
            if (next.response && _this.submitted) {
                _this.onSuccess(next);
            }
            else if (next.errorResponse) {
                _this.onError(next.errorResponse);
            }
            else {
                _this.logger.debug(next);
            }
        }, function (error) { return _this.onError(error); }, function () { return _this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null }); });
    };
    EditComponent.prototype.initControl = function () {
        var _this = this;
        this.editStoreForm = this.formBuilder.group({
            id: ['', []],
            version: ['', []],
            receiptHeader: ['', [Validators.maxLength(50)]],
            receiptAddress: ['', [Validators.required, Validators.maxLength(250)]],
            receiptTaxId: ['', [Validators.maxLength(13)]],
            receiptBranchNo: ['', [Validators.maxLength(5)]],
            receiptFooter: ['', [Validators.maxLength(25)]]
        });
        this.subscriptions = this.tdStore$
            .pipe(map(function (val) { return val.result.response; }), filter(function (val) { return val !== null && !_this.submitted; }))
            .subscribe(function (resp) {
            if (resp.receipt) {
                var receipt = resp.receipt;
                _this.editStoreForm.controls['receiptAddress'].setValue(receipt.address || '');
                _this.editStoreForm.controls['receiptHeader'].setValue(receipt.header || '');
                _this.editStoreForm.controls['receiptTaxId'].setValue(receipt.taxId || '');
                _this.editStoreForm.controls['receiptBranchNo'].setValue(receipt.branchNo || '00000');
                _this.editStoreForm.controls['receiptFooter'].setValue(receipt.footer || '');
            }
            if (_this.data.mode === TDPageModes.REQUEST_VIEW) {
                _this.editStoreForm.disable();
            }
            _this.tmpElementValue = new Array();
            _this.preventBlock(_this.editStoreForm.controls, 'receiptTaxId', /^\d*$/);
            _this.preventBlock(_this.editStoreForm.controls, 'receiptBranchNo', /^\d*$/);
        });
    };
    EditComponent.prototype.onSubmit = function () {
        var req;
        this.submitted = true;
        if (this.editStoreForm.invalid) {
            return;
        }
        req = new TdStore(this.editStoreForm.getRawValue());
        req.storeProfile = this.editStoreForm.value.storeProfile;
        req.orderSchedule = this.editStoreForm.value.orderSchedule;
        req.receipt = {
            header: this.editStoreForm.value.receiptHeader,
            address: this.editStoreForm.value.receiptAddress,
            taxId: this.editStoreForm.value.receiptTaxId,
            branchNo: this.editStoreForm.value.receiptBranchNo,
            footer: this.editStoreForm.value.receiptFooter
        };
        this.store.dispatch(new TdStoreEditAction(req));
    };
    EditComponent.prototype.onCancel = function () {
        if (this.editStoreForm.touched) {
            var initialState = {
                title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
                okText: this.translate.instant('STAY_ON_PAGE'),
                cancelText: this.translate.instant('LEAVE'),
                message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
            };
            this.notifyParent.emit({
                initialState: initialState,
                notificationType: NotificationTypeEnum.CONFIRM,
                result: null
            });
        }
        else {
            this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null });
        }
    };
    Object.defineProperty(EditComponent.prototype, "f", {
        get: function () {
            return this.editStoreForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    EditComponent.prototype.preventBlock = function (controls, key, regexp) {
        var _this = this;
        if (regexp === void 0) { regexp = /^.*$/; }
        if (!this.tmpElementValue[key]) {
            this.tmpElementValue[key] = '';
        }
        controls[key].valueChanges.pipe(filter(function (val) { return val !== _this.tmpElementValue[key]; })).forEach(function (val) {
            if (regexp.test(val)) {
                _this.tmpElementValue[key] = val;
            }
            controls[key].setValue(_this.tmpElementValue[key]);
        });
    };
    EditComponent.prototype.onSuccess = function (resp) {
        var _this = this;
        var initialState = {
            title: this.translate.instant('SUCCESS'),
            message: this.translate.instant('STORE_UPDATED'),
            routerLink: 'stores'
        };
        this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(function () {
            _this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: resp });
        });
        this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
    };
    EditComponent.prototype.onError = function (resp) {
        var _this = this;
        if (resp.translateKey) {
            var initialState = {
                title: this.translate.instant('FAILED'),
                message: this.translate.instant(resp.translateKey)
            };
            this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(function () {
                _this.notifyParent.emit({ notificationType: NotificationTypeEnum.LOGGING, result: resp });
            });
            this.modalService.show(AlertModalComponent, { initialState: initialState });
        }
    };
    EditComponent.prototype.onTriggerEdit = function () {
        this.data.mode = TDPageModes.REQUEST_EDIT;
        this.editStoreForm.enable();
        this.storeProfile.toggleEditStoreProfile();
        this.orderPolicy.toggleEditOrderPolicy();
    };
    return EditComponent;
}());
export { EditComponent };
