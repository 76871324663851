/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-verification-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./merchant-verification-error.component";
var styles_MerchantVerificationErrorComponent = [i0.styles];
var RenderType_MerchantVerificationErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantVerificationErrorComponent, data: {} });
export { RenderType_MerchantVerificationErrorComponent as RenderType_MerchantVerificationErrorComponent };
export function View_MerchantVerificationErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", " border border-grey mobile-verification-form pb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h4", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "d-flex justify-content-center text-danger pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "em", [["class", "fa fa-times-circle icon-size"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("SORRY")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("YOUR_REQUEST_COULD_NOT_COMPLETE")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("FOR_MORE_INFORMATION")); _ck(_v, 12, 0, currVal_2); }); }
export function View_MerchantVerificationErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-verification-error", [], null, null, null, View_MerchantVerificationErrorComponent_0, RenderType_MerchantVerificationErrorComponent)), i1.ɵdid(1, 114688, null, 0, i3.MerchantVerificationErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantVerificationErrorComponentNgFactory = i1.ɵccf("app-merchant-verification-error", i3.MerchantVerificationErrorComponent, View_MerchantVerificationErrorComponent_Host_0, {}, {}, []);
export { MerchantVerificationErrorComponentNgFactory as MerchantVerificationErrorComponentNgFactory };
