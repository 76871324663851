import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models/error.model';
import { SubmitOrderResponse } from '../../models/orders.model';
import { CartsAction, CartsActionTypes } from '../actions/carts.action';
import { BaseState } from '../state/base.state';

export interface SubmitOrderState extends EntityState<SubmitOrderResponse>, BaseState {
  isLoading: boolean;
  submitOrderSuccess: boolean;
  result: {
    response: SubmitOrderResponse;
    errorResponse: ErrorResponse;
  };
}

export const submitOrderAdaptor: EntityAdapter<SubmitOrderResponse> = createEntityAdapter<SubmitOrderResponse>();

export const initialSubmitOrderState: SubmitOrderState = submitOrderAdaptor.getInitialState({
  isLoading: false,
  submitOrderSuccess: null,
  result: { response: null, errorResponse: null }
});

export function submitOrderReducers(state = initialSubmitOrderState, action: CartsAction): SubmitOrderState {
  switch (action.type) {
    case CartsActionTypes.SUBMIT_ORDER_RESPONSE: {
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: null
        },
        submitOrderSuccess: true
      };
    }
    case CartsActionTypes.SUBMIT_ORDER_ERROR: {
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        },
        submitOrderSuccess: false
      };
    }
    case CartsActionTypes.RESET_SUBMIT_ORDER_STATUS: {
      return { ...state, ...initialSubmitOrderState };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = submitOrderAdaptor.getSelectors();
