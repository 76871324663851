import { UsersAction, UsersActionTypes } from '../actions/users.action';
import { initialResetPinPasswordState, ResetPasswordPinState } from '../state/reset-password-pin.state';

export function resetPasswordPinReducers(
  state = initialResetPinPasswordState,
  action: UsersAction
): ResetPasswordPinState {
  switch (action.type) {
    case UsersActionTypes.USERS_RESET_PASSWORD_RESPONSE: {
      return {
        ...state,
        result: {
          resetPasswordResult: action.payload
        }
      };
    }
    case UsersActionTypes.USERS_RESET_PIN_CODE_RESPONSE: {
      return {
        ...state,
        result: {
          resetPinCodeResult: action.payload
        }
      };
    }
    case UsersActionTypes.USERS_RESET_PASSWORD_PIN_CODE_RESET: {
      return {
        ...state,
        ...initialResetPinPasswordState
      };
    }
    default:
      return state;
  }
}
