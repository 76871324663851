import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MerchantVerificationRequest } from '../models/verification.models';
import { BaseService } from './base.service';

@Injectable()
export class VerificationService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.envService = this.env.services.verification;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public merchantVerification<T>(req: MerchantVerificationRequest): Observable<T> {
    const url = this.getFullUrl(this.envService.verifyMobile, {
      merchantId: req.merchantId
    });

    return this.http.put<T>(url, null, { headers: this.headers, params: { idCard: req.idNumber } });
  }

  public getMerchantStatus<T>(merchantId: string, accessToken: string): Observable<T> {
    this.headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
    const url = this.getFullUrl(this.envService.status, {
      merchantId: merchantId
    });

    return this.http.get<T>(url, {
      headers: this.headers
    });
  }
}
