import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../../shared/enum/modal-button-response.enum';
import { TDPageModes } from '../../shared/enum/store.enum';
import { UserSelectValueEnum } from '../../shared/enum/user-select-value.enum';
import { AlertModalComponent } from '../../shared/layouts';
import { ChildItem } from '../../shared/layouts/modals/child-item/child-item';
import { ConfirmModalComponent } from '../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { FullModalComponent } from '../../shared/layouts/modals/full-modal/full-modal.component';
import { BaseModalComponent } from '../../shared/models/base-modal.component.model';
import { ConfirmModal } from '../../shared/models/confirm-modal.mode';
import { ItemModalComponent } from '../../shared/models/item-modal.component.model';
import { Role } from '../../shared/models/role.model';
import { RolesAction } from '../../shared/store/actions/roles.actions';
import { UsersListRequestAction } from '../../shared/store/actions/user-list.actions';
import {
  UserSelectValueRequestAction,
  UserSelectValueResetAction
} from '../../shared/store/actions/user-select-value.actions';
import { UsersDeleteAction, UsersDeleteResetAction } from '../../shared/store/actions/users.action';
import { selectDeleteUsersResult } from '../../shared/store/selectors/delete-user.selector';
import { selectRolesResult } from '../../shared/store/selectors/roles.selector';
import { selectTenantInfoResult } from '../../shared/store/selectors/tenant-info.selector';
import { selectUsersListResult } from '../../shared/store/selectors/user-list.selector';
import { selectUsersResult } from '../../shared/store/selectors/users.selector';
import { AppStates } from '../../shared/store/state/app.states';
import { isOwner } from '../../shared/utils/check-role';
import { AddComponent } from './add/add.component';
import { EditComponent } from './edit/edit.component';

@Component({
  selector: 'app-user',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  public headerRow: string[];
  public listRequest;
  public usersList$ = this.store.pipe(select(selectUsersListResult));
  public userStatus = [];
  public isShowAdvanceSearch: boolean;
  public isShowSearchTag: boolean;
  public bsModalRef: BsModalRef;
  public searchAndFilterForm: FormGroup;
  public currentPage: number;
  private merchantNo: string;
  private localStore: Observable<any>;
  public role$: Observable<Array<Role>>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store<AppStates>,
    private readonly modalService: BsModalService,
    private translate: TranslateService
  ) {
    this.headerRow = ['USER_ID', 'NAME', 'USERNAME', 'ROLE', 'LAST_ACCESSED', 'STATUS', 'USER_ACTION'];
  }

  ngOnInit() {
    this.isShowAdvanceSearch = false;
    this.isShowSearchTag = false;
    this.currentPage = 1;
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new UserSelectValueRequestAction({ type: [UserSelectValueEnum.ROLE] }));
    this.role$ = this.localStore.pipe(
      select(selectRolesResult),
      map(data => data.result.response)
    );

    this.initState();
    this.initControl();
    this.initDropDown();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new UserSelectValueResetAction());
  }

  private initDropDown() {
    this.translate
      .get('loaded.done')
      .pipe(
        untilComponentDestroyed(this),
        tap(() => this.initUserStatus())
      )
      .subscribe();

    this.translate.onLangChange
      .pipe(
        untilComponentDestroyed(this),
        tap(() => this.initUserStatus())
      )
      .subscribe();
  }

  private initUserStatus() {
    this.userStatus = [
      { value: '', label: this.translate.instant('ALL_STATUS') },
      { value: 'true', label: this.translate.instant('ACTIVE') },
      { value: 'false', label: this.translate.instant('INACTIVE') }
    ];
  }

  initState() {
    this.store.dispatch(new RolesAction());

    this.localStore.pipe(select(selectTenantInfoResult)).subscribe(tenantInfo => {
      this.merchantNo = tenantInfo.merchant;
      this.listRequest = {
        merchantNo: this.merchantNo,
        size: '20',
        page: '0',
        sortBy: 'userCode',
        sortOrder: 'desc',
        active: null,
        roles: null,
        searchCriteria: null
      };
      this.dispatchUserListAction(this.listRequest);
    });

    this.localStore.pipe(select(selectDeleteUsersResult)).subscribe(deleteStatus => {
      if (deleteStatus.result.response && deleteStatus.result.response.statusCode === '200') {
        const initialState: BaseModalComponent = {
          title: this.translate.instant('SUCCESS'),
          message: this.translate.instant('USER_HAS_BEEN_DELETE')
        };
        this.alertSuccessModal(initialState);
        this.store.dispatch(new UsersDeleteResetAction());
      }
    });

    this.localStore
      .pipe(
        select(selectUsersResult),
        map(userResponse => userResponse.result.response),
        filter(response => response && response.updateSuccess)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.dispatchUserListAction(this.listRequest);
        }, 4000);
      });
  }

  initControl() {
    this.searchAndFilterForm = this.formBuilder.group({
      selectedRoles: [],
      searchText: null,
      status: ''
    });
  }

  alertSuccessModal(initialState: {}) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        setTimeout(() => {
          this.dispatchUserListAction(this.listRequest);
        }, 1500);
      }
    });
  }

  callAddUser() {
    const initialState: ItemModalComponent = {
      title: null,
      childItem: new ChildItem(AddComponent, {
        title: this.translate.instant('ADD_USER')
      })
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState: initialState
    });
  }

  callEditUser(id: string, mode: TDPageModes) {
    const initialState: ItemModalComponent = {
      title: null,
      childItem: new ChildItem(EditComponent, {
        id: id,
        title: this.translate.instant(mode === TDPageModes.REQUEST_EDIT ? 'EDIT_USER' : 'VIEW_USER'),
        mode: mode
      })
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState: initialState
    });
  }

  onRowPerPagesSelected(value: string) {
    this.currentPage = 1;
    this.listRequest.size = value;
    this.listRequest.page = 0;
    this.dispatchUserListAction(this.listRequest);
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.listRequest.page = (event.page - 1).toString();
    this.dispatchUserListAction(this.listRequest);
  }

  dispatchUserListAction(userListRequest) {
    this.store.dispatch(new UsersListRequestAction(userListRequest));
  }

  dispatchDeleteUserAction(userNo: string) {
    this.store.dispatch(
      new UsersDeleteAction({
        userNo
      })
    );
  }

  selectedStatus(event: any): void {
    this.listRequest.active = event;
    this.listRequest.page = 0;
    this.currentPage = 1;
    this.dispatchUserListAction(this.listRequest);
  }

  handleSearchText(): void {
    this.listRequest.searchCriteria = this.searchAndFilterForm.value.searchText;
    this.listRequest.page = 0;
    this.currentPage = 1;
    this.dispatchUserListAction(this.listRequest);
  }

  clearSearchText() {
    this.searchAndFilterForm.controls['searchText'].reset();
    this.listRequest.searchCriteria = null;
    this.listRequest.page = 0;
    this.currentPage = 1;
    this.dispatchUserListAction(this.listRequest);
  }

  onClickedOutside(e) {
    if (
      e.target &&
      (e.target.classList.contains('is-highlighted') ||
        e.target.classList.contains('ng-option') ||
        e.target.classList.contains('ng-option-label') ||
        e.target.classList.contains('ng-value-icon'))
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
  }

  selectedRoles(): void {
    const roles = this.searchAndFilterForm.get('selectedRoles').value;
    if (!roles.length) {
      this.isShowSearchTag = false;
    }
  }

  handleRemoveRole(roleIndex: any) {
    const roles = this.searchAndFilterForm.get('selectedRoles').value;
    roles.splice(roleIndex, 1);
    this.searchAndFilterForm.get('selectedRoles').setValue(roles);
    if (!roles.length) {
      this.isShowSearchTag = false;
    }
    this.listRequest.roles = roles.join();
    this.listRequest.page = 0;
    this.currentPage = 1;
    this.dispatchUserListAction(this.listRequest);
  }

  handleAdvanceFilter() {
    const roles = this.searchAndFilterForm.get('selectedRoles').value;

    this.isShowSearchTag = !!roles.length;
    this.isShowAdvanceSearch = false;
    this.listRequest.roles = roles.length ? roles.join() : null;
    this.listRequest.page = 0;
    this.currentPage = 1;
    this.dispatchUserListAction(this.listRequest);
  }

  clearAllRoles() {
    this.searchAndFilterForm.get('selectedRoles').reset();
    this.isShowSearchTag = false;
    this.listRequest.roles = null;
    this.listRequest.page = 0;
    this.currentPage = 1;
    this.dispatchUserListAction(this.listRequest);
  }

  handleDeleteUser(userNo: string, fullName: string) {
    const initialState: ConfirmModal = {
      title: this.translate.instant('DELETE'),
      okText: this.translate.instant('YES_DELETE'),
      cancelText: this.translate.instant('CANCEL'),
      message: this.translate.instant('ARE_YOU_SURE_DELETE', { fullName })
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.dispatchDeleteUserAction(userNo);
      }
    });
  }

  isOwner(role) {
    return isOwner(role);
  }

  get pageMode() {
    return TDPageModes;
  }
}
