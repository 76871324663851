import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrderPolicyComponent } from '../../../shared/components/td-store/order-policy/order-policy.component';
import { StoreProfileComponent } from '../../../shared/components/td-store/store-profile/store-profile.component';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { TDPageModes } from '../../../shared/enum/store.enum';
import { UserSelectValueEnum } from '../../../shared/enum/user-select-value.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ChildComponent } from '../../../shared/layouts/modals/child-item/child-component';
import { BaseModalComponent } from '../../../shared/models/base-modal.component.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { Receipt, TdStore } from '../../../shared/models/td-store.model';
import { ResetTdStoreState, TdStoreEditAction, TdStoreGetAction } from '../../../shared/store/actions/td-store.action';
import {
  UserSelectValueRequestAction,
  UserSelectValueResetAction
} from '../../../shared/store/actions/user-select-value.actions';
import { selectTdStoreResult } from '../../../shared/store/selectors/td-store.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { BaseTdStoreState } from '../../../shared/store/state/td-store.state';

@Component({
  selector: 'app-store-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit, OnDestroy, ChildComponent {
  @ViewChild('storeProfile', { static: true }) storeProfile: StoreProfileComponent;
  @ViewChild('orderPolicy', { static: true }) orderPolicy: OrderPolicyComponent;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { id: string; title: string; mode: TDPageModes };

  public editStoreForm: FormGroup;
  public submitted: boolean;
  public subscriptions: Subscription;
  public tdStore$: Observable<BaseTdStoreState>;

  private tmpElementValue: string[];
  private localStore: Observable<any>;

  constructor(
    private readonly store: Store<AppStates>,
    private readonly formBuilder: FormBuilder,
    private readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly logger: NGXLogger
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initState();
    this.initControl();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetTdStoreState());
    this.store.dispatch(new UserSelectValueResetAction());
    this.subscriptions.unsubscribe();
  }

  get pageMode() {
    return TDPageModes;
  }

  initState() {
    this.store.dispatch(
      new UserSelectValueRequestAction({
        type: [UserSelectValueEnum.ALL]
      })
    );
    this.store.dispatch(new TdStoreGetAction(this.data.id));
    this.tdStore$ = this.localStore.pipe(select(selectTdStoreResult));

    this.subscriptions = this.tdStore$.pipe(map(action => action.result)).subscribe(
      next => {
        if (next.response && this.submitted) {
          this.onSuccess(next);
        } else if (next.errorResponse) {
          this.onError(next.errorResponse);
        } else {
          this.logger.debug(next);
        }
      },
      error => this.onError(error),
      () => this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null })
    );
  }

  initControl() {
    this.editStoreForm = this.formBuilder.group({
      id: ['', []],
      version: ['', []],
      receiptHeader: ['', [Validators.maxLength(50)]],
      receiptAddress: ['', [Validators.required, Validators.maxLength(250)]],
      receiptTaxId: ['', [Validators.maxLength(13)]],
      receiptBranchNo: ['', [Validators.maxLength(5)]],
      receiptFooter: ['', [Validators.maxLength(25)]]
    });

    this.subscriptions = this.tdStore$
      .pipe(
        map(val => val.result.response),
        filter(val => val !== null && !this.submitted)
      )
      .subscribe(resp => {
        if (resp.receipt) {
          const receipt: Receipt = resp.receipt;

          this.editStoreForm.controls['receiptAddress'].setValue(receipt.address || '');
          this.editStoreForm.controls['receiptHeader'].setValue(receipt.header || '');
          this.editStoreForm.controls['receiptTaxId'].setValue(receipt.taxId || '');
          this.editStoreForm.controls['receiptBranchNo'].setValue(receipt.branchNo || '00000');
          this.editStoreForm.controls['receiptFooter'].setValue(receipt.footer || '');
        }

        if (this.data.mode === TDPageModes.REQUEST_VIEW) {
          this.editStoreForm.disable();
        }

        this.tmpElementValue = new Array<string>();
        this.preventBlock(this.editStoreForm.controls, 'receiptTaxId', /^\d*$/);
        this.preventBlock(this.editStoreForm.controls, 'receiptBranchNo', /^\d*$/);
      });
  }

  onSubmit() {
    let req: TdStore;

    this.submitted = true;

    if (this.editStoreForm.invalid) {
      return;
    }

    req = new TdStore(this.editStoreForm.getRawValue());

    req.storeProfile = this.editStoreForm.value.storeProfile;

    req.orderSchedule = this.editStoreForm.value.orderSchedule;

    req.receipt = {
      header: this.editStoreForm.value.receiptHeader,
      address: this.editStoreForm.value.receiptAddress,
      taxId: this.editStoreForm.value.receiptTaxId,
      branchNo: this.editStoreForm.value.receiptBranchNo,
      footer: this.editStoreForm.value.receiptFooter
    } as Receipt;

    this.store.dispatch(new TdStoreEditAction(req));
  }

  onCancel() {
    if (this.editStoreForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState: initialState,
        notificationType: NotificationTypeEnum.CONFIRM,
        result: null
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null });
    }
  }

  get f(): {
    [key: string]: AbstractControl;
  } {
    return this.editStoreForm.controls;
  }

  private preventBlock(controls: any, key: string, regexp: RegExp = /^.*$/) {
    if (!this.tmpElementValue[key]) {
      this.tmpElementValue[key] = '';
    }

    controls[key].valueChanges.pipe(filter(val => val !== this.tmpElementValue[key])).forEach(val => {
      if (regexp.test(val)) {
        this.tmpElementValue[key] = val;
      }
      controls[key].setValue(this.tmpElementValue[key]);
    });
  }

  private onSuccess(resp: any) {
    const initialState: BaseModalComponent = {
      title: this.translate.instant('SUCCESS'),
      message: this.translate.instant('STORE_UPDATED'),
      routerLink: 'stores'
    };

    this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: resp });
    });
    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  private onError(resp: any) {
    if (resp.translateKey) {
      const initialState: BaseModalComponent = {
        title: this.translate.instant('FAILED'),
        message: this.translate.instant(resp.translateKey)
      };

      this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.LOGGING, result: resp });
      });
      this.modalService.show(AlertModalComponent, { initialState });
    }
  }

  public onTriggerEdit() {
    this.data.mode = TDPageModes.REQUEST_EDIT;
    this.editStoreForm.enable();
    this.storeProfile.toggleEditStoreProfile();
    this.orderPolicy.toggleEditOrderPolicy();
  }
}
