import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TdStore } from '../models/td-store.model';
import { UrlParams } from '../models/url-params.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class StoresService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.envService = this.env.services.stores;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public addStore<T>(req: TdStore): Observable<T> {
    return this.http.post<T>(this.getUrl(), req, { headers: this.loaderHeaders() });
  }

  public editStore<T>(req: TdStore): Observable<T> {
    return this.http.put<T>(this.getUrl(), req, { headers: this.loaderHeaders() });
  }

  public getStore<T>(storeNo: string): Observable<T> {
    return this.http.get<T>(
      this.getFullUrl(this.envService.view, {
        storeNo: storeNo
      }),
      { headers: this.headers }
    );
  }

  public getStores<T>(urlParams: UrlParams): Observable<T> {
    const params = this.getParams(urlParams);

    return this.http.get<T>(this.getUrl(), {
      headers: this.headers,
      params: params
    });
  }
}
