import { ErrorResponse } from '../../models/error.model';
import { UsersAction, UsersActionTypes } from '../actions/users.action';
import { DeleteUserState, initialDeleteUsersState } from '../state/delete-user.state';

export function deleteUserReducer(state = initialDeleteUsersState, action: UsersAction): DeleteUserState {
  switch (action.type) {
    case UsersActionTypes.USERS_DELETE_SUCCESS: {
      return {
        ...state,
        result: {
          ...state.result,
          response: action.payload,
          errorResponse: new ErrorResponse()
        }
      };
    }
    case UsersActionTypes.USERS_DELETE_FAILED: {
      return {
        ...state,
        result: {
          ...state.result,
          response: null,
          errorResponse: action.payload
        }
      };
    }
    case UsersActionTypes.USERS_DELETE_RESET_STATUS: {
      return Object.assign(state, initialDeleteUsersState);
    }
    default: {
      return state;
    }
  }
}
