import { BaseApiResponse } from '../../models/base-api-response';
import { ErrorResponse } from '../../models/error.model';
import { BaseState } from './base.state';

export interface DeleteUserState extends BaseState {
  result: {
    response: BaseApiResponse | null;
    errorResponse: ErrorResponse | null;
  };
}

export const initialDeleteUsersState: DeleteUserState = {
  result: {
    response: null,
    errorResponse: new ErrorResponse()
  }
};
