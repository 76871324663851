import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { UserInfoState } from '../state/user-info.state';

const selectUserInfo = (state: AppStates) => state.userInfo;

export const selectUserInfoResult = createSelector(
  selectUserInfo,
  (state: UserInfoState) => state
);

export const selectDisplayNameResult = createSelector(
  selectUserInfo,
  (state: UserInfoState) => state.lastName + ' ' + state.firstName
);

export const selectAuthoritiesResult = createSelector(
  selectUserInfo,
  (state: UserInfoState) => {
    return state && state.authorities ? state.authorities : [];
  }
);
