import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as JwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { filter } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { ClientIdTypeEnum } from '../enum/client-id.enum';
import { AuthUser } from '../models/auth.model';
import { TenantInformation } from '../models/tenant-information.model';
import { UserResponse } from '../models/user.model';
import { MenuRequestedAction } from '../store/actions/menu.action';
import { ClientIdAction, TenantInformationAction, UserInformationAction } from '../store/actions/user-info.action';
import { AppStates } from '../store/state/app.states';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(
    private readonly logger: NGXLogger,
    private http: HttpClient,
    private cookieService: CookieService,
    private readonly store: Store<AppStates>,
    private readonly usersService: UsersService
  ) {}

  public load() {
    return () =>
      new Promise(resolve => {
        const accessToken = this.cookieService.get(env.cookies.accessToken);

        if (accessToken) {
          const userInfo: AuthUser = JwtDecode(accessToken);

          this.dispatchInfo(userInfo);
          this.getUserTenant(userInfo.user_info.userNo);
          this.store.dispatch(new ClientIdAction(userInfo.client_id));
        } else {
          this.store.dispatch(new ClientIdAction(env.defaultClientId as ClientIdTypeEnum));
        }

        resolve(true);
      });
  }

  private dispatchInfo(userInfo: AuthUser) {
    userInfo.user_info.authorities = userInfo.authorities;

    this.store.dispatch(new UserInformationAction(userInfo.user_info));
    this.store.dispatch(new TenantInformationAction(userInfo.tenant_info));

    this.store.dispatch(new MenuRequestedAction(userInfo.authorities));
  }

  private getUserTenant(userNo: string) {
    if (!userNo) {
      return;
    }
    this.usersService
      .getUser(userNo)
      .pipe(filter(value => Boolean(value)))
      .subscribe((user: UserResponse) => {
        const tenantInfo: TenantInformation = {
          merchant: user.tenant.merchant,
          sites: user.tenant.sites,
          groups: user.tenant.storeGroups,
          stores: user.tenant.stores
        };
        this.store.dispatch(new TenantInformationAction(tenantInfo));
      });
  }
}
