import { Action } from '@ngrx/store';
import { StatusEnum } from '../../enum/status.enum';
import { ErrorResponse } from '../../models/error.model';
import { UserListRequest, UserListResponse } from '../../models/user-list.model';

export enum UsersActionTypes {
  USERS_REQUEST = '[Users] User List Request',
  USERS_RESPONSE = '[Users] User List Response',
  USERS_ERROR = '[Users] User List Error'
}

export class UsersListRequestAction implements Action {
  readonly type = UsersActionTypes.USERS_REQUEST;
  constructor(public payload: UserListRequest) {}
}

export class UsersListResponseAction implements Action {
  readonly type = UsersActionTypes.USERS_RESPONSE;
  constructor(public payload: UserListResponse) {
    payload.content.forEach(content => {
      content.status = content.active ? StatusEnum.ACTIVE : StatusEnum.INACTIVE;
    });
  }
}

export class UsersListErrorAction implements Action {
  readonly type = UsersActionTypes.USERS_ERROR;
  constructor(public payload: ErrorResponse) {}
}

export type UserListActions = UsersListRequestAction | UsersListResponseAction | UsersListErrorAction;
