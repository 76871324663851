/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-size-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../child-item/child-directive";
import * as i3 from "./custom-size-modal.component";
import * as i4 from "ngx-bootstrap/modal";
import * as i5 from "@angular/router";
import * as i6 from "@ngx-translate/core";
import * as i7 from "ngx-logger";
var styles_CustomSizeModalComponent = [i0.styles];
var RenderType_CustomSizeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomSizeModalComponent, data: {} });
export { RenderType_CustomSizeModalComponent as RenderType_CustomSizeModalComponent };
function View_CustomSizeModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_CustomSizeModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { childHost: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomSizeModalComponent_1)), i1.ɵdid(2, 16384, [[1, 4]], 0, i2.ChildDirective, [i1.ViewContainerRef], null, null)], null, null); }
export function View_CustomSizeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-size-modal", [], null, null, null, View_CustomSizeModalComponent_0, RenderType_CustomSizeModalComponent)), i1.ɵdid(1, 245760, null, 0, i3.CustomSizeModalComponent, [i4.BsModalRef, i4.BsModalService, i5.Router, i1.ComponentFactoryResolver, i6.TranslateService, i7.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomSizeModalComponentNgFactory = i1.ɵccf("app-custom-size-modal", i3.CustomSizeModalComponent, View_CustomSizeModalComponent_Host_0, {}, {}, []);
export { CustomSizeModalComponentNgFactory as CustomSizeModalComponentNgFactory };
