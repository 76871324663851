import { Component, ComponentFactoryResolver, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { AlertModalComponent } from '../..';
import { ModalButtonResponseEnum } from '../../../enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../enum/notification-type.enum';
import { AlertModal } from '../../../models/alert-modal.mode';
import { BaseModalComponent } from '../../../models/base-modal.component.model';
import { ConfirmModal } from '../../../models/confirm-modal.mode';
import { NotificationEmit } from '../../../models/notification-emit.model';
import { ChildComponent } from '../child-item/child-component';
import { ChildDirective } from '../child-item/child-directive';
import { ChildItem } from '../child-item/child-item';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-full-modal',
  templateUrl: './full-modal.component.html',
  styleUrls: ['./full-modal.component.scss']
})
export class FullModalComponent implements OnInit, OnDestroy, BaseModalComponent {
  @ViewChild(ChildDirective, { static: true }) childHost: ChildDirective;

  public title: string;
  public routerLink?: string;
  public childItem: ChildItem;
  public action: Subject<ModalButtonResponseEnum>;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly modalService: BsModalService,
    private readonly router: Router,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly translate: TranslateService,
    private readonly logger: NGXLogger
  ) {}

  ngOnDestroy(): void {}

  ngOnInit() {
    this.action = new EventEmitter();
    this.loadComponent();
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.childItem.component);
    const viewContainerRef = this.childHost.viewContainerRef;
    viewContainerRef.clear();

    const instance = viewContainerRef.createComponent(componentFactory).instance as ChildComponent;

    instance.data = this.childItem.data;
    instance.notifyParent = this.childItem.notifyParent;
    instance.notifyParent.pipe(untilComponentDestroyed(this)).subscribe((emit: NotificationEmit) => {
      switch (emit.notificationType) {
        case NotificationTypeEnum.FORCE_CLOSE:
          this.bsModalRef.hide();
          break;
        case NotificationTypeEnum.CONFIRM:
          this.confirmModal(emit.initialState);
          break;
        case NotificationTypeEnum.ALERT:
          this.alertModal(emit.initialState);
          break;
        case NotificationTypeEnum.LOGGING:
          this.logger.debug(emit.result);
          break;
        default:
          break;
      }
    });
  }

  alertModal(initialState: AlertModal) {
    this.modalService.show(AlertModalComponent, { initialState });
  }

  confirmModal(initialState?: ConfirmModal) {
    let confirmModalRef: BsModalRef;
    let closeMode: ModalButtonResponseEnum = ModalButtonResponseEnum.CANCEL;

    if (initialState && initialState.closeMode) {
      closeMode = initialState.closeMode;
    }

    confirmModalRef = this.modalService.show(ConfirmModalComponent, { initialState });
    confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === closeMode) {
        this.bsModalRef.hide();
      }
    });
  }
}
