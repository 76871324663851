import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { TreeviewItem } from 'ngx-treeview';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TdStoresTreeViewActionTypes, TdStoresTreeViewResponseAction } from '../actions/td-stores-tree-view.action';
var TdStoresTreeViewEffects = /** @class */ (function () {
    function TdStoresTreeViewEffects(actions, router, logger, translate) {
        var _this = this;
        this.actions = actions;
        this.router = router;
        this.logger = logger;
        this.translate = translate;
        this.create$ = this.actions.pipe(ofType(TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_REQUEST), map(function (action) {
            _this.logger.debug("@Effect " + TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_REQUEST);
            var rootText = action.payload ? 'ACCESS_TO_ALL_STORES' : 'NO_RESULT_FOUND';
            var treeViewItemRoot = {
                text: _this.translate.instant(rootText),
                value: ''
            };
            if (action.payload) {
                var treeViewItem = action.payload.map(function (value) {
                    var item = new TreeviewItem({
                        text: value.code + "-" + value.name,
                        value: value.no
                    });
                    item.setCheckedRecursive(false);
                    return item;
                });
                treeViewItemRoot = tslib_1.__assign({}, treeViewItemRoot, {
                    children: treeViewItem
                });
            }
            return new TdStoresTreeViewResponseAction(new Array(new TreeviewItem(treeViewItemRoot)));
        }));
        this.checkedCreate$ = this.actions.pipe(ofType(TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_CHECKED_REQUEST), map(function (action) {
            _this.logger.debug("@Effect " + TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_CHECKED_REQUEST);
            var rootText = action.payload ? 'ACCESS_TO_ALL_STORES' : 'NO_RESULT_FOUND';
            var treeViewItemRoot = {
                text: _this.translate.instant(rootText),
                value: '',
                checked: false
            };
            if (action.payload) {
                var treeViewItem = action.payload.merchant.map(function (value) {
                    return {
                        text: value.name,
                        value: value.no,
                        checked: action.payload.stores.includes(value.no)
                    };
                });
                treeViewItemRoot = tslib_1.__assign({}, treeViewItemRoot, {
                    children: treeViewItem
                });
            }
            return new TdStoresTreeViewResponseAction(new Array(new TreeviewItem(treeViewItemRoot)));
        }));
    }
    // TdStoresTreeViewSelectedRequestAction
    TdStoresTreeViewEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], TdStoresTreeViewEffects.prototype, "create$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], TdStoresTreeViewEffects.prototype, "checkedCreate$", void 0);
    return TdStoresTreeViewEffects;
}());
export { TdStoresTreeViewEffects };
