import { OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { tap } from 'rxjs/operators';
import { ClientIdTypeEnum } from '../enum/client-id.enum';
import { selectClientIdResult } from '../store/selectors/client-id.selector';
import * as i0 from "@angular/core";
import * as i1 from "ngx-logger";
import * as i2 from "@ngrx/store";
var PosService = /** @class */ (function () {
    function PosService(logger, store) {
        var _this = this;
        this.logger = logger;
        this.store = store;
        this.subscriptions = store
            .pipe(select(selectClientIdResult), tap(function (clientId) { return (_this.clientId = clientId); }))
            .subscribe();
    }
    PosService.prototype.orderComplete = function (poNumber) {
        if (this.isNotPOS) {
            return;
        }
        try {
            this.logger.info('orderComplete', poNumber);
            // @ts-ignore
            Android.orderComplete(poNumber);
        }
        catch (e) {
            this.logger.error('orderComplete', e);
        }
    };
    PosService.prototype.goto = function (pageName) {
        if (this.isNotPOS) {
            return;
        }
        try {
            this.logger.info('goto', pageName);
            // @ts-ignore
            Android.goto(pageName);
        }
        catch (e) {
            this.logger.error('goto', e);
        }
    };
    Object.defineProperty(PosService.prototype, "isNotPOS", {
        get: function () {
            if (this.clientId !== ClientIdTypeEnum.POS_APPL) {
                this.logger.info('ClientID is not POS_APPL');
            }
            return this.clientId !== ClientIdTypeEnum.POS_APPL;
        },
        enumerable: true,
        configurable: true
    });
    PosService.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    PosService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PosService_Factory() { return new PosService(i0.ɵɵinject(i1.NGXLogger), i0.ɵɵinject(i2.Store)); }, token: PosService, providedIn: "root" });
    return PosService;
}());
export { PosService };
