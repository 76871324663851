<div id="forgot-username-form" class="flex-item d-flex justify-content-center">
  <form
    [formGroup]="forgotUsernameForm"
    (ngSubmit)="onSubmit()"
    class="right-section border border-top-0 border-left-0 shadow-lg col-sm-10 d-flex"
  >
    <div class="header">
      <h5 class="text-td-red text-center mb-4 d-none d-md-block text-center">
        {{ 'ENTER_MOBILE_NUMBER' | translate }}
      </h5>

      <div class="mobile-header d-md-none">
        <h5 class="text-center">
          {{ 'FORGOT_USERNAME' | translate }}
        </h5>
        <p>{{ 'PLEASE_ENTER_YOUR_MOBILE_NUMBER' | translate }}</p>
      </div>
    </div>
    <div *ngIf="(forgotUsernameResult$ | async).result.errorResponse.translateKey">
      <div class="alert alert-danger alert-icon" role="alert">
        <span class="fa nicon icon-xcross mr-3"></span>
        <span
          *ngIf="(forgotUsernameResult$ | async).result.errorResponse.code === '00000'; else defaultMessage"
          translate="FORGOT_USERNAME_FAILED"
          [translateParams]="{
            mobileNumber: (forgotUsernameResult$ | async).result.mobileNumber | mask: '000-000-0000'
          }"
        >
        </span>
        <ng-template #defaultMessage>
          <span [innerHTML]="(forgotUsernameResult$ | async).result.errorResponse.translateKey | translate"></span>
        </ng-template>
      </div>
    </div>
    <div class="right-section-control flex-item">
      <div class="form-group">
        <label for="mobileNumber">{{ 'MOBILE_NUMBER' | translate }}</label>
        <input
          type="text"
          formControlName="mobileNumber"
          class="form-control"
          id="mobileNumber"
          name="mobileNumber"
          mask="000-000-0000"
          [validation]="true"
          placeholder="{{ 'ENTER_MOBILE_NUMBER' | translate }}"
          [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }"
        />
        <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">
          <div *ngIf="f.mobileNumber.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <div *ngIf="f.mobileNumber.errors['Mask error']">
            {{ 'ERRORS.INVALID_PHONE' | translate }}
          </div>
        </div>
      </div>

      <div class="col-12 p-0">
        <button type="submit" id="btn-forgot-username" class="btn btn-primary btn-block" (click)="onSubmit()">
          {{ 'CONTINUE' | translate }}
        </button>
      </div>
    </div>
    <div class="d-md-none mt-3">
      <a href="#" routerLink="/login">&lt; {{ 'CANCEL' | translate }}</a>
    </div>
  </form>
</div>
