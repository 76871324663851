import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPascalCase]'
})
export class PascalCaseDirective {
  constructor(private el: ElementRef) {}

  @HostListener('blur') onBlur() {
    if (this.el.nativeElement.value) {
      this.el.nativeElement.value = this.el.nativeElement.value
        .toLowerCase()
        .replace(/^[a-z]|\s[a-z]/g, message => message.toUpperCase());
    }
  }
}
