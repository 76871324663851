<div class="left-section">
  <h2 class="forgot-section-header">{{ 'TD_STORE_PORTAL' | translate }}</h2>
  <div>
    <div *ngIf="step === 'REQUEST_OTP'">
      <h4>
        {{ 'FORGOT_PASSWORD.FORGOT_PASSWORD' | translate }}
      </h4>
      <p>{{ 'FORGOT_PASSWORD.PLEASE_ENTER_YOUR_USERNAME' | translate }}</p>
    </div>
    <div *ngIf="step === 'ENTER_OTP'">
      <h4>
        {{ 'FORGOT_PASSWORD.FORGOT_PASSWORD' | translate }}
      </h4>
      <div>
        <span>{{ 'FORGOT_PASSWORD.REQUEST_ENTER_OTP_SMS' | translate }}</span>
      </div>
      <div class="mt-1 mb-4">
        <span
          >{{ 'FORGOT_PASSWORD.REQUEST_NOT_RECEIVE_OTP' | translate }}&nbsp;<a
            href="javascript:void(0);"
            (click)="resendOTP()"
            >{{ 'FORGOT_PASSWORD.REQUEST_RESEND_OTP' | translate }}</a
          >
        </span>
      </div>
    </div>
    <div *ngIf="step === 'CHANGE_PASSWORD'">
      <h4>
        {{ 'RESET_PASSWORD' | translate }}
      </h4>
      <p>{{ 'WELCOME_TO_THE_SYSTEM_FOR_MORE_SECURITY_PLEASE_SET_YOUR_NEW_PASSWORD' | translate }}</p>
    </div>

    <button class="btn btn-outline-primary" (click)="onCancel()">{{ 'CANCEL' | translate }}</button>
  </div>
</div>
