<div class="alert alert-secondary " [formGroup]="searchAndFilterForm" novalidate>
  <div class="row my-1">
    <div class="col-sm-5 col-lg-5 px-1">
      <div class="input-group ">
        <input
          formControlName="searchText"
          type="text"
          class="form-control border-secondary"
          (keyup.enter)="handleSearchText()"
          [maxLength]="50"
          placeholder="{{ 'ENTER_PRODUCT_NAME_OR_BARCODE' | translate }}"
        />
        <div class="input-group-append">
          <span
            class="clear-text fa fa-times btn-outline-secondary"
            [ngClass]="{ 'd-none': !f.searchText.value }"
            (click)="clearSearchText()"
          ></span>
          <button class="btn btn-outline-secondary" type="button" (click)="handleSearchText()">
            <em class="fa fa-search"></em>
          </button>
        </div>
      </div>
    </div>
    <div class="d-sm-none d-xl-block col-xl-1"></div>
    <div class="col-sm-3 col-xl-3 pt-2 mb-0 text-center text-sm-right">
      <span class="align-middle ">
        <em class="fa fa-shopping-basket"></em>
        <span class="badge badge-pill badge-danger">{{ (orderSummary$ | async).result.response?.numberOfItems }}</span>
      </span>
      <span class="mb-0">{{ 'BASKET' | translate }}</span>
    </div>
    <div class="col-sm-4 col-xl-3 text-center">
      <h5 class="align-middle pt-2 mb-0 text-danger">
        {{ 'TOTAL_AMOUNT' | translate }}
        {{ (orderSummary$ | async).result.response?.amount.amount | number: '1.2-2' }}
        {{ (orderSummary$ | async).result.response?.amount.currency | translate }}
      </h5>
    </div>
  </div>
</div>
