import { ErrorResponse } from '../../models/error.model';
import { UserResponse } from '../../models/user.model';
import { BaseState } from './base.state';

export interface UsersState extends BaseState {
  result: {
    response: UserResponse | null;
    errorResponse: ErrorResponse | null;
  };
}

export const initialUsersState: UsersState = {
  result: {
    response: null,
    errorResponse: null
  }
};
