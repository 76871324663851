import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models/error.model';
import { Role } from '../../models/role.model';

export enum RolesActionTypes {
  ROLES = '[Roles] Roles',
  ROLES_SUCCESS = '[Roles] Roles Success',
  ROLES_FAILURE = '[Roles] Roles Failure'
}

export class RolesAction implements Action {
  readonly type = RolesActionTypes.ROLES;

  constructor(public payload?: string) {}
}

export class RolesSuccessAction implements Action {
  readonly type = RolesActionTypes.ROLES_SUCCESS;

  constructor(public payload: Role[]) {}
}

export class RolesFailureAction implements Action {
  readonly type = RolesActionTypes.ROLES_FAILURE;

  constructor(public payload: ErrorResponse) {}
}

export type All = RolesAction | RolesSuccessAction | RolesFailureAction;
