/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tree-menu.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/angular-tree-component/dist/components/tree.component.ngfactory";
import * as i3 from "angular-tree-component/dist/models/tree.model";
import * as i4 from "angular-tree-component/dist/components/tree.component";
import * as i5 from "angular-tree-component/dist/models/tree-dragged-element.model";
import * as i6 from "@angular/common";
import * as i7 from "./tree-menu.component";
import * as i8 from "@ngrx/store";
import * as i9 from "@ngx-translate/core";
var styles_TreeMenuComponent = [i0.styles];
var RenderType_TreeMenuComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TreeMenuComponent, data: {} });
export { RenderType_TreeMenuComponent as RenderType_TreeMenuComponent };
function View_TreeMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tree-root", [], null, [[null, "initialized"], [null, "activate"], [null, "deactivate"], ["body", "keydown"], ["body", "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("body:mousedown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMousedown($event) !== false);
        ad = (pd_1 && ad);
    } if (("initialized" === en)) {
        var pd_2 = (_co.treeInit($event) !== false);
        ad = (pd_2 && ad);
    } if (("activate" === en)) {
        var pd_3 = (_co.onActivate($event) !== false);
        ad = (pd_3 && ad);
    } if (("deactivate" === en)) {
        var pd_4 = (_co.onDeactivate($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_TreeComponent_0, i2.RenderType_TreeComponent)), i1.ɵprd(131584, null, i3.TreeModel, i3.TreeModel, []), i1.ɵdid(2, 573440, [[1, 4], ["th_tree", 4]], 4, i4.TreeComponent, [i3.TreeModel, i5.TreeDraggedElement], { nodes: [0, "nodes"], options: [1, "options"] }, { activate: "activate", deactivate: "deactivate", initialized: "initialized" }), i1.ɵqud(603979776, 3, { loadingTemplate: 0 }), i1.ɵqud(603979776, 4, { treeNodeTemplate: 0 }), i1.ɵqud(603979776, 5, { treeNodeWrapperTemplate: 0 }), i1.ɵqud(603979776, 6, { treeNodeFullTemplate: 0 }), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 7).transform(_co.categoryTree$)); var currVal_1 = _co.options; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TreeMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tree-root", [], null, [[null, "initialized"], [null, "activate"], [null, "deactivate"], ["body", "keydown"], ["body", "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("body:mousedown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMousedown($event) !== false);
        ad = (pd_1 && ad);
    } if (("initialized" === en)) {
        var pd_2 = (_co.treeInit($event) !== false);
        ad = (pd_2 && ad);
    } if (("activate" === en)) {
        var pd_3 = (_co.onActivate($event) !== false);
        ad = (pd_3 && ad);
    } if (("deactivate" === en)) {
        var pd_4 = (_co.onDeactivate($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_TreeComponent_0, i2.RenderType_TreeComponent)), i1.ɵprd(131584, null, i3.TreeModel, i3.TreeModel, []), i1.ɵdid(2, 573440, [[2, 4], ["en_tree", 4]], 4, i4.TreeComponent, [i3.TreeModel, i5.TreeDraggedElement], { nodes: [0, "nodes"], options: [1, "options"] }, { activate: "activate", deactivate: "deactivate", initialized: "initialized" }), i1.ɵqud(603979776, 7, { loadingTemplate: 0 }), i1.ɵqud(603979776, 8, { treeNodeTemplate: 0 }), i1.ɵqud(603979776, 9, { treeNodeWrapperTemplate: 0 }), i1.ɵqud(603979776, 10, { treeNodeFullTemplate: 0 }), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 7).transform(_co.categoryTree$)); var currVal_1 = _co.options; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_TreeMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { th_tree: 0 }), i1.ɵqud(402653184, 2, { en_tree: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeMenuComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["en_US", 2]], null, 0, null, View_TreeMenuComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.translate.currentLang === "th"); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_TreeMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tree-menu", [], null, null, null, View_TreeMenuComponent_0, RenderType_TreeMenuComponent)), i1.ɵdid(1, 245760, null, 0, i7.TreeMenuComponent, [i8.Store, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TreeMenuComponentNgFactory = i1.ɵccf("app-tree-menu", i7.TreeMenuComponent, View_TreeMenuComponent_Host_0, {}, { catCode: "catCode" }, []);
export { TreeMenuComponentNgFactory as TreeMenuComponentNgFactory };
