import { AuthResponse } from '../../models/auth.model';
import { ErrorResponse } from '../../models/error.model';
import { BaseState } from './base.state';

export interface BaseAuthState extends BaseState {
  result: {
    isAuthenticated: boolean;
    response: AuthResponse | null;
    errorResponse: ErrorResponse | null;
  };
}

export const initialAuthState: BaseAuthState = {
  result: {
    isAuthenticated: false,
    response: null,
    errorResponse: new ErrorResponse()
  }
};
