import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthGuardService } from '../../services';
import { MenuActionTypes, MenuLoadedAction, MenuRequestedAction } from '../actions/menu.action';

@Injectable()
export class MenuEffects {
  constructor(
    private actions: Actions,
    private readonly logger: NGXLogger,
    private authGuardService: AuthGuardService
  ) {}

  @Effect()
  MenuRequested: Observable<Action> = this.actions.pipe(
    ofType<MenuRequestedAction>(MenuActionTypes.MenuRequested),
    map(action => {
      this.logger.debug('@Effect MenuRequestAction: ' + JSON.stringify(action.payload));
      return new MenuLoadedAction(this.authGuardService.getMenuByPermission(action.payload));
    })
  );
}
