import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddItemRequest, DeleteCartItemRequest, DeleteInvalidItemsRequest } from '../models/cart.model';
import { SubmitOrderRequest } from '../models/orders.model';
import { BaseService } from './base.service';

@Injectable()
export class CartsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.envService = this.env.services.carts;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public orderSummary<T>(storeNo: string): Observable<T> {
    const url = this.getFullUrl(this.envService.summary, {
      storeNo: storeNo
    });

    return this.http.get<T>(url, { headers: this.headers });
  }

  public getCart<T>(cartId: string): Observable<T> {
    const url = this.getFullUrl(this.envService.checkCartDetails, {
      cartId: cartId
    });

    return this.http.put<T>(url, null, { headers: this.headers });
  }

  public createCart<T>(storeNo: string): Observable<T> {
    const url = this.getFullUrl(this.envService.createCart, {
      storeNo: storeNo
    });

    return this.http.post<T>(url, null, { headers: this.headers });
  }

  public addItem<T>(addItemRequest: AddItemRequest): Observable<T> {
    const url = this.getFullUrl(this.envService.addItem, {
      cartId: addItemRequest.cartId
    });

    return this.http.put<T>(
      url,
      {
        barcode: addItemRequest.barcode,
        qty: addItemRequest.qty
      },
      { headers: this.headers }
    );
  }

  public updateItem<T>(addItemRequest: AddItemRequest): Observable<T> {
    const url = this.getFullUrl(this.envService.updateItem, {
      cartId: addItemRequest.cartId
    });

    return this.http.put<T>(
      url,
      {
        barcode: addItemRequest.barcode,
        qty: addItemRequest.qty
      },
      { headers: this.headers }
    );
  }

  public deleteItem<T>(deleteCartItemRequest: DeleteCartItemRequest): Observable<T> {
    const url = this.getFullUrl(this.envService.deleteCartItem, {
      cartId: deleteCartItemRequest.cartId,
      barcode: deleteCartItemRequest.barcode
    });

    return this.http.put<T>(url, null, { headers: this.headers });
  }

  public deleteInvalidItems<T>(deleteInvalidItemsRequest: DeleteInvalidItemsRequest): Observable<T> {
    const url = this.getFullUrl(this.envService.deleteInvalidItems, {
      cartId: deleteInvalidItemsRequest.cartId
    });

    return this.http.put<T>(url, null, { headers: this.headers });
  }

  // TODO Remove if Backend decrypt token themselves
  // public submitOrder<T>(submitOrder: SubmitOrderRequest, authUserInfo: UserInfoState): Observable<T> {
  public submitOrder<T>(submitOrder: SubmitOrderRequest): Observable<T> {
    const url = this.env.serverUrl + this.env.services.orders.url;

    return this.http.post<T>(
      url,
      {
        cart: submitOrder.cart,
        additionalNote: submitOrder.additionalNote
      },
      { headers: this.headers }
    );
  }
}
