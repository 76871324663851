import { ErrorResponse } from '../../models/error.model';
import { UsersAction, UsersActionTypes } from '../actions/users.action';
import { initialUsersState, UsersState } from '../state/users.state';

export function usersReducers(state = initialUsersState, action: UsersAction): UsersState {
  switch (action.type) {
    case UsersActionTypes.USERS_SUCCESS: {
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: new ErrorResponse()
        }
      };
    }
    case UsersActionTypes.USERS_FAILED: {
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    }
    case UsersActionTypes.RESET_USER_STATE: {
      return { ...state, ...initialUsersState };
    }
    default:
      return state;
  }
}
