import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services';
import { environment as env } from '../../../environments/environment';
import { ClientIdTypeEnum } from '../enum/client-id.enum';
import { AuthResponse } from '../models/auth.model';
import { LogInFailureAction, LogInSuccessAction } from '../store/actions/auth.actions';
import { ClientIdAction } from '../store/actions/user-info.action';
import { AppStates } from '../store/state/app.states';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private store: Store<AppStates>,
    private logger: NGXLogger
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    HttpEvent<any> | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any
  > {
    const refreshToken = this.cookieService.get(env.cookies.refreshToken);

    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError(error => {
        // We don't want to refresh token for some requests like oauth or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (
          // Add more urls to ignore here
          request.url.includes('oauth')
        ) {
          // We do another check to see if refresh token failed
          // In this case we need to delete all authentication cookies and redirect to login page
          this.cookieService.delete(env.cookies.accessToken);
          this.cookieService.delete(env.cookies.refreshToken);
          this.store.dispatch(new ClientIdAction(env.defaultClientId as ClientIdTypeEnum));
          this.store.dispatch(new LogInFailureAction(error));

          return throwError(error);
        }
        if (error && error instanceof HttpErrorResponse) {
          if (error.status && error.status === 401) {
            const wwwAuthHeader = error.headers.get('WWW-authenticate');

            if (wwwAuthHeader.includes('Jwt expired') || wwwAuthHeader === 'Bearer') {
              if (this.refreshTokenInProgress) {
                return this.refreshTokenSubject.pipe(
                  filter(Boolean),
                  take(1),
                  switchMap(() => next.handle(this.addAuthenticationToken(request)))
                );
              } else {
                this.refreshTokenInProgress = true;

                // Set the refreshTokenSubject to null so that subsequent API calls will wait
                // until the new token has been retrieved
                this.refreshTokenSubject.next(null);

                return this.authService.refreshToken(refreshToken).pipe(
                  switchMap((credential: AuthResponse) => {
                    // After got new refresh token
                    // Then, set new credential to local cookies
                    this.store.dispatch(new LogInSuccessAction(credential));

                    // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                    // for the next time the token needs to be refreshed
                    this.refreshTokenInProgress = false;
                    this.refreshTokenSubject.next(credential.access_token);

                    return next.handle(this.addAuthenticationToken(request));
                  }),
                  catchError(err => {
                    this.logger.debug('Refresh Token :: catchError', err);

                    // Only oauth path will delete cookies and navigate to login page
                    if (err.payload && err.payload.url.includes('oauth')) {
                      this.cookieService.delete(env.cookies.accessToken);
                      this.cookieService.delete(env.cookies.refreshToken);
                      this.store.dispatch(new ClientIdAction(env.defaultClientId as ClientIdTypeEnum));
                      this.store.dispatch(new LogInFailureAction(err));
                    }
                    this.refreshTokenInProgress = false;
                    return throwError(err);
                  })
                );
              }
            }
          }
        }

        return throwError(error);
      })
    );
  }

  addAuthenticationToken(request) {
    // Get access token from cookies
    const accessToken = this.cookieService.get(env.cookies.accessToken);
    // console.log('accessToken', accessToken);
    // Access for testing can be deleted later.

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // Add more urls to ignore here
    if (request.url.includes('oauth') || request.url.includes('assets')) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
}
