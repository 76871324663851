import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { VerificationService } from '../../services/verification.service';
import {
  MerchantVerificationErrorAction,
  MerchantVerificationRequestAction,
  MerchantVerificationResponseAction,
  VerificationActionTypes
} from '../actions/verification.actions';

@Injectable()
export class VerificationEffects {
  constructor(
    private actions: Actions,
    private readonly logger: NGXLogger,
    private verificationService: VerificationService
  ) {}

  @Effect()
  merchantVerification$: Observable<Action> = this.actions.pipe(
    ofType<MerchantVerificationRequestAction>(VerificationActionTypes.MERCHANT_VERIFICATION_REQUEST),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect MerchantVerificationAction: ' + this.stringify(payload));

      return this.verificationService.merchantVerification(payload).pipe(
        map(() => {
          return new MerchantVerificationResponseAction({ statusCode: '200', message: 'OK' });
        }),
        catchError(err => of(new MerchantVerificationErrorAction(err.error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
