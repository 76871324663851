import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { TDPageModes } from '../../../enum/store.enum';
import { MasterData } from '../../../models/master-data.model';
import { StoreProfile } from '../../../models/td-store.model';
import { selectTdStoreResult } from '../../../store/selectors/td-store.selector';
import { selectUserSelectValueGroupByType } from '../../../store/selectors/user-select-value.selectors';
import { AppStates } from '../../../store/state/app.states';
import { BaseTdStoreState } from '../../../store/state/td-store.state';

@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.scss']
})
export class StoreProfileComponent implements OnInit, OnDestroy {
  public tdStore$: Observable<BaseTdStoreState>;
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() mode: TDPageModes;

  constructor(private fb: FormBuilder, private readonly store: Store<AppStates>) {}

  private localStore: Observable<any>;
  public userSelectValue$: Observable<{ [key: string]: Array<MasterData> } | null>;

  ngOnDestroy(): void {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.tdStore$ = this.localStore.pipe(select(selectTdStoreResult));
    this.userSelectValue$ = this.localStore.pipe(select(selectUserSelectValueGroupByType));

    this.parentForm.addControl('storeProfile', this.createStoreProfile());
    this.setStoreProfileValue();

    if (this.mode === TDPageModes.REQUEST_VIEW) {
      this.storeProfile.disable();
    }
  }

  setStoreProfileValue() {
    this.tdStore$
      .pipe(
        map(response => response && response.result && response.result.response),
        filter(response => response !== null),
        take(1)
      )
      .subscribe(value => {
        if (value) {
          const storeProfilePatched = this.createStoreProfileValue(value.storeProfile);
          this.storeProfile.patchValue(storeProfilePatched);
        }
      });
  }

  createStoreProfileValue(store: StoreProfile) {
    return {
      ...store,
      phone: (store.countryCode || null) + (store.phone || null)
    };
  }

  createStoreProfile() {
    const regExpBranchNo = /^\d{5}$/;
    const initialNull = [{ value: null, disabled: false }];
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    const timezone = 'T0100';

    const storeForm: FormGroup = this.fb.group({
      contactName: initialNullRequired,
      email: [{ value: null, disabled: false }, [Validators.maxLength(100), Validators.email]],
      address: initialNullRequired,
      postCode: initialNullRequired,
      country: initialNullRequired,
      taxNo: initialNull,
      branchNo: [{ value: null, disabled: false }, [Validators.pattern(regExpBranchNo)]],
      state: initialNullRequired,
      region: initialNullRequired,
      timezone: [{ value: timezone, disabled: false }, Validators.required],
      phone: initialNullRequired
    });

    this.userSelectValue$.subscribe(value => {
      if (value && value.country && !this.storeProfile.controls.country.value) {
        const defaultCountryCode = value.country.find(data => data.name === 'Thailand').code;
        storeForm.controls.country.setValue(defaultCountryCode);
      }
    });

    return storeForm;
  }

  get storeProfile() {
    return this.parentForm.get('storeProfile') as FormGroup;
  }

  isPhoneError(control: AbstractControl): string {
    return this.submitted && control.errors ? 'is-invalid' : '';
  }

  toggleEditStoreProfile() {
    this.storeProfile.enable();
  }
}
