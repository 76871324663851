import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCatalogListMenuState from '../reducers/catalog-list-menu.reducers';

export const selectCatalogListMenuState = createFeatureSelector<fromCatalogListMenuState.CategoryMenuTreeState>(
  'catalogListMenu'
);

export const selectCatalogListMenuResult = createSelector(
  selectCatalogListMenuState,
  fromCatalogListMenuState.selectAll
);

export const selectCatalogListMenuTotalResult = createSelector(
  selectCatalogListMenuState,
  fromCatalogListMenuState.selectTotal
);
