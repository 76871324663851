import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MasterData } from '../../models/master-data.model';
import { UserSelectValueActions, UserSelectValueActionType } from '../actions/user-select-value.actions';

export interface UserSelectValueState extends EntityState<MasterData> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<MasterData> = createEntityAdapter<MasterData>();

export const initialUserSelectValueState: UserSelectValueState = adapter.getInitialState({
  isLoading: false
});

export function userSelectValueReducers(
  state = initialUserSelectValueState,
  action: UserSelectValueActions
): UserSelectValueState {
  switch (action.type) {
    case UserSelectValueActionType.USER_SELECT_VALUE_RESPONSE:
      return adapter.addAll(action.payload, {
        ...state,
        isLoading: true
      });

    case UserSelectValueActionType.USER_SELECT_VALUE_RESET:
      return initialUserSelectValueState;
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
