import { Location } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { ClientIdTypeEnum } from '../../enum/client-id.enum';
import { selectClientIdResult } from '../../store/selectors/client-id.selector';
import { SidebarService } from '../sidebar/sidebar.service';
var AdminLayoutComponent = /** @class */ (function () {
    function AdminLayoutComponent(router, location, sidebarService, cookieService, store) {
        this.router = router;
        this.location = location;
        this.sidebarService = sidebarService;
        this.cookieService = cookieService;
        this.store = store;
        this.location = location;
    }
    AdminLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store
            .pipe(select(selectClientIdResult), tap(function (clientId) { return (_this.clientId = clientId); }), untilComponentDestroyed(this))
            .subscribe();
    };
    AdminLayoutComponent.prototype.getSideBarState = function () {
        return this.sidebarService.getSidebarState();
    };
    AdminLayoutComponent.prototype.toggleSidebar = function () {
        this.sidebarService.setSidebarState(!this.sidebarService.getSidebarState());
    };
    Object.defineProperty(AdminLayoutComponent.prototype, "isNotPOS", {
        get: function () {
            return this.clientId !== ClientIdTypeEnum.POS_APPL;
        },
        enumerable: true,
        configurable: true
    });
    AdminLayoutComponent.prototype.ngOnDestroy = function () { };
    return AdminLayoutComponent;
}());
export { AdminLayoutComponent };
