import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CatalogsService } from '../../services';
import {
  CatalogListMenuActionTypes,
  CatalogListMenuErrorAction,
  CatalogListMenuRequestAction,
  CatalogListMenuResponseAction
} from '../actions/catalog-list-menu.action';

@Injectable()
export class CatalogListMenuEffects {
  constructor(
    private actions: Actions,
    private catalogService: CatalogsService,
    private readonly router: Router,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  CatalogListMenu: Observable<Action> = this.actions.pipe(
    ofType<CatalogListMenuRequestAction>(CatalogListMenuActionTypes.CATALOG_LIST_MENU_REQUEST),
    switchMap(() => {
      this.logger.debug('@Effect Catalog Menu Request: ');
      return this.catalogService.getCatalogListMenu().pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Get Catalog Menu Response: ' + this.stringify(resp));
          return new CatalogListMenuResponseAction(resp);
        }),
        catchError(err => {
          return of(new CatalogListMenuErrorAction(err.error));
        })
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
