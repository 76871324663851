<div id="forgot-password-form" class="flex-item d-flex justify-content-center">
  <div class="right-section border border-top-0 border-left-0 shadow-lg col-sm-10 d-flex">
    <div class="header">
      <h5 class="text-td-red text-center mb-4 d-none d-md-block text-center">{{ getPageTitle() | translate }}</h5>

      <div class="mobile-header d-md-none">
        <div *ngIf="step === 'REQUEST_OTP'">
          <h5 class="text-center">
            {{ 'FORGOT_PASSWORD.FORGOT_PASSWORD' | translate }}
          </h5>
          <p>{{ 'FORGOT_PASSWORD.PLEASE_ENTER_YOUR_USERNAME' | translate }}</p>
        </div>
        <div *ngIf="step === 'ENTER_OTP'">
          <h5 class="text-center">
            {{ 'FORGOT_PASSWORD.FORGOT_PASSWORD' | translate }}
          </h5>
          <div>
            <span>{{ 'FORGOT_PASSWORD.REQUEST_ENTER_OTP_SMS' | translate }}</span>
          </div>
          <div class="mt-1 mb-4">
            <span
              >{{ 'FORGOT_PASSWORD.REQUEST_NOT_RECEIVE_OTP' | translate }}&nbsp;<a
                href="javascript:void(0);"
                (click)="resendOTP()"
                >{{ 'FORGOT_PASSWORD.REQUEST_RESEND_OTP' | translate }}</a
              >
            </span>
          </div>
        </div>
        <div *ngIf="step === 'CHANGE_PASSWORD'">
          <h5 class="text-center">
            {{ 'RESET_PASSWORD' | translate }}
          </h5>
          <p>{{ 'WELCOME_TO_THE_SYSTEM_FOR_MORE_SECURITY_PLEASE_SET_YOUR_NEW_PASSWORD' | translate }}</p>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        (forgotPasswordResult$ | async).result.errorResponse.translateKey &&
        (forgotPasswordResult$ | async).result.errorResponse.code !== '01008'
      "
      class="form-group"
    >
      <div class="alert alert-danger alert-icon" role="alert">
        <span class="fa nicon icon-xcross mr-3"></span>
        <span
          *ngIf="(forgotPasswordResult$ | async).result.errorResponse.code === '00000'; else defaultMessage"
          translate="FORGOT_PASSWORD.FORGOT_PASSWORD_OTP_FAILED"
        >
        </span>
        <ng-template #defaultMessage>
          <span [innerHTML]="(forgotPasswordResult$ | async).result.errorResponse.translateKey | translate"></span>
        </ng-template>
      </div>
    </div>

    <div *ngIf="step === 'ENTER_OTP' && !(forgotPasswordResult$ | async).result.errorResponse.translateKey">
      <div *ngIf="!isTimeout && (forgotPasswordResult$ | async).result.isSentOTP" class="form-group">
        <div class="alert alert-success alert-icon" role="alert">
          <span class="fa nicon icon-check-circle mr-3"></span>
          <span
            [innerHTML]="
              'FORGOT_PASSWORD.FORGOT_PASSWORD_OTP_SUCCESS'
                | translate
                  : {
                      phoneNo: (forgotPasswordResult$ | async).result.phoneNo | mask: 'AAA-AAA-AAAA',
                      refNo: (forgotPasswordResult$ | async).result.refNo
                    }
            "
          ></span>
        </div>
      </div>

      <div *ngIf="isTimeout && (forgotPasswordResult$ | async).result.isSentOTP" class="form-group">
        <div class="alert alert-danger alert-icon" role="alert">
          <span class="fa nicon icon-xcross mr-3"></span>
          <span [innerHTML]="'FORGOT_PASSWORD.FORGOT_PASSWORD_OTP_TIMEOUT' | translate"></span>
        </div>
      </div>
    </div>

    <div class="right-section-control flex-item">
      <form [formGroup]="forgotPasswordForm">
        <!-- Request OTP -->
        <form formGroupName="requestOTP" *ngIf="step === 'REQUEST_OTP'" (submit)="onSubmit()">
          <div class="form-group">
            <label for="userName">{{ 'USERNAME' | translate }}</label>
            <input
              type="text"
              formControlName="userName"
              class="form-control"
              id="userName"
              name="userName"
              placeholder="{{ 'ENTER_USERNAME' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && formRequestOTP.controls.userName.errors }"
            />

            <div *ngIf="submitted && formRequestOTP.controls.userName.errors" class="invalid-feedback">
              <div *ngIf="formRequestOTP.controls.userName.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </form>
        <!-- Request OTP -->

        <!-- Input OTP -->
        <form formGroupName="inputOTP" [ngClass]="{ 'd-none': step !== 'ENTER_OTP' }" (keydown)="onEnterKey($event)">
          <div class="text-center">
            <div class="countdown">
              <span [innerHTML]="'FORGOT_PASSWORD.OTP_EXPIRY_IN' | translate"></span><span class="minutes"></span>:<span
                class="seconds"
              ></span
              ><span [innerHTML]="'FORGOT_PASSWORD.OTP_EXPIRY_MINUTE' | translate"></span>
            </div>
          </div>

          <div class="form-group">
            <div class="otp">
              <app-otp-input
                [ngClass]="{ 'is-timeout': isTimeout }"
                [otp]="otp"
                (inputChange)="onOtpChange($event)"
              ></app-otp-input>
            </div>
          </div>
        </form>
        <!-- Input OTP -->

        <!-- Change Password -->
        <form formGroupName="changePassword" *ngIf="step === 'CHANGE_PASSWORD'" (keydown)="onEnterKey($event)">
          <div class="form-group">
            <label for="newPassword">{{ 'NEW_PASSWORD' | translate }}</label>
            <div class="inner-addon right-addon">
              <span (click)="toggleInputPasswordType()" [ngClass]="{ 'is-show': isShowPassword }">
                <em
                  class="fas nicon icon-eye glyphicon"
                  [ngClass]="{ 'is-invalid': submitted && formChangePassword.controls.newPassword.errors }"
                ></em>
              </span>
              <input
                type="{{ isShowPassword ? 'text' : 'password' }}"
                class="form-control"
                formControlName="newPassword"
                id="newPassword"
                name="newPassword"
                placeholder="{{ 'ENTER_PASSWORD' | translate }}"
                [ngClass]="{ 'is-invalid': submitted && formChangePassword.controls.newPassword.errors }"
                (keydown.space)="$event.preventDefault()"
                (paste)="$event.preventDefault()"
                (focus)="clearPasswordSamePreviousError()"
              />
              <div
                *ngIf="submitted && formChangePassword.controls.newPassword.errors"
                class="invalid-feedback"
                id="new-password-error"
              >
                <div *ngIf="formChangePassword.controls.newPassword.errors.required; else invalidPassword">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <ng-template #invalidPassword>
                  <div *ngIf="formChangePassword.controls.newPassword.errors.invalidPassword">
                    {{ 'ERRORS.PASSWORD_RULE' | translate }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="reEnterPassword">{{ 'CONFIRM_PASSWORD' | translate }}</label>

            <div class="inner-addon right-addon">
              <span (click)="toggleInputReEnterPasswordType()" [ngClass]="{ 'is-show': isShowReEnterPassword }">
                <em
                  class="fas nicon icon-eye glyphicon"
                  [ngClass]="{ 'is-invalid': submitted && formChangePassword.controls.reEnterPassword.errors }"
                ></em>
              </span>
              <input
                type="{{ isShowReEnterPassword ? 'text' : 'password' }}"
                class="form-control"
                formControlName="reEnterPassword"
                id="reEnterPassword"
                name="reEnterPassword"
                placeholder="{{ 'ENTER_PASSWORD' | translate }}"
                [ngClass]="{ 'is-invalid': submitted && formChangePassword.controls.reEnterPassword.errors }"
                (keydown.space)="$event.preventDefault()"
                (paste)="$event.preventDefault()"
              />
              <div
                *ngIf="submitted && formChangePassword.controls.reEnterPassword.errors"
                class="invalid-feedback"
                id="re-enter-password-error"
              >
                <div *ngIf="formChangePassword.controls.reEnterPassword.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <div *ngIf="formChangePassword.controls.reEnterPassword.errors.passwordNotEquivalent">
                  {{ 'ERRORS.PASSWORD_NOT_MATCH' | translate }}
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- Change Password -->
      </form>

      <div>
        <button
          type="submit"
          id="btnContinue"
          name="btnContinue"
          class="btn btn-primary btn-block"
          [disabled]="isDisableButton()"
          (click)="onSubmit()"
        >
          {{ 'CONTINUE' | translate }}
        </button>
      </div>
    </div>

    <div class="d-md-none mt-3">
      <a href="#" routerLink="/login">&lt; {{ 'CANCEL' | translate }}</a>
    </div>
  </div>
</div>
