import { createSelector } from '@ngrx/store';
import * as fromAddItemState from '../reducers/add-item.reducers';
import * as fromCartState from '../reducers/cart.reducers';
import { AppStates } from '../state/app.states';

const selectCartState = (state: AppStates) => state.createCart;

const selectAddItemState = (state: AppStates) => state.cart;

export const selectCreateCartResult = createSelector(
  selectCartState,
  (state: fromCartState.CreateCartState) => state
);

export const selectAddItemResult = createSelector(
  selectAddItemState,
  (state: fromAddItemState.AddItemState) => {
    return state.result;
  }
);

export const selectCartItemResult = createSelector(
  selectAddItemState,
  (state: fromAddItemState.AddItemState) => {
    return state.result;
  }
);
