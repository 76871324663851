/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-left.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./login-left.component";
var styles_LoginLeftComponent = [i0.styles];
var RenderType_LoginLeftComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginLeftComponent, data: {} });
export { RenderType_LoginLeftComponent as RenderType_LoginLeftComponent };
export function View_LoginLeftComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "\u0E22\u0E34\u0E19\u0E14\u0E35\u0E15\u0E49\u0E2D\u0E19\u0E23\u0E31\u0E1A TD STORE PORTAL"], ["id", "login-left-logo"], ["src", "/assets/images/bird-and-bubble.svg"]], null, null, null, null, null))], null, null); }
export function View_LoginLeftComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-left", [], null, null, null, View_LoginLeftComponent_0, RenderType_LoginLeftComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoginLeftComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginLeftComponentNgFactory = i1.ɵccf("app-login-left", i2.LoginLeftComponent, View_LoginLeftComponent_Host_0, {}, {}, []);
export { LoginLeftComponentNgFactory as LoginLeftComponentNgFactory };
