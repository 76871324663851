import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { BaseTdStoreGroupListState } from '../state/td-store-group-list.state';

const selectTdStoreGroupList = (state: AppStates) => state.tdStoreGroupList;

export const selectTdStoreGroupListResult = createSelector(
  selectTdStoreGroupList,
  (state: BaseTdStoreGroupListState) => state.result.response
);
