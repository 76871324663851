import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserListService } from '../../services';
import { UsersActionTypes, UsersListErrorAction, UsersListResponseAction } from '../actions/user-list.actions';
var UserListEffects = /** @class */ (function () {
    function UserListEffects(actions, logger, usersService) {
        var _this = this;
        this.actions = actions;
        this.logger = logger;
        this.usersService = usersService;
        this.Users$ = this.actions.pipe(ofType(UsersActionTypes.USERS_REQUEST), map(function (action) {
            _this.logger.debug('@Effect UsersListResponseAction: ' + _this.stringify(action.payload));
            return action.payload;
        }), switchMap(function (payload) {
            return _this.usersService.getUsers(payload).pipe(map(function (resp) {
                return new UsersListResponseAction(resp.body);
            }), catchError(function (err) { return of(new UsersListErrorAction(err.error)); }));
        }));
    }
    UserListEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserListEffects.prototype, "Users$", void 0);
    return UserListEffects;
}());
export { UserListEffects };
