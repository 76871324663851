import { Action } from '@ngrx/store';
import { BaseApiResponse } from '../../models/base-api-response';
import { ErrorResponse } from '../../models/error.model';
import { ResetPasswordPinRequest, UserDeleteRequest, UserRequest, UserResponse } from '../../models/user.model';

export enum UsersActionTypes {
  USERS_ADD = '[Users] Add',
  USERS_EDIT = '[Users] Edit',
  USERS_GET = '[Users] Get',
  USERS_SUCCESS = '[Users] Success',
  USERS_FAILED = '[Users] Failed',
  USERS_DELETE = '[Users] Delete',
  USERS_DELETE_SUCCESS = '[Users] Delete Success',
  USERS_DELETE_FAILED = '[Users] Delete Failed',
  USERS_DELETE_RESET_STATUS = '[Users] Delete Reset Status',
  RESET_USER_STATE = '[Users] Reset User State',
  USERS_RESET_PASSWORD_REQUEST = '[Users] Reset Password Request',
  USERS_RESET_PASSWORD_RESPONSE = '[Users] Reset Password Response',
  USERS_RESET_PASSWORD_ERROR = '[Users] Reset Password Error',
  USERS_RESET_PIN_CODE_REQUEST = '[Users] Reset Pin Code Request',
  USERS_RESET_PIN_CODE_RESPONSE = '[Users] Reset Pin Code Response',
  USERS_RESET_PIN_CODE_ERROR = '[Users] Reset Pin Code Error',
  USERS_RESET_PASSWORD_PIN_CODE_RESET = '[Users] Reset Password Pin Code Reset'
}

export class UsersAddAction implements Action {
  readonly type = UsersActionTypes.USERS_ADD;

  constructor(public payload: UserRequest) {}
}

export class UsersEditAction implements Action {
  readonly type = UsersActionTypes.USERS_EDIT;

  constructor(public payload: UserRequest) {}
}

export class UsersGetAction implements Action {
  readonly type = UsersActionTypes.USERS_GET;

  constructor(public payload: string) {}
}

export class UsersSuccessAction implements Action {
  readonly type = UsersActionTypes.USERS_SUCCESS;

  constructor(public payload: UserResponse) {}
}

export class UsersFailedAction implements Action {
  readonly type = UsersActionTypes.USERS_FAILED;

  constructor(public payload: ErrorResponse) {}
}

export class UsersDeleteAction implements Action {
  readonly type = UsersActionTypes.USERS_DELETE;

  constructor(public payload: UserDeleteRequest) {}
}

export class UsersDeleteSuccessAction implements Action {
  readonly type = UsersActionTypes.USERS_DELETE_SUCCESS;

  constructor(public payload: BaseApiResponse) {}
}

export class UsersDeleteFailedAction implements Action {
  readonly type = UsersActionTypes.USERS_DELETE_FAILED;

  constructor(public payload: ErrorResponse) {}
}

export class UsersDeleteResetAction implements Action {
  readonly type = UsersActionTypes.USERS_DELETE_RESET_STATUS;

  constructor(public payload?: BaseApiResponse) {}
}

export class ResetUserState implements Action {
  readonly type = UsersActionTypes.RESET_USER_STATE;

  constructor() {}
}

export class UsersResetPasswordRequestAction implements Action {
  readonly type = UsersActionTypes.USERS_RESET_PASSWORD_REQUEST;

  constructor(public payload: ResetPasswordPinRequest) {}
}

export class UsersResetPasswordResponseAction implements Action {
  readonly type = UsersActionTypes.USERS_RESET_PASSWORD_RESPONSE;

  constructor(public payload?: BaseApiResponse) {}
}

export class UsersResetPasswordErrorAction implements Action {
  readonly type = UsersActionTypes.USERS_RESET_PASSWORD_ERROR;

  constructor(public payload?: BaseApiResponse) {}
}

export class UsersResetPinCodeRequestAction implements Action {
  readonly type = UsersActionTypes.USERS_RESET_PIN_CODE_REQUEST;

  constructor(public payload: ResetPasswordPinRequest) {}
}

export class UsersResetPinCodeResponseAction implements Action {
  readonly type = UsersActionTypes.USERS_RESET_PIN_CODE_RESPONSE;

  constructor(public payload?: BaseApiResponse) {}
}

export class UsersResetPinCodeErrorAction implements Action {
  readonly type = UsersActionTypes.USERS_RESET_PIN_CODE_ERROR;

  constructor(public payload?: BaseApiResponse) {}
}

export class UsersResetPasswordPinCodeResetAction implements Action {
  readonly type = UsersActionTypes.USERS_RESET_PASSWORD_PIN_CODE_RESET;

  constructor() {}
}

export type UsersAction =
  | UsersAddAction
  | UsersSuccessAction
  | UsersFailedAction
  | UsersDeleteAction
  | UsersDeleteSuccessAction
  | UsersDeleteFailedAction
  | UsersDeleteResetAction
  | ResetUserState
  | UsersResetPasswordRequestAction
  | UsersResetPasswordResponseAction
  | UsersResetPasswordErrorAction
  | UsersResetPinCodeRequestAction
  | UsersResetPinCodeResponseAction
  | UsersResetPinCodeErrorAction
  | UsersResetPasswordPinCodeResetAction;
