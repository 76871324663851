import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { environment as env } from '../environments/environment';
import { selectAuthResult } from './shared/store/selectors/auth.selector';
import { AppStates } from './shared/store/state/app.states';
import { BaseAuthState } from './shared/store/state/auth.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  public lang: string;
  public result$: Observable<BaseAuthState>;
  private localStore: Observable<any>;

  constructor(
    private readonly logger: NGXLogger,
    private readonly translate: TranslateService,
    private readonly store: Store<AppStates>
  ) {}

  ngOnInit(): void {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.lang = env.defaultLanguage;
    moment.locale(this.lang);
    this.translate.setDefaultLang(this.lang);

    this.translate.addLangs(env.supportedLanguages);
    this.translate.setDefaultLang(env.defaultLanguage);
    this.translate.use(this.lang);

    this.result$ = this.localStore.pipe(select(selectAuthResult));

    this.logger.trace('Production value is ' + env.production);
    this.logger.debug('Your tier is ' + env.tier);
    this.logger.info('Your appVersion is ' + env.version);
  }

  ngOnDestroy(): void {}
}
