import { ProductGradingEnum } from '../enum/product-grading.enum';
import { ProductStatusEnum } from '../enum/product-status.enum';
import { Amount } from './amount.model';
import { Pageable } from './pagination.model';
import { ProductImage } from './product-image.model';

export class CatalogListRequest {
  searchCriteria: string;
  catalogLV1: string;
  catalogLV2: string;
  offset: string;
  limit: string;
  timeZone: string;
}

export class Catalog {
  barcode: string;
  articleNo: string;
  articleType: string;
  productName: string;
  productStatus: ProductStatusEnum;
  productGrading: ProductGradingEnum;
  catalogLV1: string;
  catalogLV2: string;
  unit: string;
  productImage: ProductImage;
  unitFactor: number;
  retailPrice: Amount;
  retailPricePerUnit: Amount;
  wholesalePrice: Amount;
  wholesalePricePerUnit: Amount;
  profit: Amount;
  profitPerUnit: Amount;
  promotion: string;
  qty?: string;

  constructor(data?: Catalog) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

export class CatalogListResponse {
  content: Catalog[];
  page?: number;
  size?: number;
  totalElements: number;
  first?: boolean;
  numberOfElements?: number;
  totalPages?: number;
  hasContent?: boolean;
  last?: boolean;
  pageable?: Pageable;
  empty?: boolean;
}
