import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { ClientIdTypeEnum } from '../enum/client-id.enum';
import { AuthRequest } from '../models/auth.model';
import { selectClientIdResult } from '../store/selectors/client-id.selector';
import { AppStates } from '../store/state/app.states';

@Injectable()
export class AuthService {
  private clientId: ClientIdTypeEnum | null;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private readonly store: Store<AppStates>
  ) {
    this.store
      .pipe(
        select(selectClientIdResult),
        tap(clientId => (this.clientId = clientId))
      )
      .subscribe();
  }

  public logIn<T>(payload: AuthRequest): Observable<T> {
    const body = new HttpParams()
      .set('username', payload.username)
      .set('password', payload.password)
      .set('grant_type', 'password');

    return this.http.post<T>(this.getUrl(), body, { headers: this.httpHeader });
  }

  public refreshToken<T>(refreshToken: string): Observable<T> {
    const body = new HttpParams().set('refresh_token', refreshToken).set('grant_type', 'refresh_token');

    return this.http.post<T>(this.getUrl(), body, { headers: this.httpHeader });
  }

  public bypassAuthenToken<T>(): Observable<T> {
    const body = new HttpParams().set('grant_type', 'client_credentials');

    return this.http.post<T>(this.getUrl(), body, { headers: this.httpHeader });
  }

  private getUrl(urlParameters: string = '') {
    const config = this.doEnvConfig();

    return env.serverUrl + config.url + urlParameters;
  }

  private doEnvConfig(): any {
    return env.services.auth[this.clientId];
  }

  get httpHeader(): HttpHeaders {
    const config = this.doEnvConfig();

    return new HttpHeaders(config.headers);
  }
}
