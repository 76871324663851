<div class="modal-header">
  <h6 class="modal-title">{{ title }}</h6>
  <button type="button" class="close" aria-label="Close" (click)="decline()">
    <span aria-hidden="true"><em class="fa fa-times"></em></span>
  </button>
</div>
<div class="modal-body" [innerHTML]="message"></div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="decline()">{{ cancelText }}</button>
  <button type="button" class="btn btn-primary" (click)="confirm()">{{ okText }}</button>
</div>
