import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { BaseForgotPasswordState } from '../state/forgot-password.state';

const selectForgotPassword = (state: AppStates) => state.forgotPassword;

export const selectForgotPasswordResult = createSelector(
  selectForgotPassword,
  (state: BaseForgotPasswordState) => state
);
