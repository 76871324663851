import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { LogInAction, LoginReset } from '../../shared/store/actions/auth.actions';
import { ForceChangePasswordReset } from '../../shared/store/actions/force-change-password.action';
import { ForgotPasswordReset } from '../../shared/store/actions/forgot-password.action';
import { ForgotUsernameReset } from '../../shared/store/actions/forgot-username.action';
import { selectAuthResult } from '../../shared/store/selectors/auth.selector';
import { selectForceChangePasswordResult } from '../../shared/store/selectors/force-change-password.selector';
import { selectForgotPasswordResult } from '../../shared/store/selectors/forgot-password.selector';
import { selectForgotUsernameResult } from '../../shared/store/selectors/forgot-username.selector';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(store, formBuilder) {
        this.store = store;
        this.formBuilder = formBuilder;
        this.submitted = false;
    }
    LoginComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new LoginReset());
        this.resetAlertMessage();
    };
    LoginComponent.prototype.ngOnInit = function () {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', [Validators.required]]
        });
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.result$ = this.localStore.pipe(select(selectAuthResult));
        this.forceChangePasswordResult$ = this.localStore.pipe(select(selectForceChangePasswordResult));
        this.forgotUsernameResult$ = this.localStore.pipe(select(selectForgotUsernameResult));
        this.forgotPasswordResult$ = this.localStore.pipe(select(selectForgotPasswordResult));
        this.isShowPassword = false;
    };
    LoginComponent.prototype.toggleInputType = function () {
        this.isShowPassword = !this.isShowPassword;
    };
    LoginComponent.prototype.onSubmit = function () {
        this.submitted = true;
        this.isShowPassword = false;
        if (this.loginForm.invalid) {
            return;
        }
        this.resetAlertMessage();
        this.store.dispatch(new LogInAction(this.loginForm.value));
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        get: function () {
            return this.loginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.resetAlertMessage = function () {
        this.store.dispatch(new ForceChangePasswordReset()); // remove alert success from forceChangePasswordResult
        this.store.dispatch(new ForgotUsernameReset()); // remove alert success from forgotUsernameResult
        this.store.dispatch(new ForgotPasswordReset()); // remove alert success from forgotPasswordResult
    };
    return LoginComponent;
}());
export { LoginComponent };
