import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { BaseForgotUsernameState } from '../state/forgot-username.state';

const selectForgotUsername = (state: AppStates) => state.forgotUsername;

export const selectForgotUsernameResult = createSelector(
  selectForgotUsername,
  (state: BaseForgotUsernameState) => state
);
