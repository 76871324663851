import { Action } from '@ngrx/store';
import {
  AddItemError,
  AddItemRequest,
  AddItemResponse,
  CartRequest,
  CreateCartRequest,
  CreateCartResponse,
  DeleteCartItemRequest,
  DeleteInvalidItemsRequest
} from '../../models/cart.model';
import { ErrorResponse } from '../../models/error.model';
import { OrderSummary, OrderSummaryRequest, SubmitOrderRequest, SubmitOrderResponse } from '../../models/orders.model';

export enum CartsActionTypes {
  ORDER_SUMMARY_REQUEST = '[Orders] Order Summary Request',
  ORDER_SUMMARY_RESPONSE = '[Orders] Order Summary Response',
  ORDER_SUMMARY_ERROR = '[Orders] Order Summary Error',
  CREATE_CARTS_REQUEST = '[Orders] Create Cart Request',
  CREATE_CARTS_RESPONSE = '[Orders] Create Cart Response',
  CREATE_CARTS_ERROR = '[Orders] Create Cart Error',
  ADD_ITEM_REQUEST = '[Orders] Add Item Request',
  ADD_ITEM_RESPONSE = '[Orders] Add Item Response',
  ADD_ITEM_ERROR = '[Orders] Add Item Error',
  RESET_ADD_ITEM_STATUS = '[Orders] Reset Add Item Status',
  RESET_ADD_ITEM_ERROR = '[Orders] Reset Add Item Error',
  GET_CART = '[Orders] Get Cart',
  GET_CART_RESPONSE = '[Orders] Get Cart Response',
  GET_CART_ERROR = '[Orders] Get Cart Error',
  DELETE_CART_ITEM_REQUEST = '[Orders] Delete Cart Item Request',
  DELETE_CART_ITEM_RESPONSE = '[Orders] Delete Cart Item Response',
  DELETE_CART_ITEM_ERROR = '[Orders] Delete Cart Item Error',
  DELETE_INVALID_ITEMS_REQUEST = '[Orders] Delete Invalid Items Request',
  DELETE_INVALID_ITEMS_RESPONSE = '[Orders] Delete Invalid Items Response',
  DELETE_INVALID_ITEMS_ERROR = '[Orders] Delete Invalid Items Error',
  SUBMIT_ORDER_REQUEST = '[Orders] Submit Order Request',
  SUBMIT_ORDER_RESPONSE = '[Orders] Submit Order Response',
  SUBMIT_ORDER_ERROR = '[Orders] Submit Order Error',
  RESET_SUBMIT_ORDER_STATUS = '[Orders] Reset Submit Order Status'
}

export class OrdersSummaryRequestAction implements Action {
  readonly type = CartsActionTypes.ORDER_SUMMARY_REQUEST;

  constructor(public payload: OrderSummaryRequest) {}
}

export class OrdersSummaryResponseAction implements Action {
  readonly type = CartsActionTypes.ORDER_SUMMARY_RESPONSE;

  constructor(public payload: OrderSummary) {}
}

export class OrdersSummaryErrorAction implements Action {
  readonly type = CartsActionTypes.ORDER_SUMMARY_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class CreateCartRequestAction implements Action {
  readonly type = CartsActionTypes.CREATE_CARTS_REQUEST;
  constructor(public payload: CreateCartRequest) {}
}

export class CreateCartResponseAction implements Action {
  readonly type = CartsActionTypes.CREATE_CARTS_RESPONSE;
  constructor(public payload: CreateCartResponse) {}
}

export class CreateCartErrorAction implements Action {
  readonly type = CartsActionTypes.CREATE_CARTS_ERROR;
  constructor(public payload: ErrorResponse) {}
}

export class AddItemRequestAction implements Action {
  readonly type = CartsActionTypes.ADD_ITEM_REQUEST;
  constructor(public payload: AddItemRequest) {}
}

export class AddItemResponseAction implements Action {
  readonly type = CartsActionTypes.ADD_ITEM_RESPONSE;
  constructor(public payload: AddItemResponse) {}
}

export class AddItemErrorAction implements Action {
  readonly type = CartsActionTypes.ADD_ITEM_ERROR;
  constructor(public payload: AddItemError) {}
}

export class ResetAddItemStatusAction implements Action {
  readonly type = CartsActionTypes.RESET_ADD_ITEM_STATUS;
  constructor() {}
}

export class ResetAddItemErrorAction implements Action {
  readonly type = CartsActionTypes.RESET_ADD_ITEM_ERROR;
  constructor() {}
}

export class GetCartAction implements Action {
  readonly type = CartsActionTypes.GET_CART;
  constructor(public payload: CartRequest) {}
}

export class GetCartResponseAction implements Action {
  readonly type = CartsActionTypes.GET_CART_RESPONSE;
  constructor(public payload: AddItemResponse) {}
}

export class GetCartErrorAction implements Action {
  readonly type = CartsActionTypes.GET_CART_ERROR;
  constructor(public payload: AddItemError) {}
}

export class DeleteCartItemRequestAction implements Action {
  readonly type = CartsActionTypes.DELETE_CART_ITEM_REQUEST;
  constructor(public payload: DeleteCartItemRequest) {}
}

export class DeleteCartItemResponseAction implements Action {
  readonly type = CartsActionTypes.DELETE_CART_ITEM_REQUEST;
  constructor(public payload: AddItemResponse) {}
}

export class DeleteCartItemErrorAction implements Action {
  readonly type = CartsActionTypes.DELETE_CART_ITEM_REQUEST;
  constructor(public payload: AddItemError) {}
}

export class DeleteInvalidItemsRequestAction implements Action {
  readonly type = CartsActionTypes.DELETE_INVALID_ITEMS_REQUEST;
  constructor(public payload: DeleteInvalidItemsRequest) {}
}

export class DeleteInvalidItemsResponseAction implements Action {
  readonly type = CartsActionTypes.DELETE_INVALID_ITEMS_RESPONSE;
  constructor(public payload: AddItemResponse) {}
}

export class DeleteInvalidItemsErrorAction implements Action {
  readonly type = CartsActionTypes.DELETE_INVALID_ITEMS_ERROR;
  constructor(public payload: AddItemError) {}
}

export class SubmitOrderRequestAction implements Action {
  readonly type = CartsActionTypes.SUBMIT_ORDER_REQUEST;
  constructor(public payload: SubmitOrderRequest) {}
}

export class SubmitOrderResponseAction implements Action {
  readonly type = CartsActionTypes.SUBMIT_ORDER_RESPONSE;
  constructor(public payload: SubmitOrderResponse) {}
}

export class SubmitOrderErrorAction implements Action {
  readonly type = CartsActionTypes.SUBMIT_ORDER_ERROR;
  constructor(public payload: ErrorResponse) {}
}

export class SubmitOrderResetStatusAction implements Action {
  readonly type = CartsActionTypes.RESET_SUBMIT_ORDER_STATUS;
  constructor() {}
}

export type CartsAction =
  | OrdersSummaryRequestAction
  | OrdersSummaryResponseAction
  | OrdersSummaryErrorAction
  | CreateCartRequestAction
  | CreateCartResponseAction
  | CreateCartErrorAction
  | AddItemRequestAction
  | AddItemResponseAction
  | AddItemErrorAction
  | ResetAddItemStatusAction
  | ResetAddItemErrorAction
  | GetCartAction
  | GetCartResponseAction
  | GetCartErrorAction
  | DeleteCartItemRequestAction
  | DeleteCartItemResponseAction
  | DeleteCartItemErrorAction
  | DeleteInvalidItemsRequestAction
  | DeleteInvalidItemsResponseAction
  | DeleteInvalidItemsErrorAction
  | SubmitOrderRequestAction
  | SubmitOrderResponseAction
  | SubmitOrderErrorAction
  | SubmitOrderResetStatusAction;
