import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ItemActionTypeEnum } from '../../../../shared/enum/item-action.enum';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { ProductStatusEnum } from '../../../../shared/enum/product-status.enum';
import { ChildItem } from '../../../../shared/layouts/modals/child-item/child-item';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { FullModalComponent } from '../../../../shared/layouts/modals/full-modal/full-modal.component';
import { AddItemRequestAction, DeleteCartItemRequestAction, DeleteInvalidItemsRequestAction, GetCartAction } from '../../../../shared/store/actions/carts.action';
import { selectCartItemResult, selectCreateCartResult } from '../../../../shared/store/selectors/cart.selector';
import { CheckDisableStatus } from '../../../../shared/utils/check-disable-status';
import { ItemComponent } from '../../item/item.component';
var ItemListComponent = /** @class */ (function () {
    function ItemListComponent(store, formBuilder, translate, modalService) {
        this.store = store;
        this.formBuilder = formBuilder;
        this.translate = translate;
        this.modalService = modalService;
        this.$inactiveProductStatus = { $or: this.checkDisableStatus.invalidItemStatus() };
        this.subject = new Subject();
        this.paymentConfirmForm = this.formBuilder.group({
            cartItems: this.formBuilder.array([])
        });
    }
    ItemListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.createdCart$ = this.localStore.pipe(select(selectCreateCartResult));
        this.createdCart$.subscribe(function (createdCart) {
            if (createdCart.result.response) {
                _this.cart = createdCart.result.response;
                _this.store.dispatch(new GetCartAction({ cartId: _this.cart.cartId }));
            }
        });
        this.cartItem$ = this.localStore.pipe(select(selectCartItemResult), switchMap(function (cartItem) {
            return of(cartItem.response);
        }));
        this.subject
            .pipe(untilComponentDestroyed(this), debounceTime(500))
            .subscribe(function (quantity) {
            if (quantity.value) {
                _this.updateItem(quantity.value, quantity.name);
            }
        });
    };
    ItemListComponent.prototype.onAdd = function (quantity, barcode) {
        var newVal = +quantity >= 999 ? 999 : +quantity + 1;
        document.getElementById('cart-item-' + barcode).value = newVal.toString();
        this.updateItem(newVal.toString(), barcode);
    };
    ItemListComponent.prototype.onReduce = function (quantity, barcode) {
        var newVal = +quantity <= 1 ? 1 : +quantity - 1;
        document.getElementById('cart-item-' + barcode).value = newVal.toString();
        this.updateItem(newVal.toString(), barcode);
    };
    ItemListComponent.prototype.onQuantityKeyUp = function (quantity) {
        if (!quantity.value) {
            quantity.classList.add('border', 'border-danger');
            // cart-item-error{{ cartItem.barcode }}
        }
        else {
            quantity.classList.remove('border', 'border-danger');
        }
        this.subject.next(quantity);
    };
    ItemListComponent.prototype.updateItem = function (quantity, barcode) {
        var addItemRequest = {
            barcode: barcode,
            qty: quantity,
            action: ItemActionTypeEnum.UPDATE,
            cartId: this.cart.cartId
        };
        if (quantity) {
            this.store.dispatch(new AddItemRequestAction(addItemRequest));
        }
    };
    ItemListComponent.prototype.deleteItem = function (barcode) {
        var _this = this;
        var initialState = {
            title: this.translate.instant('DELETE'),
            okText: this.translate.instant('YES_DELETE'),
            cancelText: this.translate.instant('CANCEL'),
            message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ITEM')
        };
        var confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState
        });
        confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(function (result) {
            if (result === ModalButtonResponseEnum.OK) {
                var deleteCartItemRequest = {
                    cartId: _this.cart.cartId,
                    barcode: barcode
                };
                _this.store.dispatch(new DeleteCartItemRequestAction(deleteCartItemRequest));
            }
        });
    };
    ItemListComponent.prototype.callItemDetails = function (catalog) {
        var initialState = {
            title: null,
            childItem: new ChildItem(ItemComponent, {
                catalog: catalog,
                cart: this.cart,
                title: this.translate.instant('BACK_TO_PREVIOUS_PAGE'),
                isResetQuantity: false,
                step: '2'
            })
        };
        this.bsModalRef = this.modalService.show(FullModalComponent, {
            animated: false,
            backdrop: false,
            initialState: initialState
        });
    };
    ItemListComponent.prototype.deleteInvalidItems = function () {
        this.store.dispatch(new DeleteInvalidItemsRequestAction({ cartId: this.cart.cartId }));
    };
    Object.defineProperty(ItemListComponent.prototype, "productStatus", {
        get: function () {
            return ProductStatusEnum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemListComponent.prototype, "checkDisableStatus", {
        get: function () {
            return CheckDisableStatus;
        },
        enumerable: true,
        configurable: true
    });
    ItemListComponent.prototype.ngOnDestroy = function () { };
    return ItemListComponent;
}());
export { ItemListComponent };
