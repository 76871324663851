import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserSelectValueService } from '../../services/user-select-value.service';
import {
  UserSelectValueActionType,
  UserSelectValueErrorAction,
  UserSelectValueRequestAction,
  UserSelectValueResponseAction
} from '../actions/user-select-value.actions';

@Injectable()
export class UserSelectValueEffects {
  constructor(
    private actions$: Actions,
    private userSelectValueService: UserSelectValueService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  merchantSelectValue$: Observable<Action> = this.actions$.pipe(
    ofType<UserSelectValueRequestAction>(UserSelectValueActionType.USER_SELECT_VALUE_REQUEST),
    map(action => {
      this.logger.debug('@Effect UserSelectValue: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.userSelectValueService.getMerchantSelectValue(payload).pipe(
        map(res => {
          return new UserSelectValueResponseAction(res);
        }),
        catchError(error => {
          return of(new UserSelectValueErrorAction(error));
        })
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
