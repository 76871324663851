import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
var UsersService = /** @class */ (function (_super) {
    tslib_1.__extends(UsersService, _super);
    function UsersService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.envService = _this.env.services.users;
        _this.headers = new HttpHeaders(_this.envService.headers);
        return _this;
    }
    UsersService.prototype.addUser = function (req) {
        return this.http.post(this.getUrl(), req, { headers: this.loaderHeaders() });
    };
    UsersService.prototype.editUser = function (req) {
        return this.http.put(this.getUrl(), req, { headers: this.loaderHeaders() });
    };
    UsersService.prototype.deleteUser = function (req) {
        var url = this.getFullUrl(this.envService.deleteUserProfile, {
            userNo: req.userNo
        });
        return this.http.delete(url, {
            headers: this.loaderHeaders(),
            observe: 'response'
        });
    };
    UsersService.prototype.resetPassword = function (req) {
        var url = this.getFullUrl(this.envService.resetPassword, {
            userName: req.userName
        });
        return this.http.post(url, null, { headers: this.loaderHeaders(), observe: 'response' });
    };
    UsersService.prototype.resetPinCode = function (req) {
        var url = this.getFullUrl(this.envService.resetPinCode, {
            userName: req.userName
        });
        return this.http.post(url, null, { headers: this.loaderHeaders(), observe: 'response' });
    };
    UsersService.prototype.forgotUsername = function (req, accessToken) {
        var url = this.getFullUrl(this.envService.forgetUserName, {
            mobileNo: req.mobileNumber
        });
        var headers = this.headers.append('Authorization', "Bearer " + accessToken);
        return this.http.post(url, null, { headers: headers, observe: 'response' });
    };
    UsersService.prototype.forgotPasswordCreateOTP = function (userName, accessToken) {
        var headers = this.headers.append('Authorization', "Bearer " + accessToken);
        var url = this.getFullUrl(this.envService.createOTP, {
            userName: userName
        });
        return this.http.post(url, null, { headers: headers, observe: 'response' });
    };
    UsersService.prototype.forgotPasswordVerifyOTP = function (userName, otp, accessToken) {
        var headers = this.headers.append('Authorization', "Bearer " + accessToken);
        var url = this.getFullUrl(this.envService.verifyOTP, {
            userName: userName
        });
        var data = {
            otp: otp
        };
        return this.http.post(url, data, { headers: headers, observe: 'response' });
    };
    UsersService.prototype.forgotPassword = function (userName, newPassword, otp, accessToken) {
        var headers = this.headers.append('Authorization', "Bearer " + accessToken);
        var url = this.getFullUrl(this.envService.forgotPassword, {
            userName: userName
        });
        var data = {
            newPassword: newPassword,
            otp: otp
        };
        return this.http.post(url, data, { headers: headers, observe: 'response' });
    };
    UsersService.prototype.getUser = function (userNo) {
        console.log(userNo);
        var url = this.getFullUrl(this.envService.getUserProfile, {
            userNo: userNo
        });
        return this.http.get(url, { headers: this.headers });
    };
    UsersService.prototype.changePassword = function (request) {
        var url = this.getFullUrl(this.envService.forceChangePassword, {
            userName: request.userName
        });
        return this.http.post(url, { newPassword: request.newPassword }, {
            headers: this.headers,
            observe: 'response'
        });
    };
    return UsersService;
}(BaseService));
export { UsersService };
