import { isArray } from 'ngx-bootstrap';

export class Tenant {
  merchant?: any;
  storeCountries?: any;
  storeGroups?: any;
  stores: string[];
  sites?: string[];
}

export class UserRequest {
  id?: string;
  no?: string;
  version?: number;
  firstName: string;
  lastName: string;
  localFirstName: string;
  localLastName: string;
  countryCode: string;
  mobileNo: string;
  roles: string[];
  birthDate: string;
  email: string;
  gender: string;
  active: boolean;
  tenant: Tenant;
  accessAllStores: boolean;
  accessStoreGroups: string[];
  accessStores: string[];

  constructor(value?: UserRequest) {
    if (value) {
      if (!isArray(value.roles)) {
        value.roles = [value.roles];
      }
      Object.assign(this, value);
    }
  }
}

export class UserDeleteRequest {
  userNo: string;

  constructor(value?: UserDeleteRequest) {
    Object.assign(this, value);
  }
}

export interface UserResponse {
  id: string;
  version: number;
  userNo: string;
  userName: string;
  userCode: string;
  firstName: string;
  lastName: string;
  fullName: string;
  localFirstName: string;
  localLastName: string;
  countryCode: string;
  mobileNo: string;
  email: string;
  gender: string;
  active: boolean;
  birthDate: string;
  applications: string[];
  roles: string[];
  tenant: Tenant;
  createdDate: Date;
  createdBy: string;
  lastModifiedDate: Date;
  lastModifiedBy: string;
  status?: string;
  lastSuccessLoginDate?: Date;
  updateSuccess?: boolean;
  accessAllStores: boolean;
  accessStoreGroups: string[];
  accessStores: string[];
}

export class ResetPasswordPinRequest {
  userName: string;
}

export class ResetPasswordPinResponse {
  statusCode: number;
  statusText: string;
}
