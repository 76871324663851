<div class="container-fluid admin-form">
  <h4>{{ 'ORDER_PRODUCT' | translate }}</h4>
  <hr />
  <div class="clearfix py-2"></div>
  <div class="card">
    <div class="card-body row">
      <div class="col-12" [formGroup]="selectStoreForm">
        <div class="form-row m-2" *ngIf="(storeList$ | async)?.result.response">
          <span class="align-middle float-left pl-1 pt-2 d-none d-sm-block">
            <em class=" fa fa-store "></em>
          </span>
          <div class="col-12 col-sm-5 mb-2">
            <ng-select
              #store
              (change)="selectedStore()"
              [clearable]="false"
              [items]="storeList"
              [searchable]="false"
              bindLabel="name"
              bindValue="no"
              formControlName="store"
              id="store"
              placeholder="{{ 'PLEASE_SELECT' | translate }}"
            >
            </ng-select>
          </div>
          <div class="col-12 col-sm-3 mb-2">
            <button
              class="btn btn-primary col-12"
              [disabled]="!(tdStore$ | async).result.response?.orderSchedule"
              (click)="createCart()"
            >
              {{ 'ORDER_PRODUCT' | translate }}
            </button>
          </div>
          <span class="align-middle float-left pl-3 pt-2">
            <em class="fa fa-wallet"></em>
            {{ 'E_WALLET_REMAINING' | translate }}
            {{ 0.0 | number }}
            {{ 'THB' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
