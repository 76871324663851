import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { BaseTdStoreListState } from '../state/td-store-list.state';
import { TenantInfoState } from '../state/tenant-info.state';

const selectTdStoreList = (state: AppStates) => state.tdStoreList;
const selectTenantInfo = (state: AppStates) => state.tenantInfo;

export const selectTdStoreListResult = createSelector(
  selectTdStoreList,
  (state: BaseTdStoreListState) => state
);

export const selectTdStoreByUserResult = createSelector(
  selectTdStoreList,
  selectTenantInfo,
  (selectedTDStoreList: BaseTdStoreListState, selectedTenantInfo: TenantInfoState) => {
    const tdStoreList = selectedTDStoreList.result.response;

    if (tdStoreList && selectedTenantInfo.stores) {
      selectedTDStoreList.result.response.content = tdStoreList.content.filter(store =>
        selectedTenantInfo.stores.includes(store.no)
      );
    }

    return selectedTDStoreList;
  }
);
