import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../services';
import { UsersService } from '../../services/users.service';
import { ForgotPasswordActionTypes, ForgotPasswordChangePasswordFailed, ForgotPasswordChangePasswordSuccess, ForgotPasswordOTPFailed, ForgotPasswordOTPSuccess, ForgotPasswordVerifyOTPSuccess } from '../actions/forgot-password.action';
var ForgotPasswordEffects = /** @class */ (function () {
    function ForgotPasswordEffects(actions$, authService, userService, router) {
        var _this = this;
        this.actions$ = actions$;
        this.authService = authService;
        this.userService = userService;
        this.router = router;
        this.forgotPasswordCreateOTP$ = this.actions$.pipe(ofType(ForgotPasswordActionTypes.FORGOT_PASSWORD_OTP_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            return _this.authService.bypassAuthenToken().pipe(map(function (result) { return result['access_token']; }), switchMap(function (accessToken) {
                return _this.userService.forgotPasswordCreateOTP(payload, accessToken);
            }), map(function (response) {
                return new ForgotPasswordOTPSuccess({ data: response.body });
            }), catchError(function (err) {
                return of(new ForgotPasswordOTPFailed(err));
            }));
        }));
        this.forgotPasswordVerifyOTP$ = this.actions$.pipe(ofType(ForgotPasswordActionTypes.FORGOT_PASSWORD_VERIFY_OTP_REQUEST), tap(function (action) {
            return console.log('@Effect Forgot Password Verify OTP Request Action : ' + JSON.stringify(action.payload));
        }), map(function (action) { return action.payload; }), switchMap(function (payload) {
            return _this.authService.bypassAuthenToken().pipe(map(function (result) { return result['access_token']; }), switchMap(function (accessToken) {
                return _this.userService.forgotPasswordVerifyOTP(payload.userName, payload.otp, accessToken);
            }), map(function (response) {
                return new ForgotPasswordVerifyOTPSuccess({ data: response.body });
            }), catchError(function (err) {
                return of(new ForgotPasswordOTPFailed(err));
            }));
        }));
        this.forgotPasswordChangePassword$ = this.actions$.pipe(ofType(ForgotPasswordActionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_REQUEST), tap(function (action) {
            return console.log('@Effect Forgot Password Change Password Request Action : ' + JSON.stringify(action.payload));
        }), map(function (action) { return action.payload; }), switchMap(function (payload) {
            return _this.authService.bypassAuthenToken().pipe(map(function (result) { return result['access_token']; }), switchMap(function (accessToken) {
                return _this.userService.forgotPassword(payload.userName, payload.newPassword, payload.otp, accessToken);
            }), map(function (response) {
                _this.router.navigateByUrl('/login');
                return new ForgotPasswordChangePasswordSuccess({ data: response.body });
            }), catchError(function (err) {
                return of(new ForgotPasswordChangePasswordFailed(err));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ForgotPasswordEffects.prototype, "forgotPasswordCreateOTP$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ForgotPasswordEffects.prototype, "forgotPasswordVerifyOTP$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ForgotPasswordEffects.prototype, "forgotPasswordChangePassword$", void 0);
    return ForgotPasswordEffects;
}());
export { ForgotPasswordEffects };
