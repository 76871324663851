import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { tap } from 'rxjs/operators';
import { ItemActionTypeEnum } from '../../../../shared/enum/item-action.enum';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { AddItemRequestAction, ResetAddItemErrorAction } from '../../../../shared/store/actions/carts.action';
import { selectCreateCartResult } from '../../../../shared/store/selectors/cart.selector';
var DuplicateItemComponent = /** @class */ (function () {
    function DuplicateItemComponent(store, formBuilder) {
        this.store = store;
        this.formBuilder = formBuilder;
        this.notifyParent = new EventEmitter();
    }
    DuplicateItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.cart$ = this.localStore.pipe(select(selectCreateCartResult));
        this.cart$
            .pipe(tap(function (cart) {
            _this.cart = cart.result.response;
        }))
            .subscribe();
        this.barcode = this.data.barcode;
        this.qty = this.data.qty;
        this.duplicateItemForm = this.formBuilder.group({
            amount: [this.qty, [Validators.required, Validators.min(1), Validators.max(999)]]
        });
    };
    DuplicateItemComponent.prototype.cancel = function () {
        this.closeModal();
    };
    DuplicateItemComponent.prototype.dispatchUpdateItemAction = function (barcode, qty) {
        var addItemRequest = {
            barcode: barcode,
            qty: qty,
            action: ItemActionTypeEnum.UPDATE,
            cartId: this.cart.cartId
        };
        this.store.dispatch(new AddItemRequestAction(addItemRequest));
    };
    DuplicateItemComponent.prototype.updateItem = function (barcode) {
        if (this.duplicateItemForm.controls.amount.value) {
            this.dispatchUpdateItemAction(barcode, this.duplicateItemForm.controls.amount.value.toString());
        }
        this.closeModal();
    };
    DuplicateItemComponent.prototype.closeModal = function () {
        this.notifyParent.emit({
            notificationType: NotificationTypeEnum.FORCE_CLOSE,
            result: null
        });
        this.store.dispatch(new ResetAddItemErrorAction());
    };
    Object.defineProperty(DuplicateItemComponent.prototype, "duplicateItemFormGetter", {
        get: function () {
            return this.duplicateItemForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    DuplicateItemComponent.prototype.ngOnDestroy = function () { };
    return DuplicateItemComponent;
}());
export { DuplicateItemComponent };
