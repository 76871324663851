import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { selectClientIdResult } from '../store/selectors/client-id.selector';
var AuthService = /** @class */ (function () {
    function AuthService(http, cookieService, store) {
        var _this = this;
        this.http = http;
        this.cookieService = cookieService;
        this.store = store;
        this.store
            .pipe(select(selectClientIdResult), tap(function (clientId) { return (_this.clientId = clientId); }))
            .subscribe();
    }
    AuthService.prototype.logIn = function (payload) {
        var body = new HttpParams()
            .set('username', payload.username)
            .set('password', payload.password)
            .set('grant_type', 'password');
        return this.http.post(this.getUrl(), body, { headers: this.httpHeader });
    };
    AuthService.prototype.refreshToken = function (refreshToken) {
        var body = new HttpParams().set('refresh_token', refreshToken).set('grant_type', 'refresh_token');
        return this.http.post(this.getUrl(), body, { headers: this.httpHeader });
    };
    AuthService.prototype.bypassAuthenToken = function () {
        var body = new HttpParams().set('grant_type', 'client_credentials');
        return this.http.post(this.getUrl(), body, { headers: this.httpHeader });
    };
    AuthService.prototype.getUrl = function (urlParameters) {
        if (urlParameters === void 0) { urlParameters = ''; }
        var config = this.doEnvConfig();
        return env.serverUrl + config.url + urlParameters;
    };
    AuthService.prototype.doEnvConfig = function () {
        return env.services.auth[this.clientId];
    };
    Object.defineProperty(AuthService.prototype, "httpHeader", {
        get: function () {
            var config = this.doEnvConfig();
            return new HttpHeaders(config.headers);
        },
        enumerable: true,
        configurable: true
    });
    return AuthService;
}());
export { AuthService };
