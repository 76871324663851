import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlParams } from '../models/url-params.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.envService = this.env.services.merchants;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getStoresByMerchant<T>(urlParams: UrlParams): Observable<T> {
    const params = this.getParams(urlParams);

    const url = this.getFullUrl(this.envService.stores, {
      merchantNo: urlParams.merchantNo
    });

    return this.http.get<T>(url, { headers: this.headers, params: params });
  }

  public getStoresGroupByMerchant<T>(urlParams: UrlParams): Observable<T> {
    const url = this.getFullUrl(this.envService.storesGroups, {
      merchantNo: urlParams.merchantNo
    });

    return this.http.get<T>(url, { headers: this.headers });
  }
}
