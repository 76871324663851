import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { tap } from 'rxjs/operators';
import { DeviceStatusEnum } from '../../shared/enum/status.enum';
import { TDPageModes } from '../../shared/enum/store.enum';
import { ChildItem } from '../../shared/layouts/modals/child-item/child-item';
import { FullModalComponent } from '../../shared/layouts/modals/full-modal/full-modal.component';
import { TdStoreListGetAction } from '../../shared/store/actions/td-store-list.action';
import { selectTdStoreListResult } from '../../shared/store/selectors/td-store-list.selector';
import { selectTenantInfoResult } from '../../shared/store/selectors/tenant-info.selector';
import { EditComponent } from './edit/edit.component';
var StoresComponent = /** @class */ (function () {
    function StoresComponent(formBuilder, store, modalService, translate) {
        this.formBuilder = formBuilder;
        this.store = store;
        this.modalService = modalService;
        this.translate = translate;
        this.storeList$ = this.store.pipe(select(selectTdStoreListResult));
        this.status = [];
        this.headerRow = ['STORE_ID', 'STORE_NAME', 'LAST_UPDATED', 'STATUS', 'POS', 'USER_ACTION'];
    }
    StoresComponent.prototype.ngOnInit = function () {
        this.currentPage = 1;
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.initState();
        this.initControl();
        this.initDropDown();
    };
    StoresComponent.prototype.ngOnDestroy = function () { };
    StoresComponent.prototype.initDropDown = function () {
        var _this = this;
        this.translate
            .get('loaded.done')
            .pipe(untilComponentDestroyed(this), tap(function () { return _this.initStoreStatus(); }))
            .subscribe();
        this.translate.onLangChange
            .pipe(untilComponentDestroyed(this), tap(function () { return _this.initStoreStatus(); }))
            .subscribe();
    };
    StoresComponent.prototype.initStoreStatus = function () {
        this.status = [
            { value: '', label: this.translate.instant('ALL_STATUS') },
            { value: 'ACTIVE', label: this.translate.instant('ACTIVE') },
            { value: 'INACTIVE', label: this.translate.instant('INACTIVE') }
        ];
    };
    Object.defineProperty(StoresComponent.prototype, "pageMode", {
        get: function () {
            return TDPageModes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StoresComponent.prototype, "deviceStatus", {
        get: function () {
            return DeviceStatusEnum;
        },
        enumerable: true,
        configurable: true
    });
    StoresComponent.prototype.initState = function () {
        var _this = this;
        this.localStore.pipe(select(selectTenantInfoResult)).subscribe(function (tenantInfo) {
            _this.merchantNo = tenantInfo.merchant;
            _this.listRequest = {
                merchantNo: _this.merchantNo,
                size: '20',
                page: '0',
                sortBy: 'code',
                sortOrder: 'desc',
                status: null,
                text: null
            };
            _this.dispatchStoreListAction(_this.listRequest);
        });
    };
    StoresComponent.prototype.initControl = function () {
        this.searchAndFilterForm = this.formBuilder.group({
            searchText: null,
            status: ''
        });
    };
    StoresComponent.prototype.onRowPerPagesSelected = function (value) {
        this.pageSize = Number(value);
        this.listRequest.size = value;
        this.listRequest.page = '0';
        this.dispatchStoreListAction(this.listRequest);
    };
    StoresComponent.prototype.pageChanged = function (event) {
        this.currentPage = event.page;
        this.listRequest.page = (event.page - 1).toString();
        this.dispatchStoreListAction(this.listRequest);
    };
    StoresComponent.prototype.dispatchStoreListAction = function (storeListRequest) {
        this.store.dispatch(new TdStoreListGetAction(storeListRequest));
    };
    StoresComponent.prototype.selectedStatus = function (event) {
        this.currentPage = 1;
        this.listRequest.page = '0';
        this.listRequest.status = event;
        this.dispatchStoreListAction(this.listRequest);
    };
    StoresComponent.prototype.handleSearchText = function () {
        this.listRequest.text = this.searchAndFilterForm.value.searchText;
        this.dispatchStoreListAction(this.listRequest);
    };
    StoresComponent.prototype.clearSearchText = function () {
        this.currentPage = 1;
        this.listRequest.page = '0';
        this.searchAndFilterForm.controls['searchText'].reset();
        this.listRequest.text = null;
        this.dispatchStoreListAction(this.listRequest);
    };
    StoresComponent.prototype.callEditStore = function (id, mode) {
        var initialState = {
            title: null,
            childItem: new ChildItem(EditComponent, {
                id: id,
                title: this.translate.instant(mode === TDPageModes.REQUEST_EDIT ? 'EDIT_STORE' : 'VIEW_STORE'),
                mode: mode
            })
        };
        this.bsModalRef = this.modalService.show(FullModalComponent, {
            animated: false,
            backdrop: false,
            initialState: initialState
        });
    };
    return StoresComponent;
}());
export { StoresComponent };
