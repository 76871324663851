import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrderSummary } from '../../../shared/models/orders.model';
import { Contact, TdStore } from '../../../shared/models/td-store.model';
import { selectOrderSummaryResult } from '../../../shared/store/selectors/order-summary.selector';
import { selectTdStoreResult } from '../../../shared/store/selectors/td-store.selector';
import { AppStates } from '../../../shared/store/state/app.states';

export class Step2DataOut {
  additionalNote: string;
}

@Component({
  selector: 'app-orders-step2',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit, OnDestroy {
  @ViewChild('additionalNote', { static: true }) additionalNoteEl: ElementRef;
  @Output() dataOut: EventEmitter<Step2DataOut> = new EventEmitter<Step2DataOut>();

  public orderSummary$: Observable<OrderSummary>;
  public tdStore$: Observable<TdStore>;
  public storeAddress: string;
  public storeContactNumber: string;

  private localStore: Observable<any>;

  constructor(private readonly store: Store<AppStates>) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.storeAddress = '';
    this.storeContactNumber = '';

    this.orderSummary$ = this.localStore.pipe(
      select(selectOrderSummaryResult),
      map(value => value.result.response)
    );
    this.tdStore$ = this.localStore.pipe(
      select(selectTdStoreResult),
      map(value => value.result.response),
      tap((value: TdStore) => {
        this.storeAddress = this.setStoreAddress(value.storeProfile);
        this.storeContactNumber = this.setStoreContactNumber(value.storeProfile);
      })
    );
  }

  setStoreAddress(contact: Contact): string {
    const sortedAddress: Array<string> = ['address', 'state', 'postCode', 'country'];
    const address: string = sortedAddress
      .filter(value => contact[value])
      .map(value => contact[value])
      .join(' ');

    return address === '' ? '-' : address;
  }

  setStoreContactNumber(contact: Contact): string {
    const sortedContactNumber: Array<string> = ['countryCode', 'mobileNo'];
    const contactNumber = sortedContactNumber
      .filter(value => contact[value])
      .map(value => (value !== 'countryCode' ? contact[value] : `(${contact.countryCode})`))
      .join(' ');

    return contactNumber === '' ? '-' : contactNumber;
  }

  Onchange(event: EventTarget) {
    const additionalNote = event['value'];
    this.dataOut.emit({ additionalNote } as Step2DataOut);
  }

  ngOnDestroy(): void {}
}
