import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { BaseForceChangePasswordResponseState } from '../state/force-change-password.state';

const selectForceChangePassword = (state: AppStates) => state.forceChangePassword;

export const selectForceChangePasswordResult = createSelector(
  selectForceChangePassword,
  (state: BaseForceChangePasswordResponseState) => state
);
