import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { UserListState } from '../state/user-list.state';

const selectUsersList = (state: AppStates) => state.usersList;

export const selectUsersListResult = createSelector(
  selectUsersList,
  (state: UserListState) => state.usersList.response
);
