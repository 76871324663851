import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DeviceStatusEnum } from '../../shared/enum/status.enum';
import { TDPageModes } from '../../shared/enum/store.enum';
import { ChildItem } from '../../shared/layouts/modals/child-item/child-item';
import { FullModalComponent } from '../../shared/layouts/modals/full-modal/full-modal.component';
import { ItemModalComponent } from '../../shared/models/item-modal.component.model';
import { UrlParams } from '../../shared/models/url-params.model';
import { TdStoreListGetAction } from '../../shared/store/actions/td-store-list.action';
import { selectTdStoreListResult } from '../../shared/store/selectors/td-store-list.selector';
import { selectTenantInfoResult } from '../../shared/store/selectors/tenant-info.selector';
import { AppStates } from '../../shared/store/state/app.states';
import { EditComponent } from './edit/edit.component';

@Component({
  selector: 'app-store',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit, OnDestroy {
  public headerRow: string[];
  public listRequest: UrlParams;
  public storeList$ = this.store.pipe(select(selectTdStoreListResult));
  public bsModalRef: BsModalRef;
  public status = [];
  public currentPage: number;
  public pageSize: number;
  public searchAndFilterForm: FormGroup;
  private merchantNo: string;
  private text: string;
  private localStore: Observable<any>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store<AppStates>,
    private readonly modalService: BsModalService,
    private translate: TranslateService
  ) {
    this.headerRow = ['STORE_ID', 'STORE_NAME', 'LAST_UPDATED', 'STATUS', 'POS', 'USER_ACTION'];
  }

  ngOnInit() {
    this.currentPage = 1;
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.initState();
    this.initControl();
    this.initDropDown();
  }

  ngOnDestroy(): void {}

  private initDropDown() {
    this.translate
      .get('loaded.done')
      .pipe(
        untilComponentDestroyed(this),
        tap(() => this.initStoreStatus())
      )
      .subscribe();

    this.translate.onLangChange
      .pipe(
        untilComponentDestroyed(this),
        tap(() => this.initStoreStatus())
      )
      .subscribe();
  }

  private initStoreStatus() {
    this.status = [
      { value: '', label: this.translate.instant('ALL_STATUS') },
      { value: 'ACTIVE', label: this.translate.instant('ACTIVE') },
      { value: 'INACTIVE', label: this.translate.instant('INACTIVE') }
    ];
  }

  get pageMode() {
    return TDPageModes;
  }

  get deviceStatus() {
    return DeviceStatusEnum;
  }

  private initState() {
    this.localStore.pipe(select(selectTenantInfoResult)).subscribe(tenantInfo => {
      this.merchantNo = tenantInfo.merchant;
      this.listRequest = {
        merchantNo: this.merchantNo,
        size: '20',
        page: '0',
        sortBy: 'code',
        sortOrder: 'desc',
        status: null,
        text: null
      };
      this.dispatchStoreListAction(this.listRequest);
    });
  }

  private initControl() {
    this.searchAndFilterForm = this.formBuilder.group({
      searchText: null,
      status: ''
    });
  }

  onRowPerPagesSelected(value: string) {
    this.pageSize = Number(value);
    this.listRequest.size = value;
    this.listRequest.page = '0';
    this.dispatchStoreListAction(this.listRequest);
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.listRequest.page = (event.page - 1).toString();
    this.dispatchStoreListAction(this.listRequest);
  }

  dispatchStoreListAction(storeListRequest: UrlParams) {
    this.store.dispatch(new TdStoreListGetAction(storeListRequest));
  }

  selectedStatus(event: any): void {
    this.currentPage = 1;
    this.listRequest.page = '0';
    this.listRequest.status = event;
    this.dispatchStoreListAction(this.listRequest);
  }

  handleSearchText(): void {
    this.listRequest.text = this.searchAndFilterForm.value.searchText;
    this.dispatchStoreListAction(this.listRequest);
  }

  clearSearchText() {
    this.currentPage = 1;
    this.listRequest.page = '0';
    this.searchAndFilterForm.controls['searchText'].reset();
    this.listRequest.text = null;
    this.dispatchStoreListAction(this.listRequest);
  }

  callEditStore(id: string, mode: TDPageModes) {
    const initialState: ItemModalComponent = {
      title: null,
      childItem: new ChildItem(EditComponent, {
        id: id,
        title: this.translate.instant(mode === TDPageModes.REQUEST_EDIT ? 'EDIT_STORE' : 'VIEW_STORE'),
        mode: mode
      })
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState: initialState
    });
  }
}
