import { createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';
import * as fromMerchantSelectValue from '../reducers/user-select-value.reducers';
import { AppStates } from '../state/app.states';

const selectUserSelectValueState = (state: AppStates) => state.userSelectValue;

export const selectUserSelectValue = createSelector(
  selectUserSelectValueState,
  fromMerchantSelectValue.selectAll
);

export const selectUserSelectValueGroupByType = createSelector(
  selectUserSelectValue,
  merchantSelectValue => {
    const list = groupBy(merchantSelectValue, 'type');
    return list;
  }
);

export const selectUserSelectValueGroupBySpecificType = (types: string[]) =>
  createSelector(
    selectUserSelectValue,
    userSelectValue => {
      const list = groupBy(userSelectValue.filter(value => types.includes(value.type)), 'type');
      return list;
    }
  );
