<div id="home-page" class="page-wrapper">
  <div class="page-content flex-item">
    <div class="flex-item d-none d-md-block">
      <router-outlet name="leftsection"></router-outlet>
    </div>
    <div class="form-section flex-item">
      <div class="home-logo mt-3">
        <img src="assets/images/vertical-logo-border.png" alt="TD Tawandang" />
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="mt-3">
    <p class="footer">
      &copy; 2019 TD Tawandang Co.,Ltd. All rightS reserved. www.tdshop.io
      <app-version-tag></app-version-tag>
    </p>
  </div>
</div>
