import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MerchantService } from '../../services/merchant.service';
import { StoresService } from '../../services/stores.service';
import { TdStoreGroupListActionTypes, TdStoreGroupListFailureAction, TdStoreGroupListSuccessAction } from '../actions/td-store-group-list.action';
import { TdStoreListActionTypes, TdStoreListFailureAction, TdStoreListSuccessAction } from '../actions/td-store-list.action';
var TdStoreListEffects = /** @class */ (function () {
    function TdStoreListEffects(actions, storesService, merchantService, router, logger) {
        var _this = this;
        this.actions = actions;
        this.storesService = storesService;
        this.merchantService = merchantService;
        this.router = router;
        this.logger = logger;
        this.TdStore = this.actions.pipe(ofType(TdStoreListActionTypes.TD_STORE_LIST), switchMap(function (action) {
            _this.logger.debug("@Effect " + TdStoreListActionTypes.TD_STORE_LIST + ": " + _this.stringify(action.payload));
            return _this.merchantService.getStoresByMerchant(action.payload).pipe(map(function (resp) {
                return new TdStoreListSuccessAction(resp);
            }), catchError(function (err) {
                return of(new TdStoreListFailureAction(err.error));
            }));
        }));
        this.TdStoreGroup = this.actions.pipe(ofType(TdStoreGroupListActionTypes.TD_STORE_GROUP_GET_REQUEST), switchMap(function (action) {
            _this.logger.debug("@Effect " + TdStoreGroupListActionTypes.TD_STORE_GROUP_GET_REQUEST + ": " + _this.stringify(action.payload));
            return _this.merchantService.getStoresGroupByMerchant(action.payload).pipe(map(function (resp) {
                return new TdStoreGroupListSuccessAction(resp);
            }), catchError(function (err) {
                return of(new TdStoreGroupListFailureAction(err.error));
            }));
        }));
        this.TdStoresSuccess = this.actions.pipe(ofType(TdStoreListActionTypes.TD_STORE_LIST_SUCCESS), tap(function (resp) {
            _this.logger.debug('@Effect TdStoreListSuccessAction: ' + _this.stringify(resp.payload));
        }));
        this.TdStoresFailure = this.actions.pipe(ofType(TdStoreListActionTypes.TD_STORE_LIST_FAILURE), tap(function (resp) {
            _this.logger.debug('@Effect TdStoreListFailureAction: ' + _this.stringify(resp));
        }));
    }
    TdStoreListEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], TdStoreListEffects.prototype, "TdStore", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], TdStoreListEffects.prototype, "TdStoreGroup", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], TdStoreListEffects.prototype, "TdStoresSuccess", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], TdStoreListEffects.prototype, "TdStoresFailure", void 0);
    return TdStoreListEffects;
}());
export { TdStoreListEffects };
