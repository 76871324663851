import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { TreeItem, TreeviewConfig } from 'ngx-treeview';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ResetPasswordTypeEnum } from '../../../shared/enum/reset-password-type.enum';
import { StoreType, TDPageModes } from '../../../shared/enum/store.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ChildComponent } from '../../../shared/layouts/modals/child-item/child-component';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { BaseModalComponent } from '../../../shared/models/base-modal.component.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { MasterData } from '../../../shared/models/master-data.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { TdStoreGroupListResponse, TdStoreList } from '../../../shared/models/td-store-group-list.model';
import { TdStoreListResponse } from '../../../shared/models/td-store-list.model';
import { Tenant, UserRequest, UserResponse } from '../../../shared/models/user.model';
import { TdStoreGroupListGetAction } from '../../../shared/store/actions/td-store-group-list.action';
import { TdStoreListGetAction } from '../../../shared/store/actions/td-store-list.action';
import {
  ResetTdStoresTreeViewAction,
  TdStoresTreeViewSelectedRequestAction
} from '../../../shared/store/actions/td-stores-tree-view.action';
import {
  ResetUserState,
  UsersEditAction,
  UsersGetAction,
  UsersResetPasswordPinCodeResetAction,
  UsersResetPasswordRequestAction,
  UsersResetPinCodeRequestAction
} from '../../../shared/store/actions/users.action';
import { selectUserInfoResult } from '../../../shared/store/selectors/auth-user-info.selector';
import {
  selectResetPasswordResult,
  selectResetPinResult
} from '../../../shared/store/selectors/reset-password-pin.selector';
import { selectTdStoreGroupListResult } from '../../../shared/store/selectors/td-store-group-list.selector';
import { selectTdStoreListResult } from '../../../shared/store/selectors/td-store-list.selector';
import { selectTdStoresTreeViewResult } from '../../../shared/store/selectors/td-stores-tree-view.selector';
import { selectTenantInfoResult } from '../../../shared/store/selectors/tenant-info.selector';
import { selectUserSelectValueGroupByType } from '../../../shared/store/selectors/user-select-value.selectors';
import { selectUsersResult } from '../../../shared/store/selectors/users.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { UserInfoState } from '../../../shared/store/state/user-info.state';
import { UsersState } from '../../../shared/store/state/users.state';
import { getRoles, isOwner } from '../../../shared/utils/check-role';

@Component({
  selector: 'app-users-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit, OnDestroy, ChildComponent {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { id: string; title: string; mode: TDPageModes };

  public editUserForm: FormGroup;
  public submitted: boolean;
  public tdStoreList: Array<TreeItem>;
  public users$: Observable<UsersState>;
  public tdStores$: Observable<TdStoreListResponse>;
  public tdStoreGroupList$: Observable<TdStoreGroupListResponse[]>;
  public tdStoresConfig: TreeviewConfig;
  public bsConfig: BsDatepickerConfig;
  public userResponse: UserResponse;
  public userSelectValue$: Observable<{ [key: string]: Array<MasterData> } | null>;
  public listOfValue: {};
  public merchantType: StoreType;
  private readonly env = environment;
  private merchantNo: string;
  private tmpElementValue: string[];
  private userName: string;
  private localStore: Observable<any>;
  public userInfo: UserInfoState;
  public isStoreOwner: boolean;
  public selectedAllStoreInMerchant: boolean;
  public selectedStoreGroup: string[];
  public selectedStore: string[];
  public tdStoreAuthorityList: string[];
  public tdStoreGroupList: TdStoreGroupListResponse[];

  constructor(
    private readonly store: Store<AppStates>,
    private readonly formBuilder: FormBuilder,
    private readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly logger: NGXLogger
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.merchantType = null;
    this.listOfValue = {};
    this.isStoreOwner = false;
    this.selectedAllStoreInMerchant = false;
    this.selectedStoreGroup = [];
    this.selectedStore = [];
    this.initState();
    this.initControl();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetUserState());
    this.store.dispatch(new UsersResetPasswordPinCodeResetAction());
  }

  initState() {
    this.localStore.pipe(select(selectUserInfoResult)).subscribe(userInfo => {
      this.userInfo = userInfo;
      this.isStoreOwner = userInfo.roles.includes('STORE_OWNER') || userInfo.roles.includes('STORE_OWNER_PARTNER');

      if (!this.isStoreOwner) {
        this.editUserForm.controls['stores'].setValidators(Validators.required);
      }
    });

    this.localStore.pipe(select(selectTenantInfoResult)).subscribe(tenantInfo => {
      this.merchantNo = tenantInfo.merchant;
      this.tdStoreAuthorityList = tenantInfo.stores;
    });

    this.tdStores$ = this.localStore.pipe(
      select(selectTdStoreListResult),
      map(resp => resp.result.response),
      filter(value => value !== null),
      switchMap(resp => of(resp))
    );
    this.tdStores$.subscribe(value => {
      this.merchantType = value.content[0].merchantType;
      this.listOfValue['roles'] = getRoles(this.merchantType, false);
    });
    this.store.dispatch(
      new TdStoreListGetAction({ merchantNo: this.merchantNo, storeNo: this.tdStoreAuthorityList.join(',') })
    );

    if (this.isStoreOwner) {
      this.store.dispatch(new TdStoreGroupListGetAction({ merchantNo: this.merchantNo }));
      this.tdStoreGroupList$ = this.localStore.pipe(
        select(selectTdStoreGroupListResult),
        switchMap(resp => of(resp))
      );
    } else {
      this.localStore
        .pipe(
          select(selectTdStoresTreeViewResult),
          switchMap(resp => of(resp))
        )
        .subscribe((value: TreeItem[]) => {
          if (this.data.mode === TDPageModes.REQUEST_VIEW) {
            value.forEach((item: TreeItem) => (item.disabled = true));
          }

          this.tdStoreList = value;
        });
    }

    this.store.dispatch(new UsersGetAction(this.data.id));
    this.users$ = this.localStore.pipe(select(selectUsersResult));

    this.users$.pipe(map(action => action.result)).subscribe(
      next => {
        if (next.response && this.submitted) {
          this.onSuccess(next);
        } else if (next.errorResponse) {
          this.onError(next.errorResponse);
        } else {
          this.logger.debug(next);
        }
      },
      error => this.onError(error),
      () => this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null })
    );

    this.store.dispatch(new ResetTdStoresTreeViewAction());

    this.localStore
      .pipe(
        select(selectResetPasswordResult),
        filter(result => Boolean(result) && Boolean(result.statusCode)),
        tap(result => this.resetPasswordEvent(result.statusCode, ResetPasswordTypeEnum.PASSWORD))
      )
      .subscribe();

    this.localStore
      .pipe(
        select(selectResetPinResult),
        filter(result => Boolean(result) && Boolean(result.statusCode)),
        tap(result => this.resetPasswordEvent(result.statusCode, ResetPasswordTypeEnum.PIN))
      )
      .subscribe();
    this.userSelectValue$ = this.localStore.pipe(select(selectUserSelectValueGroupByType));
  }

  initControl() {
    this.editUserForm = this.formBuilder.group({
      id: '',
      no: '',
      version: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      localFirstName: ['', [Validators.required, Validators.maxLength(100)]],
      localLastName: ['', [Validators.required, Validators.maxLength(100)]],
      mobile: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.email, Validators.maxLength(100)]],
      gender: ['', []],
      active: ['', []],
      birthDay: ['', []],
      roles: [null, [Validators.required]],
      stores: [null, []]
    });

    this.tdStoresConfig = new TreeviewConfig();
    this.tdStoresConfig.hasAllCheckBox = false;

    this.bsConfig = {
      dateInputFormat: this.env.dateFormat,
      maxDate: new Date(),
      showWeekNumbers: false
    } as BsDatepickerConfig;

    combineLatest([
      this.localStore.pipe(
        select(selectUserInfoResult),
        map(authUserInfo => authUserInfo.userNo)
      ),
      this.users$.pipe(
        filter(val => val.result.response !== null),
        map(val => val.result.response),
        filter(val => val !== null && !this.submitted)
      )
    ]).subscribe(([loginUserName, userResponse]) => {
      this.onBindingFormData(userResponse);
      this.userResponse = userResponse;

      this.editUserForm.controls['active'].setValue(userResponse.active.toString());
      this.editUserForm.controls['active'].disable();

      if (loginUserName === userResponse.userNo) {
        this.editUserForm.controls['active'].disable();
      } else {
        this.editUserForm.controls['active'].enable();
      }

      if (this.data.mode === TDPageModes.REQUEST_VIEW) {
        this.editUserForm.disable();
      }
    });

    if (this.isStoreOwner) {
      combineLatest([
        this.tdStoreGroupList$.pipe(filter(val => Boolean(val))),
        this.users$.pipe(
          filter(val => val.result.response !== null),
          map(val => val.result.response)
        )
      ]).subscribe(([StoreList, user]) => {
        this.selectedAllStoreInMerchant = user.accessAllStores;
        this.selectedStoreGroup = user.accessStoreGroups;
        this.selectedStore = user.accessStores;

        StoreList.forEach(storeGroup => {
          const selectGroup = this.selectedStoreGroup.includes(storeGroup.no) && !this.selectedAllStoreInMerchant;
          storeGroup.selected = selectGroup;
          storeGroup.stores.forEach(store => {
            if (!selectGroup) {
              store.selected = this.selectedStore.includes(store.no) && !this.selectedAllStoreInMerchant;
            }
          });
        });
        this.tdStoreGroupList = [...StoreList];
      });
    } else {
      combineLatest([
        this.tdStores$.pipe(map(val => val.content.filter(Boolean))),
        this.users$.pipe(
          filter(val => val.result.response !== null),
          map(val => val.result.response.tenant.stores.filter(Boolean))
        )
      ]).subscribe(([merchant, stores]) =>
        this.store.dispatch(new TdStoresTreeViewSelectedRequestAction({ merchant, stores }))
      );
    }
  }

  private resetPasswordEvent(statusCode: string, type: ResetPasswordTypeEnum) {
    if (statusCode === '200') {
      this.alertSuccessResetPasswordPin(type);
    } else {
      this.alertFailedResetPasswordPin(type);
    }
  }

  onBindingFormData(data: UserResponse) {
    const countryCode = data.countryCode || '';
    const mobileNo = data.mobileNo || '';

    this.userName = data.userName;

    this.editUserForm.controls['mobile'].setValue(`${countryCode}${mobileNo}`);
    this.editUserForm.controls['birthDay'].setValue(data.birthDate);
    this.editUserForm.controls['roles'].enable();

    for (const [key, value] of Object.entries(data).filter(
      ([name, val]) => val !== null && this.editUserForm.contains(name)
    )) {
      if (key === 'roles') {
        const role = value[0];

        if (isOwner(role)) {
          this.editUserForm.controls['roles'].disable();
          this.listOfValue['roles'] = getRoles(this.merchantType, true);
        }

        this.editUserForm.controls['roles'].setValue(role);
      } else if (key !== 'active') {
        this.editUserForm.controls[key].setValue(value);
      }
    }

    // Add validation rules
    this.tmpElementValue = new Array<string>();
    this.preventBlock(this.editUserForm.controls, 'firstName', /^[a-z A-Z]*$/);
    this.preventBlock(this.editUserForm.controls, 'lastName', /^[a-z A-Z]*$/);
  }

  setStores(result: string[]) {
    this.editUserForm.controls['stores'].setValue(result);
  }

  onSubmit() {
    let req: UserRequest;

    this.submitted = true;

    if (this.editUserForm.invalid || this.validateStore) {
      if (!this.editUserForm.invalid && this.validateStore) {
        this.showAlert(this.translate.instant('FAILED'), this.translate.instant('PLEASE_SELECT_AT_LEAST_ONE_STORE'));
      }
      return;
    }
    req = new UserRequest(this.editUserForm.getRawValue());
    req.tenant = new Tenant();
    req.tenant.merchant = this.merchantNo;
    req.accessAllStores = this.selectedAllStoreInMerchant;
    req.accessStoreGroups = this.selectedStoreGroup;
    req.accessStores = this.isStoreOwner ? this.selectedStore : this.editUserForm.value.stores;
    req.countryCode = this.editUserForm.value.mobile.internationalNumber.split(' ')[0];
    req.mobileNo = this.editUserForm.value.mobile.nationalNumber.split(' ').join('');

    if (this.editUserForm.value.birthDay && this.editUserForm.value.birthDay !== '') {
      req.birthDate = moment(this.editUserForm.value.birthDay, environment.dateFormat).format(environment.dateFormat);
    }

    this.store.dispatch(new UsersEditAction(req));
  }

  onCancel() {
    if (this.editUserForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState: initialState,
        notificationType: NotificationTypeEnum.CONFIRM,
        result: null
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null });
    }
  }

  showAlert(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  get validateStore(): boolean {
    const checkSelectedStore =
      this.selectedAllStoreInMerchant || this.selectedStoreGroup.length > 0 || this.selectedStore.length > 0;
    return this.isStoreOwner && !checkSelectedStore;
  }

  onChangeAll(event: any, storeGroupList: TdStoreGroupListResponse[]) {
    if (event.target.checked) {
      this.selectedAllStoreInMerchant = true;
      this.selectedStoreGroup = [];
      this.selectedStore = [];
      storeGroupList.forEach((storeGroup: TdStoreGroupListResponse) => this.clearSelectedStoreAll(storeGroup));
    } else {
      this.selectedAllStoreInMerchant = false;
      storeGroupList.forEach((storeGroup: TdStoreGroupListResponse) => this.selectedEnableStoreAll(storeGroup));
    }
  }

  onCheckboxChangeGroup(event: any, storeGroup: TdStoreGroupListResponse) {
    if (event.target.checked) {
      this.selectStoreGroup(storeGroup);
    } else {
      this.deSelectStoreGroup(storeGroup);
    }
  }

  onCheckboxChange(event: any, store: TdStoreList) {
    if (event.target.checked) {
      store.selected = true;
      this.selectedStore.push(store.no);
    } else {
      store.selected = false;
      this.removeSelectedStore(store.no);
    }
  }

  selectStoreGroup(storeGroupUser: TdStoreGroupListResponse) {
    storeGroupUser.selected = true;
    this.selectedStoreGroup.push(storeGroupUser.no);
    storeGroupUser.stores.forEach((store: TdStoreList) => {
      store.selected = false;
      store.disabled = true;
      this.removeSelectedStore(store.no);
    });
  }

  deSelectStoreGroup(storeGroupUser: TdStoreGroupListResponse) {
    storeGroupUser.selected = false;
    this.removeSelectedStoreGroup(storeGroupUser.no);
    storeGroupUser.stores.forEach((store: TdStoreList) => {
      store.disabled = false;
    });
  }

  clearSelectedStoreAll(storeGroupUser: TdStoreGroupListResponse) {
    storeGroupUser.selected = false;
    storeGroupUser.disabled = true;
    storeGroupUser.stores.forEach((store: TdStoreList) => {
      store.selected = false;
      store.disabled = true;
    });
  }

  selectedEnableStoreAll(storeGroupUser: TdStoreGroupListResponse) {
    storeGroupUser.disabled = false;
    storeGroupUser.stores.forEach((store: TdStoreList) => {
      store.disabled = false;
    });
  }

  removeSelectedStoreGroup(code: string) {
    this.selectedStoreGroup.splice(this.selectedStoreGroup.findIndex(itemSelected => itemSelected === code), 1);
  }

  removeSelectedStore(code: string) {
    this.selectedStore.splice(this.selectedStore.findIndex(itemSelected => itemSelected === code), 1);
  }

  handleResetPassword() {
    if (this.userResponse) {
      this.confirmResetPassword();
    }
  }

  private confirmResetPassword() {
    const initialState = {
      title: this.translate.instant('RESET_PASSWORD'),
      message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_RESET_PASSWORD', {
        fullName: `${this.userResponse.firstName} ${this.userResponse.lastName}`
      }),
      okText: this.translate.instant('OK')
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        // TODO Dispatch reset password
        this.store.dispatch(new UsersResetPasswordRequestAction({ userName: this.userName }));
        confirmModalRef.content.action.unsubscribe();
      }
    });
  }

  handleResetPinCode() {
    const initialState = {
      title: this.translate.instant('RESET_PIN_CODE'),
      message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_RESET_PIN_CODE', {
        fullName: `${this.userResponse.firstName} ${this.userResponse.lastName}`
      }),
      okText: this.translate.instant('OK')
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        // TODO Dispatch reset password
        this.store.dispatch(new UsersResetPinCodeRequestAction({ userName: this.userName }));
        confirmModalRef.content.action.unsubscribe();
      }
    });
  }

  alertSuccessResetPasswordPin(type: ResetPasswordTypeEnum) {
    const initialState: BaseModalComponent = {
      title: this.translate.instant('SUCCESS'),
      message:
        type === ResetPasswordTypeEnum.PASSWORD
          ? this.translate.instant('PASSWORD_RESET_SUCCESS', { mobileNo: this.userResponse.mobileNo })
          : this.translate.instant('PIN_RESET_SUCCESS', { mobileNo: this.userResponse.mobileNo })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  alertFailedResetPasswordPin(type: string) {
    const initialState: BaseModalComponent = {
      title: this.translate.instant('FAILED'),
      message:
        type === ResetPasswordTypeEnum.PASSWORD
          ? this.translate.instant('PASSWORD_RESET_FAILED')
          : this.translate.instant('PIN_RESET_FAILED')
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  get f(): {
    [key: string]: AbstractControl;
  } {
    return this.editUserForm.controls;
  }

  get pageMode() {
    return TDPageModes;
  }

  private preventBlock(controls: any, key: string, regexp: RegExp = /^.*$/) {
    if (!this.tmpElementValue[key]) {
      this.tmpElementValue[key] = '';
    }

    controls[key].valueChanges.pipe(filter(val => val !== this.tmpElementValue[key])).forEach(val => {
      if (regexp.test(val)) {
        this.tmpElementValue[key] = val;
      }
      controls[key].setValue(this.tmpElementValue[key]);
    });
  }

  private onSuccess(resp: any) {
    const initialState: BaseModalComponent = {
      title: this.translate.instant('SUCCESS'),
      message: this.translate.instant('USER_UPDATED'),
      routerLink: 'users'
    };

    this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: resp });
    });
    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  private onError(resp: any) {
    if (resp.translateKey) {
      const initialState: BaseModalComponent = {
        title: this.translate.instant('FAILED'),
        message: this.translate.instant(resp.translateKey)
      };

      const modal = this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.LOGGING, result: resp });
        modal.unsubscribe();
      });
      this.modalService.show(AlertModalComponent, { initialState });
    }
  }

  get isViewMode(): boolean {
    return this.data.mode === TDPageModes.REQUEST_VIEW;
  }

  public onTriggerEdit() {
    this.data.mode = TDPageModes.REQUEST_EDIT;
    this.data.title = this.translate.instant('EDIT_USER');
    if (!this.isStoreOwner) {
      this.tdStoreList.forEach((item: TreeItem) => (item.disabled = false));
    }
    this.editUserForm.enable();
  }
}
