export enum ProductStatusEnum {
  SELECT = 'SELECT',
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  SEASONAL = 'SEASONAL',
  HOLD_BUY = 'HOLD_BUY',
  RECALL = 'RECALL',
  CLEAR = 'CLEAR',
  CLEAR_PROMOTION = 'CLEAR_PROMOTION',
  HOLD_BUY_SELL = 'HOLD_BUY_SELL',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  DELETE = 'DELETE'
}
