import { OnInit } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
var VersionTagComponent = /** @class */ (function () {
    function VersionTagComponent() {
    }
    VersionTagComponent.prototype.ngOnInit = function () {
        this.initVersion = env.version + "-" + env.commit;
        if (env.tier === 'dev') {
            this.initVersion += " branch:" + env.branch;
        }
    };
    Object.defineProperty(VersionTagComponent.prototype, "version", {
        get: function () {
            return this.initVersion;
        },
        enumerable: true,
        configurable: true
    });
    return VersionTagComponent;
}());
export { VersionTagComponent };
