import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models/error.model';
import { OrderSummary } from '../../models/orders.model';
import { CartsAction, CartsActionTypes } from '../actions/carts.action';
import { BaseState } from '../state/base.state';

export interface OrderState extends EntityState<OrderSummary>, BaseState {
  isLoading: boolean;
  result: {
    response: OrderSummary | null;
    errorResponse: ErrorResponse | null;
  };
}

export const adapter: EntityAdapter<OrderSummary> = createEntityAdapter<OrderSummary>();

export const initialOrderSummaryState: OrderState = adapter.getInitialState({
  isLoading: false,
  result: { response: null, errorResponse: null }
});

export function orderSummaryReducers(state = initialOrderSummaryState, action: CartsAction): OrderState {
  switch (action.type) {
    case CartsActionTypes.ORDER_SUMMARY_RESPONSE:
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    case CartsActionTypes.ORDER_SUMMARY_ERROR:
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
