<div class="flex-item d-flex justify-content-center">
  <form
    [formGroup]="loginForm"
    (ngSubmit)="onSubmit()"
    class="right-section border border-top-0 border-left-0 shadow-lg col-sm-10"
  >
    <div class="flex-item">
      <div class="login-header mb-4">
        <h5 class="text-td-red text-center">{{ 'LOGIN' | translate }}</h5>
        <app-switch-lang-toggle></app-switch-lang-toggle>
      </div>
      <!--        <p class="text-secondary text-center">{{ 'WERE_HAPPY' | translate }}</p>-->
      <div *ngIf="(forceChangePasswordResult$ | async).result.isSuccess" class="form-group">
        <div class="alert alert-success alert-icon" role="alert">
          <span class="fa nicon icon-check-circle mr-3"></span>
          <span>
            {{ 'RESET_PASSWORD_SUCCESSFULLY' | translate }}<br />
            {{ 'PLEASE_LOGIN_WITH_YOUR_NEW_PASSWORD' | translate }}
          </span>
        </div>
      </div>
      <div *ngIf="(forgotUsernameResult$ | async).result.isSuccess" class="form-group">
        <div class="alert alert-success alert-icon" role="alert">
          <span class="fa nicon icon-check-circle mr-3"></span>
          <span
            translate="FORGOT_USERNAME_SUCCESS"
            [translateParams]="{
              mobileNumber: (forgotUsernameResult$ | async).result.mobileNumber | mask: '000-000-0000'
            }"
          ></span>
        </div>
      </div>
      <div *ngIf="(forgotPasswordResult$ | async).result.isSuccess" class="form-group">
        <div class="alert alert-success alert-icon" role="alert">
          <span class="fa nicon icon-check-circle mr-3"></span>
          <span>
            {{ 'RESET_PASSWORD_SUCCESSFULLY' | translate }}<br />
            {{ 'PLEASE_LOGIN_WITH_YOUR_NEW_PASSWORD' | translate }}
          </span>
        </div>
      </div>
      <div *ngIf="(result$ | async).result.errorResponse.translateKey" class="form-group">
        <div class="alert alert-danger alert-icon" role="alert">
          <span class="fa nicon icon-xcross mr-3"></span>
          <span [innerHTML]="(result$ | async).result.errorResponse.translateKey | translate"></span>
        </div>
      </div>

      <div class="right-section-control">
        <div class="form-group">
          <label for="username">{{ 'USERNAME' | translate }}</label>
          <input
            type="text"
            formControlName="username"
            class="form-control"
            id="username"
            name="username"
            placeholder="{{ 'ENTER_USERNAME' | translate }}"
            [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
          />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">{{ 'PASSWORD' | translate }}</label>
          <div class="inner-addon right-addon">
            <span (click)="toggleInputType()" [ngClass]="{ 'is-show': isShowPassword }">
              <em
                class="fas nicon icon-eye glyphicon"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              ></em>
            </span>
            <input
              type="{{ isShowPassword ? 'text' : 'password' }}"
              formControlName="password"
              class="form-control"
              id="password"
              name="password"
              placeholder="{{ 'ENTER_PASSWORD' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button type="submit" id="btn-signin" class="btn btn-primary btn-block">
            {{ 'LOGIN' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <a href="#" routerLink="/forgot-username">{{ 'FORGOT_USERNAME' | translate }}</a>
      <a href="#" routerLink="/forgot-password" class="text-right">{{
        'FORGOT_PASSWORD.FORGOT_PASSWORD' | translate
      }}</a>
    </div>
  </form>
</div>
