import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CreateCartResponse } from '../../models/cart.model';
import { ErrorResponse } from '../../models/error.model';
import { CartsAction, CartsActionTypes } from '../actions/carts.action';
import { BaseState } from '../state/base.state';

export interface CreateCartState extends EntityState<CreateCartResponse>, BaseState {
  isLoading: boolean;
  result: {
    response: CreateCartResponse;
    errorResponse: ErrorResponse;
  };
}

export const cartAdaptor: EntityAdapter<CreateCartResponse> = createEntityAdapter<CreateCartResponse>();

export const initialCreateCartState: CreateCartState = cartAdaptor.getInitialState({
  isLoading: false,
  result: { response: null, errorResponse: null }
});

export function cartReducers(state = initialCreateCartState, action: CartsAction): CreateCartState {
  switch (action.type) {
    case CartsActionTypes.CREATE_CARTS_RESPONSE: {
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    }
    case CartsActionTypes.CREATE_CARTS_ERROR: {
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = cartAdaptor.getSelectors();
