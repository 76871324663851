import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var EventService = /** @class */ (function () {
    function EventService() {
        this._eventsSubject = new Subject();
    }
    EventService.prototype.BroadcastEvent = function (key, value) {
        this._eventsSubject.next({ key: key, value: value });
    };
    EventService.prototype.GetEvent = function (key) {
        return this._eventsSubject.asObservable().pipe(filter(function (e) { return e.key === key; }), map(function (e) { return e.value; }));
    };
    EventService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventService_Factory() { return new EventService(); }, token: EventService, providedIn: "root" });
    return EventService;
}());
export { EventService };
