import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var SidebarService = /** @class */ (function () {
    function SidebarService(router) {
        this.router = router;
        this.toggled = false;
    }
    SidebarService.prototype.toggle = function () {
        this.toggled = !this.toggled;
    };
    SidebarService.prototype.getSidebarState = function () {
        return this.toggled;
    };
    SidebarService.prototype.setSidebarState = function (state) {
        this.toggled = state;
    };
    SidebarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SidebarService_Factory() { return new SidebarService(i0.ɵɵinject(i1.Router)); }, token: SidebarService, providedIn: "root" });
    return SidebarService;
}());
export { SidebarService };
