import { ErrorResponse } from '../../models/error.model';
import { All, RolesActionTypes } from '../actions/roles.actions';
import { BaseRolesState, initialRolesState } from '../state/roles.state';

export function rolesReducers(state = initialRolesState, action: All): BaseRolesState {
  switch (action.type) {
    case RolesActionTypes.ROLES_SUCCESS: {
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: new ErrorResponse()
        }
      };
    }
    case RolesActionTypes.ROLES_FAILURE: {
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    }
    case RolesActionTypes.ROLES: {
      return initialRolesState;
    }
    default: {
      return state;
    }
  }
}
