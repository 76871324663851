import { Action } from '@ngrx/store';
import { AuthRequest, AuthResponse } from '../../models/auth.model';
import { ErrorResponse } from '../../models/error.model';

export enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  REFRESH = '[Auth] Refresh',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGIN_RESET = '[Auth] Login Reset',
  LOGOUT = '[Auth] Logout'
}

export class LogInAction implements Action {
  readonly type = AuthActionTypes.LOGIN;

  constructor(public payload: AuthRequest) {}
}

export class RefreshAction implements Action {
  readonly type = AuthActionTypes.REFRESH;

  constructor(public payload: string) {}
}

export class LogInSuccessAction implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;

  constructor(public payload: AuthResponse) {}
}

export class LogInFailureAction implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;

  constructor(public payload: ErrorResponse) {}
}

export class LogOutAction implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class LoginReset implements Action {
  readonly type = AuthActionTypes.LOGIN_RESET;
}

export type All = LogInAction | RefreshAction | LogInSuccessAction | LogInFailureAction | LogOutAction | LoginReset;
