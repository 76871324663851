import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AddItemError, AddItemResponse } from '../../models/cart.model';
import { CartsAction, CartsActionTypes } from '../actions/carts.action';
import { BaseState } from '../state/base.state';

export interface AddItemState extends EntityState<AddItemResponse>, BaseState {
  isLoading: boolean;
  result: {
    response: AddItemResponse;
    errorResponse: AddItemError;
    addItemSuccess?: boolean;
  };
}

export const addItemAdaptor: EntityAdapter<AddItemResponse> = createEntityAdapter<AddItemResponse>();

export const initialAddItemState: AddItemState = addItemAdaptor.getInitialState({
  isLoading: false,
  result: { response: null, errorResponse: null }
});

export function addItemReducers(state = initialAddItemState, action: CartsAction): AddItemState {
  switch (action.type) {
    case CartsActionTypes.ADD_ITEM_RESPONSE: {
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: null,
          addItemSuccess: true
        }
      };
    }
    case CartsActionTypes.ADD_ITEM_ERROR: {
      return {
        ...state,
        result: {
          response: state.result.response,
          errorResponse: action.payload
        }
      };
    }
    case CartsActionTypes.RESET_ADD_ITEM_ERROR: {
      return {
        ...state,
        result: {
          response: state.result.response,
          errorResponse: null
        }
      };
    }
    case CartsActionTypes.RESET_ADD_ITEM_STATUS: {
      return {
        ...state,
        result: {
          response: state.result.response,
          errorResponse: null,
          addItemSuccess: null
        }
      };
    }

    case CartsActionTypes.GET_CART_RESPONSE: {
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = addItemAdaptor.getSelectors();
