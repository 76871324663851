import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TdStoreGroupListResponse } from '../../models/td-store-group-list.model';
import { TdStoreListResponse } from '../../models/td-store-list.model';
import { MerchantService } from '../../services/merchant.service';
import { StoresService } from '../../services/stores.service';
import {
  TdStoreGroupListActionTypes,
  TdStoreGroupListFailureAction,
  TdStoreGroupListGetAction,
  TdStoreGroupListSuccessAction
} from '../actions/td-store-group-list.action';
import {
  TdStoreListActionTypes,
  TdStoreListFailureAction,
  TdStoreListGetAction,
  TdStoreListSuccessAction
} from '../actions/td-store-list.action';

@Injectable()
export class TdStoreListEffects {
  constructor(
    private actions: Actions,
    private storesService: StoresService,
    private merchantService: MerchantService,
    private readonly router: Router,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  TdStore: Observable<Action> = this.actions.pipe(
    ofType<TdStoreListGetAction>(TdStoreListActionTypes.TD_STORE_LIST),
    switchMap(action => {
      this.logger.debug(`@Effect ${TdStoreListActionTypes.TD_STORE_LIST}: ` + this.stringify(action.payload));

      return this.merchantService.getStoresByMerchant(action.payload).pipe(
        map((resp: TdStoreListResponse) => {
          return new TdStoreListSuccessAction(resp);
        }),
        catchError(err => {
          return of(new TdStoreListFailureAction(err.error));
        })
      );
    })
  );

  @Effect()
  TdStoreGroup: Observable<Action> = this.actions.pipe(
    ofType<TdStoreGroupListGetAction>(TdStoreGroupListActionTypes.TD_STORE_GROUP_GET_REQUEST),
    switchMap(action => {
      this.logger.debug(
        `@Effect ${TdStoreGroupListActionTypes.TD_STORE_GROUP_GET_REQUEST}: ` + this.stringify(action.payload)
      );

      return this.merchantService.getStoresGroupByMerchant(action.payload).pipe(
        map((resp: TdStoreGroupListResponse[]) => {
          return new TdStoreGroupListSuccessAction(resp);
        }),
        catchError(err => {
          return of(new TdStoreGroupListFailureAction(err.error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  TdStoresSuccess: Observable<Action> = this.actions.pipe(
    ofType(TdStoreListActionTypes.TD_STORE_LIST_SUCCESS),
    tap((resp: TdStoreListSuccessAction) => {
      this.logger.debug('@Effect TdStoreListSuccessAction: ' + this.stringify(resp.payload));
    })
  );

  @Effect({ dispatch: false })
  TdStoresFailure: Observable<Action> = this.actions.pipe(
    ofType(TdStoreListActionTypes.TD_STORE_LIST_FAILURE),
    tap((resp: TdStoreListFailureAction) => {
      this.logger.debug('@Effect TdStoreListFailureAction: ' + this.stringify(resp));
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
