<div class="container-fluid">
  <div class="row" [formGroup]="orderSchedule">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12 mb-3">
          <label for="minOrder">{{ 'MINIMUM_ORDER' | translate }}<span class="text-danger">*</span></label>
          <app-numeric-textbox
            [negativeSign]="false"
            [format]="'0,0.00'"
            id="minOrder"
            formControlName="minOrder"
            class="form-control"
            placeholder="{{ 'MINIMUM_ORDER' | translate }}"
            [ngClass]="{ 'is-invalid': submitted && orderSchedule.controls.minOrder.errors }"
          ></app-numeric-textbox>
          <div *ngIf="submitted && orderSchedule.controls.minOrder.errors" class="invalid-feedback">
            <div *ngIf="orderSchedule.controls.minOrder.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
      <app-order-policy-schedule
        #orderPolicySchedule
        [parentForm]="orderSchedule"
        [submitted]="submitted"
        [mode]="mode"
      ></app-order-policy-schedule>
    </div>
  </div>
</div>
