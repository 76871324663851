<app-search-bar (searchCriteria)="handleSearchText($event)"></app-search-bar>
<div class="row">
  <div class="col-5 col-md-3">
    <strong>{{ 'CAT_TYPE' | translate }}</strong>
    <app-tree-menu (catCode)="onActivate($event)"></app-tree-menu>
  </div>
  <div
    class="col-7 col-md-9"
    *ngIf="catalogList$ | async"
    infiniteScroll
    (scrolled)="onScrollDown()"
    [infiniteScrollDisabled]="stopLoading"
    [infiniteScrollContainer]="'div.full-popup-inner'"
    [fromRoot]="true"
  >
    <h6 class="text-danger" *ngIf="displaySearchText; else displayCat">
      {{ 'SEARCH_RESULT' | translate }} "{{ displaySearchText | translate }}"
    </h6>
    <ng-template #displayCat>
      <h6 class="text-danger" *ngIf="displayCatText; else allCategory">{{ displayCatText | translate }}</h6>
    </ng-template>
    <ng-template #allCategory
      ><h6 class="text-danger">{{ 'CAT_CODE.null' | translate }}</h6></ng-template
    >
    <p>({{ catalogListTotal$ | async }} {{ 'ITEMS' | translate }})</p>
    <div class="no-result-container" *ngIf="(catalogList$ | async).length === 0">
      <div class="d-flex justify-content-md-center">
        <img width="240" height="240" src="assets/images/no-item.svg" alt="no item" />
      </div>
    </div>
    <div class="row">
      <div class="card" *ngFor="let catalog of catalogList$ | async">
        <img
          class="card-img-top pointer bg-light"
          [ngClass]="{ pointer: !checkDisableStatus.isDisablePriceNull(catalog) }"
          (click)="checkDisableStatus.isDisablePriceNull(catalog) ? $event.preventDefault() : callItemDetails(catalog)"
          appSrc="{{ catalog.productImage?.small }}"
          alt="{{ catalog.productName }}"
        />
        <div
          class="card-body"
          [ngClass]="{ pointer: !checkDisableStatus.isDisablePriceNull(catalog) }"
          (click)="checkDisableStatus.isDisablePriceNull(catalog) ? $event.preventDefault() : callItemDetails(catalog)"
        >
          <h6 class="card-title card-title-text" *ngIf="catalog.productName | textExist">
            {{ catalog.productName | textEllipsis }}
          </h6>
          <p class="card-text promotion-text text-danger">
            {{ catalog.promotion || '' | textEllipsis: ['25'] }}
          </p>
          <p class="card-text" *ngIf="catalog.wholesalePrice">
            {{ catalog.wholesalePrice?.amount | number }} {{ catalog.wholesalePrice?.currency | translate }}&nbsp;<span
              class="text-secondary"
              >/{{ 'UNIT.' + catalog.unit | translate }}</span
            >
          </p>
        </div>

        <div class="card-footer">
          <span
            class="mr-2 text-danger"
            *ngIf="
              catalog.productStatus === productStatus.RECALL || catalog.productStatus === productStatus.HOLD_BUY_SELL;
              else outOfStock
            "
            >{{ 'PRODUCT_STATUS.' + productStatus.HOLD_BUY_SELL | translate }}</span
          >
          <ng-template #outOfStock>
            <span
              class="mr-2 text-danger"
              *ngIf="catalog.productStatus === productStatus.OUT_OF_STOCK; else defaultFooter"
              >{{ 'PRODUCT_STATUS.' + productStatus.OUT_OF_STOCK | translate }}</span
            >
          </ng-template>
          <ng-template #defaultFooter>
            <span class="mr-2">{{ 'QUANTITY' | translate }}</span>
            <input
              #quantity
              id="{{ catalog.barcode }}-list"
              name="{{ catalog.barcode }}s-list"
              type="text"
              maxlength="3"
              class="input-quantity mr-2"
              value="1"
              [ngClass]="{ 'border border-danger': !quantity.value }"
              appNumbersOnly
              (keyup.enter)="addItem(catalog.barcode, quantity.value)"
              [disabled]="checkDisableStatus.isDisablePriceNull(catalog)"
            />
            <button
              id="add-item-{{ catalog.barcode }}"
              type="button"
              class="btn btn-danger order-button"
              (click)="addItem(catalog.barcode, quantity.value)"
              [disabled]="checkDisableStatus.isDisablePriceNull(catalog)"
            >
              {{ 'BUY' | translate }}
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
