import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { environment as env } from '../../../../environments/environment';

interface SwitchLang {
  code: string;
  name: string;
}

@Component({
  selector: 'app-switch-lang-toggle',
  templateUrl: './switch-lang-toggle.component.html',
  styleUrls: ['./switch-lang-toggle.component.scss']
})
export class SwitchLangToggleComponent implements OnInit, OnDestroy {
  public langList: Array<SwitchLang>;
  public currentLang: string;
  public nextLang: SwitchLang;

  private currentLangIdx = 0;

  constructor(private readonly translate: TranslateService) {}

  ngOnInit() {
    this.langList = this.setSwitchObject();

    // set current language value
    this.currentLang = this.translate.currentLang || env.defaultLanguage;
    const idx = this.langList.findIndex(x => x.code === this.translate.currentLang || x.code === env.defaultLanguage);
    this.currentLangIdx = idx;

    // set next language value
    const nextLangIdx = (idx + 1) % this.langList.length;
    this.nextLang = this.langList[nextLangIdx];
  }

  ngOnDestroy(): void {}

  setSwitchObject(): Array<SwitchLang> {
    const array = new Array<SwitchLang>();

    env.supportedLanguages.forEach(val => {
      const newObj: SwitchLang = { code: val, name: '' };

      this.translate
        .get(`LANG_CODE.${val}`)
        .pipe(untilComponentDestroyed(this))
        .subscribe(value => (newObj.name = value));
      array.push(newObj);
    });

    return array;
  }

  setLang() {
    // set current language value
    this.currentLangIdx = ++this.currentLangIdx % this.langList.length;
    this.currentLang = this.langList[this.currentLangIdx].code;

    // set next language value
    const nextLangIdx = (this.currentLangIdx + 1) % this.langList.length;
    this.nextLang = this.langList[nextLangIdx];
    this.translate.use(this.currentLang);
  }
}
