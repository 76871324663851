import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthGuardService } from '../../services';
import { MenuActionTypes, MenuLoadedAction } from '../actions/menu.action';
var MenuEffects = /** @class */ (function () {
    function MenuEffects(actions, logger, authGuardService) {
        var _this = this;
        this.actions = actions;
        this.logger = logger;
        this.authGuardService = authGuardService;
        this.MenuRequested = this.actions.pipe(ofType(MenuActionTypes.MenuRequested), map(function (action) {
            _this.logger.debug('@Effect MenuRequestAction: ' + JSON.stringify(action.payload));
            return new MenuLoadedAction(_this.authGuardService.getMenuByPermission(action.payload));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], MenuEffects.prototype, "MenuRequested", void 0);
    return MenuEffects;
}());
export { MenuEffects };
