import { createSelector } from '@ngrx/store';
import * as fromVerificationState from '../reducers/verification.reducers';
import { AppStates } from '../state/app.states';

const selectMerchantVerificationState = (state: AppStates) => state.merchantVerification;

export const selectMerchantVerificationResult = createSelector(
  selectMerchantVerificationState,
  (state: fromVerificationState.MerchantVerificationState) => state
);
