import { UserActionTypes, UserInfoActions } from '../actions/user-info.action';
import { UserInfoInitialState, UserInfoState } from '../state/user-info.state';

export function userInfoReducers(state = UserInfoInitialState, action: UserInfoActions): UserInfoState {
  switch (action.type) {
    case UserActionTypes.USER_INFO: {
      return {
        ...state,
        userNo: action.payload.userNo,
        userName: action.payload.userName,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        mobileNo: action.payload.mobileNo,
        roles: action.payload.roles,
        authorities: action.payload.authorities,
        forceChangePassword: action.payload.forceChangePassword,
        profileImageName: action.payload.profileImageName,
        displayName: setDisplayName(action.payload.firstName, action.payload.lastName)
      };
    }
    case UserActionTypes.USER_INFO_RESET: {
      return {
        ...UserInfoInitialState
      };
    }
    default: {
      return state;
    }
  }
}

function setDisplayName(firstName: string, lastName: string): string {
  firstName = firstName ? firstName : '';
  lastName = lastName ? ' ' + lastName : '';

  return `${firstName + lastName}`;
}
