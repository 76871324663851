import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { UsersState } from '../state/users.state';

const selectUsers = (state: AppStates) => state.users;

export const selectUsersResult = createSelector(
  selectUsers,
  (state: UsersState) => state
);
