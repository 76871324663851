import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { Role } from '../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  constructor(private readonly logger: NGXLogger) {}

  public getRoles(roleId?: string) {
    const roleList = 'CASHIER, STORE_MANAGER, STORE_OWNER'; // 'CASHIER, STORE_MANAGER, STORE_OWNER, ADMIN, IMPLEMENTOR, SALE_MANAGER, PRODUCT_MANAGER, MARKETING_MANAGER';
    let roles = this.generateRoleList(roleList);

    try {
      roles = roleId ? roles.slice(0, +roleId) : roles; // select one or all roleList.

      return of(roles);
    } catch (e) {
      this.logger.error(e);

      return of(roles);
    }
  }

  private generateRoleList(roleList: string) {
    return roleList.split(', ').map(
      (item, index) =>
        new Role({
          id: index.toString(),
          name: item,
          code: item
        } as Role)
    );
  }
}
