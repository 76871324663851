import { Action } from '@ngrx/store';
import { TreeviewItem } from 'ngx-treeview';
import { TdStoreListContent } from '../../models/td-store-list.model';
import { TdStoreSelectObject } from '../../models/td-store-select-object.model';

export enum TdStoresTreeViewActionTypes {
  TD_STORES_TREE_VIEW_REQUEST = '[TdStore] TD-stores Tree Request',
  TD_STORES_TREE_VIEW_CHECKED_REQUEST = '[TdStore] TD-stores Tree Selected Request',
  TD_STORES_TREE_VIEW_RESPONSE = '[TdStore] TD-stores Tree Response',
  RESET_TD_STORES_TREE_STATE = '[TdStore] Reset TD-stores Tree State'
}

export class TdStoresTreeViewRequestAction implements Action {
  readonly type = TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_REQUEST;

  constructor(public payload?: TdStoreSelectObject[]) {}
}

export class TdStoresTreeViewSelectedRequestAction implements Action {
  readonly type = TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_CHECKED_REQUEST;

  constructor(public payload: { merchant: TdStoreListContent[]; stores: string[] }) {}
}

export class TdStoresTreeViewResponseAction implements Action {
  readonly type = TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_RESPONSE;

  constructor(public payload: TreeviewItem[]) {}
}

export class ResetTdStoresTreeViewAction implements Action {
  readonly type = TdStoresTreeViewActionTypes.RESET_TD_STORES_TREE_STATE;

  constructor() {}
}

export type All =
  | TdStoresTreeViewRequestAction
  | TdStoresTreeViewSelectedRequestAction
  | TdStoresTreeViewResponseAction
  | ResetTdStoresTreeViewAction;
