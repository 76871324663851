import { ClientIdTypeEnum } from '../enum/client-id.enum';
import { TenantInformation } from './tenant-information.model';

export class AuthRequest {
  username: string;
  password?: string;
  token?: string;
  refresh_token?: string;
  grant_type: string;
}

export class AuthResponse {
  access_token: string;
  expires_in: number;
  jti: string;
  refresh_token: string;
  scope: string;
  tenant_info: TenantInformation;
  token_type: string;
  user_info: UserInfo;
}

export class AuthUser {
  tenant_info: TenantInformation;
  user_info: UserInfo;
  user_name: string;
  scope: string[];
  exp: number;
  jti: string;
  authorities: string[];
  client_id: ClientIdTypeEnum;
}

export class UserInfo {
  userNo: string;
  userName: string;
  userCode: string;
  firstName: string;
  lastName: null;
  localFirstName: string;
  localLastName: string;
  email: string;
  mobileNo: string;
  roles: string[];
  authorities: string[];
  forceChangePassword: boolean;
  forceChangePinCode?: boolean;
  profileImageName: string;
  merchantName: string;
  merchantNo: string;
  displayName: string;
}
