import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserSelectValueService } from '../../services/user-select-value.service';
import { UserSelectValueActionType, UserSelectValueErrorAction, UserSelectValueResponseAction } from '../actions/user-select-value.actions';
var UserSelectValueEffects = /** @class */ (function () {
    function UserSelectValueEffects(actions$, userSelectValueService, logger) {
        var _this = this;
        this.actions$ = actions$;
        this.userSelectValueService = userSelectValueService;
        this.logger = logger;
        this.merchantSelectValue$ = this.actions$.pipe(ofType(UserSelectValueActionType.USER_SELECT_VALUE_REQUEST), map(function (action) {
            _this.logger.debug('@Effect UserSelectValue: ' + _this.stringify(action.payload));
            return action.payload;
        }), switchMap(function (payload) {
            return _this.userSelectValueService.getMerchantSelectValue(payload).pipe(map(function (res) {
                return new UserSelectValueResponseAction(res);
            }), catchError(function (error) {
                return of(new UserSelectValueErrorAction(error));
            }));
        }));
    }
    UserSelectValueEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UserSelectValueEffects.prototype, "merchantSelectValue$", void 0);
    return UserSelectValueEffects;
}());
export { UserSelectValueEffects };
