<div class="container-fluid admin-form">
  <h4>{{ 'VIEW_STORE_LIST' | translate }}</h4>

  <form [formGroup]="searchAndFilterForm" class="mt-md-4 mt-sm-2 ml-auto col-lg-6 col-md-9 px-0" novalidate>
    <div class="d-inline-block form-group col-lg-8 col-md-7 pr-md-0">
      <div class="input-group position-relative">
        <input
          formControlName="searchText"
          type="text"
          class="form-control"
          (keyup.enter)="handleSearchText()"
          [maxLength]="50"
          placeholder="{{ 'ENTER_KEYWORD_SEARCH' | translate }}"
        />
        <em
          class="fa fa-times-circle icon-cross"
          [ngClass]="{ 'd-none': !searchAndFilterForm.value.searchText }"
          (click)="clearSearchText()"
        ></em>
        <div class="input-group-append">
          <button class="btn search-button btn-nicon btn-search" type="button" (click)="handleSearchText()"></button>
        </div>
      </div>
    </div>
    <div class="d-inline-block form-group col-lg-4 col-md-5 pr-md-0 mb-md-2 mb-sm-3">
      <ng-select
        [searchable]="false"
        id="status"
        [clearable]="false"
        (change)="selectedStatus($event)"
        formControlName="status"
      >
        <ng-option *ngFor="let status of status" [value]="status.value">{{ status.label }}</ng-option>
      </ng-select>
    </div>
  </form>

  <div class="clearfix">
    <div class="no-result-container" *ngIf="(storeList$ | async).result.response?.empty">
      <div class="d-flex justify-content-md-center">
        <img width="240" height="240" src="assets/images/not-found.svg" alt="not-found" />
      </div>
    </div>

    <div class="table-wrapper">
      <table
        id="datatable"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
        *ngIf="(storeList$ | async).result.response && (storeList$ | async).result.response?.hasContent"
      >
        <thead>
          <tr>
            <th>{{ headerRow[0] | translate }}</th>
            <th>{{ headerRow[1] | translate }}</th>
            <th>{{ headerRow[2] | translate }}</th>
            <th>{{ headerRow[3] | translate }}</th>
            <th>{{ headerRow[4] | translate }}</th>
            <th class="disabled-sorting" *hasPermission="['storeprofile_m']">
              {{ headerRow[5] | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tdStore of (storeList$ | async).result.response.content" class="pointer">
            <td (click)="callEditStore(tdStore.no, pageMode.REQUEST_VIEW)">
              {{ tdStore.code }}
            </td>
            <td (click)="callEditStore(tdStore.no, pageMode.REQUEST_VIEW)">
              {{ tdStore.name }}
            </td>
            <td (click)="callEditStore(tdStore.no, pageMode.REQUEST_VIEW)">
              {{ tdStore.lastModifiedDate | dateFormat | date: 'medium' | amLocal | amDateFormat: 'DD/MM/YYYY' }}
            </td>
            <td (click)="callEditStore(tdStore.no, pageMode.REQUEST_VIEW)">
              {{ tdStore.status | translate }}
            </td>
            <td (click)="callEditStore(tdStore.no, pageMode.REQUEST_VIEW)">
              <span *ngIf="tdStore.deviceStatus && tdStore.deviceStatus !== deviceStatus.WAITING; else none">{{
                tdStore.deviceStatus | translate
              }}</span>
              <ng-template #none>
                <span>({{ 'NONE' | translate }})</span>
              </ng-template>
            </td>
            <td
              class="action"
              (click)="callEditStore(tdStore.no, pageMode.REQUEST_VIEW)"
              *hasPermission="['storeprofile_m']"
            >
              <!-- <span (click)="callEditStore(tdStore.no, pageMode.REQUEST_EDIT)"> -->
              <em class="fa fa-edit pointer-event text-center mx-1"></em>
              <!-- </span> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav class="border-0" *ngIf="(storeList$ | async).result.response?.hasContent">
      <div class="form-group row float-md-left col-sm-6">
        <label for="recordPerPage" class="col-form-label">{{ 'ROW_PER_PAGE' | translate }}</label>
        <div class="col-md-6">
          <select
            class="custom-select custom-select-sm col-lg-3 col-md-5"
            id="recordPerPage"
            #recordPerPage
            (change)="onRowPerPagesSelected(recordPerPage.value)"
          >
            <option value="20" selected>20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div class="float-right d-flex">
        <div class="page-container">
          <label
            >{{ (currentPage - 1) * listRequest.size + 1 }} {{ 'TO' | translate }}
            {{
              currentPage * listRequest.size > (storeList$ | async).result.response.totalElements
                ? (storeList$ | async).result.response.totalElements
                : currentPage * listRequest.size
            }}
            {{ 'OF' | translate }} {{ (storeList$ | async).result.response.totalElements }} {{ 'ENTRIES' | translate }}
          </label>
        </div>

        <div class="d-inline-block">
          <pagination
            class="justify-content-end pagination-sm text-success"
            [boundaryLinks]="true"
            [totalItems]="(storeList$ | async).result.response.totalElements"
            [itemsPerPage]="(storeList$ | async).result.response.size"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;"
            [maxSize]="5"
            (pageChanged)="pageChanged($event)"
            [ngModel]="currentPage"
          >
          </pagination>
        </div>
      </div>
    </nav>
  </div>
</div>
