<div class="full-popup-head">
  <div class="full-popup-title float-left">{{ data.title }}</div>
  <div class="full-popup-close float-right">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="fa fa-times"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div class="admin-form">
  <form [formGroup]="addUserForm" (ngSubmit)="onSubmit()" class="add-user-form">
    <div class="row alert-dark mt-4 mb-3 mx-0">
      <span class="pl-2 my-1">{{ 'USER_PROFILE' | translate }}</span>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="firstName" data-required>{{ 'FIRST_NAME_EN' | translate }}</label>
          <input
            type="text"
            class="form-control"
            formControlName="firstName"
            id="firstName"
            name="firstName"
            maxlength="100"
            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
            appPascalCase
            appAlphabetOnly
          />
          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="lastName" data-required>{{ 'LAST_NAME_EN' | translate }}</label>
          <input
            type="text"
            formControlName="lastName"
            class="form-control"
            id="lastName"
            name="lastName"
            maxlength="100"
            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
            appPascalCase
            appAlphabetOnly
          />
          <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="localFirstName" data-required>{{ 'FIRST_NAME_LOCAL' | translate }}</label>
          <input
            type="text"
            formControlName="localFirstName"
            class="form-control"
            id="localFirstName"
            name="localFirstName"
            maxlength="100"
            [ngClass]="{ 'is-invalid': submitted && f.localFirstName.errors }"
          />
          <div *ngIf="submitted && f.localFirstName.errors" class="invalid-feedback">
            <div *ngIf="f.localFirstName.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="localLastName" data-required>{{ 'LAST_NAME_LOCAL' | translate }}</label>
          <input
            type="text"
            formControlName="localLastName"
            class="form-control"
            id="localLastName"
            name="localLastName"
            maxlength="100"
            [ngClass]="{ 'is-invalid': submitted && f.localLastName.errors }"
          />
          <div *ngIf="submitted && f.localLastName.errors" class="invalid-feedback">
            <div *ngIf="f.localLastName.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="mobile" data-required>{{ 'MOBILE' | translate }}</label>
          <ngx-intl-tel-input
            [cssClass]="'form-control d-block ' + (submitted && f.mobile.errors ? 'is-invalid' : '')"
            [preferredCountries]="['th']"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="false"
            [maxlength]="'25'"
            id="mobile"
            formControlName="mobile"
          ></ngx-intl-tel-input>
          <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
            <div *ngIf="f.mobile.errors.required; else validatePhoneNumber">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <ng-template #validatePhoneNumber>
              <div *ngIf="f.mobile.errors.validatePhoneNumber">
                {{ 'ERRORS.INVALID_PHONE' | translate }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="email">{{ 'EMAIL' | translate }}</label>
          <input
            type="email"
            formControlName="email"
            class="form-control"
            id="email"
            name="email"
            maxlength="100"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
          />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="f.email.errors.email">
              {{ 'ERRORS.INVALID_EMAIL' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label>{{ 'GENDER' | translate }}</label>
          <div class="">
            <div
              class="custom-control custom-radio custom-control-inline"
              [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"
            >
              <input
                type="radio"
                id="genderM"
                name="gender"
                class="custom-control-input"
                formControlName="gender"
                value="male"
              />
              <label class="custom-control-label" for="genderM">{{ 'MALE' | translate }}</label>
            </div>
            <div
              class="custom-control custom-radio custom-control-inline"
              [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"
            >
              <input
                type="radio"
                id="genderFE"
                name="gender"
                class="custom-control-input"
                formControlName="gender"
                value="female"
              />
              <label class="custom-control-label" for="genderFE">{{ 'FEMALE' | translate }}</label>
            </div>
            <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
              <div *ngIf="f.gender.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="birthDay">{{ 'BIRTH_DATE' | translate }}</label>
          <input
            type="text"
            class="form-control"
            [placeholder]="'DDMMYYYY' | translate"
            bsDatepicker
            id="birthDay"
            name="birthDay"
            [bsConfig]="bsConfig"
            formControlName="birthDay"
            readonly
            [ngClass]="{ 'is-invalid': submitted && f.birthDay.errors }"
          />
          <em class="fa fa-calendar-alt btn-calendar"></em>
          <div *ngIf="submitted && f.birthDay.errors" class="invalid-feedback">
            <div *ngIf="f.birthDay.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label>{{ 'USER_STATUS' | translate }}</label>
          <div class="">
            <div
              class="custom-control custom-radio custom-control-inline"
              [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"
            >
              <input
                type="radio"
                id="activeT"
                name="active"
                class="custom-control-input"
                formControlName="active"
                value="true"
              />
              <label class="custom-control-label" for="activeT">{{ 'ACTIVE' | translate }}</label>
            </div>
            <div
              class="custom-control custom-radio custom-control-inline"
              [ngClass]="{ 'is-invalid': submitted && f.gender.errors }"
            >
              <input
                type="radio"
                id="activeF"
                name="active"
                class="custom-control-input"
                formControlName="active"
                value="false"
              />
              <label class="custom-control-label" for="activeF">{{ 'INACTIVE' | translate }}</label>
            </div>
            <div *ngIf="submitted && f.active.errors" class="invalid-feedback">
              <div *ngIf="f.active.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row alert-dark mt-4 mb-3 mx-0">
      <span class="pl-2 my-1">{{ 'ROLE' | translate }}</span>
    </div>
    <div class="row" *ngIf="merchantType !== null">
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="roles" data-required>{{ 'ASSIGN_ROLE' | translate }}</label>
          <ng-select
            [searchable]="false"
            [clearable]="false"
            [ngClass]="{ 'is-invalid': submitted && f.roles.errors }"
            placeholder="{{ 'PLEASE_SELECT' | translate }}"
            id="roles"
            name="roles"
            class="form-control"
            formControlName="roles"
          >
            <ng-option *ngFor="let role of listOfValue['roles']" [value]="role.code">{{
              role.name | translate
            }}</ng-option>
          </ng-select>
          <div *ngIf="submitted && f.roles.errors" class="invalid-feedback">
            <div *ngIf="f.roles.errors.required">
              {{ 'ERRORS.REQUIRED_ROLE' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row alert-dark mt-4 mb-3 mx-0">
      <span class="pl-2 my-1">{{ 'STORE' | translate }}</span>
    </div>

    <div
      *ngIf="isStoreOwner; else storeList"
      id="StoreAuthorityDetail"
      class="checkbox-wrapper before-fixed-row-bottom-popup form-row"
    >
      <div class="container-fluid">
        <div class="row py-2">
          <div class="col-md-12 mb-3 pb-5">
            <ng-container *ngIf="tdStoreGroupList$ | async as list; else storeGroupNotFound">
              <div class="row alert-dark my-2 box-store-merchant">
                <label class="m-2 pl-2 bd-highlight ">
                  <input
                    type="checkbox"
                    [checked]="selectedAllStoreInMerchant"
                    name="allStoreInMerchant"
                    (change)="onChangeAll($event, list)"
                  />
                  <span>{{ 'ACCESS_TO_ALL_STORES' | translate }}</span>
                </label>
              </div>
              <div class="row">
                <div
                  class="col-md-6 mt-2 px-2"
                  *ngFor="let storeGroup of list; index as i"
                  [style.order]="storeGroup.defaultGroup ? -1 : 1"
                >
                  <label class="p-1 pl-2 bd-highlight ">
                    <input
                      type="checkbox"
                      [disabled]="storeGroup.disabled || selectedAllStoreInMerchant"
                      [checked]="storeGroup.selected"
                      (change)="onCheckboxChangeGroup($event, storeGroup)"
                      name="{{ storeGroup.no }}"
                    />
                    <span class="font-weight-bold"> {{ storeGroup.name }}</span>
                  </label>
                  <hr class="my-1" />
                  <div class="row pl-3">
                    <div class="col-md-6" *ngFor="let store of storeGroup.stores">
                      <label class="p-2  bd-highlight ">
                        <input
                          type="checkbox"
                          [disabled]="store.disabled || storeGroup.selected || selectedAllStoreInMerchant"
                          [checked]="store.selected"
                          (change)="onCheckboxChange($event, store)"
                          name="{{ store.code }}"
                        />
                        <span>{{ store.code }}-{{ store.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #storeGroupNotFound>
              <div class="no-result-container text-center">
                <div class="d-flex justify-content-center">
                  <img width="240" height="240" src="assets/images/not-found.svg" alt="Data not found" />
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <ng-template #storeList>
      <div class="before-fixed-row-bottom-popup form-row">
        <div class="col-md-6">
          <div class="form-group col-xs-1 col-md-12">
            <label for="roles" data-required>{{ 'ASSIGN_STORE' | translate }}</label>
            <ngx-treeview
              [items]="tdStoreList$ | async"
              [config]="tdStoresConfig"
              (selectedChange)="setStores($event)"
              id="treeview"
              class="ngx-treeview form-control"
              [ngClass]="{ 'is-invalid': submitted && f.stores.errors }"
            >
            </ngx-treeview>
            <div *ngIf="submitted && f.stores.errors" class="invalid-feedback">
              <div *ngIf="f.stores.errors.required">
                {{ 'ERRORS.REQUIRED_STORE' | translate }}
              </div>
            </div>
            <input type="hidden" formControlName="stores" id="stores" name="stores" />
          </div>
        </div>
      </div>
    </ng-template>

    <div class="fixed-row-bottom-popup form-row text-right">
      <button type="button" id="btn-outline-primary" (click)="onCancel()" class="btn btn-outline-primary ">
        {{ 'CANCEL' | translate }}
      </button>
      &nbsp;
      <button type="submit" id="btn-add" class="btn btn-primary ">
        {{ 'SAVE' | translate }}
      </button>
    </div>
  </form>
</div>
