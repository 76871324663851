import { TdStoreListAction, TdStoreListActionTypes } from '../actions/td-store-list.action';
import { BaseTdStoreListState, initialTdStoreListState } from '../state/td-store-list.state';

export function tdStoreListReducers(state = initialTdStoreListState, action: TdStoreListAction): BaseTdStoreListState {
  switch (action.type) {
    case TdStoreListActionTypes.TD_STORE_LIST_SUCCESS: {
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    }
    case TdStoreListActionTypes.TD_STORE_LIST_FAILURE: {
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    }
    case TdStoreListActionTypes.RESET_TD_STORE_LIST_STATE: {
      return initialTdStoreListState;
    }
    default: {
      return state;
    }
  }
}
