import { ErrorResponse } from '../../models/error.model';
import { TdStoreGroupListResponse } from '../../models/td-store-group-list.model';
import { BaseState } from './base.state';

export interface BaseTdStoreGroupListState extends BaseState {
  result: {
    response: TdStoreGroupListResponse[] | null;
    errorResponse: ErrorResponse | null;
  };
}

export const initialTdStoreGroupListState: BaseTdStoreGroupListState = {
  result: {
    response: null,
    errorResponse: null
  }
};
