import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AuthService } from '../services';

@Injectable()
export class MerchantVerificationResolver implements Resolve<any> {
  constructor(private authService: AuthService) {}

  resolve() {
    return this.authService.bypassAuthenToken();
  }
}
