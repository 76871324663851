import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { ForgotUsernameRequestAction, ForgotUsernameReset } from '../../shared/store/actions/forgot-username.action';
import { selectForgotUsernameResult } from '../../shared/store/selectors/forgot-username.selector';
import { AppStates } from '../../shared/store/state/app.states';
import { BaseForgotUsernameState } from '../../shared/store/state/forgot-username.state';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss']
})
export class ForgotUsernameComponent implements OnInit, OnDestroy {
  public forgotUsernameForm: FormGroup;
  public forgotUsernameResult$: Observable<BaseForgotUsernameState>;
  public submitted: boolean;

  private localStore: Observable<any>;

  constructor(private readonly store: Store<AppStates>, private readonly formBuilder: FormBuilder) {}

  ngOnInit() {
    this.store.dispatch(new ForgotUsernameReset());

    this.forgotUsernameForm = this.formBuilder.group({
      mobileNumber: ['', Validators.required]
    });

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.forgotUsernameResult$ = this.localStore.pipe(select(selectForgotUsernameResult));
  }

  ngOnDestroy(): void {}

  onSubmit() {
    this.submitted = true;

    if (this.forgotUsernameForm.invalid) {
      return;
    }

    this.store.dispatch(new ForgotUsernameRequestAction({ mobileNumber: this.forgotUsernameForm.value.mobileNumber }));

    // reset form
    requestAnimationFrame(() => {
      this.forgotUsernameForm.reset();
      this.submitted = false;
    });
  }

  get f(): {
    [key: string]: AbstractControl;
  } {
    return this.forgotUsernameForm.controls;
  }
}
