import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { NGXLogger } from 'ngx-logger';
import { environment as env } from '../../../../environments/environment';

interface SwitchLang {
  code: string;
  name: string;
}

@Component({
  selector: 'app-switch-lang-menu',
  templateUrl: './switch-lang-menu.component.html',
  styleUrls: ['./switch-lang-menu.component.scss']
})
export class SwitchLangMenuComponent implements OnInit, OnDestroy {
  public langList: Array<SwitchLang>;
  public currentLang: string;

  constructor(private readonly translate: TranslateService, private readonly logger: NGXLogger) {}

  ngOnInit() {
    this.langList = new Array<SwitchLang>();
    this.currentLang = this.translate.currentLang || env.defaultLanguage;

    env.supportedLanguages.forEach(val => {
      const newObj: SwitchLang = { code: val, name: '' };

      this.translate
        .get(`LANG_CODE.${val}`)
        .pipe(untilComponentDestroyed(this))
        .subscribe(value => (newObj.name = value));
      this.langList.push(newObj);
    });
  }

  ngOnDestroy(): void {}

  setLang(langCode: string) {
    this.translate.use(langCode);
    this.currentLang = langCode;
    this.logger.info('Your current language-code is ' + langCode);
  }
}
