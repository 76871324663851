import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ItemActionTypeEnum } from '../../../../shared/enum/item-action.enum';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { ProductStatusEnum } from '../../../../shared/enum/product-status.enum';
import { ChildItem } from '../../../../shared/layouts/modals/child-item/child-item';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { FullModalComponent } from '../../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  AddItemRequest,
  AddItemResponse,
  CreateCartResponse,
  DeleteCartItemRequest,
  ItemDetail
} from '../../../../shared/models/cart.model';
import { Catalog } from '../../../../shared/models/catalog.model';
import { ConfirmModal } from '../../../../shared/models/confirm-modal.mode';
import { ItemModalComponent } from '../../../../shared/models/item-modal.component.model';
import {
  AddItemRequestAction,
  DeleteCartItemRequestAction,
  DeleteInvalidItemsRequestAction,
  GetCartAction
} from '../../../../shared/store/actions/carts.action';
import { CreateCartState } from '../../../../shared/store/reducers/cart.reducers';
import { selectCartItemResult, selectCreateCartResult } from '../../../../shared/store/selectors/cart.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { CheckDisableStatus } from '../../../../shared/utils/check-disable-status';
import { ItemComponent } from '../../item/item.component';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class ItemListComponent implements OnInit, OnDestroy {
  public createdCart$: Observable<CreateCartState>;
  public cartItem$: Observable<AddItemResponse>;
  public paymentConfirmForm: FormGroup;
  public bsModalRef: BsModalRef;
  public $inactiveProductStatus = { $or: this.checkDisableStatus.invalidItemStatus() };

  private cart: CreateCartResponse;
  private subject: Subject<any> = new Subject();
  private localStore: Observable<any>;

  constructor(
    private readonly store: Store<AppStates>,
    private readonly formBuilder: FormBuilder,
    private translate: TranslateService,
    private readonly modalService: BsModalService
  ) {
    this.paymentConfirmForm = this.formBuilder.group({
      cartItems: this.formBuilder.array([])
    });
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.createdCart$ = this.localStore.pipe(select(selectCreateCartResult));
    this.createdCart$.subscribe(createdCart => {
      if (createdCart.result.response) {
        this.cart = createdCart.result.response;
        this.store.dispatch(new GetCartAction({ cartId: this.cart.cartId }));
      }
    });
    this.cartItem$ = this.localStore.pipe(
      select(selectCartItemResult),
      switchMap(cartItem => {
        return of(cartItem.response);
      })
    );

    this.subject
      .pipe(
        untilComponentDestroyed(this),
        debounceTime(500)
      )
      .subscribe(quantity => {
        if (quantity.value) {
          this.updateItem(quantity.value, quantity.name);
        }
      });
  }

  onAdd(quantity: string, barcode: string) {
    const newVal = +quantity >= 999 ? 999 : +quantity + 1;
    (document.getElementById('cart-item-' + barcode) as HTMLInputElement).value = newVal.toString();
    this.updateItem(newVal.toString(), barcode);
  }

  onReduce(quantity: string, barcode: string) {
    const newVal = +quantity <= 1 ? 1 : +quantity - 1;
    (document.getElementById('cart-item-' + barcode) as HTMLInputElement).value = newVal.toString();
    this.updateItem(newVal.toString(), barcode);
  }

  onQuantityKeyUp(quantity) {
    if (!quantity.value) {
      quantity.classList.add('border', 'border-danger');
      // cart-item-error{{ cartItem.barcode }}
    } else {
      quantity.classList.remove('border', 'border-danger');
    }
    this.subject.next(quantity);
  }

  updateItem(quantity: string, barcode: string) {
    const addItemRequest: AddItemRequest = {
      barcode: barcode,
      qty: quantity,
      action: ItemActionTypeEnum.UPDATE,
      cartId: this.cart.cartId
    };
    if (quantity) {
      this.store.dispatch(new AddItemRequestAction(addItemRequest));
    }
  }

  deleteItem(barcode: string) {
    const initialState: ConfirmModal = {
      title: this.translate.instant('DELETE'),
      okText: this.translate.instant('YES_DELETE'),
      cancelText: this.translate.instant('CANCEL'),
      message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ITEM')
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        const deleteCartItemRequest: DeleteCartItemRequest = {
          cartId: this.cart.cartId,
          barcode: barcode
        };
        this.store.dispatch(new DeleteCartItemRequestAction(deleteCartItemRequest));
      }
    });
  }

  callItemDetails(catalog: Catalog | ItemDetail) {
    const initialState: ItemModalComponent = {
      title: null,
      childItem: new ChildItem(ItemComponent, {
        catalog: catalog,
        cart: this.cart,
        title: this.translate.instant('BACK_TO_PREVIOUS_PAGE'),
        isResetQuantity: false,
        step: '2'
      })
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState: initialState
    });
  }

  deleteInvalidItems() {
    this.store.dispatch(new DeleteInvalidItemsRequestAction({ cartId: this.cart.cartId }));
  }

  get productStatus() {
    return ProductStatusEnum;
  }

  get checkDisableStatus() {
    return CheckDisableStatus;
  }

  ngOnDestroy(): void {}
}
