import { ErrorResponse } from '../../models/error.model';
import { UserListActions, UsersActionTypes } from '../actions/user-list.actions';
import { initialUserListState, UserListState } from '../state/user-list.state';

export function userListReducers(state = initialUserListState, action: UserListActions): UserListState {
  switch (action.type) {
    case UsersActionTypes.USERS_RESPONSE: {
      return {
        ...state,
        usersList: {
          response: action.payload,
          error: new ErrorResponse()
        }
      };
    }
    case UsersActionTypes.USERS_ERROR: {
      return {
        ...state,
        usersList: {
          response: null,
          error: {
            code: action.payload.code,
            message: action.payload.message,
            translateKey: action.payload.translateKey
          }
        }
      };
    }
    default: {
      return state;
    }
  }
}
