import { Action } from '@ngrx/store';
import { ClientIdTypeEnum } from '../../enum/client-id.enum';
import { UserInfo } from '../../models/auth.model';
import { TenantInformation } from '../../models/tenant-information.model';

export enum UserActionTypes {
  USER_INFO = '[User] User Info',
  USER_TENANT = '[User] Tenant Info',
  USER_CLIENT_ID = '[User] Client ID',
  USER_INFO_RESET = '[User] User Info Reset'
}

export class UserInformationAction implements Action {
  readonly type = UserActionTypes.USER_INFO;

  constructor(public payload: UserInfo) {}
}

export class TenantInformationAction implements Action {
  readonly type = UserActionTypes.USER_TENANT;

  constructor(public payload: TenantInformation) {}
}

export class ClientIdAction implements Action {
  readonly type = UserActionTypes.USER_CLIENT_ID;

  constructor(public payload: ClientIdTypeEnum | null) {}
}

export class UserInformationResetAction implements Action {
  readonly type = UserActionTypes.USER_INFO_RESET;

  constructor() {}
}

export type UserInfoActions =
  | UserInformationAction
  | TenantInformationAction
  | ClientIdAction
  | UserInformationResetAction;
