<div id="forgor-password-form" class="flex-item d-flex justify-content-center">
  <form
    class="right-section border border-top-0 border-left-0 shadow-lg col-sm-10 d-flex"
    [formGroup]="changePasswordForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="flex-item">
      <div class="header">
        <h5 class="text-td-red text-center mb-4 d-none d-md-block text-center">
          {{ 'SET_NEW_PASSWORD' | translate }}
        </h5>

        <div class="mobile-header d-md-none">
          <h5 class="text-center">
            {{ 'RESET_PASSWORD' | translate }}
          </h5>
          <strong>{{
            'HELLO_FULL_NAME'
              | translate
                : {
                    firstName: (userInfoResult$ | async)?.firstName,
                    lastName: (userInfoResult$ | async)?.lastName
                  }
          }}</strong>
          <p>{{ 'WELCOME_TO_THE_SYSTEM_FOR_MORE_SECURITY_PLEASE_SET_YOUR_NEW_PASSWORD' | translate }}</p>
        </div>
      </div>

      <div
        *ngIf="(forceChangePasswordResult$ | async).result.errorResponse.translateKey === 'ERROR_CODE.undefined'"
        class="form-group"
      >
        <div class="alert alert-danger alert-icon" role="alert">
          <span class="fa nicon icon-xcross mr-3"></span>
          <span
            [innerHTML]="(forceChangePasswordResult$ | async)?.result.errorResponse.translateKey | translate"
          ></span>
        </div>
      </div>

      <div class="right-section-control">
        <div class="form-group">
          <label for="newPassword">{{ 'NEW_PASSWORD' | translate }}</label>
          <div class="inner-addon right-addon">
            <span (click)="toggleInputPasswordType()" [ngClass]="{ 'is-show': isShowPassword }">
              <em
                class="fas nicon icon-eye glyphicon"
                [ngClass]="{ 'is-invalid': submitted && changePasswordFormGetter.newPassword.errors }"
              ></em>
            </span>
            <input
              type="{{ isShowPassword ? 'text' : 'password' }}"
              class="form-control"
              formControlName="newPassword"
              id="newPassword"
              name="newPassword"
              placeholder="{{ 'ENTER_PASSWORD' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && changePasswordFormGetter.newPassword.errors }"
              (keydown.space)="$event.preventDefault()"
              (paste)="$event.preventDefault()"
              (focus)="clearPasswordSamePreviousError()"
            />
            <div
              *ngIf="submitted && changePasswordFormGetter.newPassword.errors"
              class="invalid-feedback"
              id="new-password-error"
            >
              <div *ngIf="changePasswordFormGetter.newPassword.errors.required; else invalidPassword">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <ng-template #invalidPassword>
                <div *ngIf="changePasswordFormGetter.newPassword.errors.invalidPassword">
                  {{ 'ERRORS.PASSWORD_RULE' | translate }}
                </div>
              </ng-template>
              <div *ngIf="changePasswordFormGetter.newPassword.errors.passwordSamePrevious">
                {{ 'ERRORS.PASSWORD_MUST_NOT_SAME_PREVIOUS' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="reEnterPassword">{{ 'CONFIRM_PASSWORD' | translate }}</label>

          <div class="inner-addon right-addon">
            <span (click)="toggleInputReEnterPasswordType()" [ngClass]="{ 'is-show': isShowReEnterPassword }">
              <em
                class="fas nicon icon-eye glyphicon"
                [ngClass]="{ 'is-invalid': submitted && changePasswordFormGetter.reEnterPassword.errors }"
              ></em>
            </span>
            <input
              type="{{ isShowReEnterPassword ? 'text' : 'password' }}"
              class="form-control"
              formControlName="reEnterPassword"
              id="reEnterPassword"
              name="reEnterPassword"
              placeholder="{{ 'ENTER_PASSWORD' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && changePasswordFormGetter.reEnterPassword.errors }"
              (keydown.space)="$event.preventDefault()"
              (paste)="$event.preventDefault()"
            />
            <div
              *ngIf="submitted && changePasswordFormGetter.reEnterPassword.errors"
              class="invalid-feedback"
              id="re-enter-password-error"
            >
              <div *ngIf="changePasswordFormGetter.reEnterPassword.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="changePasswordFormGetter.reEnterPassword.errors.passwordNotEquivalent">
                {{ 'ERRORS.PASSWORD_NOT_MATCH' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button id="submit" type="submit" class="btn btn-primary btn-block btn px-5">
            {{ 'CONTINUE' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="d-md-none mt-3">
      <a href="#" routerLink="/login">&lt; {{ 'CANCEL' | translate }}</a>
    </div>
  </form>
</div>
