export const storeModelRoles = [
  { code: 'STORE_MANAGER', name: 'STORE_MANAGER' },
  { code: 'CASHIER', name: 'CASHIER' },
  { code: 'STORE_OWNER', name: 'STORE_OWNER' }
];
export const partnerRoles = [
  { code: 'STORE_MANAGER_PARTNER', name: 'STORE_MANAGER_PARTNER' },
  { code: 'CASHIER_PARTNER', name: 'CASHIER_PARTNER' },
  { code: 'STORE_OWNER_PARTNER', name: 'STORE_OWNER_PARTNER' }
];
