import { Component, HostListener, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthGuardService } from '../../services';
import { selectUserInfoResult } from '../../store/selectors/auth-user-info.selector';
import { AppStates } from '../../store/state/app.states';
import { UserInfoState } from '../../store/state/user-info.state';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public userInfoResult$: Observable<UserInfoState>;

  constructor(
    public sidebarService: SidebarService,
    private readonly store: Store<AppStates>,
    private authGuardService: AuthGuardService
  ) {
    this.autoToggleMenu();
  }

  @HostListener('window:resize', ['$event'])
  autoToggleMenu() {
    if (window.innerWidth <= 768) {
      this.sidebarService.setSidebarState(true);
    } else {
      this.sidebarService.setSidebarState(false);
    }
  }

  ngOnInit() {
    this.userInfoResult$ = this.store.pipe(select(selectUserInfoResult));
  }

  toggleSidebar() {
    this.sidebarService.setSidebarState(!this.sidebarService.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarService.getSidebarState();
  }

  hideSidebar() {
    this.sidebarService.setSidebarState(true);
  }

  onLogOut() {
    this.authGuardService.logOut();
  }
}
