import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { MerchantVerificationRequestAction } from '../../../shared/store/actions/verification.actions';
import { MerchantVerificationState } from '../../../shared/store/reducers/verification.reducers';
import { selectMerchantVerificationResult } from '../../../shared/store/selectors/verification.selector';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-mobile-verification',
  templateUrl: './merchant-verification.component.html',
  styleUrls: ['./merchant-verification.component.scss']
})
export class MerchantVerificationComponent implements OnInit, OnDestroy {
  public merchantVerificationForm: FormGroup;
  public submitted = false;
  public merchantVerificationResult$: Observable<MerchantVerificationState>;
  private merchantId: string = null;
  private localStore: Observable<any>;
  constructor(
    private readonly store: Store<AppStates>,
    private readonly formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.merchantVerificationForm = this.formBuilder.group({
      idCardNumber: [null, { validators: [Validators.required, Validators.minLength(13)], updateOn: 'submit' }]
    });

    this.merchantId = this.activeRoute.snapshot.queryParams.m;
    this.merchantVerificationResult$ = this.localStore.pipe(select(selectMerchantVerificationResult));
    this.merchantVerificationResult$.subscribe(response => {
      if (response.result && response.result.errorResponse && response.result.errorResponse.code === '04007') {
        this.f.idCardNumber.setErrors({ invalidId: true });
      } else if (response.result && response.result.response && response.result.response.statusCode === '200') {
        this.router.navigateByUrl('/mer-verify-success');
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.merchantVerificationForm.valid) {
      const merchantVerification = {
        merchantId: this.merchantId,
        idNumber: this.f.idCardNumber.value
      };
      this.store.dispatch(new MerchantVerificationRequestAction(merchantVerification));
    }
  }

  clearFormError(event) {
    event.preventDefault();
    if (event.key !== 'Enter') {
      this.f.idCardNumber.setErrors(null);
    }
  }

  get f(): {
    [key: string]: AbstractControl;
  } {
    return this.merchantVerificationForm.controls;
  }

  ngOnDestroy(): void {}
}
