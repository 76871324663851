import env from './.env';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: env.AppName,
  version: env.version + '-localhost',
  commit: env.commit,
  branch: env.branch,
  tier: 'dev',
  serverUrl: 'https://api-dev.tdshop.io',
  imgUrl: 'https://storage.googleapis.com/product-assortments-dev',
  defaultClientId: 'STORE_PORTAL',
  defaultLanguage: 'th',
  supportedLanguages: ['en-US', 'th'],
  dateFormat: 'DD/MM/YYYY',
  cookies: {
    bufferTimePeriod: 1800000,
    domain: 'localhost',
    path: '/',
    accessToken: 'store-access-token',
    refreshToken: 'store-refresh-token'
  },
  services: {
    auth: {
      STORE_PORTAL: {
        url: '/oauth/token',
        headers: {
          Authorization: 'Basic U1RPUkVfUE9SVEFMOlBAc3N3MHJk',
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-host': 'oauth.api.tdshop.io'
        }
      },
      POS_APPL: {
        url: '/oauth/token',
        headers: {
          Authorization: 'Basic UE9TX0FQUEw6UEBzc3cwcmQ=',
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-host': 'oauth.api.tdshop.io'
        }
      }
    },
    users: {
      url: '/users',
      deleteUserProfile: '/{userNo}',
      getUserProfile: '/{userNo}',
      forceChangePassword: '/{userName}/forceChangePassword',
      resetPassword: '/{userName}/resetPassword',
      resetPinCode: '/{userName}/resetPinCode',
      createOTP: '/{userName}/otp',
      verifyOTP: '/{userName}/verifyOtp',
      forgotPassword: '/{userName}/forgetPassword',
      forgetUserName: '/{mobileNo}/forgetUserName',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    stores: {
      url: '/stores',
      view: '/view/{storeNo}',
      headers: {
        'x-host': 'store.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    orderSuggestion: {
      url: '/suggestions/',
      summary: '/summary',
      headers: {
        'x-host': 'store.api.tdshop.io'
      }
    },
    carts: {
      url: '/carts',
      summary: '/{storeNo}/summary',
      createCart: '/{storeNo}',
      addItem: '/{cartId}/addItem',
      updateItem: '/{cartId}/updateItem',
      deleteInvalidItems: '/{cartId}/deleteInvalidItems',
      deleteCartItem: '/{cartId}/{barcode}',
      checkCartDetails: '/{cartId}/checkCartDetails',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    products: {
      url: '/tdproducts/catalog',
      menu: '/menu',
      headers: {
        'x-host': 'product.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    orders: {
      url: '/orders',
      headers: {
        'x-host': 'order.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    merchants: {
      url: '/merchants',
      stores: '/{merchantNo}/stores',
      storesGroups: '/{merchantNo}/groups',
      storesGroupsById: '/{merchantNo}/groups/{groupId}',
      headers: {
        'x-host': 'merchant.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    },
    userSelectValue: {
      url: '/user-select-values',
      headers: {
        'x-host': 'identity.api.tdshop.io'
      }
    },
    verification: {
      url: '/merchants-requests',
      verifyMobile: '/verifyMobile/{merchantId}',
      status: '/status/{merchantId}',
      headers: {
        'x-host': 'merchant.api.tdshop.io',
        'x-tenant-id': '*:*:*:*'
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
