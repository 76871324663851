import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AlertModalComponent } from '../../layouts';
import { BaseApiResponse } from '../../models/base-api-response';
import { BaseModalComponent } from '../../models/base-modal.component.model';
import { UserResponse } from '../../models/user.model';
import { UsersService } from '../../services/users.service';
import {
  UsersActionTypes,
  UsersAddAction,
  UsersDeleteAction,
  UsersDeleteFailedAction,
  UsersDeleteSuccessAction,
  UsersEditAction,
  UsersFailedAction,
  UsersGetAction,
  UsersResetPasswordRequestAction,
  UsersResetPasswordResponseAction,
  UsersResetPinCodeRequestAction,
  UsersResetPinCodeResponseAction,
  UsersSuccessAction
} from '../actions/users.action';

@Injectable()
export class UsersEffects {
  public bsModalRef: BsModalRef;

  constructor(
    private actions: Actions,
    private usersService: UsersService,
    private readonly logger: NGXLogger,
    private readonly modalService: BsModalService,
    private readonly translate: TranslateService
  ) {}

  @Effect()
  UsersAdd$: Observable<Action> = this.actions.pipe(
    ofType<UsersAddAction>(UsersActionTypes.USERS_ADD),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect UsersAddAction: ' + this.stringify(payload));

      return this.usersService.addUser(payload).pipe(
        map((resp: UserResponse) => {
          resp.updateSuccess = true;
          return new UsersSuccessAction(resp);
        }),
        catchError(err => of(new UsersFailedAction(err.error)))
      );
    })
  );

  @Effect()
  UsersEdit$: Observable<Action> = this.actions.pipe(
    ofType<UsersEditAction>(UsersActionTypes.USERS_EDIT),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect UsersEditAction: ' + this.stringify(payload));

      return this.usersService.editUser(payload).pipe(
        map((resp: UserResponse) => {
          resp.updateSuccess = true;
          return new UsersSuccessAction(resp);
        }),
        catchError(err => of(new UsersFailedAction(err.error)))
      );
    })
  );

  @Effect()
  UsersGet$: Observable<Action> = this.actions.pipe(
    ofType<UsersGetAction>(UsersActionTypes.USERS_GET),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect UsersGetAction: ' + this.stringify(payload));

      return this.usersService.getUser(payload).pipe(
        switchMap((resp: UserResponse) => of(new UsersSuccessAction(resp))),
        catchError(err => {
          const initialState: BaseModalComponent = {
            title: this.translate.instant('FAILED'),
            message: this.translate.instant(err.error.translateKey)
          };

          this.bsModalRef = this.modalService.show(AlertModalComponent, {
            initialState
          });

          return of(new UsersFailedAction(err.error));
        })
      );
    })
  );

  @Effect()
  usersDelete$: Observable<Action> = this.actions.pipe(
    ofType<UsersDeleteAction>(UsersActionTypes.USERS_DELETE),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect UsersDeleteAction: ' + this.stringify(payload));
      return this.usersService.deleteUser(payload).pipe(
        map((resp: HttpResponse<BaseApiResponse>) => {
          return new UsersDeleteSuccessAction({
            statusCode: resp.status.toString(),
            message: resp.statusText
          });
        }),
        catchError(err => {
          let message = this.translate.instant(err.error.translateKey);

          if (message === 'ErrorResponse.message') {
            message = err.error.message;
          }
          const initialState: BaseModalComponent = {
            title: this.translate.instant('FAILED'),
            message: message
          };
          this.bsModalRef = this.modalService.show(AlertModalComponent, {
            initialState
          });
          return of(new UsersDeleteFailedAction(err.error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  usersSuccess$: Observable<Action> = this.actions.pipe(
    ofType<UsersSuccessAction>(UsersActionTypes.USERS_SUCCESS),
    tap((resp: UsersSuccessAction) => {
      this.logger.debug(`@Effect ${UsersActionTypes.USERS_SUCCESS}: ` + this.stringify(resp.payload));
    })
  );

  @Effect({ dispatch: false })
  usersFailed$: Observable<Action> = this.actions.pipe(
    ofType<UsersFailedAction>(UsersActionTypes.USERS_FAILED),
    tap((resp: UsersFailedAction) => {
      this.logger.debug(`@Effect ${UsersActionTypes.USERS_FAILED}: ` + this.stringify(resp.payload));
    })
  );

  @Effect()
  resetPassword$: Observable<Action> = this.actions.pipe(
    ofType<UsersResetPasswordRequestAction>(UsersActionTypes.USERS_RESET_PASSWORD_REQUEST),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Reset Password Request: ' + this.stringify(payload));
      return this.usersService.resetPassword(payload).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Reset Password Response: ' + this.stringify(resp));
          return new UsersResetPasswordResponseAction({ statusCode: resp.status.toString(), message: resp.statusText });
        }),
        catchError(err =>
          of(
            new UsersResetPasswordResponseAction({ statusCode: err.status.toString(), message: 'Reset Password Error' })
          )
        )
      );
    })
  );

  @Effect()
  resetPinCode$: Observable<Action> = this.actions.pipe(
    ofType<UsersResetPinCodeRequestAction>(UsersActionTypes.USERS_RESET_PIN_CODE_REQUEST),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Reset Pin Code Request: ' + this.stringify(payload));
      return this.usersService.resetPinCode(payload).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Reset Pin Code Response: ' + this.stringify(resp));
          return new UsersResetPinCodeResponseAction({ statusCode: resp.status.toString(), message: resp.statusText });
        }),
        catchError(err =>
          of(
            new UsersResetPinCodeResponseAction({ statusCode: err.status.toString(), message: 'Reset Pin Code Error' })
          )
        )
      );
    })
  );
  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
