/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-password-left.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./forgot-password-left.component";
import * as i5 from "@angular/router";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../shared/services/event.service";
var styles_ForgotPasswordLeftComponent = [i0.styles];
var RenderType_ForgotPasswordLeftComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotPasswordLeftComponent, data: {} });
export { RenderType_ForgotPasswordLeftComponent as RenderType_ForgotPasswordLeftComponent };
function View_ForgotPasswordLeftComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("FORGOT_PASSWORD.FORGOT_PASSWORD")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("FORGOT_PASSWORD.PLEASE_ENTER_YOUR_USERNAME")); _ck(_v, 5, 0, currVal_1); }); }
function View_ForgotPasswordLeftComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "mt-1 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", "\u00A0"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resendOTP() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("FORGOT_PASSWORD.FORGOT_PASSWORD")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("FORGOT_PASSWORD.REQUEST_ENTER_OTP_SMS")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("FORGOT_PASSWORD.REQUEST_NOT_RECEIVE_OTP")); _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("FORGOT_PASSWORD.REQUEST_RESEND_OTP")); _ck(_v, 13, 0, currVal_3); }); }
function View_ForgotPasswordLeftComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("RESET_PASSWORD")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("WELCOME_TO_THE_SYSTEM_FOR_MORE_SECURITY_PLEASE_SET_YOUR_NEW_PASSWORD")); _ck(_v, 5, 0, currVal_1); }); }
export function View_ForgotPasswordLeftComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "left-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "forgot-section-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordLeftComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordLeftComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordLeftComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-outline-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.step === "REQUEST_OTP"); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.step === "ENTER_OTP"); _ck(_v, 8, 0, currVal_2); var currVal_3 = (_co.step === "CHANGE_PASSWORD"); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("TD_STORE_PORTAL")); _ck(_v, 2, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("CANCEL")); _ck(_v, 12, 0, currVal_4); }); }
export function View_ForgotPasswordLeftComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password-left", [], null, null, null, View_ForgotPasswordLeftComponent_0, RenderType_ForgotPasswordLeftComponent)), i1.ɵdid(1, 245760, null, 0, i4.ForgotPasswordLeftComponent, [i5.Router, i6.Store, i7.EventService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordLeftComponentNgFactory = i1.ɵccf("app-forgot-password-left", i4.ForgotPasswordLeftComponent, View_ForgotPasswordLeftComponent_Host_0, {}, {}, []);
export { ForgotPasswordLeftComponentNgFactory as ForgotPasswordLeftComponentNgFactory };
