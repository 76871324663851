import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as JwtDecode from 'jwt-decode';
import { BsModalRef } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { environment as env } from '../../../environments/environment';
import { UserInformationAction } from '../../shared/store/actions/user-info.action';
import { AppStates } from '../../shared/store/state/app.states';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit {
  public cookies: string;
  public bsModalRef: BsModalRef;

  constructor(
    private cookieService: CookieService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly store: Store<AppStates>
  ) {
    this.cookies = JSON.stringify(cookieService.getAll());
  }

  ngOnInit() {
    const storeNo = this.activatedRoute.snapshot.paramMap.get('storeNo');

    if (this.cookieService.get(env.cookies.accessToken) && this.cookieService.get(env.cookies.refreshToken)) {
      this.setUserInfo(this.cookieService.get(env.cookies.accessToken));
      this.router.navigateByUrl(`/order/summary/${storeNo}`);
    } else {
      this.router.navigateByUrl('/');
    }
  }

  private setUserInfo(token: string) {
    const userInfo: any = JwtDecode(token);

    this.store.dispatch(new UserInformationAction(userInfo.user_info));
  }
}
