import { createSelector } from '@ngrx/store';
import * as fromSubmitOrderState from '../reducers/submit-order.reducers';
import { AppStates } from '../state/app.states';

const selectSubmitOrderState = (state: AppStates) => state.submitOrder;

export const selectSubmitOrderResult = createSelector(
  selectSubmitOrderState,
  (state: fromSubmitOrderState.SubmitOrderState) => state
);
