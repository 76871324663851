import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CatalogListRequest } from '../models/catalog.model';
import { BaseService } from './base.service';

@Injectable()
export class CatalogsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.envService = this.env.services.products;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getCatalog<T>(urlParams: CatalogListRequest): Observable<T> {
    const params = this.getParams(urlParams);

    return this.http.get<T>(this.getUrl(), {
      headers: this.headers,
      params: params
    });
  }

  public getCatalogListMenu<T>(): Observable<T> {
    return this.http.get<T>(this.getFullUrl(this.envService.menu), {
      headers: this.headers
    });
  }
}
