import { BaseApiResponse } from '../../models/base-api-response';

export interface ResetPasswordPinState {
  result: {
    resetPasswordResult?: BaseApiResponse | null;
    resetPinCodeResult?: BaseApiResponse | null;
  };
}

export const initialResetPinPasswordState: ResetPasswordPinState = {
  result: {
    resetPasswordResult: null,
    resetPinCodeResult: null
  }
};
