import { ErrorResponse } from '../../models/error.model';
import { TdStoreListResponse } from '../../models/td-store-list.model';
import { BaseState } from './base.state';

export interface BaseTdStoreListState extends BaseState {
  result: {
    response: TdStoreListResponse | null;
    errorResponse: ErrorResponse | null;
  };
}

export const initialTdStoreListState: BaseTdStoreListState = {
  result: {
    response: null,
    errorResponse: null
  }
};
