import { Component, OnInit } from '@angular/core';

export declare interface DataTable {
  headerRow: string[];
  footerRow: string[];
  dataRows: string[][];
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public dataTable: DataTable;

  constructor() {}

  ngOnInit() {}
}
