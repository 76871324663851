import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { ClientIdTypeEnum } from '../enum/client-id.enum';
import { UserInfo } from '../models/auth.model';
import { Menu } from '../models/menu.model';
import { LogOutAction } from '../store/actions/auth.actions';
import { ResetUserState } from '../store/actions/users.action';
import { selectAuthoritiesResult } from '../store/selectors/auth-user-info.selector';
import { selectClientIdResult } from '../store/selectors/client-id.selector';
import { selectUserInformationResult } from '../store/selectors/user-info.selector';
import { AppStates } from '../store/state/app.states';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  protected clientId: ClientIdTypeEnum | null;
  protected bypassForceChange: boolean;
  protected authoritiesSubscription: Subscription;
  protected authUserInfo: UserInfo;

  constructor(
    public readonly router: Router,
    protected readonly store: Store<AppStates>,
    protected cookieService: CookieService
  ) {
    this.store
      .pipe(
        select(selectClientIdResult),
        tap(clientId => (this.clientId = clientId))
      )
      .subscribe();

    this.store
      .pipe(
        select(selectUserInformationResult),
        tap(userInfo => (this.bypassForceChange = !(userInfo.forceChangePassword && userInfo.forceChangePinCode)))
      )
      .subscribe(userInfo => {
        this.authUserInfo = userInfo;
      });
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.doAuthenticated(route.data.permissions);
  }

  public canActivateChild(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.doAuthenticated(route.data.permissions);
  }

  protected doAuthenticated(permissions: string[]): boolean {
    let canActivate = this.cookieService.check(env.cookies.refreshToken);

    if (!canActivate) {
      this.router.navigate(['/login']);
      return false;
    } else if (canActivate && this.bypassForceChange) {
      this.store
        .select(selectAuthoritiesResult)
        .pipe(take(1))
        .subscribe(authorities => {
          if (authorities && permissions && !authorities.some(val => permissions.indexOf(val) > -1)) {
            canActivate = false;
            this.router.navigateByUrl('/home');
          }
        });
    }
    // else if (canActivate && this.authUserInfo.forceChangePassword) {
    //   // canActivate = canActivate && this.authUserInfo.forceChangePassword;
    //   // canActivate = true;
    //   this.router.navigate(['/force-change-password']);
    //   return false;
    // }

    return canActivate;
  }

  public getMenuByPermission(userAuthorities: string[]): Menu[] {
    return this.generateMenu(JSON.parse(JSON.stringify(this.router.config)), userAuthorities);
  }

  public checkPermission(permission: Array<string> | string): boolean {
    const permissions: Array<string> = Array.isArray(permission) ? permission : [permission];
    let hasPermission = false;

    this.authoritiesSubscription = this.store
      .select(state => state.userInfo.authorities)
      .subscribe(authorities => {
        if (permissions && authorities) {
          hasPermission = permissions.some(val => authorities.indexOf(val) > -1);
        }
      });

    return hasPermission;
  }

  public hasOnlyPermission(menu: string, permissionName: string, userAuthorities: string[]) {
    const filteredPermission = userAuthorities.filter(permission => {
      return permission.indexOf(menu) > -1;
    });

    return filteredPermission.length === 1 && filteredPermission[0] === `${menu}_${permissionName}`;
  }

  public logOut() {
    this.store.dispatch(new LogOutAction());
    this.store.dispatch(new ResetUserState());
  }

  // TODO:: Fix cognitive-complexity
  // tslint:disable-next-line:cognitive-complexity
  private generateMenu(config: any[], userAuthorities: string[]) {
    const menus: Menu[] = [];

    const configFiltered = config
      .map(route => (route.path === '' && route.children && route.children.length ? route.children.shift() : route))
      .filter(route => {
        if (route.children) {
          if (route.data && route.data.permissions && route.data.permissions.length > 0) {
            let hasPermission = true;

            hasPermission = route.data.permissions.some(val => userAuthorities.indexOf(val) > -1);

            // Delete children in case parent has permission set
            route.children = [];

            return hasPermission && route.data.isShowOnMenu;
          } else {
            route.children = route.children.filter(child => {
              let hasPermission = true;

              if (child.data && child.data.permissions) {
                hasPermission = child.data.permissions.some(val => userAuthorities.indexOf(val) > -1);
              }

              return hasPermission && child.data && child.data.isShowOnMenu;
            });
          }
        }

        return (route.data && route.data.isShowOnMenu) || (route.children && route.children.length);
      })
      .sort((a, b) => a.data.order - b.data.order);

    configFiltered.forEach(parent => {
      const menu = {
        title: parent.data.title,
        link: parent.path ? '/' + parent.path : '',
        ...(parent.data.icon && { icon: parent.data.icon }),
        ...(parent.data.active && { active: parent.data.active }),
        ...(parent.data.badge && { badge: parent.data.badge }),
        type: parent.children && parent.children.length ? 'dropdown' : 'simple'
      } as Menu;

      if (menu.type === 'dropdown') {
        this.doDropdownMenu(menu, parent.children);
      }

      menus.push(menu);
    });

    return menus;
  }

  private doDropdownMenu(menu: Menu, children) {
    menu.submenus = [];

    children.forEach(child => {
      const subMenu = {
        title: child.data.title ? child.data.title : '',
        link: child.path
      } as Menu;

      menu.submenus.push(subMenu);
    });
  }
}
