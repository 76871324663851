import { createSelector } from '@ngrx/store';
import * as fromMenu from '../reducers/menu.reducers';
import { AppStates } from '../state/app.states';

const selectMenuState = (state: AppStates) => state.menus;

export const selectMenus = createSelector(
  selectMenuState,
  (state: fromMenu.MenuState) => state.menus
);
