<div class="col-12">
  <div
    class="col-12 text-right clearfix mb-2"
    *ngIf="
      ((cartItem$ | async)?.items | filterBy: { productStatus: $inactiveProductStatus }) &&
      ((cartItem$ | async)?.items | filterBy: { productStatus: $inactiveProductStatus }).length > 0
    "
  >
    <span class="text-danger pointer" (click)="deleteInvalidItems()">
      <u
        ><strong>{{ 'REMOVE_ALL_ITEM_IS_UNAVAILABLE' | translate }}</strong></u
      >
    </span>
  </div>
  <div *ngFor="let cartItem of (cartItem$ | async)?.items; let i = index">
    <div
      class="row border mb-3"
      [ngClass]="checkDisableStatus.isDisabled(cartItem.productStatus) ? 'border-danger' : ''"
    >
      <div class="col-12 col-xl-2 d-flex justify-content-center d-xl-block " (click)="callItemDetails(cartItem)">
        <div class="catalog-img my-xl-2 my-1">
          <img class="border-0" appSrc="{{ cartItem.productImage?.small }}" alt="{{ cartItem.productName }}" />
        </div>
      </div>
      <div class="col-12 col-xl-10 d-flex flex-wrap flex-sm-row flex-md-row">
        <div class="col-12 col-xl-6 align-middle mt-2 float-left " (click)="callItemDetails(cartItem)">
          <p>
            <u
              >{{ i + 1 }}. {{ cartItem.productName | textEllipsis: ['27'] }} ({{
                'UNIT.' + cartItem.unit | translate
              }}
              {{ cartItem.unitFactor }})
            </u>
          </p>
          <p class="text-danger">
            {{ cartItem.wholesalePrice.amount | number }} {{ cartItem.wholesalePrice.currency | translate }} /
            {{ 'UNIT.' + cartItem.unit | translate }}
          </p>
          <span class="text-secondary small">{{ 'ARTICLE_NO' | translate }}: {{ cartItem.articleNo }}</span>
        </div>
        <div class="col-12 col-xl-3 mt-2 float-left ">
          <div class="input-group">
            <span class="pr-1">
              <button
                appDebounceClick
                class="btn btn-outline-secondary"
                type="button"
                id="reduce-cart-item-{{ cartItem.barcode }}"
                (debounceClick)="onReduce(quantity.value, cartItem.barcode)"
                [disabled]="checkDisableStatus.isDisabled(cartItem.productStatus)"
              >
                -
              </button>
            </span>
            <input
              #quantity
              id="cart-item-{{ cartItem.barcode }}"
              name="{{ cartItem.barcode }}"
              type="text"
              min="1"
              max="999"
              maxlength="3"
              value="{{ cartItem.qty }}"
              class="form-control text-center input-sm"
              [ngClass]="[checkDisableStatus.isDisabled(cartItem.productStatus) ? 'border border-danger' : '']"
              (paste)="$event.preventDefault()"
              (keyup)="onQuantityKeyUp(quantity)"
              (click)="$event.preventDefault()"
              [disabled]="checkDisableStatus.isDisabled(cartItem.productStatus)"
              appNumbersOnly
            />
            <span class="pl-1">
              <button
                appDebounceClick
                class="btn btn-outline-secondary"
                type="button"
                id="add-cart-item-{{ cartItem.barcode }}"
                (debounceClick)="onAdd(quantity.value, cartItem.barcode)"
                [disabled]="checkDisableStatus.isDisabled(cartItem.productStatus)"
              >
                +
              </button>
            </span>
            <span class="clearfix"></span>
          </div>

          <div
            *ngIf="cartItem.productStatus === productStatus.OUT_OF_STOCK; else hold"
            class="text-danger small text-center"
          >
            <span>
              {{ 'ERRORS.O' | translate }} <br />
              {{ 'PLEASE_REMOVE_ITEM_FROM_YOUR_BASKET' | translate }}
            </span>
          </div>
          <ng-template #hold>
            <div
              *ngIf="[productStatus.RECALL, productStatus.HOLD_BUY_SELL].includes(cartItem.productStatus); else deleted"
              class="text-danger small text-center"
            >
              <span>
                {{ 'ERRORS.HBS' | translate }} <br />
                {{ 'PLEASE_REMOVE_ITEM_FROM_YOUR_BASKET' | translate }}
              </span>
            </div>
          </ng-template>
          <ng-template #deleted>
            <div
              *ngIf="productStatus.DELETE === cartItem.productStatus; else defaultQuantity"
              class="text-danger small text-center"
            >
              <span>
                {{ 'ERRORS.D' | translate }} <br />
                {{ 'PLEASE_REMOVE_ITEM_FROM_YOUR_BASKET' | translate }}
              </span>
            </div>
          </ng-template>
          <ng-template #defaultQuantity>
            <div
              *ngIf="
                !checkDisableStatus.isDisabled(cartItem.productStatus) && quantity.classList.contains('border-danger');
                else total
              "
              id="cart-item-error-{{ cartItem.barcode }}"
              class="text-danger small text-center"
            >
              <span>
                {{ 'ERRORS.REQUIRED' | translate }}
              </span>
            </div>
          </ng-template>
          <ng-template #total>
            <div class="text-secondary small text-center">
              {{ 'TOTAL' | translate }} {{ cartItem.totalQty | number }} {{ 'UNIT.PC' | translate }}
            </div>
          </ng-template>
        </div>
        <div class="col-12 col-xl-3 text-left text-xl-right mt-2">
          <h5 class="text-danger ">
            {{ cartItem.amount.amount | number: '1.2-2' }} {{ cartItem.amount.currency | translate }}
          </h5>
          <div class="d-flex justify-content-end">
            <span class="text-danger position-absolute fixed-bottom mr-3 mb-2"
              ><em
                class="float-right fa fa-trash-alt pointer"
                appDebounceClick
                (debounceClick)="deleteItem(cartItem.barcode)"
                id="delete-{{ cartItem.barcode }}"
              ></em
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
