import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PasswordValidation } from '../../shared/directives/validators/password-validation.directive';
import { ForceChangePasswordRequestAction } from '../../shared/store/actions/force-change-password.action';
import { selectUserInfoResult } from '../../shared/store/selectors/auth-user-info.selector';
import { selectForceChangePasswordResult } from '../../shared/store/selectors/force-change-password.selector';
var ForceChangePasswordComponent = /** @class */ (function () {
    function ForceChangePasswordComponent(formBuilder, store, router) {
        this.formBuilder = formBuilder;
        this.store = store;
        this.router = router;
        this.changePasswordForm = this.formBuilder.group({
            newPassword: ['', [Validators.required, PasswordValidation]],
            reEnterPassword: ['', [Validators.required]]
        }, { validator: this.checkPasswords });
    }
    ForceChangePasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.forceChangePasswordResult$ = this.localStore.pipe(select(selectForceChangePasswordResult));
        this.submitted = false;
        this.forceChangePasswordResult$.subscribe(function (data) {
            if (!data.result.response && data.result.errorResponse.code === '02002') {
                _this.changePasswordFormGetter.newPassword.setErrors({ passwordSamePrevious: true });
            }
            else if (data.result.response && data.result.response.statusCode === '200') {
                _this.router.navigateByUrl('/login');
            }
        });
        this.userInfoResult$ = this.localStore.pipe(select(selectUserInfoResult));
        this.userInfoResult$.subscribe(function (userInfo) {
            _this.userName = userInfo.userName;
        });
    };
    ForceChangePasswordComponent.prototype.ngOnDestroy = function () { };
    ForceChangePasswordComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.changePasswordForm.valid) {
            this.store.dispatch(new ForceChangePasswordRequestAction({
                userName: this.userName,
                newPassword: this.changePasswordFormGetter.newPassword.value
            }));
        }
    };
    Object.defineProperty(ForceChangePasswordComponent.prototype, "changePasswordFormGetter", {
        get: function () {
            return this.changePasswordForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ForceChangePasswordComponent.prototype.checkPasswords = function (group) {
        // here we have the 'passwords' group
        var pass = group.controls.newPassword.value;
        var confirmPass = group.controls.reEnterPassword.value;
        return pass === confirmPass ? null : group.controls.reEnterPassword.setErrors({ passwordNotEquivalent: true });
    };
    ForceChangePasswordComponent.prototype.toggleInputPasswordType = function () {
        this.isShowPassword = !this.isShowPassword;
    };
    ForceChangePasswordComponent.prototype.toggleInputReEnterPasswordType = function () {
        this.isShowReEnterPassword = !this.isShowReEnterPassword;
    };
    ForceChangePasswordComponent.prototype.clearPasswordSamePreviousError = function () {
        this.changePasswordFormGetter.newPassword.setErrors(null);
        this.changePasswordFormGetter.newPassword.updateValueAndValidity();
    };
    return ForceChangePasswordComponent;
}());
export { ForceChangePasswordComponent };
