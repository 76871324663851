import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { OrderState } from '../../../shared/store/reducers/orders.reducer';
import { selectOrderSummaryResult } from '../../../shared/store/selectors/order-summary.selector';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Output() searchCriteria = new EventEmitter<string>();

  public searchAndFilterForm: FormGroup;
  public orderSummary$: Observable<OrderState>;
  private localStore: Observable<any>;
  constructor(private readonly formBuilder: FormBuilder, private readonly store: Store<AppStates>) {
    this.searchAndFilterForm = this.formBuilder.group({
      searchText: ''
    });
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.orderSummary$ = this.localStore.pipe(select(selectOrderSummaryResult));
  }

  handleSearchText() {
    this.searchCriteria.emit(this.searchAndFilterForm.controls['searchText'].value);
  }

  clearSearchText() {
    this.searchAndFilterForm.controls['searchText'].reset();
    this.searchCriteria.emit(this.searchAndFilterForm.controls['searchText'].value);
  }

  get f(): {
    [key: string]: AbstractControl;
  } {
    return this.searchAndFilterForm.controls;
  }

  ngOnDestroy(): void {}
}
