<tree-root
  #th_tree
  [nodes]="categoryTree$ | async"
  (initialized)="treeInit($event)"
  [options]="options"
  (activate)="onActivate($event)"
  (deactivate)="onDeactivate($event)"
  *ngIf="translate.currentLang === 'th'; else en_US"
>
</tree-root>
<ng-template #en_US>
  <tree-root
    #en_tree
    [nodes]="categoryTree$ | async"
    (initialized)="treeInit($event)"
    [options]="options"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)"
  >
  </tree-root>
</ng-template>
