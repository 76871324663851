import { ClientIdTypeEnum } from '../../enum/client-id.enum';
import { UserActionTypes, UserInfoActions } from '../actions/user-info.action';
import { initialClientIdState } from '../state/client-id.state';

export function clientIdReducers(state = initialClientIdState, action: UserInfoActions): ClientIdTypeEnum {
  switch (action.type) {
    case UserActionTypes.USER_CLIENT_ID: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
