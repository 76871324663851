import { ErrorResponse } from '../../models/error.model';
import { TdStore } from '../../models/td-store.model';
import { BaseState } from './base.state';

export interface BaseTdStoreState extends BaseState {
  result: {
    response: TdStore | null;
    errorResponse: ErrorResponse | null;
  };
}

export const initialTdStoreState: BaseTdStoreState = {
  result: {
    response: null,
    errorResponse: null
  }
};
