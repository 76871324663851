// Start of Event Service code
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// Used to store key value pairs
interface Event {
  key: string;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class EventService {
  protected _eventsSubject = new Subject<Event>();

  constructor() {}

  public BroadcastEvent(key: string, value: any) {
    this._eventsSubject.next({ key, value });
  }

  public GetEvent(key: string): Observable<Event> {
    return this._eventsSubject.asObservable().pipe(
      filter(e => e.key === key),
      map(e => e.value)
    );
  }
}
