<div class="full-popup-head alert-secondary">
  <div class="full-popup-back float-left">
    <a aria-label="Close" id="back" class="close" (click)="onCancel()">
      <em class="fa fa-angle-left"></em><span class="text d-none d-md-inline">{{ data.title }}</span>
    </a>
  </div>
  <div class="float-right">
    <h5 class="text-danger float-right pl-sm-5 pl-3">
      {{ 'TOTAL_AMOUNT' | translate }}
      {{ orderSummary.amount.amount || 0 | number: '1.2-2' }}
      {{ orderSummary.amount.currency || '' | translate }}
    </h5>
    <span class="pl-4 float-right d-none d-sm-block">{{ 'BASKET' | translate }}</span>
    <em class="fa fa-shopping-basket float-right"></em>
    <span class="badge badge-pill badge-danger">{{ orderSummary.numberOfItems }}</span>
  </div>
  <div class="clearfix"></div>
</div>
<div class="order-item">
  <div class="row">
    <div class="col-12 col-md-4 bg-light">
      <img appSrc="{{ catalog.productImage?.medium }}" alt="{{ catalog.productName }}" class="img-fluid mb-3" src="" />
    </div>
    <div class="col-12 col-md-8">
      <h3 class="text-danger">{{ productTitle }}</h3>
      <div class="mb-3">{{ 'ARTICLE_NO' | translate }}: {{ catalog.articleNo }}</div>
      <div class="text-danger mb-3">
        <rating [max]="5" [(ngModel)]="catalog.productGrading" [customTemplate]="productGrading" [readonly]="true">
        </rating>
        <ng-template #productGrading let-i="index" let-v="value">
          <em class="{{ i < v ? 'fa fa-star' : 'fa fa-star-o' }}"></em>
        </ng-template>
        <span class="text"> {{ 'RATING_SCORE' | translate }} {{ catalog.productGrading }}</span>
      </div>
      <h3 class="price-area text-danger">
        {{ catalog.wholesalePrice.amount | number }} {{ catalog.wholesalePrice.currency | translate }}
        <small class="text-muted">/{{ 'UNIT.' + catalog.unit | translate }}</small>
      </h3>
      <div class="text d-none">
        <!-- TODO:: Hide Promotion d-none-->
        โปรโมชั่นประหยัด 0 บาท วันนี้ - 31/10/2019
      </div>
      <small class="text-muted">{{ constInfo }}</small>
      <hr />
      <span
        class="mr-2 text-danger"
        *ngIf="
          [productStatusList.RECALL, productStatusList.HOLD_BUY_SELL].includes(catalog.productStatus);
          else outOfStock
        "
        >{{ 'PRODUCT_STATUS.' + productStatusList.HOLD_BUY_SELL | translate }}</span
      >
      <ng-template #outOfStock>
        <span class="mr-2 text-danger" *ngIf="catalog.productStatus === productStatusList.OUT_OF_STOCK; else deleted">{{
          'PRODUCT_STATUS.' + productStatusList.OUT_OF_STOCK | translate
        }}</span>
      </ng-template>
      <ng-template #deleted>
        <span class="mr-2 text-danger" *ngIf="catalog.productStatus === productStatusList.DELETE; else defaultFooter">{{
          'PRODUCT_STATUS.' + productStatusList.DELETE | translate
        }}</span>
      </ng-template>
      <ng-template #defaultFooter>
        <form [formGroup]="itemDetailForm" (ngSubmit)="onSubmit()" *ngIf="data.step === '1'">
          <div class="form-row">
            <label class="col-form-label col-form-label-lg text-danger" for="{{ catalog.barcode }}">{{
              'QUANTITY' | translate
            }}</label>
            <span class="input-group">
              <span class="pr-1">
                <button
                  (click)="onReduce()"
                  [disabled]="checkDisableStatus.isDisabled(catalog.productStatus)"
                  class="btn btn-outline-secondary"
                  type="button"
                >
                  -
                </button>
              </span>
              <input
                (paste)="$event.preventDefault()"
                [disabled]="checkDisableStatus.isDisabled(catalog.productStatus)"
                [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"
                appNumbersOnly
                class="form-control text-center"
                formControlName="amount"
                id="{{ catalog.barcode }}"
                max="999"
                maxlength="3"
                min="1"
                name="{{ catalog.barcode }}"
                type="text"
              />
              <span class="pl-1">
                <button
                  (click)="onAdd()"
                  [disabled]="checkDisableStatus.isDisabled(catalog.productStatus)"
                  class="btn btn-outline-secondary"
                  type="button"
                >
                  +
                </button>
              </span>
              <span class="clearfix"></span>
              <span *ngIf="submitted && f.amount.errors" class="invalid-feedback text-center">
                <span *ngIf="f.amount.errors">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </span>
              </span>
            </span>
            <button type="submit" id="add-item" class="btn btn-danger order-button">
              {{ 'BUY' | translate }}
            </button>
          </div>
        </form>
      </ng-template>
    </div>
  </div>
</div>
