<div class="left-section">
  <h2 class="forgot-section-header">{{ 'TD_STORE_PORTAL' | translate }}</h2>
  <h4>
    {{ 'RESET_PASSWORD' | translate }}
  </h4>
  <strong>{{'HELLO_FULL_NAME' | translate : {
    firstName: (userInfoResult$ | async)?.firstName,
    lastName: (userInfoResult$ | async)?.lastName
  } }}</strong>
  <p>{{ 'WELCOME_TO_THE_SYSTEM_FOR_MORE_SECURITY_PLEASE_SET_YOUR_NEW_PASSWORD' | translate }}</p>
  <div>
    <button class="btn btn-outline-primary" (click)="onCancel()">{{ 'CANCEL' | translate }}</button>
  </div>
</div>
