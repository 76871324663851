<p class="text-center" [innerHTML]="'YOUR_INFORMATION_HAS_REGISTERED' | translate"></p>
<div class="d-flex justify-content-center">
  <div class="col-10">
    <form
      [formGroup]="merchantVerificationForm"
      (ngSubmit)="onSubmit()"
      class="border border-grey mobile-verification-form pb-5"
    >
      <div class="form-group">
        <label for="idCardNumber">{{ 'PLEASE_ENTER_YOUR_REGISTERED_ID_CARD' | translate }}</label>
        <input
          type="text"
          formControlName="idCardNumber"
          class="form-control"
          id="idCardNumber"
          name="idCardNumber"
          placeholder="{{ 'ID_CARD' | translate }}"
          maxlength="13"
          [ngClass]="{ 'is-invalid': submitted && f.idCardNumber.errors }"
          (keyup)="this.clearFormError($event)"
          appNumbersOnly
          [allowZeroAtStart]="true"
        />
        <div *ngIf="submitted && f.idCardNumber.errors" class="invalid-feedback">
          <div *ngIf="f.idCardNumber.errors.required; else minLength">
            {{ 'ERRORS.REQUIRED_DATA' | translate }}
          </div>
          <ng-template #minLength>
            <div *ngIf="f.idCardNumber.errors.minlength; else invalidId">
              {{ 'ERRORS.PLEASE_ENTER_ID_CARD' | translate }}
            </div>
          </ng-template>
          <ng-template #invalidId>
            <div *ngIf="f.idCardNumber.errors.invalidId">
              {{ (merchantVerificationResult$ | async)?.result.errorResponse.translateKey | translate }}
            </div>
          </ng-template>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" id="btn-verify" class="btn btn-primary btn-block">
          {{ 'VERIFY' | translate }}
        </button>
      </div>
    </form>
    <p class="text-center">{{ 'FOR_MORE_INFORMATION' | translate }}</p>
  </div>
</div>
