<div class="d-flex justify-content-center">
  <div class="col-12">
    <div class=" border border-grey mobile-verification-form pb-5">
      <h4 class="text-center">{{ 'SORRY' | translate }}</h4>
      <p class="text-center">{{ 'YOUR_REQUEST_COULD_NOT_COMPLETE' | translate }}</p>
      <div class="d-flex justify-content-center text-danger pt-3">
        <em class="fa fa-times-circle icon-size"></em>
      </div>
    </div>
    <p class="text-center">{{ 'FOR_MORE_INFORMATION' | translate }}</p>
  </div>
</div>
