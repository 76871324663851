import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
var CartsService = /** @class */ (function (_super) {
    tslib_1.__extends(CartsService, _super);
    function CartsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.envService = _this.env.services.carts;
        _this.headers = new HttpHeaders(_this.envService.headers);
        return _this;
    }
    CartsService.prototype.orderSummary = function (storeNo) {
        var url = this.getFullUrl(this.envService.summary, {
            storeNo: storeNo
        });
        return this.http.get(url, { headers: this.headers });
    };
    CartsService.prototype.getCart = function (cartId) {
        var url = this.getFullUrl(this.envService.checkCartDetails, {
            cartId: cartId
        });
        return this.http.put(url, null, { headers: this.headers });
    };
    CartsService.prototype.createCart = function (storeNo) {
        var url = this.getFullUrl(this.envService.createCart, {
            storeNo: storeNo
        });
        return this.http.post(url, null, { headers: this.headers });
    };
    CartsService.prototype.addItem = function (addItemRequest) {
        var url = this.getFullUrl(this.envService.addItem, {
            cartId: addItemRequest.cartId
        });
        return this.http.put(url, {
            barcode: addItemRequest.barcode,
            qty: addItemRequest.qty
        }, { headers: this.headers });
    };
    CartsService.prototype.updateItem = function (addItemRequest) {
        var url = this.getFullUrl(this.envService.updateItem, {
            cartId: addItemRequest.cartId
        });
        return this.http.put(url, {
            barcode: addItemRequest.barcode,
            qty: addItemRequest.qty
        }, { headers: this.headers });
    };
    CartsService.prototype.deleteItem = function (deleteCartItemRequest) {
        var url = this.getFullUrl(this.envService.deleteCartItem, {
            cartId: deleteCartItemRequest.cartId,
            barcode: deleteCartItemRequest.barcode
        });
        return this.http.put(url, null, { headers: this.headers });
    };
    CartsService.prototype.deleteInvalidItems = function (deleteInvalidItemsRequest) {
        var url = this.getFullUrl(this.envService.deleteInvalidItems, {
            cartId: deleteInvalidItemsRequest.cartId
        });
        return this.http.put(url, null, { headers: this.headers });
    };
    // TODO Remove if Backend decrypt token themselves
    // public submitOrder<T>(submitOrder: SubmitOrderRequest, authUserInfo: UserInfoState): Observable<T> {
    CartsService.prototype.submitOrder = function (submitOrder) {
        var url = this.env.serverUrl + this.env.services.orders.url;
        return this.http.post(url, {
            cart: submitOrder.cart,
            additionalNote: submitOrder.additionalNote
        }, { headers: this.headers });
    };
    return CartsService;
}(BaseService));
export { CartsService };
