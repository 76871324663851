import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Menu } from '../../models/menu.model';
import { selectMenus } from '../../store/selectors/menu.selector';
import { AppStates } from '../../store/state/app.states';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class SidebarComponent implements OnInit {
  menus: Menu[] = [];
  authoritiesSubscription: Subscription;
  userAuthorities: string[];
  filteredMenu: any;
  menus$: Observable<Menu[]>;

  constructor(public sidebarService: SidebarService, private readonly store: Store<AppStates>) {}

  ngOnInit() {
    this.store.select(selectMenus).subscribe(menus => {
      this.menus = menus;
    });
  }

  getSideBarState() {
    return this.sidebarService.getSidebarState();
  }

  toggle(currentMenu) {
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }
      });
    }
  }

  getState(currentMenu) {
    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }
}
