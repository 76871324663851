var _a;
import * as tslib_1 from "tslib";
import { createEntityAdapter } from '@ngrx/entity';
import { CatalogListMenuActionTypes } from '../actions/catalog-list-menu.action';
var ɵ0 = function (catalog) { return catalog.code; };
export var adapter = createEntityAdapter({
    selectId: ɵ0
});
export var initialCatalogListMenuState = adapter.getInitialState({
    isLoading: false
});
export function catalogListMenuReducers(state, action) {
    if (state === void 0) { state = initialCatalogListMenuState; }
    switch (action.type) {
        case CatalogListMenuActionTypes.CATALOG_LIST_MENU: {
            action.payload.unshift({ code: 'null' });
            return adapter.upsertMany(action.payload, state);
        }
        case CatalogListMenuActionTypes.CATALOG_LIST_MENU_ERROR: {
            return tslib_1.__assign({}, state, { result: {
                    response: null,
                    errorResponse: action.payload
                } });
        }
        case CatalogListMenuActionTypes.CATALOG_LIST_MENU_RESET: {
            return adapter.removeAll(state);
        }
        default: {
            return state;
        }
    }
}
export var selectAll = (_a = adapter.getSelectors(), _a.selectAll), selectEntities = _a.selectEntities, selectIds = _a.selectIds, selectTotal = _a.selectTotal;
export { ɵ0 };
