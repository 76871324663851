import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Catalog } from '../../models/catalog.model';
import { ErrorResponse } from '../../models/error.model';
import { CatalogListActions, CatalogListActionTypes } from '../actions/catalog-list.action';

export interface CatalogState extends EntityState<Catalog> {
  isLoading: boolean;
  result?: {
    response: Array<Catalog> | null;
    errorResponse: ErrorResponse | null;
  };
  totalElement: number;
  last: boolean;
}

export const adapter: EntityAdapter<Catalog> = createEntityAdapter<Catalog>({
  selectId: (catalog: Catalog) => catalog.articleNo
});

export const initialCatalogListState: CatalogState = adapter.getInitialState({
  isLoading: false,
  totalElement: 0,
  last: false,
  result: null
});

export function catalogListReducers(state = initialCatalogListState, action: CatalogListActions): CatalogState {
  switch (action.type) {
    case CatalogListActionTypes.CATALOG_LIST: {
      return adapter.upsertMany(action.payload.content, {
        ...state,
        totalElement: action.payload.totalElements,
        last: action.payload.last,
        result: {
          response: action.payload.content,
          errorResponse: null
        }
      });
    }
    case CatalogListActionTypes.CATALOG_LIST_ERROR: {
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    }
    case CatalogListActionTypes.CATALOG_LIST_RESET: {
      return adapter.removeAll(state);
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
