import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models/error.model';
import { MerchantVerificationResponse } from '../../models/verification.models';
import { VerificationActions, VerificationActionTypes } from '../actions/verification.actions';

export interface MerchantVerificationState extends EntityState<MerchantVerificationResponse> {
  isLoading: boolean;
  result: {
    response: MerchantVerificationResponse;
    errorResponse: ErrorResponse;
  };
}

export const adapter: EntityAdapter<MerchantVerificationResponse> = createEntityAdapter<MerchantVerificationResponse>();

export const initialMerchantVerificationState: MerchantVerificationState = adapter.getInitialState({
  isLoading: false,
  result: null
});

export function merchantVerificationReducers(
  state = initialMerchantVerificationState,
  action: VerificationActions
): MerchantVerificationState {
  switch (action.type) {
    case VerificationActionTypes.MERCHANT_VERIFICATION_RESPONSE: {
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    }
    case VerificationActionTypes.MERCHANT_VERIFICATION_ERROR: {
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
