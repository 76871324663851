import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { filter, map, take } from 'rxjs/operators';
import { TDPageModes } from '../../../enum/store.enum';
import { selectTdStoreResult } from '../../../store/selectors/td-store.selector';
var OrderPolicyComponent = /** @class */ (function () {
    function OrderPolicyComponent(fb, store) {
        this.fb = fb;
        this.store = store;
    }
    Object.defineProperty(OrderPolicyComponent.prototype, "orderSchedule", {
        get: function () {
            return this.parentForm.get('orderSchedule');
        },
        enumerable: true,
        configurable: true
    });
    OrderPolicyComponent.prototype.ngOnDestroy = function () { };
    OrderPolicyComponent.prototype.ngOnInit = function () {
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.tdStore$ = this.localStore.pipe(select(selectTdStoreResult));
        this.parentForm.addControl('orderSchedule', this.createForm);
        this.setOrderPolicyValue();
        if (this.mode === TDPageModes.REQUEST_VIEW) {
            this.orderSchedule.disable();
        }
    };
    Object.defineProperty(OrderPolicyComponent.prototype, "createForm", {
        get: function () {
            return this.fb.group({
                minOrder: [{ value: 15000, disabled: false }, [Validators.required, Validators.min(0)]],
                schedules: this.fb.array([])
            });
        },
        enumerable: true,
        configurable: true
    });
    OrderPolicyComponent.prototype.setOrderPolicyValue = function () {
        var _this = this;
        this.tdStore$
            .pipe(map(function (response) { return response && response.result && response.result.response; }), filter(function (response) { return response !== null; }), take(1))
            .subscribe(function (value) {
            if (value && value.orderSchedule) {
                _this.orderSchedule.patchValue({
                    minOrder: value.orderSchedule.minOrder
                });
            }
        });
    };
    OrderPolicyComponent.prototype.toggleEditOrderPolicy = function () {
        this.orderSchedule.enable();
    };
    return OrderPolicyComponent;
}());
export { OrderPolicyComponent };
