/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../child-item/child-directive";
import * as i3 from "./full-modal.component";
import * as i4 from "ngx-bootstrap/modal";
import * as i5 from "@angular/router";
import * as i6 from "@ngx-translate/core";
import * as i7 from "ngx-logger";
var styles_FullModalComponent = [i0.styles];
var RenderType_FullModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullModalComponent, data: {} });
export { RenderType_FullModalComponent as RenderType_FullModalComponent };
function View_FullModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_FullModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { childHost: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "full-popup-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "full-popup-head"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "full-popup-title float-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "full-popup-close float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["aria-label", "Close"], ["class", "close"], ["id", "full-modal-closeBtn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "em", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "full-popup-inner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullModalComponent_1)), i1.ɵdid(11, 16384, [[1, 4]], 0, i2.ChildDirective, [i1.ViewContainerRef], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); }); }
export function View_FullModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-modal", [], null, null, null, View_FullModalComponent_0, RenderType_FullModalComponent)), i1.ɵdid(1, 245760, null, 0, i3.FullModalComponent, [i4.BsModalRef, i4.BsModalService, i5.Router, i1.ComponentFactoryResolver, i6.TranslateService, i7.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FullModalComponentNgFactory = i1.ɵccf("app-full-modal", i3.FullModalComponent, View_FullModalComponent_Host_0, {}, {}, []);
export { FullModalComponentNgFactory as FullModalComponentNgFactory };
