import { isArray } from 'ngx-bootstrap';

export class TdStore {
  id: string;
  version: number;
  code: string;
  name: string;
  active?: boolean;
  merchant: string;
  storeCountry: string;
  storeGroup?: string;
  groupHierarchy?: string[];
  contact?: Contact;
  receipt?: Receipt;
  policy?: Policy;
  devices?: Device[];
  createdDate?: string;
  createdBy?: string;
  lastModifiedDate?: Date;
  lastModifiedBy?: string;
  status?: string;
  storeProfile: StoreProfile;
  orderSchedule: OrderSchedule;

  constructor(value?: TdStore) {
    if (value) {
      value.groupHierarchy = TdStore.transformArray(value.groupHierarchy);
      value.devices = TdStore.transformArray(value.devices);
      Object.assign(this, value);
    }
  }

  private static transformArray(value) {
    try {
      if (!isArray(value)) {
        value = [value];
      }
      return value;
    } catch (e) {
      return null;
    }
  }
}

export class Contact {
  address: string;
  state: string;
  postCode: string;
  country: string;
  countryCode?: string;
  mobileNo?: string;
}

export class Receipt {
  header: string;
  address: string;
  taxId: string;
  branchNo: string;
  footer: string;
}

export class Policy {
  minOrder: MinOrder;
  orderSchedule: string[];
  deliverySchedule: string[];
  currency: string;
  language: string;
  timezone: string;
  country: string;
  openTime: string;
  closeTime: string;
}

export class Device {
  code: string;
  type: string;
  reference1: string;
  reference2: string;
  reference3: string;
}

export class MinOrder {
  amount: number;
  currency: string;
}

export class StoreProfile {
  storeNo: string;
  contactName: string;
  email: string;
  address: string;
  postCode: string;
  country: string;
  taxNo: string;
  branchNo: string;
  state: string;
  region: string;
  timezone: string;
  phone: string;
  countryCode: string;
}

export class OrderSchedule {
  minOrder: number;
  schedules: Schedules[];
}

export class Schedules {
  orderScheduleDate: string;
  orderScheduleTime: string;
  deliverySchedule: string;
}
