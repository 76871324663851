import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'decimalPipeProxy'
})
export class DecimalProxyPipe extends DecimalPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {
    super(translate.currentLang);
  }

  transform(value: any, args?: any): any {
    return super.transform(value, args);
  }
}
