import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CatalogsService } from '../../services';
import { CatalogListActionTypes, CatalogListErrorAction, CatalogListResponseAction } from '../actions/catalog-list.action';
var CatalogListEffects = /** @class */ (function () {
    function CatalogListEffects(actions, catalogService, router, logger) {
        var _this = this;
        this.actions = actions;
        this.catalogService = catalogService;
        this.router = router;
        this.logger = logger;
        this.CatalogList = this.actions.pipe(ofType(CatalogListActionTypes.CATALOG_LIST_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect Catalog Request: ' + _this.stringify(payload));
            return _this.catalogService.getCatalog(payload).pipe(map(function (resp) {
                _this.logger.debug('@Effect Get Catalogs Response: ' + _this.stringify(resp));
                return new CatalogListResponseAction(resp);
            }), catchError(function (err) {
                return of(new CatalogListErrorAction(err.error));
            }));
        }));
    }
    CatalogListEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], CatalogListEffects.prototype, "CatalogList", void 0);
    return CatalogListEffects;
}());
export { CatalogListEffects };
