import { ErrorResponse } from '../../models/error.model';
import { ForgotUsernameActions, ForgotUsernameActionTypes } from '../actions/forgot-username.action';
import { BaseForgotUsernameState, initialForgotUsernameState } from '../state/forgot-username.state';

export function forgotUsernameReducers(
  state = initialForgotUsernameState,
  action: ForgotUsernameActions
): BaseForgotUsernameState {
  switch (action.type) {
    case ForgotUsernameActionTypes.FORGOT_USERNAME_SUCCESS: {
      return {
        ...state,
        result: {
          ...state.result,
          isSuccess: true,
          response: action.payload.data,
          errorResponse: new ErrorResponse(),
          mobileNumber: action.payload.mobileNumber
        }
      };
    }
    case ForgotUsernameActionTypes.FORGOT_USERNAME_FAILED: {
      return {
        ...state,
        result: {
          ...state.result,
          isSuccess: false,
          response: null,
          errorResponse: action.payload.error,
          mobileNumber: action.payload.mobileNumber
        }
      };
    }
    case ForgotUsernameActionTypes.FORGOT_USERNAME_RESET: {
      return initialForgotUsernameState;
    }
    default: {
      return state;
    }
  }
}
