import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StoresService = /** @class */ (function (_super) {
    tslib_1.__extends(StoresService, _super);
    function StoresService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.envService = _this.env.services.stores;
        _this.headers = new HttpHeaders(_this.envService.headers);
        return _this;
    }
    StoresService.prototype.addStore = function (req) {
        return this.http.post(this.getUrl(), req, { headers: this.loaderHeaders() });
    };
    StoresService.prototype.editStore = function (req) {
        return this.http.put(this.getUrl(), req, { headers: this.loaderHeaders() });
    };
    StoresService.prototype.getStore = function (storeNo) {
        return this.http.get(this.getFullUrl(this.envService.view, {
            storeNo: storeNo
        }), { headers: this.headers });
    };
    StoresService.prototype.getStores = function (urlParams) {
        var params = this.getParams(urlParams);
        return this.http.get(this.getUrl(), {
            headers: this.headers,
            params: params
        });
    };
    StoresService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoresService_Factory() { return new StoresService(i0.ɵɵinject(i1.HttpClient)); }, token: StoresService, providedIn: "root" });
    return StoresService;
}(BaseService));
export { StoresService };
