<div [formGroup]="parentForm">
  <div
    class="row"
    formArrayName="schedules"
    *ngFor="let tdStore of schedules.controls; let i = index; first as firstIndex; last as lastIndex"
  >
    <div class="col-md-12 inline-flex">
      <div class="row flex-item" [formGroupName]="i">
        <div class="col-md-6 mb-3">
          <label for="orderScheduleDate" *ngIf="firstIndex"
            >{{ 'ORDER_SCHEDULE_DATE_TIME' | translate }}<span class="text-danger">*</span></label
          >
          <div class="row">
            <div class="col-md-6">
              <ng-select
                id="orderScheduleDate"
                placeholder="{{ 'PLEASE_SELECT' | translate }}"
                [items]="orderScheduleDateOptions"
                [searchable]="false"
                [clearable]="false"
                [multiple]="false"
                bindLabel="name"
                bindValue="code"
                formControlName="orderScheduleDate"
                (change)="onSelectedScheduleDate($event, i)"
                (open)="updateOrderScheduleDateOptions()"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.orderScheduleDate.errors
                }"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span *ngIf="item.name">{{ 'SCHEDULE.' + item.name | translate }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <span>{{ 'SCHEDULE.' + item.name | translate }}</span>
                </ng-template>
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.orderScheduleDate.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.orderScheduleDate.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <ng-select
                id="orderScheduleTime"
                placeholder="{{ 'PLEASE_SELECT' | translate }}"
                [items]="(userSelectValue$ | async).orderScheduleTime"
                [searchable]="false"
                [clearable]="false"
                [multiple]="false"
                bindLabel="name"
                bindValue="code"
                formControlName="orderScheduleTime"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.orderScheduleTime.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.orderScheduleTime.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.orderScheduleTime.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="row">
            <div class="col-md-12">
              <label for="deliverySchedule" *ngIf="firstIndex"
                >{{ 'DELIVERY_SCHEDULE' | translate }}<span class="text-danger">*</span></label
              >
              <ng-select
                id="deliverySchedule"
                placeholder="{{ 'PLEASE_SELECT' | translate }}"
                [items]="(userSelectValue$ | async).deliveryScheduleDate"
                [searchable]="false"
                [clearable]="false"
                [multiple]="false"
                bindLabel="name"
                bindValue="code"
                formControlName="deliverySchedule"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.deliverySchedule.errors
                }"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span *ngIf="item.name">{{ 'SCHEDULE.' + item.name | translate }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <span>{{ 'SCHEDULE.' + item.name | translate }}</span>
                </ng-template>
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.deliverySchedule.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.deliverySchedule.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        id="delete-schedule"
        class="is-link flex"
        (click)="deleteSchedule(i)"
        title="Delete"
        [ngClass]="{
          'is-hidden': firstIndex || !checkEditPermission()
        }"
      >
        <em class="fa fa-trash-alt"></em>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mb-3">
      <a id="add-schedule" *ngIf="showBtn()" href="javascript:void(0)" class="is-link is-text" (click)="addSchedule()">
        +Add more schedule
      </a>
    </div>
  </div>
</div>
