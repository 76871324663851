import { Directive, EventEmitter, Output, ViewContainerRef } from '@angular/core';
import { ChildComponent } from './child-component';

@Directive({
  selector: '[appChildHost]',
  exportAs: 'notifyParent'
})
export class ChildDirective implements ChildComponent {
  @Output() notifyParent: EventEmitter<any> = new EventEmitter<any>();
  @Output() data: any;

  constructor(public viewContainerRef: ViewContainerRef) {}
}
