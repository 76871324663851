import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { ResetPasswordPinState } from '../state/reset-password-pin.state';

const selectResetPasswordPinState = (state: AppStates) => state.resetPasswordPin;

export const selectResetPasswordResult = createSelector(
  selectResetPasswordPinState,
  (state: ResetPasswordPinState) => state.result.resetPasswordResult
);

export const selectResetPinResult = createSelector(
  selectResetPasswordPinState,
  (state: ResetPasswordPinState) => state.result.resetPinCodeResult
);
