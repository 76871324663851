import { AuthActionTypes } from '../../actions/auth.actions';
import { ForceChangePasswordActionTypes } from '../../actions/force-change-password.action';

export function clearState(reducer) {
  return function(state, action) {
    switch (action.type) {
      case AuthActionTypes.LOGOUT:
        state = undefined;
        break;
      case ForceChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS:
        state = { forceChangePassword: state.forceChangePassword };
        break;
      default:
        break;
    }

    return reducer(state, action);
  };
}
