import { ErrorResponse } from '../../models/error.model';
import { ForceChangePasswordActions, ForceChangePasswordActionTypes } from '../actions/force-change-password.action';
import {
  BaseForceChangePasswordResponseState,
  initialForceChangePasswordResponseState
} from '../state/force-change-password.state';

export function forceChangePasswordReducers(
  state = initialForceChangePasswordResponseState,
  action: ForceChangePasswordActions
): BaseForceChangePasswordResponseState {
  switch (action.type) {
    case ForceChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS: {
      // The state will be cleared on metaReducers by clearState function with ForceChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS.
      console.log('==========', ForceChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS);
      return {
        ...state,
        result: {
          isSuccess: true,
          response: action.payload,
          errorResponse: new ErrorResponse()
        }
      };
    }

    case ForceChangePasswordActionTypes.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        result: {
          isSuccess: false,
          response: null,
          errorResponse: {
            code: action.payload.code,
            message: action.payload.message,
            translateKey: action.payload.translateKey
          }
        }
      };
    }

    case ForceChangePasswordActionTypes.CHANGE_PASSWORD_RESET: {
      return initialForceChangePasswordResponseState;
    }
    default:
      return state;
  }
}
