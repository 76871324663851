import { Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppStates } from '../store/state/app.states';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[hasPermission]'
})
// Usage *hasPermission="['can_write', 'can_read']; op 'OR'"
export class HasPermissionDirective implements OnInit, OnDestroy {
  private permissions = [];
  private logicalOp = 'OR';
  private isHidden = true;
  private authoritiesSubscription: Subscription;
  private userAuthorities: string[];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly store: Store<AppStates>
  ) {}

  ngOnInit() {
    this.authoritiesSubscription = this.store
      .select(state => state.userInfo.authorities)
      .subscribe(authorities => {
        this.userAuthorities = authorities;
        this.updateView();
      });
  }

  ngOnDestroy(): void {
    this.authoritiesSubscription.unsubscribe();
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
  }

  @Input()
  set hasPermissionOp(permOp) {
    this.logicalOp = permOp;
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    if (this.userAuthorities) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.userAuthorities.find(x => x.toUpperCase() === checkPermission.toUpperCase());

        if (permissionFound) {
          hasPermission = true;
          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }
    return hasPermission;
  }
}
