import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models/error.model';
import { MasterData } from '../../models/master-data.model';
import { UserSelectValueRequest } from '../../models/user-select-value.model';

export enum UserSelectValueActionType {
  USER_SELECT_VALUE_REQUEST = '[User Select Value] User Select Value Request',
  USER_SELECT_VALUE_RESPONSE = '[User Select Value] User Select Value Response',
  USER_SELECT_VALUE_ERROR = '[User Select Value] User Select Value Error',
  USER_SELECT_VALUE_RESET = '[User Select Value] User Select Value Reset'
}

export class UserSelectValueRequestAction implements Action {
  readonly type = UserSelectValueActionType.USER_SELECT_VALUE_REQUEST;
  constructor(public payload: UserSelectValueRequest) {}
}

export class UserSelectValueResponseAction implements Action {
  readonly type = UserSelectValueActionType.USER_SELECT_VALUE_RESPONSE;
  constructor(public payload: MasterData[]) {}
}

export class UserSelectValueErrorAction implements Action {
  readonly type = UserSelectValueActionType.USER_SELECT_VALUE_ERROR;
  constructor(public payload: ErrorResponse) {}
}

export class UserSelectValueResetAction implements Action {
  readonly type = UserSelectValueActionType.USER_SELECT_VALUE_RESET;
}

export type UserSelectValueActions =
  | UserSelectValueRequestAction
  | UserSelectValueResponseAction
  | UserSelectValueErrorAction
  | UserSelectValueResetAction;
