import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ChangePasswordResponse } from '../../models/force-change-password.model';
import { UsersService } from '../../services';
import {
  ForceChangePasswordActionTypes,
  ForceChangePasswordFailed,
  ForceChangePasswordRequestAction,
  ForceChangePasswordSuccess
} from '../actions/force-change-password.action';

@Injectable()
export class ForceChangePasswordEffect {
  constructor(private actions$: Actions, private usersService: UsersService) {}

  @Effect()
  create$: Observable<Action> = this.actions$.pipe(
    ofType(ForceChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST),
    map((action: ForceChangePasswordRequestAction) => action.payload),
    switchMap(changePasswordRequest => {
      return this.usersService.changePassword(changePasswordRequest).pipe(
        map((changedPassword: HttpResponse<ChangePasswordResponse>) => {
          return new ForceChangePasswordSuccess({
            statusCode: changedPassword.status.toString(),
            message: changedPassword.statusText
          });
        }),
        catchError(err => {
          return of(new ForceChangePasswordFailed(err.error));
        })
      );
    })
  );
}
