import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class ErrorMissingTranslationHandlerService implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    this.logger.debug('ErrorMissingTranslationHandlerService', params);

    if (params.key.startsWith('ERROR_CODE')) {
      return params.translateService.instant('ERROR_CODE.undefined') + `[${params.key}]`;
    }
    return params.key;
  }

  constructor(private readonly logger: NGXLogger) {}
}
