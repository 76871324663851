import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { ClientIdTypeEnum } from '../../enum/client-id.enum';
import { selectClientIdResult } from '../../store/selectors/client-id.selector';
import { AppStates } from '../../store/state/app.states';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  private clientId: ClientIdTypeEnum | null;

  constructor(
    private readonly router: Router,
    private location: Location,
    public sidebarService: SidebarService,
    private cookieService: CookieService,
    private readonly store: Store<AppStates>
  ) {
    this.location = location;
  }

  ngOnInit() {
    this.store
      .pipe(
        select(selectClientIdResult),
        tap(clientId => (this.clientId = clientId)),
        untilComponentDestroyed(this)
      )
      .subscribe();
  }

  getSideBarState() {
    return this.sidebarService.getSidebarState();
  }

  toggleSidebar() {
    this.sidebarService.setSidebarState(!this.sidebarService.getSidebarState());
  }

  get isNotPOS(): boolean {
    return this.clientId !== ClientIdTypeEnum.POS_APPL;
  }

  ngOnDestroy(): void {}
}
