import { ErrorResponse } from '../../models/error.model';
import { ChangePasswordResponse } from '../../models/force-change-password.model';

export interface BaseForceChangePasswordResponseState {
  result: {
    isSuccess: boolean;
    response: ChangePasswordResponse | null;
    errorResponse: ErrorResponse | null;
  };
}

export const initialForceChangePasswordResponseState: BaseForceChangePasswordResponseState = {
  result: {
    isSuccess: false,
    response: null,
    errorResponse: new ErrorResponse()
  }
};
