import { createSelector } from '@ngrx/store';
import * as fromOrderState from '../reducers/orders.reducer';
import { AppStates } from '../state/app.states';

const selectOrderSummaryState = (state: AppStates) => state.orderSummary;

export const selectOrderSummaryResult = createSelector(
  selectOrderSummaryState,
  (state: fromOrderState.OrderState) => state
);

export const selectOrderSummaryResponseResult = createSelector(
  selectOrderSummaryState,
  (state: fromOrderState.OrderState) => state.result
);
