/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../sidebar/sidebar.component.ngfactory";
import * as i3 from "../sidebar/sidebar.component";
import * as i4 from "../sidebar/sidebar.service";
import * as i5 from "@ngrx/store";
import * as i6 from "../navbar/navbar.component.ngfactory";
import * as i7 from "../navbar/navbar.component";
import * as i8 from "../../services/auth-guard.service";
import * as i9 from "@angular/common";
import * as i10 from "@angular/router";
import * as i11 from "./admin-layout.component";
import * as i12 from "ngx-cookie-service";
var styles_AdminLayoutComponent = [i0.styles];
var RenderType_AdminLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminLayoutComponent, data: {} });
export { RenderType_AdminLayoutComponent as RenderType_AdminLayoutComponent };
function View_AdminLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, i2.View_SidebarComponent_0, i2.RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i3.SidebarComponent, [i4.SidebarService, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AdminLayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).autoToggleMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_NavbarComponent_0, i6.RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i7.NavbarComponent, [i4.SidebarService, i5.Store, i8.AuthGuardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AdminLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "page-wrapper"]], null, null, null, null, null)), i1.ɵprd(512, null, i9.ɵNgClassImpl, i9.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i9.NgClass, [i9.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { toggled: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminLayoutComponent_1)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminLayoutComponent_2)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i9.ɵNgClassImpl, i9.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i9.NgClass, [i9.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(11, { "page-content": 0 }), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(14, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "page-wrapper"; var currVal_1 = _ck(_v, 3, 0, _co.getSideBarState()); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.isNotPOS; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.isNotPOS; _ck(_v, 7, 0, currVal_3); var currVal_4 = _ck(_v, 11, 0, _co.isNotPOS); _ck(_v, 10, 0, currVal_4); _ck(_v, 14, 0); }, null); }
export function View_AdminLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_AdminLayoutComponent_0, RenderType_AdminLayoutComponent)), i1.ɵdid(1, 245760, null, 0, i11.AdminLayoutComponent, [i10.Router, i9.Location, i4.SidebarService, i12.CookieService, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminLayoutComponentNgFactory = i1.ɵccf("app-layout", i11.AdminLayoutComponent, View_AdminLayoutComponent_Host_0, {}, {}, []);
export { AdminLayoutComponentNgFactory as AdminLayoutComponentNgFactory };
