import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { filter, map } from 'rxjs/operators';
import { PasswordValidation } from 'src/app/shared/directives/validators/password-validation.directive';
import { ModalButtonResponseEnum } from 'src/app/shared/enum/modal-button-response.enum';
import { AlertModalComponent } from 'src/app/shared/layouts/modals/alert-modal/alert-modal.component';
import { EventService } from 'src/app/shared/services';
import { ForgotPasswordStepEnum } from '../../shared/enum/user.enum';
import { ForgotPasswordChangePasswordRequestAction, ForgotPasswordOTPRequestAction, ForgotPasswordOTPTimeoutRequestAction, ForgotPasswordReset, ForgotPasswordVerifyOTPRequestAction } from '../../shared/store/actions/forgot-password.action';
import { selectForgotPasswordResult } from '../../shared/store/selectors/forgot-password.selector';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(store, fb, eventService, modalService, translate, router) {
        var _this = this;
        this.store = store;
        this.fb = fb;
        this.eventService = eventService;
        this.modalService = modalService;
        this.translate = translate;
        this.router = router;
        this.otp = '';
        this.userName = '';
        this.isTimeout = false;
        this.isCountingDown = false;
        eventService.GetEvent('resendOTP').subscribe(function () {
            _this.resendOTP();
        });
    }
    Object.defineProperty(ForgotPasswordComponent.prototype, "formRequestOTP", {
        get: function () {
            return this.forgotPasswordForm.get('requestOTP');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ForgotPasswordComponent.prototype, "formChangePassword", {
        get: function () {
            return this.forgotPasswordForm.get('changePassword');
        },
        enumerable: true,
        configurable: true
    });
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.step = ForgotPasswordStepEnum.REQUEST_OTP;
        this.store.dispatch(new ForgotPasswordReset());
        var controlsConfig = {
            requestOTP: this.fb.group({
                userName: ['', Validators.required]
            }),
            inputOTP: this.fb.group({}),
            changePassword: this.fb.group({
                newPassword: ['', [Validators.required, PasswordValidation]],
                reEnterPassword: ['', [Validators.required]]
            }, { validator: this.checkPasswords })
        };
        this.forgotPasswordForm = this.fb.group(controlsConfig);
        this.formChangePassword.disable();
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.forgotPasswordResult$ = this.localStore.pipe(select(selectForgotPasswordResult), filter(function (value) { return value !== null; }));
        this.forgotPasswordResult$.pipe(map(function (value) { return value.result; })).subscribe(function (result) {
            _this.step = result.step;
            _this.userName = result.userName;
            switch (_this.step) {
                case ForgotPasswordStepEnum.REQUEST_OTP:
                    _this.doRequestOtpStep(result);
                    break;
                case ForgotPasswordStepEnum.ENTER_OTP:
                    _this.doEnterOtpStep(result);
                    break;
                case ForgotPasswordStepEnum.CHANGE_PASSWORD:
                    _this.doChangePasswordStep(result);
                    break;
                default:
                    break;
            }
        });
    };
    ForgotPasswordComponent.prototype.ngOnDestroy = function () { };
    ForgotPasswordComponent.prototype.doRequestOtpStep = function (result) {
        if (!result.response && ['00000', '01002', '01003'].includes(result.errorResponse.code)) {
            this.submitted = false;
            this.formRequestOTP.get('userName').patchValue('');
        }
    };
    ForgotPasswordComponent.prototype.doEnterOtpStep = function (result) {
        if (!this.isCountingDown && !this.isTimeout) {
            this.startCountDown();
        }
        if (!result.response && ['01006', '01007'].includes(result.errorResponse.code)) {
            this.otp = '';
        }
    };
    ForgotPasswordComponent.prototype.doChangePasswordStep = function (result) {
        clearInterval(this.interval);
        this.formChangePassword.enable();
        this.submitted = false;
        if (!result.response && ['01008'].includes(result.errorResponse.code)) {
            this.alertAndBack(result.errorResponse);
        }
    };
    ForgotPasswordComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.forgotPasswordForm.invalid || this.isDisableButton()) {
            return;
        }
        if (this.step === ForgotPasswordStepEnum.REQUEST_OTP) {
            this.store.dispatch(new ForgotPasswordOTPRequestAction(this.formRequestOTP.value.userName));
        }
        else if (this.step === ForgotPasswordStepEnum.ENTER_OTP) {
            this.store.dispatch(new ForgotPasswordVerifyOTPRequestAction({ userName: this.formRequestOTP.value.userName, otp: this.otp }));
        }
        else if (this.step === ForgotPasswordStepEnum.CHANGE_PASSWORD) {
            this.store.dispatch(new ForgotPasswordChangePasswordRequestAction({
                userName: this.formRequestOTP.value.userName,
                newPassword: this.formChangePassword.value.newPassword,
                otp: this.otp
            }));
        }
    };
    ForgotPasswordComponent.prototype.getPageTitle = function () {
        if (this.step === ForgotPasswordStepEnum.REQUEST_OTP) {
            return 'ENTER_USERNAME';
        }
        else if (this.step === ForgotPasswordStepEnum.ENTER_OTP) {
            return 'ENTER_OTP';
        }
        else if (this.step === ForgotPasswordStepEnum.CHANGE_PASSWORD) {
            return 'ENTER_NEW_PASSWORD';
        }
    };
    ForgotPasswordComponent.prototype.isDisableButton = function () {
        if (this.step === ForgotPasswordStepEnum.ENTER_OTP && this.otp.length !== 6) {
            return true;
        }
        return false;
    };
    ForgotPasswordComponent.prototype.alertAndBack = function (errorResponse) {
        var _this = this;
        var initialState = {
            title: this.translate.instant('FAILED'),
            message: this.translate.instant('ERROR_CODE.' + errorResponse.code)
        };
        var alertModal = this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
        alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(function (result) {
            if (result === ModalButtonResponseEnum.OK) {
                alertModal.hide();
                _this.router.navigateByUrl('/login');
            }
        });
    };
    ForgotPasswordComponent.prototype.onEnterKey = function (event) {
        if (event.keyCode === 13) {
            this.onSubmit();
        }
    };
    ForgotPasswordComponent.prototype.resendOTP = function () {
        if (this.step === ForgotPasswordStepEnum.ENTER_OTP) {
            this.isTimeout = false;
            this.isCountingDown = false;
            this.otp = '';
            this.store.dispatch(new ForgotPasswordOTPRequestAction(this.formRequestOTP.value.userName));
            this.startCountDown();
        }
    };
    ForgotPasswordComponent.prototype.onOtpChange = function (otp) {
        this.otp = otp;
    };
    ForgotPasswordComponent.prototype.otpTimeOut = function () {
        this.isTimeout = true;
        this.isCountingDown = false;
        this.otp = '';
        this.store.dispatch(new ForgotPasswordOTPTimeoutRequestAction());
    };
    ForgotPasswordComponent.prototype.startCountDown = function () {
        var _this = this;
        clearInterval(this.interval);
        this.isCountingDown = true;
        var COUNTDOWN_MINUTES = 5;
        var counter = 60 * COUNTDOWN_MINUTES;
        var minutesElement = document.querySelector('.minutes');
        var secondsElement = document.querySelector('.seconds');
        // Initial Time
        this.displayCountdownTime(counter, minutesElement, secondsElement);
        this.interval = setInterval(function () {
            _this.displayCountdownTime(counter, minutesElement, secondsElement);
            counter--;
            if (counter < 0) {
                clearInterval(_this.interval);
                _this.otpTimeOut();
            }
        }, 1000);
    };
    ForgotPasswordComponent.prototype.displayCountdownTime = function (second, minutesElement, secondsElement) {
        var toMinutes = Math.floor(second / 60);
        var toSeconds = Math.floor(second) % 60;
        var toMinutesDisplay = toMinutes.toString();
        var toSecondsDisplay = toSeconds.toString();
        if (toSecondsDisplay.length === 1) {
            toSecondsDisplay = '0' + toSecondsDisplay;
        }
        this.updateDom(toMinutesDisplay, minutesElement);
        this.updateDom(toSecondsDisplay, secondsElement);
    };
    ForgotPasswordComponent.prototype.updateDom = function (value, element) {
        element.innerHTML = value;
    };
    ForgotPasswordComponent.prototype.checkPasswords = function (group) {
        var pass = group.controls.newPassword.value;
        var confirmPass = group.controls.reEnterPassword.value;
        return pass === confirmPass ? null : group.controls.reEnterPassword.setErrors({ passwordNotEquivalent: true });
    };
    ForgotPasswordComponent.prototype.toggleInputPasswordType = function () {
        this.isShowPassword = !this.isShowPassword;
    };
    ForgotPasswordComponent.prototype.toggleInputReEnterPasswordType = function () {
        this.isShowReEnterPassword = !this.isShowReEnterPassword;
    };
    ForgotPasswordComponent.prototype.clearPasswordSamePreviousError = function () {
        this.formChangePassword.get('newPassword').setErrors(null);
        this.formChangePassword.get('newPassword').updateValueAndValidity();
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
