import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
var CatalogsService = /** @class */ (function (_super) {
    tslib_1.__extends(CatalogsService, _super);
    function CatalogsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.envService = _this.env.services.products;
        _this.headers = new HttpHeaders(_this.envService.headers);
        return _this;
    }
    CatalogsService.prototype.getCatalog = function (urlParams) {
        var params = this.getParams(urlParams);
        return this.http.get(this.getUrl(), {
            headers: this.headers,
            params: params
        });
    };
    CatalogsService.prototype.getCatalogListMenu = function () {
        return this.http.get(this.getFullUrl(this.envService.menu), {
            headers: this.headers
        });
    };
    return CatalogsService;
}(BaseService));
export { CatalogsService };
