<div class="full-popup-head">
  <div class="full-popup-title float-left">{{ data.title }}</div>
  <div class="full-popup-close float-right">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="fa fa-times"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div class="admin-form">
  <form [formGroup]="editStoreForm" (ngSubmit)="onSubmit()">
    <div class="row alert-dark mt-4 mb-3 mx-0">
      <span class="pl-2 my-1">{{ 'STORE_PROFILE' | translate }}</span>
    </div>
    <app-store-profile [parentForm]="editStoreForm" [submitted]="submitted" [mode]="data.mode"></app-store-profile>

    <div class="row alert-dark mt-4 mb-3 mx-0">
      <span class="pl-2 my-1">{{ 'ORDER_POLICY' | translate }}</span>
    </div>
    <app-order-policy [parentForm]="editStoreForm" [submitted]="submitted" [mode]="data.mode"></app-order-policy>

    <div class="row alert-dark mt-4 mb-3 mx-0">
      <span class="pl-2 my-1">{{ 'RECEIPT_SETTING' | translate }}</span>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="receiptHeader">{{ 'HEADER' | translate }}</label>
          <input
            type="text"
            formControlName="receiptHeader"
            class="form-control"
            id="receiptHeader"
            name="receiptHeader"
            maxlength="50"
            [ngClass]="{ 'is-invalid': submitted && f.receiptHeader.errors }"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-xs-1 col-md-12">
          <label for="receiptAddress" data-required>{{ 'ADDRESS' | translate }}</label>
          <textarea
            rows="4"
            cols="80"
            type="text"
            formControlName="receiptAddress"
            class="form-control"
            id="receiptAddress"
            name="receiptAddress"
            maxlength="250"
            [ngClass]="{ 'is-invalid': submitted && f.receiptAddress.errors }"
          ></textarea>
          <div *ngIf="submitted && f.receiptAddress.errors" class="invalid-feedback">
            <div *ngIf="f.receiptAddress.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="receiptTaxId">{{ 'TAX_ID' | translate }}</label>
          <input
            type="text"
            formControlName="receiptTaxId"
            class="form-control"
            id="receiptTaxId"
            name="receiptTaxId"
            maxlength="13"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="receiptBranchNo">{{ 'BRANCH_NO' | translate }}</label>
          <input
            type="text"
            formControlName="receiptBranchNo"
            class="form-control"
            id="receiptBranchNo"
            name="receiptBranchNo"
            maxlength="5"
          />
        </div>
      </div>
    </div>
    <div class="before-fixed-row-bottom-popup form-row">
      <div class="col-md-6">
        <div class="form-group col-xs-1 col-md-12">
          <label for="receiptFooter">{{ 'FOOTER' | translate }}</label>
          <input
            type="text"
            formControlName="receiptFooter"
            class="form-control"
            id="receiptFooter"
            name="receiptFooter"
            maxlength="25"
          />
        </div>
      </div>
    </div>
    <!-- <ng-container *ngIf="data.mode === pageMode.REQUEST_EDIT; else otherMode">
      <div class="fixed-row-bottom-popup form-row text-right" *ngIf="true">
        <button type="button" id="btn-cancel" (click)="onCancel()" class="btn btn-cancel ">
          {{ 'CANCEL' | translate }}
        </button>
        &nbsp;
        <button type="submit" id="btn-add" class="btn btn-primary ">
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </ng-container>
    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div class="float-right">
            <span *ngIf="true" class="mr-2">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">
                {{ 'EDIT' | translate }}
              </button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template> -->
  </form>
</div>
