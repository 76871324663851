/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./version-tag.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./version-tag.component";
var styles_VersionTagComponent = [i0.styles];
var RenderType_VersionTagComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VersionTagComponent, data: {} });
export { RenderType_VersionTagComponent as RenderType_VersionTagComponent };
export function View_VersionTagComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "text-center"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 0, 0, currVal_0); }); }
export function View_VersionTagComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-version-tag", [], null, null, null, View_VersionTagComponent_0, RenderType_VersionTagComponent)), i1.ɵdid(1, 114688, null, 0, i2.VersionTagComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VersionTagComponentNgFactory = i1.ɵccf("app-version-tag", i2.VersionTagComponent, View_VersionTagComponent_Host_0, {}, {}, []);
export { VersionTagComponentNgFactory as VersionTagComponentNgFactory };
