import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { filter, map, tap } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../../shared/enum/modal-button-response.enum';
import { TDPageModes } from '../../shared/enum/store.enum';
import { UserSelectValueEnum } from '../../shared/enum/user-select-value.enum';
import { AlertModalComponent } from '../../shared/layouts';
import { ChildItem } from '../../shared/layouts/modals/child-item/child-item';
import { ConfirmModalComponent } from '../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { FullModalComponent } from '../../shared/layouts/modals/full-modal/full-modal.component';
import { RolesAction } from '../../shared/store/actions/roles.actions';
import { UsersListRequestAction } from '../../shared/store/actions/user-list.actions';
import { UserSelectValueRequestAction, UserSelectValueResetAction } from '../../shared/store/actions/user-select-value.actions';
import { UsersDeleteAction, UsersDeleteResetAction } from '../../shared/store/actions/users.action';
import { selectDeleteUsersResult } from '../../shared/store/selectors/delete-user.selector';
import { selectRolesResult } from '../../shared/store/selectors/roles.selector';
import { selectTenantInfoResult } from '../../shared/store/selectors/tenant-info.selector';
import { selectUsersListResult } from '../../shared/store/selectors/user-list.selector';
import { selectUsersResult } from '../../shared/store/selectors/users.selector';
import { isOwner } from '../../shared/utils/check-role';
import { AddComponent } from './add/add.component';
import { EditComponent } from './edit/edit.component';
var UsersComponent = /** @class */ (function () {
    function UsersComponent(formBuilder, store, modalService, translate) {
        this.formBuilder = formBuilder;
        this.store = store;
        this.modalService = modalService;
        this.translate = translate;
        this.usersList$ = this.store.pipe(select(selectUsersListResult));
        this.userStatus = [];
        this.headerRow = ['USER_ID', 'NAME', 'USERNAME', 'ROLE', 'LAST_ACCESSED', 'STATUS', 'USER_ACTION'];
    }
    UsersComponent.prototype.ngOnInit = function () {
        this.isShowAdvanceSearch = false;
        this.isShowSearchTag = false;
        this.currentPage = 1;
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.store.dispatch(new UserSelectValueRequestAction({ type: [UserSelectValueEnum.ROLE] }));
        this.role$ = this.localStore.pipe(select(selectRolesResult), map(function (data) { return data.result.response; }));
        this.initState();
        this.initControl();
        this.initDropDown();
    };
    UsersComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new UserSelectValueResetAction());
    };
    UsersComponent.prototype.initDropDown = function () {
        var _this = this;
        this.translate
            .get('loaded.done')
            .pipe(untilComponentDestroyed(this), tap(function () { return _this.initUserStatus(); }))
            .subscribe();
        this.translate.onLangChange
            .pipe(untilComponentDestroyed(this), tap(function () { return _this.initUserStatus(); }))
            .subscribe();
    };
    UsersComponent.prototype.initUserStatus = function () {
        this.userStatus = [
            { value: '', label: this.translate.instant('ALL_STATUS') },
            { value: 'true', label: this.translate.instant('ACTIVE') },
            { value: 'false', label: this.translate.instant('INACTIVE') }
        ];
    };
    UsersComponent.prototype.initState = function () {
        var _this = this;
        this.store.dispatch(new RolesAction());
        this.localStore.pipe(select(selectTenantInfoResult)).subscribe(function (tenantInfo) {
            _this.merchantNo = tenantInfo.merchant;
            _this.listRequest = {
                merchantNo: _this.merchantNo,
                size: '20',
                page: '0',
                sortBy: 'userCode',
                sortOrder: 'desc',
                active: null,
                roles: null,
                searchCriteria: null
            };
            _this.dispatchUserListAction(_this.listRequest);
        });
        this.localStore.pipe(select(selectDeleteUsersResult)).subscribe(function (deleteStatus) {
            if (deleteStatus.result.response && deleteStatus.result.response.statusCode === '200') {
                var initialState = {
                    title: _this.translate.instant('SUCCESS'),
                    message: _this.translate.instant('USER_HAS_BEEN_DELETE')
                };
                _this.alertSuccessModal(initialState);
                _this.store.dispatch(new UsersDeleteResetAction());
            }
        });
        this.localStore
            .pipe(select(selectUsersResult), map(function (userResponse) { return userResponse.result.response; }), filter(function (response) { return response && response.updateSuccess; }))
            .subscribe(function () {
            setTimeout(function () {
                _this.dispatchUserListAction(_this.listRequest);
            }, 4000);
        });
    };
    UsersComponent.prototype.initControl = function () {
        this.searchAndFilterForm = this.formBuilder.group({
            selectedRoles: [],
            searchText: null,
            status: ''
        });
    };
    UsersComponent.prototype.alertSuccessModal = function (initialState) {
        var _this = this;
        var alertModal = this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
        alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(function (result) {
            if (result === ModalButtonResponseEnum.OK) {
                setTimeout(function () {
                    _this.dispatchUserListAction(_this.listRequest);
                }, 1500);
            }
        });
    };
    UsersComponent.prototype.callAddUser = function () {
        var initialState = {
            title: null,
            childItem: new ChildItem(AddComponent, {
                title: this.translate.instant('ADD_USER')
            })
        };
        this.bsModalRef = this.modalService.show(FullModalComponent, {
            animated: false,
            backdrop: false,
            initialState: initialState
        });
    };
    UsersComponent.prototype.callEditUser = function (id, mode) {
        var initialState = {
            title: null,
            childItem: new ChildItem(EditComponent, {
                id: id,
                title: this.translate.instant(mode === TDPageModes.REQUEST_EDIT ? 'EDIT_USER' : 'VIEW_USER'),
                mode: mode
            })
        };
        this.bsModalRef = this.modalService.show(FullModalComponent, {
            animated: false,
            backdrop: false,
            initialState: initialState
        });
    };
    UsersComponent.prototype.onRowPerPagesSelected = function (value) {
        this.currentPage = 1;
        this.listRequest.size = value;
        this.listRequest.page = 0;
        this.dispatchUserListAction(this.listRequest);
    };
    UsersComponent.prototype.pageChanged = function (event) {
        this.currentPage = event.page;
        this.listRequest.page = (event.page - 1).toString();
        this.dispatchUserListAction(this.listRequest);
    };
    UsersComponent.prototype.dispatchUserListAction = function (userListRequest) {
        this.store.dispatch(new UsersListRequestAction(userListRequest));
    };
    UsersComponent.prototype.dispatchDeleteUserAction = function (userNo) {
        this.store.dispatch(new UsersDeleteAction({
            userNo: userNo
        }));
    };
    UsersComponent.prototype.selectedStatus = function (event) {
        this.listRequest.active = event;
        this.listRequest.page = 0;
        this.currentPage = 1;
        this.dispatchUserListAction(this.listRequest);
    };
    UsersComponent.prototype.handleSearchText = function () {
        this.listRequest.searchCriteria = this.searchAndFilterForm.value.searchText;
        this.listRequest.page = 0;
        this.currentPage = 1;
        this.dispatchUserListAction(this.listRequest);
    };
    UsersComponent.prototype.clearSearchText = function () {
        this.searchAndFilterForm.controls['searchText'].reset();
        this.listRequest.searchCriteria = null;
        this.listRequest.page = 0;
        this.currentPage = 1;
        this.dispatchUserListAction(this.listRequest);
    };
    UsersComponent.prototype.onClickedOutside = function (e) {
        if (e.target &&
            (e.target.classList.contains('is-highlighted') ||
                e.target.classList.contains('ng-option') ||
                e.target.classList.contains('ng-option-label') ||
                e.target.classList.contains('ng-value-icon'))) {
            return;
        }
        this.isShowAdvanceSearch = false;
    };
    UsersComponent.prototype.selectedRoles = function () {
        var roles = this.searchAndFilterForm.get('selectedRoles').value;
        if (!roles.length) {
            this.isShowSearchTag = false;
        }
    };
    UsersComponent.prototype.handleRemoveRole = function (roleIndex) {
        var roles = this.searchAndFilterForm.get('selectedRoles').value;
        roles.splice(roleIndex, 1);
        this.searchAndFilterForm.get('selectedRoles').setValue(roles);
        if (!roles.length) {
            this.isShowSearchTag = false;
        }
        this.listRequest.roles = roles.join();
        this.listRequest.page = 0;
        this.currentPage = 1;
        this.dispatchUserListAction(this.listRequest);
    };
    UsersComponent.prototype.handleAdvanceFilter = function () {
        var roles = this.searchAndFilterForm.get('selectedRoles').value;
        this.isShowSearchTag = !!roles.length;
        this.isShowAdvanceSearch = false;
        this.listRequest.roles = roles.length ? roles.join() : null;
        this.listRequest.page = 0;
        this.currentPage = 1;
        this.dispatchUserListAction(this.listRequest);
    };
    UsersComponent.prototype.clearAllRoles = function () {
        this.searchAndFilterForm.get('selectedRoles').reset();
        this.isShowSearchTag = false;
        this.listRequest.roles = null;
        this.listRequest.page = 0;
        this.currentPage = 1;
        this.dispatchUserListAction(this.listRequest);
    };
    UsersComponent.prototype.handleDeleteUser = function (userNo, fullName) {
        var _this = this;
        var initialState = {
            title: this.translate.instant('DELETE'),
            okText: this.translate.instant('YES_DELETE'),
            cancelText: this.translate.instant('CANCEL'),
            message: this.translate.instant('ARE_YOU_SURE_DELETE', { fullName: fullName })
        };
        var confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState
        });
        confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(function (result) {
            if (result === ModalButtonResponseEnum.OK) {
                _this.dispatchDeleteUserAction(userNo);
            }
        });
    };
    UsersComponent.prototype.isOwner = function (role) {
        return isOwner(role);
    };
    Object.defineProperty(UsersComponent.prototype, "pageMode", {
        get: function () {
            return TDPageModes;
        },
        enumerable: true,
        configurable: true
    });
    return UsersComponent;
}());
export { UsersComponent };
