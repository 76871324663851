import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { TDPageModes } from '../../../enum/store.enum';
import { selectTdStoreResult } from '../../../store/selectors/td-store.selector';
import { AppStates } from '../../../store/state/app.states';
import { BaseTdStoreState } from '../../../store/state/td-store.state';

@Component({
  selector: 'app-order-policy',
  templateUrl: './order-policy.component.html',
  styleUrls: ['./order-policy.component.scss']
})
export class OrderPolicyComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() mode: TDPageModes;

  private localStore: Observable<any>;

  public tdStore$: Observable<BaseTdStoreState>;

  constructor(private fb: FormBuilder, private readonly store: Store<AppStates>) {}

  get orderSchedule() {
    return this.parentForm.get('orderSchedule') as FormGroup;
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.tdStore$ = this.localStore.pipe(select(selectTdStoreResult));

    this.parentForm.addControl('orderSchedule', this.createForm);

    this.setOrderPolicyValue();
    if (this.mode === TDPageModes.REQUEST_VIEW) {
      this.orderSchedule.disable();
    }
  }

  get createForm() {
    return this.fb.group({
      minOrder: [{ value: 15000, disabled: false }, [Validators.required, Validators.min(0)]],
      schedules: this.fb.array([])
    });
  }

  setOrderPolicyValue() {
    this.tdStore$
      .pipe(
        map(response => response && response.result && response.result.response),
        filter(response => response !== null),
        take(1)
      )
      .subscribe(value => {
        if (value && value.orderSchedule) {
          this.orderSchedule.patchValue({
            minOrder: value.orderSchedule.minOrder
          });
        }
      });
  }

  toggleEditOrderPolicy() {
    this.orderSchedule.enable();
  }
}
