import { TreeItem, TreeviewItem } from 'ngx-treeview';

export interface BaseTdStoresTreeViewState {
  items: Array<TreeItem>;
}

export const initialTdStoresTreeViewState: BaseTdStoresTreeViewState = {
  items: new Array(
    new TreeviewItem({
      text: 'กำลังโหลดข้อมูล',
      value: null,
      checked: false,
      disabled: false,
      collapsed: false
    })
  )
};
