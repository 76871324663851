import { DatePipe, registerLocaleData } from '@angular/common';
// import ngx-translate and the http loader
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { default as localeThExtra } from '@angular/common/locales/extra/th';
import { default as localeTh } from '@angular/common/locales/th';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TreeModule } from 'angular-tree-component';
import { AccordionModule, BsDropdownModule, PaginationModule, RatingModule } from 'ngx-bootstrap';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
// @ts-ignore
import { NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { TreeviewModule } from 'ngx-treeview';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForceChangePasswordLeftComponent } from './pages/force-change-password/force-change-password-left/force-change-password-left.component';
import { ForceChangePasswordComponent } from './pages/force-change-password/force-change-password.component';
import { ForgotPasswordLeftComponent } from './pages/forgot-password/forgot-password-left/forgot-password-left.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotUsernameLeftComponent } from './pages/forgot-username/forgot-username-left/forgot-username-left.component';
import { ForgotUsernameComponent } from './pages/forgot-username/forgot-username.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginHomeComponent } from './pages/login-home/login-home.component';
import { LoginLeftComponent } from './pages/login/login-left/login-left.component';
import { ItemComponent as OrderItemComponent } from './pages/orders/item/item.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { SearchBarComponent } from './pages/orders/search-bar/search-bar.component';
import { DuplicateItemComponent } from './pages/orders/step1/duplicate-item/duplicate-item.component';
import { Step1Component } from './pages/orders/step1/step1.component';
import { TreeMenuComponent } from './pages/orders/step1/tree-menu/tree-menu.component';
import { ItemListComponent } from './pages/orders/step2/item-list/item-list.component';
import { Step2Component } from './pages/orders/step2/step2.component';
import { PosComponent } from './pages/pos/pos.component';
import { EditComponent as EditStoreComponent } from './pages/stores/edit/edit.component';
import { StoresComponent } from './pages/stores/stores.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { AddComponent as AddUserComponent } from './pages/users/add/add.component';
import { EditComponent as EditUserComponent } from './pages/users/edit/edit.component';
import { UsersComponent } from './pages/users/users.component';
import { MerchantVerificationErrorComponent } from './pages/verification/merchant-verification-error/merchant-verification-error.component';
import { MerchantVerificationInvalidComponent } from './pages/verification/merchant-verification-invalid/merchant-verification-invalid.component';
import { MerchantVerificationSuccessComponent } from './pages/verification/merchant-verification-success/merchant-verification-success.component';
import { MerchantVerificationComponent } from './pages/verification/merchant-verification/merchant-verification.component';
import { NumericKeypadComponent } from './shared/components/numeric-keypad/numeric-keypad.component';
import { OtpInputComponent } from './shared/components/otp-input/otp-input.component';
import { StarRatingComponent } from './shared/components/star-rating/star-rating.component';
import { NumericTextboxComponent } from './shared/components/td-store/money-textbox/numeric-textbox.component';
import { OrderPolicyScheduleComponent } from './shared/components/td-store/order-policy/order-policy-schedule/order-policy-schedule.component';
import { OrderPolicyComponent } from './shared/components/td-store/order-policy/order-policy.component';
import { StoreProfileComponent } from './shared/components/td-store/store-profile/store-profile.component';
import { AlphabetOnlyDirective } from './shared/directives/alphabet-only.directive';
import { AppSrcDirective } from './shared/directives/app-src.directive';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { DebounceClickDirective } from './shared/directives/debounce-click.directive';
import { HasPermissionDirective } from './shared/directives/has-permission.directive';
import { NumberDirective } from './shared/directives/number-only.directive';
import { OtpNumberDirective } from './shared/directives/otp-number.directive';
import { PascalCaseDirective } from './shared/directives/pascal-case.directive';
import { CacheInterceptor, CommonInterceptor, ErrorInterceptor, TokenInterceptor } from './shared/interceptors';
import { SpinnerLoaderInterceptor } from './shared/interceptors/spinner-loader-interceptor';
import {
  AdminLayoutComponent,
  AlertModalComponent,
  LoginComponent,
  NavbarComponent,
  SidebarComponent
} from './shared/layouts';
import { LoginLayoutComponent } from './shared/layouts/login-layout/login-layout.component';
import { MobileVerificationLayoutComponent } from './shared/layouts/mobile-verification-layout/mobile-verification-layout.component';
import { ChildDirective } from './shared/layouts/modals/child-item/child-directive';
import { ConfirmModalComponent } from './shared/layouts/modals/confirm-modal/confirm-modal.component';
import { CustomSizeModalComponent } from './shared/layouts/modals/custom-size-modal/custom-size-modal.component';
import { FullModalComponent } from './shared/layouts/modals/full-modal/full-modal.component';
import { SwitchLangMenuComponent } from './shared/layouts/switch-lang-menu/switch-lang-menu.component';
import { SwitchLangToggleComponent } from './shared/layouts/switch-lang-toggle/switch-lang-toggle.component';
import { VersionTagComponent } from './shared/layouts/version-tag/version-tag.component';
import { DateFormatPipe } from './shared/pipe/date-format/date-format.pipe';
import { DecimalProxyPipe } from './shared/pipe/decimal-proxy/decimal-proxy.pipe';
import { KeysPipe } from './shared/pipe/key/keys.pipe';
import { TextEllipsisPipe } from './shared/pipe/text-ellipsis/text-ellipsis.pipe';
import { TextExistPipe } from './shared/pipe/text-exist/text-exist.pipe';
import { MerchantVerificationResolver } from './shared/resolver/merchant-verification.resolver';
import {
  AuthGuardService,
  AuthService,
  CartsService,
  CatalogsService,
  EventService,
  UserListService,
  UsersService,
  VerificationService
} from './shared/services';
import { AppInitService } from './shared/services/app-init.service';
import { ErrorMissingTranslationHandlerService } from './shared/services/error-missing-translation-handler.service';
import { MerchantService } from './shared/services/merchant.service';
import { PosService } from './shared/services/pos.service';
import { UserSelectValueService } from './shared/services/user-select-value.service';
import { AuthEffects } from './shared/store/effects/auth.effects';
import { CartsEffects } from './shared/store/effects/carts.effect';
import { CatalogListMenuEffects } from './shared/store/effects/catalog-list-menu.effects';
import { CatalogListEffects } from './shared/store/effects/catalog-list.effects';
import { ForceChangePasswordEffect } from './shared/store/effects/force-change-password.effect';
import { ForgotPasswordEffects } from './shared/store/effects/forgot-password.effects';
import { ForgotUsernameEffects } from './shared/store/effects/forgot-username.effects';
import { MenuEffects } from './shared/store/effects/menu.effects';
import { RoleEffects } from './shared/store/effects/roles.effects';
import { TdStoreListEffects } from './shared/store/effects/td-store-list.effects';
import { TdStoreEffects } from './shared/store/effects/td-store.effects';
import { TdStoresTreeViewEffects } from './shared/store/effects/td-stores-tree-view.effects';
import { UserInfoEffects } from './shared/store/effects/user-info.effects';
import { UserListEffects } from './shared/store/effects/user-list.effects';
import { UserSelectValueEffects } from './shared/store/effects/user-select-value.effects';
import { UsersEffects } from './shared/store/effects/users.effects';
import { VerificationEffects } from './shared/store/effects/verification.effects';
import { appReducers } from './shared/store/reducers/app.reducers';
import { clearState } from './shared/store/reducers/meta-reducers/clear-state';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function InitializeAppFactory(appInitService: AppInitService) {
  return appInitService.load();
}

registerLocaleData(localeTh, 'th', localeThExtra);

// TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
// noinspection AngularInvalidImportedOrDeclaredSymbol
@NgModule({
  declarations: [
    AppComponent,
    ForceChangePasswordComponent,
    HomeComponent,
    LoginHomeComponent,
    LoginLayoutComponent,
    LoginComponent,
    SidebarComponent,
    AdminLayoutComponent,
    NavbarComponent,
    UsersComponent,
    AddUserComponent,
    EditUserComponent,
    AlertModalComponent,
    ClickOutsideDirective,
    FullModalComponent,
    CustomSizeModalComponent,
    ChildDirective,
    ConfirmModalComponent,
    StoresComponent,
    SummaryComponent,
    EditStoreComponent,
    OrdersComponent,
    Step1Component,
    Step2Component,
    SwitchLangMenuComponent,
    SearchBarComponent,
    TreeMenuComponent,
    SearchBarComponent,
    NumberDirective,
    DebounceClickDirective,
    OrderItemComponent,
    AppSrcDirective,
    TextEllipsisPipe,
    DuplicateItemComponent,
    TextExistPipe,
    ItemListComponent,
    DecimalProxyPipe,
    VersionTagComponent,
    PosComponent,
    MerchantVerificationComponent,
    MobileVerificationLayoutComponent,
    MerchantVerificationSuccessComponent,
    MerchantVerificationInvalidComponent,
    MerchantVerificationErrorComponent,
    HasPermissionDirective,
    StarRatingComponent,
    StoreProfileComponent,
    OrderPolicyComponent,
    OrderPolicyScheduleComponent,
    NumericTextboxComponent,
    ForgotUsernameComponent,
    ForgotUsernameLeftComponent,
    LoginLeftComponent,
    ForceChangePasswordLeftComponent,
    ForgotPasswordComponent,
    ForgotPasswordLeftComponent,
    SwitchLangToggleComponent,
    KeysPipe,
    OtpNumberDirective,
    OtpInputComponent,
    NumericKeypadComponent,
    DateFormatPipe,
    AlphabetOnlyDirective,
    PascalCaseDirective
  ],
  imports: [
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: ErrorMissingTranslationHandlerService,
        deps: [NGXLogger]
      }
    }),
    LoggerModule.forRoot({
      level: environment.production
        ? NgxLoggerLevel.OFF
        : environment.tier === 'dev'
        ? NgxLoggerLevel.TRACE
        : NgxLoggerLevel.INFO,
      serverLogLevel: NgxLoggerLevel.OFF
    }),
    StoreModule.forRoot(appReducers, { metaReducers: [clearState] }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([
      ForceChangePasswordEffect,
      AuthEffects,
      UserListEffects,
      RoleEffects,
      TdStoreEffects,
      TdStoreListEffects,
      TdStoresTreeViewEffects,
      UsersEffects,
      UserInfoEffects,
      CartsEffects,
      CatalogListEffects,
      CatalogListMenuEffects,
      VerificationEffects,
      UserSelectValueEffects,
      MenuEffects,
      ForgotUsernameEffects,
      ForgotPasswordEffects
    ]),
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    MomentModule,
    NgxIntlTelInputModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    TreeviewModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    TreeModule.forRoot(),
    InfiniteScrollModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    RatingModule,
    AccordionModule,
    FilterPipeModule,
    NgxMaskModule.forRoot()
  ],
  entryComponents: [AlertModalComponent, ConfirmModalComponent, FullModalComponent, CustomSizeModalComponent],
  providers: [
    AppInitService,
    AuthService,
    AuthGuardService,
    UsersService,
    UserListService,
    CookieService,
    CartsService,
    CatalogsService,
    PosService,
    VerificationService,
    EventService,
    UserSelectValueService,
    MerchantService,
    KeysPipe,
    DatePipe,
    { provide: APP_INITIALIZER, useFactory: InitializeAppFactory, deps: [AppInitService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerLoaderInterceptor, multi: true },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    MerchantVerificationResolver
  ],
  exports: [OtpInputComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
