import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { RolesService } from '../../services/roles.service';
import { RolesActionTypes, RolesFailureAction, RolesSuccessAction } from '../actions/roles.actions';
var RoleEffects = /** @class */ (function () {
    function RoleEffects(actions, rolesService, router, logger) {
        var _this = this;
        this.actions = actions;
        this.rolesService = rolesService;
        this.router = router;
        this.logger = logger;
        this.Role = this.actions.pipe(ofType(RolesActionTypes.ROLES), switchMap(function (action) {
            _this.logger.debug('@Effect RoleAction: ' + _this.stringify(action.payload));
            return _this.rolesService.getRoles(action.payload).pipe(switchMap(function (resp) {
                return of(new RolesSuccessAction(resp));
            }), catchError(function (err) {
                return of(new RolesFailureAction(err.error));
            }));
        }));
        this.RolesSuccess = this.actions.pipe(ofType(RolesActionTypes.ROLES_SUCCESS), tap(function (resp) {
            _this.logger.debug('@Effect RolesSuccessAction: ' + _this.stringify(resp.payload));
        }));
        this.RolesFailure = this.actions.pipe(ofType(RolesActionTypes.ROLES_FAILURE), tap(function (resp) {
            _this.logger.debug('@Effect RolesFailureAction: ' + _this.stringify(resp));
        }));
    }
    RoleEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], RoleEffects.prototype, "Role", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], RoleEffects.prototype, "RolesSuccess", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], RoleEffects.prototype, "RolesFailure", void 0);
    return RoleEffects;
}());
export { RoleEffects };
