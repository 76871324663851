import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { TreeviewConfig } from 'ngx-treeview';
import { of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { Tenant, UserRequest } from '../../../shared/models/user.model';
import { TdStoreGroupListGetAction } from '../../../shared/store/actions/td-store-group-list.action';
import { TdStoreListGetAction } from '../../../shared/store/actions/td-store-list.action';
import { TdStoresTreeViewRequestAction } from '../../../shared/store/actions/td-stores-tree-view.action';
import { ResetUserState, UsersAddAction } from '../../../shared/store/actions/users.action';
import { selectUserInfoResult } from '../../../shared/store/selectors/auth-user-info.selector';
import { selectTdStoreGroupListResult } from '../../../shared/store/selectors/td-store-group-list.selector';
import { selectTdStoreListResult } from '../../../shared/store/selectors/td-store-list.selector';
import { selectTdStoresTreeViewResult } from '../../../shared/store/selectors/td-stores-tree-view.selector';
import { selectTenantInfoResult } from '../../../shared/store/selectors/tenant-info.selector';
import { selectUsersResult } from '../../../shared/store/selectors/users.selector';
import { getRoles } from '../../../shared/utils/check-role';
var AddComponent = /** @class */ (function () {
    function AddComponent(store, formBuilder, modalService, translate, logger) {
        this.store = store;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.translate = translate;
        this.logger = logger;
        this.notifyParent = new EventEmitter();
        this.env = environment;
    }
    AddComponent.prototype.ngOnInit = function () {
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.merchantType = null;
        this.listOfValue = {};
        this.isStoreOwner = false;
        this.selectedAllStoreInMerchant = false;
        this.selectedStoreGroup = [];
        this.selectedStore = [];
        this.initControl();
        this.initState();
    };
    AddComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new ResetUserState());
    };
    AddComponent.prototype.initState = function () {
        var _this = this;
        this.localStore.pipe(select(selectUserInfoResult)).subscribe(function (userInfo) {
            _this.userInfo = userInfo;
            _this.isStoreOwner = userInfo.roles.includes('STORE_OWNER') || userInfo.roles.includes('STORE_OWNER_PARTNER');
            if (!_this.isStoreOwner) {
                _this.addUserForm.controls['stores'].setValidators(Validators.required);
            }
        });
        this.localStore.pipe(select(selectTenantInfoResult)).subscribe(function (tenantInfo) {
            _this.merchantNo = tenantInfo.merchant;
            _this.tdStoreAuthorityList = tenantInfo.stores;
        });
        // Retrieve TD-store list
        this.localStore
            .pipe(select(selectTdStoreListResult), tap(function (val) {
            if (val.result.response) {
                _this.merchantType = val.result.response.content[0].merchantType;
            }
        }), switchMap(function (resp) { return of(resp.result.response); }))
            .subscribe(function (val) {
            _this.store.dispatch(new TdStoresTreeViewRequestAction(val && val.content));
            _this.listOfValue['roles'] = getRoles(_this.merchantType, false);
        });
        this.store.dispatch(new TdStoreListGetAction({ merchantNo: this.merchantNo, storeNo: this.tdStoreAuthorityList.join(',') }));
        if (this.isStoreOwner) {
            this.store.dispatch(new TdStoreGroupListGetAction({ merchantNo: this.merchantNo }));
            this.tdStoreGroupList$ = this.localStore.pipe(select(selectTdStoreGroupListResult), switchMap(function (resp) { return of(resp); }));
        }
        else {
            this.tdStoreList$ = this.localStore.pipe(select(selectTdStoresTreeViewResult), switchMap(function (resp) { return of(resp); }));
        }
        this.localStore
            .pipe(select(selectUsersResult), map(function (action) { return action.result; }))
            .subscribe(function (next) {
            if (next.response) {
                _this.onSuccess(next);
            }
            else if (next.errorResponse) {
                _this.onError(next.errorResponse);
            }
            else {
                _this.logger.debug(next);
            }
        }, function (error) { return _this.onError(error); }, function () { return _this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null }); });
    };
    AddComponent.prototype.initControl = function () {
        this.addUserForm = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.maxLength(100)]],
            lastName: ['', [Validators.required, Validators.maxLength(100)]],
            localFirstName: ['', [Validators.required, Validators.maxLength(100)]],
            localLastName: ['', [Validators.required, Validators.maxLength(100)]],
            mobile: ['', [Validators.required, Validators.maxLength(100)]],
            email: ['', [Validators.email, Validators.maxLength(100)]],
            gender: ['', []],
            active: ['true', []],
            birthDay: ['', []],
            roles: [null, [Validators.required]],
            stores: [null, []]
        });
        // stores: [null, [Validators.required]]
        this.tmpElementValue = new Array();
        this.preventBlock(this.addUserForm.controls, 'firstName', /^[a-z A-Z]*$/);
        this.preventBlock(this.addUserForm.controls, 'lastName', /^[a-z A-Z]*$/);
        this.tdStoresConfig = new TreeviewConfig();
        this.tdStoresConfig.hasAllCheckBox = false;
        this.addUserForm.controls['active'].setValue('true');
        this.addUserForm.controls['active'].disable();
        this.bsConfig = {
            dateInputFormat: this.env.dateFormat,
            maxDate: new Date(),
            showWeekNumbers: false
        };
    };
    AddComponent.prototype.setStores = function (result) {
        this.addUserForm.controls['stores'].setValue(result);
    };
    AddComponent.prototype.onSubmit = function () {
        var req;
        this.submitted = true;
        if (this.addUserForm.invalid || this.validateStore) {
            if (!this.addUserForm.invalid && this.validateStore) {
                this.showAlert(this.translate.instant('FAILED'), this.translate.instant('PLEASE_SELECT_AT_LEAST_ONE_STORE'));
            }
            return;
        }
        req = new UserRequest(this.addUserForm.value);
        req.tenant = new Tenant();
        req.tenant.merchant = this.merchantNo;
        req.accessAllStores = this.selectedAllStoreInMerchant;
        req.accessStoreGroups = this.selectedStoreGroup;
        req.accessStores = this.isStoreOwner ? this.selectedStore : this.addUserForm.value.stores;
        req.countryCode = this.addUserForm.value.mobile.internationalNumber.split(' ')[0];
        req.mobileNo = this.addUserForm.value.mobile.nationalNumber.replace(/ /g, '');
        if (this.addUserForm.value.birthDay && this.addUserForm.value.birthDay !== '') {
            req.birthDate = moment(this.addUserForm.value.birthDay, environment.dateFormat).format(environment.dateFormat);
        }
        this.store.dispatch(new UsersAddAction(req));
    };
    AddComponent.prototype.showAlert = function (title, message) {
        var initialState = {
            title: title,
            message: message
        };
        this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
    };
    Object.defineProperty(AddComponent.prototype, "validateStore", {
        get: function () {
            var checkSelectedStore = this.selectedAllStoreInMerchant || this.selectedStoreGroup.length > 0 || this.selectedStore.length > 0;
            return this.isStoreOwner && !checkSelectedStore;
        },
        enumerable: true,
        configurable: true
    });
    AddComponent.prototype.onChangeAll = function (event, storeGroupList) {
        var _this = this;
        if (event.target.checked) {
            this.selectedAllStoreInMerchant = true;
            this.selectedStoreGroup = [];
            this.selectedStore = [];
            storeGroupList.forEach(function (storeGroup) { return _this.clearSelectedStoreAll(storeGroup); });
        }
        else {
            this.selectedAllStoreInMerchant = false;
            storeGroupList.forEach(function (storeGroup) { return _this.selectedEnableStoreAll(storeGroup); });
        }
    };
    AddComponent.prototype.onCheckboxChangeGroup = function (event, storeGroup) {
        if (event.target.checked) {
            this.selectStoreGroup(storeGroup);
        }
        else {
            this.deSelectStoreGroup(storeGroup);
        }
    };
    AddComponent.prototype.onCheckboxChange = function (event, store) {
        if (event.target.checked) {
            store.selected = true;
            this.selectedStore.push(store.no);
        }
        else {
            store.selected = false;
            this.removeSelectedStore(store.no);
        }
    };
    AddComponent.prototype.selectStoreGroup = function (storeGroupUser) {
        var _this = this;
        storeGroupUser.selected = true;
        this.selectedStoreGroup.push(storeGroupUser.no);
        storeGroupUser.stores.forEach(function (store) {
            store.selected = false;
            store.disabled = true;
            _this.removeSelectedStore(store.no);
        });
    };
    AddComponent.prototype.deSelectStoreGroup = function (storeGroupUser) {
        storeGroupUser.selected = false;
        this.removeSelectedStoreGroup(storeGroupUser.no);
        storeGroupUser.stores.forEach(function (store) {
            store.disabled = false;
        });
    };
    AddComponent.prototype.clearSelectedStoreAll = function (storeGroupUser) {
        storeGroupUser.selected = false;
        storeGroupUser.disabled = true;
        storeGroupUser.stores.forEach(function (store) {
            store.selected = false;
            store.disabled = true;
        });
    };
    AddComponent.prototype.selectedEnableStoreAll = function (storeGroupUser) {
        storeGroupUser.disabled = false;
        storeGroupUser.stores.forEach(function (store) {
            store.disabled = false;
        });
    };
    AddComponent.prototype.removeSelectedStoreGroup = function (code) {
        this.selectedStoreGroup.splice(this.selectedStoreGroup.findIndex(function (itemSelected) { return itemSelected === code; }), 1);
    };
    AddComponent.prototype.removeSelectedStore = function (code) {
        this.selectedStore.splice(this.selectedStore.findIndex(function (itemSelected) { return itemSelected === code; }), 1);
    };
    AddComponent.prototype.onCancel = function () {
        if (this.addUserForm.touched) {
            var initialState = {
                title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
                okText: this.translate.instant('STAY_ON_PAGE'),
                cancelText: this.translate.instant('LEAVE'),
                message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
            };
            this.notifyParent.emit({
                initialState: initialState,
                notificationType: NotificationTypeEnum.CONFIRM,
                result: null
            });
        }
        else {
            this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null });
        }
    };
    Object.defineProperty(AddComponent.prototype, "f", {
        get: function () {
            return this.addUserForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddComponent.prototype.preventBlock = function (controls, key, regexp) {
        var _this = this;
        if (regexp === void 0) { regexp = /^.*$/; }
        if (!this.tmpElementValue[key]) {
            this.tmpElementValue[key] = '';
        }
        controls[key].valueChanges.pipe(filter(function (val) { return val !== _this.tmpElementValue[key]; })).forEach(function (val) {
            if (regexp.test(val)) {
                _this.tmpElementValue[key] = val;
            }
            controls[key].setValue(_this.tmpElementValue[key]);
        });
    };
    AddComponent.prototype.onSuccess = function (resp) {
        var _this = this;
        var initialState = {
            title: this.translate.instant('SUCCESS'),
            message: this.translate.instant('USER_CREATED'),
            routerLink: 'users'
        };
        this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(function () {
            _this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: resp });
        });
        this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
    };
    AddComponent.prototype.onError = function (resp) {
        var _this = this;
        if (resp.translateKey) {
            var initialState = {
                title: this.translate.instant('FAILED'),
                message: this.translate.instant(resp.translateKey)
            };
            this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(function () {
                _this.notifyParent.emit({ notificationType: NotificationTypeEnum.LOGGING, result: resp });
            });
            this.modalService.show(AlertModalComponent, { initialState: initialState });
        }
    };
    return AddComponent;
}());
export { AddComponent };
