import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: 'input[appNumbersOnly]' })
export class NumberDirective {
  @Input() allowZeroAtStart = false;

  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const initalValue: string = this._el.nativeElement.value;
    const maxValue: number = this._el.nativeElement.max || null;
    const minValue: number = this._el.nativeElement.min || null;

    const regex = this.allowZeroAtStart ? /[^0-9]*/g : /(^0|[^0-9])/g;

    this._el.nativeElement.value = initalValue.replace(regex, '');
    this._el.nativeElement.dispatchEvent(new Event('input'));

    if (initalValue !== this._el.nativeElement.value) {
      event.preventDefault();
    }
    if (minValue && minValue > +this._el.nativeElement.value) {
      event.preventDefault();
    }
    if (maxValue && +initalValue > maxValue) {
      event.preventDefault();
    }
  }
}
