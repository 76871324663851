import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCatalogListState from '../reducers/catalog-list.reducers';

export const selectCatalogListState = createFeatureSelector<fromCatalogListState.CatalogState>('catalogList');

export const selectCatalogListResult = createSelector(
  selectCatalogListState,
  fromCatalogListState.selectAll
);

export const selectCatalogListTotalResult = createSelector(
  selectCatalogListState,
  currentState => currentState.totalElement
);

export const selectCatalogListLoadedElementResult = createSelector(
  selectCatalogListState,
  fromCatalogListState.selectTotal
);
