import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { Role } from '../models/role.model';
import * as i0 from "@angular/core";
import * as i1 from "ngx-logger";
var RolesService = /** @class */ (function () {
    function RolesService(logger) {
        this.logger = logger;
    }
    RolesService.prototype.getRoles = function (roleId) {
        var roleList = 'CASHIER, STORE_MANAGER, STORE_OWNER'; // 'CASHIER, STORE_MANAGER, STORE_OWNER, ADMIN, IMPLEMENTOR, SALE_MANAGER, PRODUCT_MANAGER, MARKETING_MANAGER';
        var roles = this.generateRoleList(roleList);
        try {
            roles = roleId ? roles.slice(0, +roleId) : roles; // select one or all roleList.
            return of(roles);
        }
        catch (e) {
            this.logger.error(e);
            return of(roles);
        }
    };
    RolesService.prototype.generateRoleList = function (roleList) {
        return roleList.split(', ').map(function (item, index) {
            return new Role({
                id: index.toString(),
                name: item,
                code: item
            });
        });
    };
    RolesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RolesService_Factory() { return new RolesService(i0.ɵɵinject(i1.NGXLogger)); }, token: RolesService, providedIn: "root" });
    return RolesService;
}());
export { RolesService };
