import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as JwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { ClientIdTypeEnum } from '../../enum/client-id.enum';
import { AuthResponse, AuthUser } from '../../models/auth.model';
import { ErrorResponse } from '../../models/error.model';
import { TenantInformation } from '../../models/tenant-information.model';
import { UserResponse } from '../../models/user.model';
import { AuthService, UsersService } from '../../services';
import {
  AuthActionTypes,
  LogInAction,
  LogInFailureAction,
  LogInSuccessAction,
  LogOutAction,
  RefreshAction
} from '../actions/auth.actions';
import { MenuRequestedAction } from '../actions/menu.action';
import {
  ClientIdAction,
  TenantInformationAction,
  UserInformationAction,
  UserInformationResetAction
} from '../actions/user-info.action';
import { AppStates } from '../state/app.states';

@Injectable()
export class AuthEffects {
  constructor(
    private actions: Actions,
    private authService: AuthService,
    private readonly router: Router,
    private cookieService: CookieService,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppStates>,
    private readonly usersService: UsersService
  ) {}

  @Effect()
  LogIn: Observable<Action> = this.actions.pipe(
    ofType<LogInAction>(AuthActionTypes.LOGIN),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect LogInAction: ' + this.stringify(payload));
      return this.authService.logIn(payload).pipe(
        switchMap((resp: AuthResponse) => {
          return of(new UserInformationResetAction(), new LogInSuccessAction(resp));
        }),
        catchError(err => {
          return of(new LogInFailureAction(err.error));
        })
      );
    })
  );

  @Effect()
  Refresh: Observable<Action> = this.actions.pipe(
    ofType<RefreshAction>(AuthActionTypes.REFRESH),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect RefreshAction: ' + this.stringify(payload));
      return this.authService.refreshToken(payload).pipe(
        switchMap((resp: AuthResponse) => {
          this.logger.debug('Refresh: LogInSuccessAction' + this.stringify(payload));
          return of(new UserInformationResetAction(), new LogInSuccessAction(resp));
        }),
        catchError(err => {
          return of(new LogInFailureAction(err.error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  LogInSuccess: Observable<Action> = this.actions.pipe(
    ofType<LogInSuccessAction>(AuthActionTypes.LOGIN_SUCCESS),
    map(action => action.payload),
    tap((payload: any) => {
      this.logger.debug('@Effect LogInSuccessAction: ' + this.stringify(payload));

      const userInfo: AuthUser = JwtDecode(payload.access_token);

      const mSec = payload.expires_in * 1000;
      const currentDate = new Date();
      const expiryDate = new Date(currentDate.getTime() + mSec + env.cookies.bufferTimePeriod);
      const refreshTokenExpiryDate = new Date(
        moment(expiryDate)
          .add(1, 'year')
          .toDate()
      );

      const cookieOptions = {
        expires: expiryDate,
        path: env.cookies.path,
        domain: env.cookies.domain,
        secure: window.location.protocol === 'https:'
      };

      this.cookieService.set(
        env.cookies.accessToken,
        payload.access_token,
        expiryDate,
        cookieOptions.path,
        cookieOptions.domain,
        cookieOptions.secure
      );
      this.cookieService.set(
        env.cookies.refreshToken,
        payload.refresh_token,
        refreshTokenExpiryDate,
        cookieOptions.path,
        cookieOptions.domain,
        cookieOptions.secure
      );
      this.dispatchInfo(userInfo);

      if (userInfo.user_info.forceChangePassword) {
        this.router.navigate(['/force-change-password'], {
          replaceUrl: true
        });
      } else if (this.router.url === '/' || this.router.url === '/login') {
        this.router.navigateByUrl('/home');
      }
    })
  );

  @Effect({ dispatch: false })
  LogInFailure: Observable<Action | ErrorResponse> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE),
    tap((resp: LogInFailureAction) => {
      this.logger.debug('@Effect LogInFailureAction: ' + this.stringify(resp));
      this.clearAuth();

      this.router.navigateByUrl('/');
    })
  );

  @Effect({ dispatch: false })
  LogOut: Observable<Action> = this.actions.pipe(
    ofType<LogOutAction>(AuthActionTypes.LOGOUT),
    tap(user => {
      this.logger.debug('@Effect LogOutAction: ' + this.stringify(user));
      this.clearAuth();

      this.router.navigateByUrl('/');
    })
  );

  private dispatchInfo(userInfo: AuthUser) {
    userInfo.user_info.authorities = userInfo.authorities;

    this.store.dispatch(new UserInformationAction(userInfo.user_info));
    this.getUserTenant(userInfo.user_info.userNo);
    this.store.dispatch(new ClientIdAction(userInfo.client_id));
    this.store.dispatch(new MenuRequestedAction(userInfo.authorities));
  }

  private clearAuth() {
    this.cookieService.delete(env.cookies.accessToken);
    this.cookieService.delete(env.cookies.refreshToken);

    this.store.dispatch(new ClientIdAction(env.defaultClientId as ClientIdTypeEnum));
    this.store.dispatch(new UserInformationResetAction());
  }

  private getUserTenant(userNo: string) {
    if (!userNo) {
      return;
    }
    this.usersService
      .getUser(userNo)
      .pipe(filter(value => Boolean(value)))
      .subscribe((user: UserResponse) => {
        const tenantInfo: TenantInformation = {
          merchant: user.tenant.merchant,
          sites: user.tenant.sites,
          groups: user.tenant.storeGroups,
          stores: user.tenant.stores
        };
        this.store.dispatch(new TenantInformationAction(tenantInfo));
      });
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
