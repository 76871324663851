import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { filter, map, take } from 'rxjs/operators';
import { TDPageModes } from '../../../enum/store.enum';
import { selectTdStoreResult } from '../../../store/selectors/td-store.selector';
import { selectUserSelectValueGroupByType } from '../../../store/selectors/user-select-value.selectors';
var StoreProfileComponent = /** @class */ (function () {
    function StoreProfileComponent(fb, store) {
        this.fb = fb;
        this.store = store;
    }
    StoreProfileComponent.prototype.ngOnDestroy = function () { };
    StoreProfileComponent.prototype.ngOnInit = function () {
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.tdStore$ = this.localStore.pipe(select(selectTdStoreResult));
        this.userSelectValue$ = this.localStore.pipe(select(selectUserSelectValueGroupByType));
        this.parentForm.addControl('storeProfile', this.createStoreProfile());
        this.setStoreProfileValue();
        if (this.mode === TDPageModes.REQUEST_VIEW) {
            this.storeProfile.disable();
        }
    };
    StoreProfileComponent.prototype.setStoreProfileValue = function () {
        var _this = this;
        this.tdStore$
            .pipe(map(function (response) { return response && response.result && response.result.response; }), filter(function (response) { return response !== null; }), take(1))
            .subscribe(function (value) {
            if (value) {
                var storeProfilePatched = _this.createStoreProfileValue(value.storeProfile);
                _this.storeProfile.patchValue(storeProfilePatched);
            }
        });
    };
    StoreProfileComponent.prototype.createStoreProfileValue = function (store) {
        return tslib_1.__assign({}, store, { phone: (store.countryCode || null) + (store.phone || null) });
    };
    StoreProfileComponent.prototype.createStoreProfile = function () {
        var _this = this;
        var regExpBranchNo = /^\d{5}$/;
        var initialNull = [{ value: null, disabled: false }];
        var initialNullRequired = [{ value: null, disabled: false }, Validators.required];
        var timezone = 'T0100';
        var storeForm = this.fb.group({
            contactName: initialNullRequired,
            email: [{ value: null, disabled: false }, [Validators.maxLength(100), Validators.email]],
            address: initialNullRequired,
            postCode: initialNullRequired,
            country: initialNullRequired,
            taxNo: initialNull,
            branchNo: [{ value: null, disabled: false }, [Validators.pattern(regExpBranchNo)]],
            state: initialNullRequired,
            region: initialNullRequired,
            timezone: [{ value: timezone, disabled: false }, Validators.required],
            phone: initialNullRequired
        });
        this.userSelectValue$.subscribe(function (value) {
            if (value && value.country && !_this.storeProfile.controls.country.value) {
                var defaultCountryCode = value.country.find(function (data) { return data.name === 'Thailand'; }).code;
                storeForm.controls.country.setValue(defaultCountryCode);
            }
        });
        return storeForm;
    };
    Object.defineProperty(StoreProfileComponent.prototype, "storeProfile", {
        get: function () {
            return this.parentForm.get('storeProfile');
        },
        enumerable: true,
        configurable: true
    });
    StoreProfileComponent.prototype.isPhoneError = function (control) {
        return this.submitted && control.errors ? 'is-invalid' : '';
    };
    StoreProfileComponent.prototype.toggleEditStoreProfile = function () {
        this.storeProfile.enable();
    };
    return StoreProfileComponent;
}());
export { StoreProfileComponent };
