import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangePasswordRequest } from '../models/force-change-password.model';
import { ForgotUsername } from '../models/forgot-username.model';
import { ResetPasswordPinRequest, UserDeleteRequest, UserRequest } from '../models/user.model';
import { BaseService } from './base.service';

@Injectable()
export class UsersService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.envService = this.env.services.users;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public addUser<T>(req: UserRequest): Observable<T> {
    return this.http.post<T>(this.getUrl(), req, { headers: this.loaderHeaders() });
  }

  public editUser<T>(req: UserRequest): Observable<T> {
    return this.http.put<T>(this.getUrl(), req, { headers: this.loaderHeaders() });
  }

  public deleteUser<T>(req: UserDeleteRequest): Observable<HttpResponse<T>> {
    const url = this.getFullUrl(this.envService.deleteUserProfile, {
      userNo: req.userNo
    });

    return this.http.delete<T>(url, {
      headers: this.loaderHeaders(),
      observe: 'response'
    });
  }

  public resetPassword<T>(req: ResetPasswordPinRequest): Observable<HttpResponse<T>> {
    const url = this.getFullUrl(this.envService.resetPassword, {
      userName: req.userName
    });

    return this.http.post<T>(url, null, { headers: this.loaderHeaders(), observe: 'response' });
  }

  public resetPinCode<T>(req: ResetPasswordPinRequest): Observable<HttpResponse<T>> {
    const url = this.getFullUrl(this.envService.resetPinCode, {
      userName: req.userName
    });

    return this.http.post<T>(url, null, { headers: this.loaderHeaders(), observe: 'response' });
  }

  public forgotUsername<T>(req: ForgotUsername, accessToken: string): Observable<HttpResponse<T>> {
    const url = this.getFullUrl(this.envService.forgetUserName, {
      mobileNo: req.mobileNumber
    });
    const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);

    return this.http.post<T>(url, null, { headers: headers, observe: 'response' });
  }

  public forgotPasswordCreateOTP<T>(userName: string, accessToken: string): Observable<HttpResponse<T>> {
    const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
    const url = this.getFullUrl(this.envService.createOTP, {
      userName: userName
    });

    return this.http.post<T>(url, null, { headers: headers, observe: 'response' });
  }

  public forgotPasswordVerifyOTP<T>(userName: string, otp: string, accessToken: string): Observable<HttpResponse<T>> {
    const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
    const url = this.getFullUrl(this.envService.verifyOTP, {
      userName: userName
    });
    const data = {
      otp: otp
    };
    return this.http.post<T>(url, data, { headers: headers, observe: 'response' });
  }

  public forgotPassword<T>(
    userName: string,
    newPassword: string,
    otp: string,
    accessToken: string
  ): Observable<HttpResponse<T>> {
    const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
    const url = this.getFullUrl(this.envService.forgotPassword, {
      userName: userName
    });
    const data = {
      newPassword: newPassword,
      otp: otp
    };
    return this.http.post<T>(url, data, { headers: headers, observe: 'response' });
  }

  public getUser<T>(userNo: string): Observable<T> {
    console.log(userNo);
    const url = this.getFullUrl(this.envService.getUserProfile, {
      userNo: userNo
    });

    return this.http.get<T>(url, { headers: this.headers });
  }

  public changePassword<T>(request: ChangePasswordRequest): Observable<HttpResponse<T>> {
    const url = this.getFullUrl(this.envService.forceChangePassword, {
      userName: request.userName
    });

    return this.http.post<T>(
      url,
      { newPassword: request.newPassword },
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }
}
