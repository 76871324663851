<div class="page-wrapper" [ngClass]="{ toggled: getSideBarState() }">
  <app-sidebar *ngIf="isNotPOS"></app-sidebar>
  <app-navbar *ngIf="isNotPOS"></app-navbar>
  <div [ngClass]="{ 'page-content': isNotPOS }">
    <div class="container-fluid">
      <router-outlet></router-outlet>
      <!--<div class="overlay" (click)="toggleSidebar()" [ngClass]="{'show' : !getSideBarState()}"></div>-->
    </div>
  </div>
</div>
