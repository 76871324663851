import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as JwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { AuthService, UsersService } from '../../services';
import { AuthActionTypes, LogInFailureAction, LogInSuccessAction } from '../actions/auth.actions';
import { MenuRequestedAction } from '../actions/menu.action';
import { ClientIdAction, TenantInformationAction, UserInformationAction, UserInformationResetAction } from '../actions/user-info.action';
var AuthEffects = /** @class */ (function () {
    function AuthEffects(actions, authService, router, cookieService, logger, store, usersService) {
        var _this = this;
        this.actions = actions;
        this.authService = authService;
        this.router = router;
        this.cookieService = cookieService;
        this.logger = logger;
        this.store = store;
        this.usersService = usersService;
        this.LogIn = this.actions.pipe(ofType(AuthActionTypes.LOGIN), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect LogInAction: ' + _this.stringify(payload));
            return _this.authService.logIn(payload).pipe(switchMap(function (resp) {
                return of(new UserInformationResetAction(), new LogInSuccessAction(resp));
            }), catchError(function (err) {
                return of(new LogInFailureAction(err.error));
            }));
        }));
        this.Refresh = this.actions.pipe(ofType(AuthActionTypes.REFRESH), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect RefreshAction: ' + _this.stringify(payload));
            return _this.authService.refreshToken(payload).pipe(switchMap(function (resp) {
                _this.logger.debug('Refresh: LogInSuccessAction' + _this.stringify(payload));
                return of(new UserInformationResetAction(), new LogInSuccessAction(resp));
            }), catchError(function (err) {
                return of(new LogInFailureAction(err.error));
            }));
        }));
        this.LogInSuccess = this.actions.pipe(ofType(AuthActionTypes.LOGIN_SUCCESS), map(function (action) { return action.payload; }), tap(function (payload) {
            _this.logger.debug('@Effect LogInSuccessAction: ' + _this.stringify(payload));
            var userInfo = JwtDecode(payload.access_token);
            var mSec = payload.expires_in * 1000;
            var currentDate = new Date();
            var expiryDate = new Date(currentDate.getTime() + mSec + env.cookies.bufferTimePeriod);
            var refreshTokenExpiryDate = new Date(moment(expiryDate)
                .add(1, 'year')
                .toDate());
            var cookieOptions = {
                expires: expiryDate,
                path: env.cookies.path,
                domain: env.cookies.domain,
                secure: window.location.protocol === 'https:'
            };
            _this.cookieService.set(env.cookies.accessToken, payload.access_token, expiryDate, cookieOptions.path, cookieOptions.domain, cookieOptions.secure);
            _this.cookieService.set(env.cookies.refreshToken, payload.refresh_token, refreshTokenExpiryDate, cookieOptions.path, cookieOptions.domain, cookieOptions.secure);
            _this.dispatchInfo(userInfo);
            if (userInfo.user_info.forceChangePassword) {
                _this.router.navigate(['/force-change-password'], {
                    replaceUrl: true
                });
            }
            else if (_this.router.url === '/' || _this.router.url === '/login') {
                _this.router.navigateByUrl('/home');
            }
        }));
        this.LogInFailure = this.actions.pipe(ofType(AuthActionTypes.LOGIN_FAILURE), tap(function (resp) {
            _this.logger.debug('@Effect LogInFailureAction: ' + _this.stringify(resp));
            _this.clearAuth();
            _this.router.navigateByUrl('/');
        }));
        this.LogOut = this.actions.pipe(ofType(AuthActionTypes.LOGOUT), tap(function (user) {
            _this.logger.debug('@Effect LogOutAction: ' + _this.stringify(user));
            _this.clearAuth();
            _this.router.navigateByUrl('/');
        }));
    }
    AuthEffects.prototype.dispatchInfo = function (userInfo) {
        userInfo.user_info.authorities = userInfo.authorities;
        this.store.dispatch(new UserInformationAction(userInfo.user_info));
        this.getUserTenant(userInfo.user_info.userNo);
        this.store.dispatch(new ClientIdAction(userInfo.client_id));
        this.store.dispatch(new MenuRequestedAction(userInfo.authorities));
    };
    AuthEffects.prototype.clearAuth = function () {
        this.cookieService.delete(env.cookies.accessToken);
        this.cookieService.delete(env.cookies.refreshToken);
        this.store.dispatch(new ClientIdAction(env.defaultClientId));
        this.store.dispatch(new UserInformationResetAction());
    };
    AuthEffects.prototype.getUserTenant = function (userNo) {
        var _this = this;
        if (!userNo) {
            return;
        }
        this.usersService
            .getUser(userNo)
            .pipe(filter(function (value) { return Boolean(value); }))
            .subscribe(function (user) {
            var tenantInfo = {
                merchant: user.tenant.merchant,
                sites: user.tenant.sites,
                groups: user.tenant.storeGroups,
                stores: user.tenant.stores
            };
            _this.store.dispatch(new TenantInformationAction(tenantInfo));
        });
    };
    AuthEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AuthEffects.prototype, "LogIn", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], AuthEffects.prototype, "Refresh", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], AuthEffects.prototype, "LogInSuccess", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], AuthEffects.prototype, "LogInFailure", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], AuthEffects.prototype, "LogOut", void 0);
    return AuthEffects;
}());
export { AuthEffects };
