import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import Stepper from 'bs-stepper';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { FilterPipe } from 'ngx-filter-pipe';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs/internal/observable/of';
import { filter, map, tap } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../shared/enum/notification-type.enum';
import { AlertModalComponent } from '../../shared/layouts';
import { ConfirmModalComponent } from '../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { DecimalProxyPipe } from '../../shared/pipe/decimal-proxy/decimal-proxy.pipe';
import { PosService } from '../../shared/services/pos.service';
import { GetCartAction, SubmitOrderRequestAction, SubmitOrderResetStatusAction } from '../../shared/store/actions/carts.action';
import { TdStoreGetAction } from '../../shared/store/actions/td-store.action';
import { selectAddItemResult, selectCartItemResult } from '../../shared/store/selectors/cart.selector';
import { selectClientIdResult } from '../../shared/store/selectors/client-id.selector';
import { selectOrderSummaryResult } from '../../shared/store/selectors/order-summary.selector';
import { selectSubmitOrderResult } from '../../shared/store/selectors/submit-order.selector';
import { selectTdStoreResult } from '../../shared/store/selectors/td-store.selector';
import { CheckDisableStatus } from '../../shared/utils/check-disable-status';
import { Step2DataOut } from './step2/step2.component';
var OrdersComponent = /** @class */ (function () {
    function OrdersComponent(translate, store, modalService, filterPipe, logger, pos) {
        this.translate = translate;
        this.store = store;
        this.modalService = modalService;
        this.filterPipe = filterPipe;
        this.logger = logger;
        this.pos = pos;
        this.notifyParent = new EventEmitter();
        this.weekdaysShort = moment
            .localeData('en')
            .weekdaysShort()
            .map(function (value) { return value.toUpperCase(); });
    }
    OrdersComponent.prototype.ngOnInit = function () {
        this.stepper = new Stepper(this.orderStepper.nativeElement, {
            linear: true,
            animation: true
        });
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.initState();
        this.initControl();
        this.pos.goto('step1');
        this.step2Data = new Step2DataOut();
    };
    OrdersComponent.prototype.ngOnDestroy = function () {
        this.pos.goto('summary');
    };
    OrdersComponent.prototype.initState = function () {
        var _this = this;
        this.localStore.pipe(select(selectClientIdResult)).subscribe(function (clientId) { return (_this.clientId = clientId); });
        this.localStore
            .pipe(select(selectOrderSummaryResult), map(function (data) { return data.result.response; }), filter(Boolean))
            .subscribe(function (response) {
            _this.nextStepEl.nativeElement.disabled = response.numberOfItems === 0;
            _this.orderAmount = response.amount;
        });
        this.localStore
            .pipe(select(selectTdStoreResult), map(function (value) { return value.result.response; }), filter(Boolean))
            .subscribe(function (tdStore) {
            _this.deliverySchedule = of(_this.daySchedule(tdStore.orderSchedule.schedules.map(function (schedule) { return schedule.deliverySchedule; })));
            _this.orderSchedule = of(_this.daySchedule(tdStore.orderSchedule.schedules.map(function (schedule) { return schedule.orderScheduleDate; })));
            _this.storeName = of(tdStore.name);
            _this.minOrder = { amount: tdStore.orderSchedule.minOrder, currency: 'THB' };
            _this.tdStore = tdStore;
        });
        this.localStore.pipe(select(selectAddItemResult)).subscribe(function (cart) {
            if (cart.response) {
                _this.cartId = cart.response.id;
                _this.itemList = cart.response.items;
            }
        });
        this.localStore.pipe(select(selectSubmitOrderResult)).subscribe(function (result) {
            if (result.submitOrderSuccess === false && result.result.errorResponse) {
                _this.alertUnsuccessfulSubmitOrder();
            }
            else if (result.submitOrderSuccess && result.result.response) {
                _this.pos.orderComplete(result.result.response.poNumber);
                _this.alertSuccessfulSubmitOrder();
            }
        });
        this.localStore
            .pipe(select(selectCartItemResult), map(function (cartResult) { return cartResult.response; }), filter(function (response) { return !response || !response.items.length; }))
            .subscribe(function () {
            _this.stepper.to(1);
        });
    };
    OrdersComponent.prototype.initControl = function () {
        this.nextStepEl.nativeElement.disabled = true;
    };
    OrdersComponent.prototype.daySchedule = function (inputDays) {
        var _this = this;
        return this.isEveryDay(inputDays)
            ? this.translateSchedule('EVERY_DAY')
            : inputDays.map(function (day) { return _this.translateSchedule(day); }).join(', ');
    };
    OrdersComponent.prototype.isEveryDay = function (inputDays) {
        var amountDay = this.weekdaysShort.filter(function (day) { return inputDays.indexOf(day) >= 0; });
        return amountDay.length === 7;
    };
    OrdersComponent.prototype.onNext = function () {
        this.stepper.next();
        this.onPosStepEvent();
    };
    OrdersComponent.prototype.onBack = function () {
        this.stepper.previous();
        this.onPosStepEvent();
    };
    OrdersComponent.prototype.onPosStepEvent = function () {
        for (var i = 0; i < this.orderStepper.nativeElement.getElementsByClassName('step').length; i++) {
            if (this.orderStepper.nativeElement.getElementsByClassName('step')[i].className.match('active')) {
                this.pos.goto("step" + (i + 1));
                break;
            }
        }
    };
    OrdersComponent.prototype.translateSchedule = function (day) {
        this.translate.get("SCHEDULE." + day.toLocaleUpperCase()).subscribe(function (translated) {
            day = translated;
        });
        return day;
    };
    OrdersComponent.prototype.onCancel = function () {
        var initialState = {
            title: this.translate.instant('CONFIRM'),
            okText: this.translate.instant('LEAVE'),
            cancelText: this.translate.instant('CANCEL'),
            message: this.translate.instant('CONFIRM_LEAVE_ORDER_PROCESS'),
            closeMode: ModalButtonResponseEnum.OK
        };
        this.notifyParent.emit({
            initialState: initialState,
            notificationType: NotificationTypeEnum.CONFIRM,
            result: null
        });
    };
    OrdersComponent.prototype.onStep2DataOut = function (step2DataOut) {
        this.step2Data = step2DataOut;
    };
    OrdersComponent.prototype.submitOrder = function () {
        var isInvalidItem = this.filterPipe.transform(this.itemList, {
            productStatus: { $or: this.checkDisableStatus.invalidItemStatus() }
        });
        if (isInvalidItem.length) {
            this.alertSelectInvalidItem();
        }
        else if (this.minOrder.amount > this.orderAmount.amount) {
            this.alertSelectMoreOrder(this.minOrder.amount, this.minOrder.currency);
        }
        else {
            this.confirmPayment(this.orderAmount.amount, this.orderAmount.currency);
        }
    };
    OrdersComponent.prototype.alertSelectInvalidItem = function () {
        var _this = this;
        var initialState = {
            title: this.translate.instant('ALERT'),
            message: this.translate.instant('PLEASE_DELETE_INVALID_ITEM')
        };
        var alertModalRef = this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
        alertModalRef.content.action
            .pipe(untilComponentDestroyed(this), filter(function (result) { return result === ModalButtonResponseEnum.OK; }), tap(function () { return _this.resetOrderStatus(); }))
            .subscribe();
    };
    OrdersComponent.prototype.alertSelectMoreOrder = function (minimumOrder, currency) {
        var _this = this;
        var initialState = {
            title: this.translate.instant('ALERT'),
            message: this.translate.instant('MINIMUM_ORDER_AMOUNT_IS_PLEASE_ADD_MORE_ITEM', {
                minimumOrder: new DecimalProxyPipe(this.translate).transform(minimumOrder, '1.2-2'),
                currency: this.translate.instant(currency)
            })
        };
        var alertModalRef = this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
        alertModalRef.content.action
            .pipe(untilComponentDestroyed(this), filter(function (result) { return result === ModalButtonResponseEnum.OK; }), tap(function () { return _this.resetOrderStatus(); }))
            .subscribe();
    };
    OrdersComponent.prototype.resetOrderStatus = function () {
        this.store.dispatch(new SubmitOrderResetStatusAction());
        this.store.dispatch(new TdStoreGetAction(this.tdStore.storeProfile.storeNo));
        this.store.dispatch(new GetCartAction({ cartId: this.cartId }));
    };
    OrdersComponent.prototype.alertUnsuccessfulSubmitOrder = function () {
        var _this = this;
        var initialState = {
            title: this.translate.instant('FAILED'),
            message: null
        };
        initialState.message = this.translate.instant('YOUR_PAYMENT_WAS_NOT_SUCCESSFULLY');
        var alertModalRef = this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
        alertModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(function (result) {
            if (result === ModalButtonResponseEnum.OK) {
                _this.store.dispatch(new SubmitOrderResetStatusAction());
                _this.store.dispatch(new TdStoreGetAction(_this.tdStore.storeProfile.storeNo));
                _this.store.dispatch(new GetCartAction({ cartId: _this.cartId }));
            }
        });
    };
    OrdersComponent.prototype.alertSuccessfulSubmitOrder = function () {
        var _this = this;
        var initialState = {
            title: this.translate.instant('SUCCESSFUL'),
            message: this.translate.instant('YOUR_PAYMENT_WAS_SUCCESSFULLY')
        };
        var alertModalRef = this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
        alertModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(function (result) {
            if (result === ModalButtonResponseEnum.OK) {
                _this.store.dispatch(new SubmitOrderResetStatusAction());
                _this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null });
            }
        });
    };
    OrdersComponent.prototype.confirmPayment = function (totalAmount, currency) {
        var _this = this;
        var initialState = {
            title: this.translate.instant('CONFIRM'),
            message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_PAY_TOTAL_AMOUNT_PAYABLE_IS', {
                totalAmount: new DecimalProxyPipe(this.translate).transform(totalAmount, '1.2-2'),
                currency: this.translate.instant(currency)
            })
        };
        var confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState
        });
        confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(function (result) {
            if (result === ModalButtonResponseEnum.OK) {
                _this.store.dispatch(new SubmitOrderRequestAction({ cart: _this.cartId, additionalNote: _this.step2Data.additionalNote }));
            }
        });
    };
    Object.defineProperty(OrdersComponent.prototype, "checkDisableStatus", {
        get: function () {
            return CheckDisableStatus;
        },
        enumerable: true,
        configurable: true
    });
    return OrdersComponent;
}());
export { OrdersComponent };
