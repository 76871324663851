import { TdStoreGroupListAction, TdStoreGroupListActionTypes } from '../actions/td-store-group-list.action';
import { BaseTdStoreGroupListState, initialTdStoreGroupListState } from '../state/td-store-group-list.state';

export function tdStoreGroupReducers(
  state = initialTdStoreGroupListState,
  action: TdStoreGroupListAction
): BaseTdStoreGroupListState {
  switch (action.type) {
    case TdStoreGroupListActionTypes.TD_STORE_GROUP_GET_RESPONSE: {
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    }
    case TdStoreGroupListActionTypes.TD_STORE_GROUP_GET_FAILURE: {
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    }
    case TdStoreGroupListActionTypes.RESET_TD_STORE_GROUP_GET: {
      return initialTdStoreGroupListState;
    }
    default: {
      return state;
    }
  }
}
