import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../services';
import { UsersService } from '../../services/users.service';
import { ForgotUsernameActionTypes, ForgotUsernameFailed, ForgotUsernameSuccess } from '../actions/forgot-username.action';
var ForgotUsernameEffects = /** @class */ (function () {
    function ForgotUsernameEffects(actions$, authService, userService, router) {
        var _this = this;
        this.actions$ = actions$;
        this.authService = authService;
        this.userService = userService;
        this.router = router;
        this.forgotUsername$ = this.actions$.pipe(ofType(ForgotUsernameActionTypes.FORGOT_USERNAME_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            return _this.authService.bypassAuthenToken().pipe(map(function (result) { return result['access_token']; }), switchMap(function (accessToken) {
                return _this.userService.forgotUsername(payload, accessToken);
            }), map(function (forgotUsername) {
                _this.router.navigateByUrl('/login');
                return new ForgotUsernameSuccess({ data: forgotUsername, mobileNumber: payload.mobileNumber });
            }), catchError(function (err) {
                return of(new ForgotUsernameFailed({ error: err.error, mobileNumber: payload.mobileNumber }));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ForgotUsernameEffects.prototype, "forgotUsername$", void 0);
    return ForgotUsernameEffects;
}());
export { ForgotUsernameEffects };
