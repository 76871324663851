import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ForgotUsernameRequestAction, ForgotUsernameReset } from '../../shared/store/actions/forgot-username.action';
import { selectForgotUsernameResult } from '../../shared/store/selectors/forgot-username.selector';
var ForgotUsernameComponent = /** @class */ (function () {
    function ForgotUsernameComponent(store, formBuilder) {
        this.store = store;
        this.formBuilder = formBuilder;
    }
    ForgotUsernameComponent.prototype.ngOnInit = function () {
        this.store.dispatch(new ForgotUsernameReset());
        this.forgotUsernameForm = this.formBuilder.group({
            mobileNumber: ['', Validators.required]
        });
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.forgotUsernameResult$ = this.localStore.pipe(select(selectForgotUsernameResult));
    };
    ForgotUsernameComponent.prototype.ngOnDestroy = function () { };
    ForgotUsernameComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.forgotUsernameForm.invalid) {
            return;
        }
        this.store.dispatch(new ForgotUsernameRequestAction({ mobileNumber: this.forgotUsernameForm.value.mobileNumber }));
        // reset form
        requestAnimationFrame(function () {
            _this.forgotUsernameForm.reset();
            _this.submitted = false;
        });
    };
    Object.defineProperty(ForgotUsernameComponent.prototype, "f", {
        get: function () {
            return this.forgotUsernameForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    return ForgotUsernameComponent;
}());
export { ForgotUsernameComponent };
