var _a;
import * as tslib_1 from "tslib";
import { createEntityAdapter } from '@ngrx/entity';
import { CatalogListActionTypes } from '../actions/catalog-list.action';
var ɵ0 = function (catalog) { return catalog.articleNo; };
export var adapter = createEntityAdapter({
    selectId: ɵ0
});
export var initialCatalogListState = adapter.getInitialState({
    isLoading: false,
    totalElement: 0,
    last: false,
    result: null
});
export function catalogListReducers(state, action) {
    if (state === void 0) { state = initialCatalogListState; }
    switch (action.type) {
        case CatalogListActionTypes.CATALOG_LIST: {
            return adapter.upsertMany(action.payload.content, tslib_1.__assign({}, state, { totalElement: action.payload.totalElements, last: action.payload.last, result: {
                    response: action.payload.content,
                    errorResponse: null
                } }));
        }
        case CatalogListActionTypes.CATALOG_LIST_ERROR: {
            return tslib_1.__assign({}, state, { result: {
                    response: null,
                    errorResponse: action.payload
                } });
        }
        case CatalogListActionTypes.CATALOG_LIST_RESET: {
            return adapter.removeAll(state);
        }
        default: {
            return state;
        }
    }
}
export var selectAll = (_a = adapter.getSelectors(), _a.selectAll), selectEntities = _a.selectEntities, selectIds = _a.selectIds, selectTotal = _a.selectTotal;
export { ɵ0 };
