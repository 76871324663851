<div [formGroup]="storeProfile">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="storeTaxNo">{{ 'TAX_ID' | translate }}</label>
            <input
              id="storeTaxNo"
              name="storeTaxNo"
              type="text"
              class="form-control"
              maxlength="13"
              formControlName="taxNo"
              placeholder="{{ 'TAX_ID' | translate }}"
              appDigitOnly
            />
          </div>
          <div class="col-md-6 mb-3">
            <label for="storeBranchNo">{{ 'BRANCH_NO' | translate }}</label>
            <input
              id="storeBranchNo"
              name="storeBranchNo"
              type="text"
              class="form-control"
              maxlength="5"
              formControlName="branchNo"
              placeholder="{{ 'BRANCH_NO' | translate }}"
              appDigitOnly
            />
            <div *ngIf="submitted && storeProfile.controls.branchNo.errors" class="invalid-feedback">
              <div *ngIf="storeProfile.controls.branchNo.errors.pattern">
                {{ 'ERRORS.REQUIRED_5_DIGITS' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="storeAddress">{{ 'ADDRESS' | translate }}<span class="text-danger">*</span></label>
            <input
              id="storeAddress"
              name="storeAddress"
              type="text"
              class="form-control"
              maxlength="250"
              formControlName="address"
              placeholder="{{ 'ADDRESS' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && storeProfile.controls.address.errors }"
            />
            <div *ngIf="submitted && storeProfile.controls.address.errors" class="invalid-feedback">
              <div *ngIf="storeProfile.controls.address.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="storePostCode">{{ 'POSTCODE' | translate }}<span class="text-danger">*</span></label>
            <input
              id="storePostCode"
              name="storePostCode"
              type="text"
              class="form-control"
              maxlength="15"
              formControlName="postCode"
              placeholder="{{ 'POSTCODE' | translate }}"
              [ngClass]="{
                'is-invalid': submitted && storeProfile.controls.postCode.errors
              }"
            />
            <div *ngIf="submitted && storeProfile.controls.postCode.errors" class="invalid-feedback">
              <div *ngIf="storeProfile.controls.postCode.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="storeCountry">{{ 'COUNTRY' | translate }}<span class="text-danger">*</span></label>
            <ng-select
              id="storeCountry"
              placeholder="Please select..."
              [items]="(userSelectValue$ | async).country"
              [searchable]="false"
              [clearable]="false"
              bindLabel="name"
              bindValue="code"
              formControlName="country"
              [ngClass]="{
                'is-invalid': submitted && storeProfile.controls.country.errors
              }"
            >
            </ng-select>
            <div *ngIf="submitted && storeProfile.controls.country.errors" class="invalid-feedback">
              <div *ngIf="storeProfile.controls.country.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="storeContactName">{{ 'CONTACT_NAME' | translate }}<span class="text-danger">*</span></label>
            <input
              id="storeContactName"
              name="storeContactName"
              type="text"
              class="form-control"
              maxlength="250"
              formControlName="contactName"
              placeholder="{{ 'CONTACT_NAME' | translate }}"
              [ngClass]="{ 'is-invalid': submitted && storeProfile.controls.contactName.errors }"
            />
            <div *ngIf="submitted && storeProfile.controls.contactName.errors" class="invalid-feedback">
              <div *ngIf="storeProfile.controls.contactName.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="email">{{ 'EMAIL' | translate }}<span class="text-danger">*</span></label>
            <input
              id="email"
              name="email"
              type="email"
              class="form-control"
              maxlength="100"
              formControlName="email"
              placeholder="{{ 'EMAIL' | translate }}"
              [ngClass]="{
                'is-invalid': submitted && storeProfile.controls.email.errors
              }"
            />
            <div *ngIf="submitted && storeProfile.controls.email.errors" class="invalid-feedback">
              <div *ngIf="storeProfile.controls.email.errors.email">
                {{ 'ERRORS.INVALID_EMAIL' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="storeStateProvince">{{ 'STATE_PROVINCE' | translate }}<span class="text-danger">*</span></label>
            <ng-select
              id="storeStateProvince"
              name="storeStateProvince"
              placeholder="Please select..."
              [items]="(userSelectValue$ | async).state"
              [searchable]="false"
              [clearable]="false"
              bindLabel="name"
              bindValue="code"
              formControlName="state"
              [ngClass]="{
                'is-invalid': submitted && storeProfile.controls.state.errors
              }"
            >
            </ng-select>
            <div *ngIf="submitted && storeProfile.controls.state.errors" class="invalid-feedback">
              <div *ngIf="storeProfile.controls.state.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="storeRegion">{{ 'REGION' | translate }}<span class="text-danger">*</span></label>
            <ng-select
              id="storeRegion"
              name="storeRegion"
              placeholder="Please select..."
              [items]="(userSelectValue$ | async).region"
              [searchable]="false"
              [clearable]="false"
              bindLabel="name"
              bindValue="code"
              formControlName="region"
              [ngClass]="{
                'is-invalid': submitted && storeProfile.controls.region.errors
              }"
            >
            </ng-select>
            <div *ngIf="submitted && storeProfile.controls.region.errors" class="invalid-feedback">
              <div *ngIf="storeProfile.controls.region.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="storeTimezone">{{ 'TIMEZONE' | translate }}<span class="text-danger">*</span></label>
            <ng-select
              id="storeTimezone"
              name="storeTimezone"
              placeholder="Please select..."
              [items]="(userSelectValue$ | async).timezone"
              [searchable]="false"
              [clearable]="false"
              bindLabel="name"
              bindValue="code"
              formControlName="timezone"
              [ngClass]="{
                'is-invalid': submitted && storeProfile.controls.timezone.errors
              }"
            >
            </ng-select>
            <div *ngIf="submitted && storeProfile.controls.timezone.errors" class="invalid-feedback">
              <div *ngIf="storeProfile.controls.timezone.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="storePhone">{{ 'PHONE' | translate }}<span class="text-danger">*</span></label>
            <ngx-intl-tel-input
              [cssClass]="'form-control d-block ' + (isPhoneError(storeProfile.controls.phone) ? 'is-invalid' : '')"
              [preferredCountries]="['th']"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="false"
              [maxlength]="25"
              id="storePhone"
              formControlName="phone"
              [ngClass]="{ disabled: storeProfile.disabled }"
            ></ngx-intl-tel-input>
            <div *ngIf="submitted && storeProfile.controls.phone.errors" class="invalid-feedback">
              <div *ngIf="storeProfile.controls.phone.errors.required; else validatePhoneNumber">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <ng-template #validatePhoneNumber>
                <div *ngIf="storeProfile.controls.phone.errors.validatePhoneNumber">
                  {{ 'ERRORS.INVALID_PHONE' | translate }}
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
