import { StoreType } from '../enum/store.enum';
import { partnerRoles, storeModelRoles } from '../models/list-key-value.model';

export const isOwner = (role: string) => {
  const ownerRole = ['STORE_OWNER', 'STORE_OWNER_PARTNER'];
  return ownerRole.indexOf(role) !== -1;
};

export const getRoles = (merchantType: StoreType, includeOwner: boolean) => {
  const roles = merchantType === StoreType.STORE_MODEL ? storeModelRoles : partnerRoles;
  return !includeOwner ? roles.filter(v => !isOwner(v.code)) : roles;
};
