import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models/error.model';
import { TdStoreGroupListResponse } from '../../models/td-store-group-list.model';
import { UrlParams } from '../../models/url-params.model';

export enum TdStoreGroupListActionTypes {
  TD_STORE_GROUP_GET_REQUEST = '[Store Group] Get group  Request',
  TD_STORE_GROUP_GET_RESPONSE = '[Store Group] Get group Response',
  TD_STORE_GROUP_GET_FAILURE = '[Store Group] Get group Failure',
  RESET_TD_STORE_GROUP_GET = '[Store Group] Get group Reset'
}

export class TdStoreGroupListGetAction implements Action {
  readonly type = TdStoreGroupListActionTypes.TD_STORE_GROUP_GET_REQUEST;

  constructor(public payload: UrlParams) {}
}

export class TdStoreGroupListSuccessAction implements Action {
  readonly type = TdStoreGroupListActionTypes.TD_STORE_GROUP_GET_RESPONSE;

  constructor(public payload: TdStoreGroupListResponse[]) {}
}

export class TdStoreGroupListFailureAction implements Action {
  readonly type = TdStoreGroupListActionTypes.TD_STORE_GROUP_GET_FAILURE;

  constructor(public payload: ErrorResponse) {}
}

export class ResetTdStoreGroupListAction implements Action {
  readonly type = TdStoreGroupListActionTypes.RESET_TD_STORE_GROUP_GET;

  constructor() {}
}

export type TdStoreGroupListAction =
  | TdStoreGroupListGetAction
  | TdStoreGroupListSuccessAction
  | TdStoreGroupListFailureAction
  | ResetTdStoreGroupListAction;
