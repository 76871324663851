import { Action } from '@ngrx/store';
import { CatalogListRequest, CatalogListResponse } from '../../models/catalog.model';
import { ErrorResponse } from '../../models/error.model';

export enum CatalogListActionTypes {
  CATALOG_LIST_REQUEST = '[Orders] Catalog List Request',
  CATALOG_LIST = '[Orders] Catalog List',
  CATALOG_LIST_ERROR = '[Orders] Catalog List Error',
  CATALOG_LIST_RESET = '[Orders] Catalog List Reset'
}

export class CatalogListRequestAction implements Action {
  readonly type = CatalogListActionTypes.CATALOG_LIST_REQUEST;

  constructor(public payload: CatalogListRequest) {}
}

export class CatalogListResponseAction implements Action {
  readonly type = CatalogListActionTypes.CATALOG_LIST;

  constructor(public payload: CatalogListResponse) {}
}

export class CatalogListErrorAction implements Action {
  readonly type = CatalogListActionTypes.CATALOG_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class CatalogListResetAction implements Action {
  readonly type = CatalogListActionTypes.CATALOG_LIST_RESET;

  constructor() {}
}
export type CatalogListActions =
  | CatalogListRequestAction
  | CatalogListResponseAction
  | CatalogListErrorAction
  | CatalogListResetAction;
