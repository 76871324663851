/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./switch-lang-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/dropdown";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "@angular/animations";
import * as i6 from "./switch-lang-menu.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "ngx-logger";
var styles_SwitchLangMenuComponent = [i0.styles];
var RenderType_SwitchLangMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SwitchLangMenuComponent, data: {} });
export { RenderType_SwitchLangMenuComponent as RenderType_SwitchLangMenuComponent };
function View_SwitchLangMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "dropdown-item"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setLang(_v.context.$implicit.code) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "/assets/images/", _v.context.$implicit.code, ".png"); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
function View_SwitchLangMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["aria-labelledby", "button-custom-html"], ["class", "dropdown-menu dropdown-menu-right"], ["id", "dropdown-custom-html"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SwitchLangMenuComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.langList; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SwitchLangMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "div", [["class", "btn-group pointer"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i3.BsDropdownState, i3.BsDropdownState, []), i1.ɵdid(2, 212992, null, 0, i3.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ComponentLoaderFactory, i3.BsDropdownState, i3.BsDropdownConfig, i5.AnimationBuilder], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["aria-controls", "dropdown-custom-html"], ["class", "dropdown-toggle"], ["dropdownToggle", ""], ["id", "button-custom-html"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 147456, null, 0, i3.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i3.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i3.BsDropdownState], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SwitchLangMenuComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.BsDropdownMenuDirective, [i3.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).dropup; var currVal_1 = i1.ɵnov(_v, 2).isOpen; var currVal_2 = (i1.ɵnov(_v, 2).isOpen && i1.ɵnov(_v, 2).isBs4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 4).isDisabled; var currVal_5 = i1.ɵnov(_v, 4).isOpen; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "/assets/images/", _co.currentLang, ".png"); var currVal_7 = i1.ɵinlineInterpolate(1, "", _co.currentLang, ""); _ck(_v, 5, 0, currVal_6, currVal_7); }); }
export function View_SwitchLangMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-switch-lang-menu", [], null, null, null, View_SwitchLangMenuComponent_0, RenderType_SwitchLangMenuComponent)), i1.ɵdid(1, 245760, null, 0, i6.SwitchLangMenuComponent, [i7.TranslateService, i8.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SwitchLangMenuComponentNgFactory = i1.ɵccf("app-switch-lang-menu", i6.SwitchLangMenuComponent, View_SwitchLangMenuComponent_Host_0, {}, {}, []);
export { SwitchLangMenuComponentNgFactory as SwitchLangMenuComponentNgFactory };
