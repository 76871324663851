import { ErrorResponse } from '../../models/error.model';
import { Role } from '../../models/role.model';
import { BaseState } from './base.state';

export interface BaseRolesState extends BaseState {
  result: {
    response: Role[] | null;
    errorResponse: ErrorResponse | null;
  };
}

export const initialRolesState: BaseRolesState = {
  result: {
    response: null,
    errorResponse: new ErrorResponse()
  }
};
