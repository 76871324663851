<div class="full-popup-head alert-secondary">
  <div class="full-popup-back float-left">
    <h4 class="text-danger d-inline">{{ data.title }}</h4>
  </div>
  <div class="full-popup-close float-right">
    <span class="d-none d-md-inline pr-2">
      <em class="fa fa-store "></em>
      {{ storeName | async }}
      ({{ 'ORDER_SCHEDULE' | translate }}: {{ orderSchedule | async }} | {{ 'SHIPPING_SCHEDULE' | translate }}:
      {{ deliverySchedule | async }})
    </span>
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="fa fa-times"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div class="container-fluid admin-form">
  <!-- your steps here -->
  <div id="order-stepper" class="bs-stepper" #orderStepper>
    <div class="bs-stepper-header d-none d-md-flex offset-md-2 col-md-8 " role="tablist">
      <!-- steps 1 -->
      <div class="step text-center" data-target="#step1">
        <button type="button" class="step-trigger" role="tab" aria-controls="step1" id="step1-part-trigger">
          <span class="bs-stepper-circle">1</span>
          <span class="bs-stepper-label">{{ 'ADD_ITEM' | translate }}</span>
        </button>
      </div>
      <div class="line"></div>
      <!-- steps 2 -->
      <div class="step text-center" data-target="#step2">
        <button type="button" class="step-trigger" role="tab" aria-controls="step2" id="step2-part-trigger">
          <span class="bs-stepper-circle">2</span>
          <span class="bs-stepper-label">{{ 'CONFIRM_PAYMENT' | translate }}</span>
        </button>
      </div>
    </div>
    <div class="bs-stepper-content p-b-85">
      <div id="step1" class="content">
        <div>
          <app-orders-step1></app-orders-step1>
        </div>
        <div class="fixed-row-bottom form-row">
          <button (click)="onCancel()" class="btn btn-outline-primary float-left">{{ 'CANCEL' | translate }}</button>
          <button (click)="onNext()" #nextStep class="btn btn-primary float-right">
            {{ 'NEXT_STEP' | translate }}
          </button>
        </div>
      </div>
      <div id="step2" class="content">
        <div>
          <app-orders-step2 (dataOut)="onStep2DataOut($event)"></app-orders-step2>
        </div>
        <div class="fixed-row-bottom form-row">
          <button (click)="onCancel()" class="btn btn-outline-primary float-left">{{ 'CANCEL' | translate }}</button>

          <button (click)="submitOrder()" class="btn btn-primary float-right">
            {{ 'ORDER_PRODUCT' | translate }}
          </button>

          <button (click)="onBack()" class="btn btn-outline-primary float-right mr-2">
            {{ 'BACK' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
