import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as JwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { filter } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { MenuRequestedAction } from '../store/actions/menu.action';
import { ClientIdAction, TenantInformationAction, UserInformationAction } from '../store/actions/user-info.action';
import { UsersService } from './users.service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-logger";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-cookie-service";
import * as i4 from "@ngrx/store";
import * as i5 from "./users.service";
var AppInitService = /** @class */ (function () {
    function AppInitService(logger, http, cookieService, store, usersService) {
        this.logger = logger;
        this.http = http;
        this.cookieService = cookieService;
        this.store = store;
        this.usersService = usersService;
    }
    AppInitService.prototype.load = function () {
        var _this = this;
        return function () {
            return new Promise(function (resolve) {
                var accessToken = _this.cookieService.get(env.cookies.accessToken);
                if (accessToken) {
                    var userInfo = JwtDecode(accessToken);
                    _this.dispatchInfo(userInfo);
                    _this.getUserTenant(userInfo.user_info.userNo);
                    _this.store.dispatch(new ClientIdAction(userInfo.client_id));
                }
                else {
                    _this.store.dispatch(new ClientIdAction(env.defaultClientId));
                }
                resolve(true);
            });
        };
    };
    AppInitService.prototype.dispatchInfo = function (userInfo) {
        userInfo.user_info.authorities = userInfo.authorities;
        this.store.dispatch(new UserInformationAction(userInfo.user_info));
        this.store.dispatch(new TenantInformationAction(userInfo.tenant_info));
        this.store.dispatch(new MenuRequestedAction(userInfo.authorities));
    };
    AppInitService.prototype.getUserTenant = function (userNo) {
        var _this = this;
        if (!userNo) {
            return;
        }
        this.usersService
            .getUser(userNo)
            .pipe(filter(function (value) { return Boolean(value); }))
            .subscribe(function (user) {
            var tenantInfo = {
                merchant: user.tenant.merchant,
                sites: user.tenant.sites,
                groups: user.tenant.storeGroups,
                stores: user.tenant.stores
            };
            _this.store.dispatch(new TenantInformationAction(tenantInfo));
        });
    };
    AppInitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppInitService_Factory() { return new AppInitService(i0.ɵɵinject(i1.NGXLogger), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.CookieService), i0.ɵɵinject(i4.Store), i0.ɵɵinject(i5.UsersService)); }, token: AppInitService, providedIn: "root" });
    return AppInitService;
}());
export { AppInitService };
