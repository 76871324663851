import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ItemActionTypeEnum } from '../../enum/item-action.enum';
import { AddItemRequest } from '../../models/cart.model';
import { CartsService, CatalogsService } from '../../services';
import {
  AddItemErrorAction,
  AddItemRequestAction,
  AddItemResponseAction,
  CartsActionTypes,
  CreateCartErrorAction,
  CreateCartRequestAction,
  CreateCartResponseAction,
  DeleteCartItemRequestAction,
  DeleteInvalidItemsRequestAction,
  GetCartAction,
  GetCartErrorAction,
  GetCartResponseAction,
  OrdersSummaryErrorAction,
  OrdersSummaryRequestAction,
  OrdersSummaryResponseAction,
  SubmitOrderErrorAction,
  SubmitOrderRequestAction,
  SubmitOrderResponseAction
} from '../actions/carts.action';

@Injectable()
export class CartsEffects {
  constructor(
    private actions: Actions,
    private ordersService: CartsService,
    private catalogService: CatalogsService,
    private readonly router: Router,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  OrderSummary: Observable<Action> = this.actions.pipe(
    ofType<OrdersSummaryRequestAction>(CartsActionTypes.ORDER_SUMMARY_REQUEST),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Order Summary Request: ' + this.stringify(payload));
      return this.ordersService.orderSummary(payload.storeNo).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Order Summary Response: ' + this.stringify(resp));

          return new OrdersSummaryResponseAction(resp);
        }),
        catchError(err => {
          return of(new OrdersSummaryErrorAction(err.error));
        })
      );
    })
  );

  @Effect()
  CreateCart: Observable<Action> = this.actions.pipe(
    ofType<CreateCartRequestAction>(CartsActionTypes.CREATE_CARTS_REQUEST),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Create Cart Request: ' + this.stringify(payload));
      return this.ordersService.createCart(payload.storeNo).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Create Cart Response: ' + this.stringify(resp));
          return new CreateCartResponseAction({
            storeNo: payload.storeNo,
            cartId: resp.id
          });
        }),
        catchError(err => {
          return of(new CreateCartErrorAction(err.error));
        })
      );
    })
  );

  @Effect()
  AddItem: Observable<Action> = this.actions.pipe(
    ofType<AddItemRequestAction>(CartsActionTypes.ADD_ITEM_REQUEST),
    map(action => action.payload),
    switchMap((payload: AddItemRequest) => {
      if (payload.action === ItemActionTypeEnum.UPDATE) {
        this.logger.debug('@Effect Update Item Request: ' + this.stringify(payload));
        return this.ordersService.updateItem(payload).pipe(
          map((resp: any) => {
            this.logger.debug('@Effect Update Item Response: ' + this.stringify(resp));
            return new AddItemResponseAction(resp);
          }),
          catchError(err => {
            return of(new AddItemErrorAction({ ...err.error, barcode: payload.barcode, qty: payload.qty }));
          })
        );
      } else {
        this.logger.debug('@Effect Add Item Request: ' + this.stringify(payload));
        return this.ordersService.addItem(payload).pipe(
          map((resp: any) => {
            this.logger.debug('@Effect Add Item Response: ' + this.stringify(resp));
            return new AddItemResponseAction(resp);
          }),
          catchError(err => {
            return of(new AddItemErrorAction({ ...err.error, barcode: payload.barcode, qty: payload.qty }));
          })
        );
      }
    })
  );

  @Effect()
  getCart: Observable<Action> = this.actions.pipe(
    ofType<GetCartAction>(CartsActionTypes.GET_CART),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Get Cart Request: ' + this.stringify(payload));
      return this.ordersService.getCart(payload.cartId).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Get Cart Response: ' + this.stringify(resp));
          return new GetCartResponseAction(resp);
        }),
        catchError(err => {
          return of(new GetCartErrorAction(err.error));
        })
      );
    })
  );

  @Effect()
  deleteItem: Observable<Action> = this.actions.pipe(
    ofType<DeleteCartItemRequestAction>(CartsActionTypes.DELETE_CART_ITEM_REQUEST),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Delete Cart Item Request: ' + this.stringify(payload));
      return this.ordersService.deleteItem(payload).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Delete Cart Item Response: ' + this.stringify(resp));
          return new AddItemResponseAction(resp);
        }),
        catchError(err => {
          return of(new AddItemErrorAction({ ...err.error, barcode: payload.barcode }));
        })
      );
    })
  );

  @Effect()
  deleteInvalidItems: Observable<Action> = this.actions.pipe(
    ofType<DeleteInvalidItemsRequestAction>(CartsActionTypes.DELETE_INVALID_ITEMS_REQUEST),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Delete Invalid Items Request: ' + this.stringify(payload));
      return this.ordersService.deleteInvalidItems(payload).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Delete Invalid Items Response: ' + this.stringify(resp));
          return new AddItemResponseAction(resp);
        }),
        catchError(err => {
          return of(new AddItemErrorAction({ ...err.error }));
        })
      );
    })
  );

  @Effect()
  submitOrder: Observable<Action> = this.actions.pipe(
    ofType<SubmitOrderRequestAction>(CartsActionTypes.SUBMIT_ORDER_REQUEST),
    // TODO Remove i f Backend decrypt token themselves
    // withLatestFrom(this.store$.select(state => state.userInfo)),
    // switchMap(([action, userInfoState]: [SubmitOrderRequestAction, UserInfoState]) => {
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Submit Order Request: ' + this.stringify(payload));
      return this.ordersService.submitOrder(payload).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Submit Order Response: ' + this.stringify(resp));
          return new SubmitOrderResponseAction(resp);
        }),
        catchError(err => {
          return of(new SubmitOrderErrorAction(err.error));
        })
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
