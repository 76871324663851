import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UsersService } from '../../services';
import { ForceChangePasswordActionTypes, ForceChangePasswordFailed, ForceChangePasswordSuccess } from '../actions/force-change-password.action';
var ForceChangePasswordEffect = /** @class */ (function () {
    function ForceChangePasswordEffect(actions$, usersService) {
        var _this = this;
        this.actions$ = actions$;
        this.usersService = usersService;
        this.create$ = this.actions$.pipe(ofType(ForceChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST), map(function (action) { return action.payload; }), switchMap(function (changePasswordRequest) {
            return _this.usersService.changePassword(changePasswordRequest).pipe(map(function (changedPassword) {
                return new ForceChangePasswordSuccess({
                    statusCode: changedPassword.status.toString(),
                    message: changedPassword.statusText
                });
            }), catchError(function (err) {
                return of(new ForceChangePasswordFailed(err.error));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ForceChangePasswordEffect.prototype, "create$", void 0);
    return ForceChangePasswordEffect;
}());
export { ForceChangePasswordEffect };
