import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { map, tap } from 'rxjs/operators';
import { ItemActionTypeEnum } from '../../../shared/enum/item-action.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProductStatusEnum } from '../../../shared/enum/product-status.enum';
import { Catalog } from '../../../shared/models/catalog.model';
import { DecimalProxyPipe } from '../../../shared/pipe/decimal-proxy/decimal-proxy.pipe';
import { PosService } from '../../../shared/services/pos.service';
import { AddItemRequestAction } from '../../../shared/store/actions/carts.action';
import { selectAddItemResult } from '../../../shared/store/selectors/cart.selector';
import { selectOrderSummaryResult } from '../../../shared/store/selectors/order-summary.selector';
import { CheckDisableStatus } from '../../../shared/utils/check-disable-status';
var ItemComponent = /** @class */ (function () {
    function ItemComponent(formBuilder, store, translate, pos) {
        this.formBuilder = formBuilder;
        this.store = store;
        this.translate = translate;
        this.pos = pos;
        this.notifyParent = new EventEmitter();
    }
    ItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.pos.goto('item');
        this.catalog = new Catalog(this.data.catalog);
        this.cart = this.data.cart;
        this.itemDetailForm = this.formBuilder.group({
            amount: [
                this.data.isResetQuantity ? 1 : this.catalog.qty,
                [Validators.required, Validators.min(1), Validators.max(999)]
            ]
        });
        this.localStore
            .pipe(select(selectOrderSummaryResult), map(function (action) { return action.result.response; }))
            .subscribe(function (data) { return (_this.orderSummary = data); });
        this.localStore
            .pipe(select(selectAddItemResult), tap(function (addItem) {
            if (addItem.addItemSuccess) {
                if (_this.currentBarcode && _this.data.isResetQuantity) {
                    document.getElementById(_this.currentBarcode).value = '1';
                }
                _this.currentBarcode = null;
            }
        }))
            .subscribe();
        // Output: สต๊อก 0 ชิ้น | ยอดขายเฉลี่ย 0 ขิ้น/สัปดาห์ | ราคาเฉลี่ย/ชิ้น 0 บาท | ราคาขายต้่อชิ้น 0 บาท | กำไร/ชิ้น 0 บาท
        var retailPricePerUnit = this.catalog.articleType !== 'N'
            ? new DecimalProxyPipe(this.translate).transform(this.catalog.retailPricePerUnit.amount) + " " + this.translate.instant(this.catalog.retailPricePerUnit.currency)
            : '-';
        var profitPerUnit = this.catalog.articleType !== 'N'
            ? new DecimalProxyPipe(this.translate).transform(this.catalog.profitPerUnit.amount) + " " + this.translate.instant(this.catalog.profitPerUnit.currency)
            : '-';
        this.constInfo = [
            this.translate.instant('STOCK') + " 0 " + this.translate.instant('UNIT.PC'),
            "" + this.translate.instant('AVERAGE_SALES_WEEKLY', { amount: '0' }),
            this.translate.instant('AVERAGE_PRICE_PIECE') + " " + new DecimalProxyPipe(this.translate).transform(this.catalog.wholesalePricePerUnit.amount) + " " + this.translate.instant(this.catalog.wholesalePricePerUnit.currency),
            this.translate.instant('RETAIL_PRICE_PIECE') + " " + retailPricePerUnit,
            this.translate.instant('PROFIT_PIECE') + " " + profitPerUnit
        ].join(' | ');
    };
    ItemComponent.prototype.ngOnDestroy = function () {
        this.catalog = null;
    };
    ItemComponent.prototype.productStatus = function (status) {
        return this.translate.get('PRODUCT_STATUS.' + status).pipe();
    };
    ItemComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.itemDetailForm.valid) {
            this.currentBarcode = this.catalog.barcode;
            var addItemRequest = {
                barcode: this.currentBarcode,
                qty: this.itemDetailForm.controls.amount.value.toString(),
                action: ItemActionTypeEnum.ADD,
                cartId: this.cart.cartId
            };
            this.store.dispatch(new AddItemRequestAction(addItemRequest));
        }
    };
    ItemComponent.prototype.onCancel = function () {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null });
    };
    ItemComponent.prototype.onAdd = function () {
        var newVal = this.itemDetailForm.value.amount >= 999 ? 999 : +this.itemDetailForm.value.amount + 1;
        this.itemDetailForm.controls['amount'].setValue(newVal);
    };
    ItemComponent.prototype.onReduce = function () {
        var newVal = this.itemDetailForm.value.amount <= 1 ? 1 : +this.itemDetailForm.value.amount - 1;
        this.itemDetailForm.controls['amount'].setValue(newVal);
    };
    Object.defineProperty(ItemComponent.prototype, "productTitle", {
        get: function () {
            return this.translate.instant('PRODUCT_TITLE', {
                productName: this.catalog.productName,
                unit: this.translate.instant('UNIT.' + this.catalog.unit),
                unitFactor: this.catalog.unitFactor
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemComponent.prototype, "f", {
        get: function () {
            return this.itemDetailForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemComponent.prototype, "productStatusList", {
        get: function () {
            return ProductStatusEnum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemComponent.prototype, "checkDisableStatus", {
        get: function () {
            return CheckDisableStatus;
        },
        enumerable: true,
        configurable: true
    });
    return ItemComponent;
}());
export { ItemComponent };
