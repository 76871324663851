import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { RolesService } from '../../services/roles.service';
import { RolesAction, RolesActionTypes, RolesFailureAction, RolesSuccessAction } from '../actions/roles.actions';

@Injectable()
export class RoleEffects {
  constructor(
    private actions: Actions,
    private rolesService: RolesService,
    private readonly router: Router,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  Role: Observable<Action> = this.actions.pipe(
    ofType<RolesAction>(RolesActionTypes.ROLES),
    switchMap(action => {
      this.logger.debug('@Effect RoleAction: ' + this.stringify(action.payload));

      return this.rolesService.getRoles(action.payload).pipe(
        switchMap(resp => {
          return of(new RolesSuccessAction(resp));
        }),
        catchError(err => {
          return of(new RolesFailureAction(err.error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  RolesSuccess: Observable<Action> = this.actions.pipe(
    ofType(RolesActionTypes.ROLES_SUCCESS),
    tap((resp: RolesSuccessAction) => {
      this.logger.debug('@Effect RolesSuccessAction: ' + this.stringify(resp.payload));
    })
  );

  @Effect({ dispatch: false })
  RolesFailure: Observable<Action> = this.actions.pipe(
    ofType(RolesActionTypes.ROLES_FAILURE),
    tap((resp: RolesFailureAction) => {
      this.logger.debug('@Effect RolesFailureAction: ' + this.stringify(resp));
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
