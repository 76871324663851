import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { BaseAuthState } from '../state/auth.state';

const selectAuth = (state: AppStates) => state.auth;

export const selectAuthResult = createSelector(
  selectAuth,
  (state: BaseAuthState) => state
);
