<div class="left-section">
  <h2 class="forgot-section-header">{{ 'TD_STORE_PORTAL' | translate }}</h2>
  <h4>
    {{ 'FORGOT_USERNAME' | translate }}
  </h4>
  <p>{{ 'PLEASE_ENTER_YOUR_MOBILE_NUMBER' | translate }}</p>
  <div>
    <button class="btn btn-outline-primary" (click)="onCancel()">{{ 'CANCEL' | translate }}</button>
  </div>
</div>
