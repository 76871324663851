import { TdStoreAction, TdStoreActionTypes } from '../actions/td-store.action';
import { BaseTdStoreState, initialTdStoreState } from '../state/td-store.state';

export function tdStoreReducers(state = initialTdStoreState, action: TdStoreAction): BaseTdStoreState {
  switch (action.type) {
    case TdStoreActionTypes.TD_STORE_SUCCESS: {
      return {
        ...state,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    }
    case TdStoreActionTypes.TD_STORE_FAILURE: {
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    }
    case TdStoreActionTypes.RESET_TD_STORE_STATE: {
      return initialTdStoreState;
    }
    default: {
      return state;
    }
  }
}
