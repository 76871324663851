import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { VerificationService } from './verification.service';
import * as i0 from "@angular/core";
import * as i1 from "./verification.service";
import * as i2 from "./auth.service";
import * as i3 from "@angular/router";
var MerchantVerifyGuardService = /** @class */ (function () {
    function MerchantVerifyGuardService(verificationService, authService, router) {
        this.verificationService = verificationService;
        this.authService = authService;
        this.router = router;
    }
    MerchantVerifyGuardService.prototype.canActivate = function (route) {
        this.merchantId = route.queryParams.m;
        return this.checkMerchantStatus();
    };
    MerchantVerifyGuardService.prototype.canActivateChild = function (route) {
        this.merchantId = route.params.merchantId;
        return this.checkMerchantStatus();
    };
    MerchantVerifyGuardService.prototype.checkMerchantStatus = function () {
        var _this = this;
        return this.authService.bypassAuthenToken().pipe(map(function (result) { return result['access_token']; }), mergeMap(function (accessToken) { return _this.verificationService.getMerchantStatus(_this.merchantId, accessToken); }), map(function (response) {
            var status = response['status'];
            if (status === 'WV') {
                return true;
            }
            else if (['WS', 'D', 'WA', 'C', 'R', 'CR'].includes(status)) {
                _this.router.navigateByUrl('/mer-verify-invalid');
                return false;
            }
            else {
                _this.router.navigateByUrl('/mer-verify-error');
                return false;
            }
        }), catchError(function () {
            _this.router.navigateByUrl('/mer-verify-error');
            return of(false);
        }));
    };
    MerchantVerifyGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MerchantVerifyGuardService_Factory() { return new MerchantVerifyGuardService(i0.ɵɵinject(i1.VerificationService), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.Router)); }, token: MerchantVerifyGuardService, providedIn: "root" });
    return MerchantVerifyGuardService;
}());
export { MerchantVerifyGuardService };
