import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
var BaseService = /** @class */ (function () {
    function BaseService() {
        this.env = environment;
    }
    BaseService.prototype.loaderHeaders = function (ms) {
        if (ms === void 0) { ms = 0; }
        return new HttpHeaders(this.envService.headers).set('x-frontend-spinner-loader', ms.toString());
    };
    /**
     * Encode an object to HttpParams
     * eg. this.getParams({key:value})
     * @param query  Object for encoding to HttpParams.
     * @returns HttpParams with a query.
     */
    BaseService.prototype.getParams = function (query) {
        var params = new HttpParams();
        var _loop_1 = function (key) {
            if (query[key]) {
                if (query[key] instanceof Array) {
                    query[key].forEach(function (item) {
                        params = params.append(key.toString() + "[]", item);
                    });
                }
                else {
                    params = params.append(key.toString(), query[key]);
                }
            }
            else if (query[key] === 0 || query[key] === false) {
                params = params.append(key.toString(), query[key]);
            }
        };
        for (var _i = 0, _a = Object.keys(query); _i < _a.length; _i++) {
            var key = _a[_i];
            _loop_1(key);
        }
        return params;
    };
    /**
     * Returns the full url of the service.
     * eg. this.getUrl('serviceAppend',{'param1':'Param1','param2':'Param2'}
     * @param serviceUrl  serviceUrl.
     * @param urlParams  Object for replacing all matches in serviceAppend.
     * @returns Full service url
     */
    BaseService.prototype.getFullUrl = function (serviceUrl, urlParams) {
        if (urlParams === void 0) { urlParams = null; }
        console.log('serviceUrl', serviceUrl);
        for (var urlParamsKey in urlParams) {
            if (urlParams[urlParamsKey]) {
                serviceUrl = serviceUrl.replace(new RegExp("{" + urlParamsKey + "}", 'g'), urlParams[urlParamsKey]);
            }
        }
        return this.env.serverUrl + this.envService.url + serviceUrl;
    };
    BaseService.prototype.getUrl = function () {
        return this.env.serverUrl + this.envService.url;
    };
    BaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseService_Factory() { return new BaseService(); }, token: BaseService, providedIn: "root" });
    return BaseService;
}());
export { BaseService };
