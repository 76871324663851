import { ErrorResponse } from '../../models/error.model';
import { BaseState } from './base.state';

export interface BaseForgotUsernameState extends BaseState {
  result: {
    isSuccess: boolean;
    response: any | null;
    errorResponse: ErrorResponse | null;
    mobileNumber: string | null;
  };
}

export const initialForgotUsernameState: BaseForgotUsernameState = {
  result: {
    isSuccess: false,
    response: null,
    errorResponse: new ErrorResponse(),
    mobileNumber: null
  }
};
