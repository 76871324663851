import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ItemActionTypeEnum } from '../../../shared/enum/item-action.enum';
import { ProductStatusEnum } from '../../../shared/enum/product-status.enum';
import { ChildItem } from '../../../shared/layouts/modals/child-item/child-item';
import { CustomSizeModalComponent } from '../../../shared/layouts/modals/custom-size-modal/custom-size-modal.component';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { AddItemRequestAction, OrdersSummaryRequestAction, ResetAddItemStatusAction } from '../../../shared/store/actions/carts.action';
import { CatalogListRequestAction, CatalogListResetAction } from '../../../shared/store/actions/catalog-list.action';
import { selectAddItemResult, selectCreateCartResult } from '../../../shared/store/selectors/cart.selector';
import { selectCatalogListLoadedElementResult, selectCatalogListResult, selectCatalogListTotalResult } from '../../../shared/store/selectors/catalog-list.selector';
import { selectTdStorePolicyResult } from '../../../shared/store/selectors/td-store.selector';
import { CheckDisableStatus } from '../../../shared/utils/check-disable-status';
import { ItemComponent } from '../item/item.component';
import { DuplicateItemComponent } from './duplicate-item/duplicate-item.component';
var Step1Component = /** @class */ (function () {
    function Step1Component(modalService, translate, store, toastrService) {
        this.modalService = modalService;
        this.translate = translate;
        this.store = store;
        this.toastrService = toastrService;
        this.totalItem = 0;
        this.stopLoading = false;
        this.catalogListRequest = {
            searchCriteria: null,
            catalogLV1: null,
            catalogLV2: null,
            offset: 0,
            limit: 10,
            timeZone: null
        };
    }
    Step1Component.prototype.dispatchCatalogListAction = function (catalogRequest) {
        this.store.dispatch(new CatalogListRequestAction(catalogRequest));
    };
    Step1Component.prototype.ngOnInit = function () {
        this.initState();
    };
    Step1Component.prototype.initState = function () {
        var _this = this;
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.catalogList$ = this.localStore.pipe(select(selectCatalogListResult));
        this.catalogListTotal$ = this.localStore.pipe(select(selectCatalogListTotalResult));
        this.catalogListLoadedElement$ = this.localStore.pipe(select(selectCatalogListLoadedElementResult));
        this.localStore.pipe(select(selectCreateCartResult)).subscribe(function (cart) {
            _this.cart = cart.result.response;
            if (_this.cart && _this.cart.storeNo) {
                _this.store.dispatch(new OrdersSummaryRequestAction({ storeNo: _this.cart.storeNo }));
            }
        });
        this.localStore.pipe(select(selectTdStorePolicyResult)).subscribe(function (tdStore) {
            if (tdStore) {
                _this.catalogListRequest.timeZone = tdStore.storeProfile.timezone;
                _this.dispatchCatalogListAction(_this.catalogListRequest);
            }
        });
        this.localStore.pipe(select(selectAddItemResult)).subscribe(function (addItem) {
            if (addItem.errorResponse) {
                if (addItem.errorResponse.code === '00002') {
                    _this.callDuplicateItemModal(addItem.errorResponse.barcode, addItem.errorResponse.qty);
                }
                else if (['00000', '00001'].includes(addItem.errorResponse.code)) {
                    _this.showToasterError();
                }
            }
            else if (addItem.addItemSuccess) {
                if (_this.currentBarcode) {
                    document.getElementById(_this.currentBarcode + '-list').value = '1';
                }
                _this.currentBarcode = null;
                _this.showToaster();
                _this.store.dispatch(new OrdersSummaryRequestAction({ storeNo: _this.cart.storeNo }));
            }
        });
    };
    Step1Component.prototype.onActivate = function (catCode) {
        this.catCode = catCode;
        if (this.catCode) {
            var categoryLevel = this.catCode.substring(0, 4);
            this.catalogListRequest.catalogLV1 = 'CAT1' === categoryLevel ? this.catCode : null;
            this.catalogListRequest.catalogLV2 = 'CAT2' === categoryLevel ? this.catCode : null;
            this.displayCatText = this.translate.instant("CAT_CODE." + this.catCode);
            this.store.dispatch(new CatalogListResetAction());
            this.dispatchCatalogListAction(this.catalogListRequest);
        }
    };
    Step1Component.prototype.handleSearchText = function (searchCriteria) {
        this.catCode = null;
        this.displayCatText = this.translate.instant("CAT_CODE.null");
        this.catalogListRequest = { catalogLV1: null, catalogLV2: null };
        this.store.dispatch(new CatalogListResetAction());
        this.catalogListRequest.searchCriteria = searchCriteria;
        this.displaySearchText = searchCriteria;
        this.dispatchCatalogListAction(this.catalogListRequest);
    };
    Step1Component.prototype.callItemDetails = function (catalog) {
        var initialState = {
            title: null,
            childItem: new ChildItem(ItemComponent, {
                catalog: catalog,
                cart: this.cart,
                title: this.translate.instant('BACK_TO_PREVIOUS_PAGE'),
                isResetQuantity: true,
                step: '1'
            })
        };
        this.bsModalRef = this.modalService.show(FullModalComponent, {
            animated: false,
            backdrop: false,
            initialState: initialState
        });
    };
    Step1Component.prototype.callDuplicateItemModal = function (barcode, qty) {
        var initialState = {
            title: this.translate.instant('DUPLICATED_ITEM'),
            childItem: new ChildItem(DuplicateItemComponent, {
                barcode: barcode,
                qty: qty
            })
        };
        this.bsModalRef = this.modalService.show(CustomSizeModalComponent, {
            animated: true,
            backdrop: true,
            initialState: initialState
        });
        var modal = this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(function () {
            modal.unsubscribe();
        });
    };
    Step1Component.prototype.onScrollDown = function () {
        var _this = this;
        this.catalogListLoadedElement$.subscribe(function (total) {
            _this.totalItem = total;
            _this.catalogListRequest.offset = total;
        });
        this.catalogList$.subscribe(function (catalog) { return (_this.stopLoading = catalog.length === 0); });
        this.dispatchCatalogListAction(this.catalogListRequest);
    };
    Step1Component.prototype.showToaster = function () {
        this.toastrService.success(this.translate.instant('YOUR_BASKET_HAS_BEEN_UPDATED'));
        this.store.dispatch(new ResetAddItemStatusAction());
    };
    Step1Component.prototype.showToasterError = function () {
        this.toastrService.error(this.translate.instant('ERROR_CODE.undefined'));
    };
    Step1Component.prototype.addItem = function (barcode, qty) {
        this.currentBarcode = barcode;
        var addItemRequest = {
            barcode: this.currentBarcode,
            qty: qty,
            action: ItemActionTypeEnum.ADD,
            cartId: this.cart.cartId
        };
        if (qty) {
            this.store.dispatch(new AddItemRequestAction(addItemRequest));
        }
    };
    Step1Component.prototype.ngOnDestroy = function () {
        this.store.dispatch(new CatalogListResetAction());
    };
    Object.defineProperty(Step1Component.prototype, "productStatus", {
        get: function () {
            return ProductStatusEnum;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Step1Component.prototype, "checkDisableStatus", {
        get: function () {
            return CheckDisableStatus;
        },
        enumerable: true,
        configurable: true
    });
    return Step1Component;
}());
export { Step1Component };
