<div class="d-flex flex-wrap">
  <div class="col-12 col-md-8 col-lg-7">
    <app-item-list></app-item-list>
  </div>
  <div class="col-12 col-md-4 col-lg-5">
    <!-- your info here -->
    <accordion>
      <!-- your shipping info here -->
      <accordion-group [isDisabled]="true" [isOpen]="true">
        <h6 accordion-heading>
          {{ 'SHIPPING_INFORMATION' | translate }}
        </h6>
        <div class="row">
          <div id="store-name" class="col-12 info-row">
            <em class="fa fa-map-marker-alt d-none d-lg-block col-lg-1 text-danger"></em>
            <strong class="float-right float-lg-left">
              {{ (tdStore$ | async)?.name }}
            </strong>
          </div>
        </div>
        <div class="row">
          <div id="store-address" class="col-12 info-row">
            <strong class="text-right text-lg-left float-right float-lg-left">
              {{ storeAddress }}
            </strong>
          </div>
        </div>
        <div class="row">
          <div id="store-tel" class="col-12 info-row">
            <em class="fa fa-phone fa-flip-horizontal d-none d-lg-block col-lg-1 text-danger"></em>
            <strong class="float-right float-lg-left">
              {{ storeContactNumber }}
            </strong>
          </div>
        </div>
        <div class="row">
          <div class="col-12 info-row">
            <em class="fa fa-file-alt d-none d-lg-block col-lg-1 text-danger"></em>
            <strong class="float-right float-lg-left">
              {{ 'ADDITIONAL_NOTE' | translate }}
            </strong>
          </div>
          <div id="store-noted" class="col-12 info-row border-secondary">
            <textarea
              #additionalNote
              id="additionalNote"
              name="additionalNote"
              maxlength="250"
              value=""
              rows="4"
              class="w-100"
              placeholder="{{ 'ENTER_INFORMATION' | translate }}"
              (change)="Onchange($event.target)"
            ></textarea>
          </div>
        </div>
      </accordion-group>
      <!-- your payment info here -->
      <accordion-group [isDisabled]="true" [isOpen]="true">
        <h6 accordion-heading>
          {{ 'PAYMENT_INFORMATION' | translate }}
        </h6>
        <div class="row text-danger">
          <div id="total-amount-payable" class="col-12 info-row">
            <em class="fa fa-shopping-basket d-none d-lg-block col-lg-1"></em>
            <strong class="float-right float-lg-left">
              {{ 'TOTAL_AMOUNT_PAYABLE' | translate }}
            </strong>
            <br class=" d-md-none d-block" />
            <strong class="float-right">
              {{ (orderSummary$ | async)?.amount.amount | number: '1.2-2' }}
              {{ (orderSummary$ | async)?.amount.currency | translate }}
            </strong>
          </div>
        </div>
        <div class="row">
          <div id="total-item" class="col-12 info-row">
            <div class="float-right float-lg-left">
              {{ 'TOTAL_ITEM' | translate }}
            </div>
            <br class=" d-md-none d-block" />
            <div class="float-right">
              {{ (orderSummary$ | async)?.numberOfItems | number }}
              {{ 'ITEMS' | translate }}
            </div>
          </div>
        </div>
        <div class="row">
          <div id="total-including-vat" class="col-12 info-row mb-0">
            <div class="float-right float-lg-left">
              {{ 'TOTAL_INCLUDING_VAT' | translate }}
            </div>
            <br class=" d-md-none d-block" />
            <div class="float-right">
              {{ (orderSummary$ | async)?.amount.amount | number: '1.2-2' }}
              {{ (orderSummary$ | async)?.amount.currency | translate }}
            </div>
          </div>
        </div>
      </accordion-group>
    </accordion>
  </div>
</div>
