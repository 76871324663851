import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ItemActionTypeEnum } from '../../enum/item-action.enum';
import { CartsService, CatalogsService } from '../../services';
import { AddItemErrorAction, AddItemResponseAction, CartsActionTypes, CreateCartErrorAction, CreateCartResponseAction, GetCartErrorAction, GetCartResponseAction, OrdersSummaryErrorAction, OrdersSummaryResponseAction, SubmitOrderErrorAction, SubmitOrderResponseAction } from '../actions/carts.action';
var CartsEffects = /** @class */ (function () {
    function CartsEffects(actions, ordersService, catalogService, router, logger) {
        var _this = this;
        this.actions = actions;
        this.ordersService = ordersService;
        this.catalogService = catalogService;
        this.router = router;
        this.logger = logger;
        this.OrderSummary = this.actions.pipe(ofType(CartsActionTypes.ORDER_SUMMARY_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect Order Summary Request: ' + _this.stringify(payload));
            return _this.ordersService.orderSummary(payload.storeNo).pipe(map(function (resp) {
                _this.logger.debug('@Effect Order Summary Response: ' + _this.stringify(resp));
                return new OrdersSummaryResponseAction(resp);
            }), catchError(function (err) {
                return of(new OrdersSummaryErrorAction(err.error));
            }));
        }));
        this.CreateCart = this.actions.pipe(ofType(CartsActionTypes.CREATE_CARTS_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect Create Cart Request: ' + _this.stringify(payload));
            return _this.ordersService.createCart(payload.storeNo).pipe(map(function (resp) {
                _this.logger.debug('@Effect Create Cart Response: ' + _this.stringify(resp));
                return new CreateCartResponseAction({
                    storeNo: payload.storeNo,
                    cartId: resp.id
                });
            }), catchError(function (err) {
                return of(new CreateCartErrorAction(err.error));
            }));
        }));
        this.AddItem = this.actions.pipe(ofType(CartsActionTypes.ADD_ITEM_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            if (payload.action === ItemActionTypeEnum.UPDATE) {
                _this.logger.debug('@Effect Update Item Request: ' + _this.stringify(payload));
                return _this.ordersService.updateItem(payload).pipe(map(function (resp) {
                    _this.logger.debug('@Effect Update Item Response: ' + _this.stringify(resp));
                    return new AddItemResponseAction(resp);
                }), catchError(function (err) {
                    return of(new AddItemErrorAction(tslib_1.__assign({}, err.error, { barcode: payload.barcode, qty: payload.qty })));
                }));
            }
            else {
                _this.logger.debug('@Effect Add Item Request: ' + _this.stringify(payload));
                return _this.ordersService.addItem(payload).pipe(map(function (resp) {
                    _this.logger.debug('@Effect Add Item Response: ' + _this.stringify(resp));
                    return new AddItemResponseAction(resp);
                }), catchError(function (err) {
                    return of(new AddItemErrorAction(tslib_1.__assign({}, err.error, { barcode: payload.barcode, qty: payload.qty })));
                }));
            }
        }));
        this.getCart = this.actions.pipe(ofType(CartsActionTypes.GET_CART), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect Get Cart Request: ' + _this.stringify(payload));
            return _this.ordersService.getCart(payload.cartId).pipe(map(function (resp) {
                _this.logger.debug('@Effect Get Cart Response: ' + _this.stringify(resp));
                return new GetCartResponseAction(resp);
            }), catchError(function (err) {
                return of(new GetCartErrorAction(err.error));
            }));
        }));
        this.deleteItem = this.actions.pipe(ofType(CartsActionTypes.DELETE_CART_ITEM_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect Delete Cart Item Request: ' + _this.stringify(payload));
            return _this.ordersService.deleteItem(payload).pipe(map(function (resp) {
                _this.logger.debug('@Effect Delete Cart Item Response: ' + _this.stringify(resp));
                return new AddItemResponseAction(resp);
            }), catchError(function (err) {
                return of(new AddItemErrorAction(tslib_1.__assign({}, err.error, { barcode: payload.barcode })));
            }));
        }));
        this.deleteInvalidItems = this.actions.pipe(ofType(CartsActionTypes.DELETE_INVALID_ITEMS_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect Delete Invalid Items Request: ' + _this.stringify(payload));
            return _this.ordersService.deleteInvalidItems(payload).pipe(map(function (resp) {
                _this.logger.debug('@Effect Delete Invalid Items Response: ' + _this.stringify(resp));
                return new AddItemResponseAction(resp);
            }), catchError(function (err) {
                return of(new AddItemErrorAction(tslib_1.__assign({}, err.error)));
            }));
        }));
        this.submitOrder = this.actions.pipe(ofType(CartsActionTypes.SUBMIT_ORDER_REQUEST), 
        // TODO Remove i f Backend decrypt token themselves
        // withLatestFrom(this.store$.select(state => state.userInfo)),
        // switchMap(([action, userInfoState]: [SubmitOrderRequestAction, UserInfoState]) => {
        map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect Submit Order Request: ' + _this.stringify(payload));
            return _this.ordersService.submitOrder(payload).pipe(map(function (resp) {
                _this.logger.debug('@Effect Submit Order Response: ' + _this.stringify(resp));
                return new SubmitOrderResponseAction(resp);
            }), catchError(function (err) {
                return of(new SubmitOrderErrorAction(err.error));
            }));
        }));
    }
    CartsEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], CartsEffects.prototype, "OrderSummary", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], CartsEffects.prototype, "CreateCart", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], CartsEffects.prototype, "AddItem", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], CartsEffects.prototype, "getCart", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], CartsEffects.prototype, "deleteItem", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], CartsEffects.prototype, "deleteInvalidItems", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], CartsEffects.prototype, "submitOrder", void 0);
    return CartsEffects;
}());
export { CartsEffects };
