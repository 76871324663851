import { Permission } from './permission.model';

export class Role {
  id: string;
  version: string;
  name: string;
  code: string;
  permissions: Array<Permission>;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;

  constructor(data?: Role) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
