import { registerLocaleData } from '@angular/common';
// import ngx-translate and the http loader
import { HttpClient } from '@angular/common/http';
import { default as localeThExtra } from '@angular/common/locales/extra/th';
import { default as localeTh } from '@angular/common/locales/th';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppInitService } from './shared/services/app-init.service';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
// required for AOT compilation
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
export function InitializeAppFactory(appInitService) {
    return appInitService.load();
}
registerLocaleData(localeTh, 'th', localeThExtra);
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
// TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
// noinspection AngularInvalidImportedOrDeclaredSymbol
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
