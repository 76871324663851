<nav id="sidebar" class="sidebar">
  <div class="sidebar-content">
    <perfect-scrollbar>
      <div class="sidebar-menu">
        <ul id="side-menu">
          <li
            *ngFor="let menu of menus"
            [ngClass]="{
              active: menu.active,
              'sidebar-dropdown': menu.type === 'dropdown',
              'header-menu': menu.type === 'header'
            }"
            routerLinkActive="active-link"
          >
            <span *ngIf="menu.type === 'header'">{{ 'MENU.' + menu.title | translate }}</span>
            <a *ngIf="menu.type === 'simple'" routerLink="{{ menu.link }}" [routerLink]="[menu.link]">
              <em class="{{ menu.icon }}"></em>
              <span>{{ 'MENU.' + menu.title | translate }}</span>
              <!--<span *ngIf="menu.badge" class-markup="badge badge-pill" [ngClass]="menu.badge.class-markup">{{menu.badge.text}}</span>-->
            </a>
            <a *ngIf="menu.type === 'dropdown'" (click)="toggle(menu)">
              <em class="{{ menu.icon }}"></em>
              <span>{{ 'MENU.' + menu.title | translate }}</span>
              <!--<span *ngIf="menu.badge" class-markup="badge badge-pill" [ngClass]="menu.badge.class-markup">{{menu.badge.text}}</span>-->
            </a>
            <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)">
              <ul id="side-submenu">
                <li *ngFor="let submenu of menu.submenus" routerLinkActive="active-link">
                  <a [routerLink]="[menu.link, submenu.link]">
                    <em class="submenu fa fa-circle"></em>
                    {{ 'MENU.' + submenu.title | translate }}
                    <!--<span *ngIf="submenu.badge" class-markup="badge badge-pill" [ngClass]="submenu.badge.class-markup">{{submenu.badge.text}}</span>-->
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>
</nav>
