import { UserInfo } from '../../models/auth.model';

export class UserInfoState extends UserInfo {}

export const UserInfoInitialState: UserInfoState = {
  userNo: '',
  userName: '',
  userCode: '',
  firstName: '',
  lastName: null,
  localFirstName: '',
  localLastName: '',
  email: '',
  mobileNo: '',
  roles: [],
  authorities: [],
  forceChangePassword: false,
  forceChangePinCode: false,
  profileImageName: '',
  merchantName: '',
  merchantNo: '',
  displayName: ''
};
