/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./pos.component";
import * as i5 from "ngx-cookie-service";
import * as i6 from "@ngrx/store";
var styles_PosComponent = [i0.styles];
var RenderType_PosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PosComponent, data: {} });
export { RenderType_PosComponent as RenderType_PosComponent };
export function View_PosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cookies works! "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "btn btn-success "], ["id", "btn-go"], ["routerLink", "/home"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" =", "=\n"]))], function (_ck, _v) { var currVal_1 = "/home"; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.cookies === "{}"); _ck(_v, 4, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("NEXT_STEP")); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.cookies; _ck(_v, 9, 0, currVal_3); }); }
export function View_PosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pos", [], null, null, null, View_PosComponent_0, RenderType_PosComponent)), i1.ɵdid(1, 114688, null, 0, i4.PosComponent, [i5.CookieService, i2.Router, i2.ActivatedRoute, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PosComponentNgFactory = i1.ɵccf("app-pos", i4.PosComponent, View_PosComponent_Host_0, {}, {}, []);
export { PosComponentNgFactory as PosComponentNgFactory };
