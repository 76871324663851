import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models/error.model';

export enum ForgotPasswordActionTypes {
  FORGOT_PASSWORD_OTP_REQUEST = '[Forgot Password] Forgot Password OTP Request',
  FORGOT_PASSWORD_OTP_SUCCESS = '[Forgot Password] Forgot Password OTP Success',
  FORGOT_PASSWORD_OTP_FAILED = '[Forgot Password] Forgot Password OTP Failed',
  FORGOT_PASSWORD_OTP_TIMEOUT_REQUEST = '[Forgot Password] Forgot Password OTP Timeout Request',
  FORGOT_PASSWORD_VERIFY_OTP_REQUEST = '[Forgot Password] Forgot Password Verify OTP Request',
  FORGOT_PASSWORD_VERIFY_OTP_SUCCESS = '[Forgot Password] Forgot Password Verify OTP Success',
  FORGOT_PASSWORD_VERIFY_OTP_FAILED = '[Forgot Password] Forgot Password Verify OTP Failed',
  FORGOT_PASSWORD_CHANGE_PASSWORD_REQUEST = '[Forgot Password] Forgot Password Change Password Request',
  FORGOT_PASSWORD_CHANGE_PASSWORD_SUCCESS = '[Forgot Password] Forgot Password Change Password Success',
  FORGOT_PASSWORD_CHANGE_PASSWORD_FAILED = '[Forgot Password] Forgot Password Change Password Failed',
  FORGOT_PASSWORD_RESET = '[Forgot Password] Forgot Password Reset'
}

export class ForgotPasswordOTPRequestAction implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_OTP_REQUEST;

  constructor(public payload: string) {}
}

export class ForgotPasswordOTPSuccess implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_OTP_SUCCESS;

  constructor(public payload: { data: any }) {}
}

export class ForgotPasswordOTPFailed implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_OTP_FAILED;

  constructor(public payload: { error: ErrorResponse; userName: string }) {}
}

export class ForgotPasswordOTPTimeoutRequestAction implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_OTP_TIMEOUT_REQUEST;

  constructor() {}
}

export class ForgotPasswordVerifyOTPRequestAction implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_VERIFY_OTP_REQUEST;

  constructor(public payload: { userName: string; otp: string }) {}
}

export class ForgotPasswordVerifyOTPSuccess implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_VERIFY_OTP_SUCCESS;

  constructor(public payload: { data: any }) {}
}

export class ForgotPasswordVerifyOTPFailed implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_VERIFY_OTP_FAILED;

  constructor(public payload: { error: ErrorResponse; userName: string }) {}
}

export class ForgotPasswordChangePasswordRequestAction implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_REQUEST;

  constructor(public payload: { userName: string; newPassword: string; otp: string }) {}
}

export class ForgotPasswordChangePasswordSuccess implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_SUCCESS;

  constructor(public payload: { data: any }) {}
}

export class ForgotPasswordChangePasswordFailed implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_FAILED;

  constructor(public payload: { error: ErrorResponse; userName: string }) {}
}

export class ForgotPasswordReset implements Action {
  readonly type = ForgotPasswordActionTypes.FORGOT_PASSWORD_RESET;

  constructor() {}
}

export type ForgotPasswordActions =
  | ForgotPasswordOTPRequestAction
  | ForgotPasswordOTPSuccess
  | ForgotPasswordOTPFailed
  | ForgotPasswordOTPTimeoutRequestAction
  | ForgotPasswordVerifyOTPRequestAction
  | ForgotPasswordVerifyOTPSuccess
  | ForgotPasswordVerifyOTPFailed
  | ForgotPasswordChangePasswordRequestAction
  | ForgotPasswordChangePasswordSuccess
  | ForgotPasswordChangePasswordFailed
  | ForgotPasswordReset;
