<div class="full-popup-wrap">
  <div class="full-popup-head" [hidden]="!title">
    <div class="full-popup-title float-left">{{ title }}</div>
    <div class="full-popup-close float-right">
      <a aria-label="Close" class="close" id="full-modal-closeBtn" (click)="confirmModal()">
        <em class="fa fa-times"></em>
      </a>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="full-popup-inner">
    <ng-template appChildHost></ng-template>
  </div>
</div>
