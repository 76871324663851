import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.error && err.error.code) {
          err.error.translateKey = `ERROR_CODE.${err.error.code}`;
        } else {
          const error = {
            translateKey: null
          };
          error.translateKey = 'ERROR_CODE.undefined';
          err.error = error;
        }

        return throwError(err);
      })
    );
  }
}
