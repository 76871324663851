import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { ModalButtonResponseEnum } from '../../../enum/modal-button-response.enum';
import { BaseModalComponent } from '../../../models/base-modal.component.model';
import { ConfirmModal } from '../../../models/confirm-modal.mode';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements ConfirmModal, OnInit, BaseModalComponent {
  public title: string;
  public cancelText: string;
  public okText: string;
  public message: string;
  public routerLink?: string;
  public action: Subject<ModalButtonResponseEnum>;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.action = new Subject();
    this.title = this.title || this.translate.instant('LEAVE');
    this.cancelText = this.cancelText || this.translate.instant('CANCEL');
    this.okText = this.okText || this.translate.instant('OK');
    this.message = this.message || this.translate.instant('CONFIRM_LEAVE');
  }

  confirm(): void {
    this.action.next(ModalButtonResponseEnum.OK);
    this.bsModalRef.hide();

    if (this.routerLink) {
      this.router.navigateByUrl(this.routerLink);
    }
  }

  decline(): void {
    this.action.next(ModalButtonResponseEnum.CANCEL);
    this.bsModalRef.hide();
  }
}
