import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import Stepper from 'bs-stepper';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { FilterPipe } from 'ngx-filter-pipe';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { filter, map, tap } from 'rxjs/operators';
import { ClientIdTypeEnum } from '../../shared/enum/client-id.enum';
import { ModalButtonResponseEnum } from '../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../shared/enum/notification-type.enum';
import { AlertModalComponent } from '../../shared/layouts';
import { ConfirmModalComponent } from '../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { Amount } from '../../shared/models/amount.model';
import { ItemDetail } from '../../shared/models/cart.model';
import { ConfirmModal } from '../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../shared/models/notification-emit.model';
import { OrderSummary } from '../../shared/models/orders.model';
import { MinOrder, TdStore } from '../../shared/models/td-store.model';
import { DecimalProxyPipe } from '../../shared/pipe/decimal-proxy/decimal-proxy.pipe';
import { PosService } from '../../shared/services/pos.service';
import {
  GetCartAction,
  SubmitOrderRequestAction,
  SubmitOrderResetStatusAction
} from '../../shared/store/actions/carts.action';
import { TdStoreGetAction } from '../../shared/store/actions/td-store.action';
import { selectAddItemResult, selectCartItemResult } from '../../shared/store/selectors/cart.selector';
import { selectClientIdResult } from '../../shared/store/selectors/client-id.selector';
import { selectOrderSummaryResult } from '../../shared/store/selectors/order-summary.selector';
import { selectSubmitOrderResult } from '../../shared/store/selectors/submit-order.selector';
import { selectTdStoreResult } from '../../shared/store/selectors/td-store.selector';
import { AppStates } from '../../shared/store/state/app.states';
import { CheckDisableStatus } from '../../shared/utils/check-disable-status';
import { Step2DataOut } from './step2/step2.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { storeNo: string; title: string };
  @ViewChild('nextStep', { static: true }) nextStepEl: ElementRef;
  @ViewChild('orderStepper', { static: true }) orderStepper: ElementRef;

  public deliverySchedule: Observable<string>;
  public orderSchedule: Observable<string>;
  public storeName: Observable<string>;
  public step2Data: Step2DataOut;

  private stepper: Stepper;
  private weekdaysShort: Array<string>;
  private minOrder: MinOrder;
  private orderAmount: Amount;
  private cartId: string;
  private itemList: ItemDetail[];
  private tdStore: TdStore;
  private clientId: ClientIdTypeEnum | null;
  private localStore: Observable<any>;

  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store<AppStates>,
    private readonly modalService: BsModalService,
    private filterPipe: FilterPipe,
    private readonly logger: NGXLogger,
    private pos: PosService
  ) {
    this.weekdaysShort = moment
      .localeData('en')
      .weekdaysShort()
      .map(value => value.toUpperCase());
  }

  ngOnInit() {
    this.stepper = new Stepper(this.orderStepper.nativeElement, {
      linear: true,
      animation: true
    });
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initState();
    this.initControl();

    this.pos.goto('step1');
    this.step2Data = new Step2DataOut();
  }

  ngOnDestroy(): void {
    this.pos.goto('summary');
  }

  initState() {
    this.localStore.pipe(select(selectClientIdResult)).subscribe(clientId => (this.clientId = clientId));
    this.localStore
      .pipe(
        select(selectOrderSummaryResult),
        map(data => data.result.response),
        filter(Boolean)
      )
      .subscribe((response: OrderSummary) => {
        this.nextStepEl.nativeElement.disabled = response.numberOfItems === 0;
        this.orderAmount = response.amount;
      });

    this.localStore
      .pipe(
        select(selectTdStoreResult),
        map(value => value.result.response),
        filter(Boolean)
      )
      .subscribe((tdStore: TdStore) => {
        this.deliverySchedule = of(
          this.daySchedule(tdStore.orderSchedule.schedules.map(schedule => schedule.deliverySchedule))
        );
        this.orderSchedule = of(
          this.daySchedule(tdStore.orderSchedule.schedules.map(schedule => schedule.orderScheduleDate))
        );
        this.storeName = of(tdStore.name);
        this.minOrder = { amount: tdStore.orderSchedule.minOrder, currency: 'THB' };
        this.tdStore = tdStore;
      });

    this.localStore.pipe(select(selectAddItemResult)).subscribe(cart => {
      if (cart.response) {
        this.cartId = cart.response.id;
        this.itemList = cart.response.items;
      }
    });
    this.localStore.pipe(select(selectSubmitOrderResult)).subscribe(result => {
      if (result.submitOrderSuccess === false && result.result.errorResponse) {
        this.alertUnsuccessfulSubmitOrder();
      } else if (result.submitOrderSuccess && result.result.response) {
        this.pos.orderComplete(result.result.response.poNumber);
        this.alertSuccessfulSubmitOrder();
      }
    });

    this.localStore
      .pipe(
        select(selectCartItemResult),
        map(cartResult => cartResult.response),
        filter(response => !response || !response.items.length)
      )
      .subscribe(() => {
        this.stepper.to(1);
      });
  }

  initControl() {
    this.nextStepEl.nativeElement.disabled = true;
  }

  daySchedule(inputDays: string[]) {
    return this.isEveryDay(inputDays)
      ? this.translateSchedule('EVERY_DAY')
      : inputDays.map(day => this.translateSchedule(day)).join(', ');
  }

  isEveryDay(inputDays: string[]): boolean {
    const amountDay = this.weekdaysShort.filter(day => inputDays.indexOf(day) >= 0);

    return amountDay.length === 7;
  }

  onNext() {
    this.stepper.next();
    this.onPosStepEvent();
  }

  onBack() {
    this.stepper.previous();
    this.onPosStepEvent();
  }

  onPosStepEvent() {
    for (let i = 0; i < this.orderStepper.nativeElement.getElementsByClassName('step').length; i++) {
      if (this.orderStepper.nativeElement.getElementsByClassName('step')[i].className.match('active')) {
        this.pos.goto(`step${i + 1}`);
        break;
      }
    }
  }

  translateSchedule(day: string) {
    this.translate.get(`SCHEDULE.${day.toLocaleUpperCase()}`).subscribe(translated => {
      day = translated;
    });

    return day;
  }

  onCancel() {
    const initialState: ConfirmModal = {
      title: this.translate.instant('CONFIRM'),
      okText: this.translate.instant('LEAVE'),
      cancelText: this.translate.instant('CANCEL'),
      message: this.translate.instant('CONFIRM_LEAVE_ORDER_PROCESS'),
      closeMode: ModalButtonResponseEnum.OK
    };

    this.notifyParent.emit({
      initialState: initialState,
      notificationType: NotificationTypeEnum.CONFIRM,
      result: null
    });
  }

  onStep2DataOut(step2DataOut: Step2DataOut) {
    this.step2Data = step2DataOut;
  }

  submitOrder() {
    const isInvalidItem: [] = this.filterPipe.transform(this.itemList, {
      productStatus: { $or: this.checkDisableStatus.invalidItemStatus() }
    });
    if (isInvalidItem.length) {
      this.alertSelectInvalidItem();
    } else if (this.minOrder.amount > this.orderAmount.amount) {
      this.alertSelectMoreOrder(this.minOrder.amount, this.minOrder.currency);
    } else {
      this.confirmPayment(this.orderAmount.amount, this.orderAmount.currency);
    }
  }

  private alertSelectInvalidItem() {
    const initialState = {
      title: this.translate.instant('ALERT'),
      message: this.translate.instant('PLEASE_DELETE_INVALID_ITEM')
    };
    const alertModalRef = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        tap(() => this.resetOrderStatus())
      )
      .subscribe();
  }

  private alertSelectMoreOrder(minimumOrder: number, currency: string) {
    const initialState = {
      title: this.translate.instant('ALERT'),
      message: this.translate.instant('MINIMUM_ORDER_AMOUNT_IS_PLEASE_ADD_MORE_ITEM', {
        minimumOrder: new DecimalProxyPipe(this.translate).transform(minimumOrder, '1.2-2'),
        currency: this.translate.instant(currency)
      })
    };
    const alertModalRef = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModalRef.content.action
      .pipe(
        untilComponentDestroyed(this),
        filter(result => result === ModalButtonResponseEnum.OK),
        tap(() => this.resetOrderStatus())
      )
      .subscribe();
  }

  private resetOrderStatus() {
    this.store.dispatch(new SubmitOrderResetStatusAction());
    this.store.dispatch(new TdStoreGetAction(this.tdStore.storeProfile.storeNo));
    this.store.dispatch(new GetCartAction({ cartId: this.cartId }));
  }

  private alertUnsuccessfulSubmitOrder() {
    const initialState = {
      title: this.translate.instant('FAILED'),
      message: null
    };

    initialState.message = this.translate.instant('YOUR_PAYMENT_WAS_NOT_SUCCESSFULLY');

    const alertModalRef = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.store.dispatch(new SubmitOrderResetStatusAction());
        this.store.dispatch(new TdStoreGetAction(this.tdStore.storeProfile.storeNo));
        this.store.dispatch(new GetCartAction({ cartId: this.cartId }));
      }
    });
  }

  private alertSuccessfulSubmitOrder() {
    const initialState = {
      title: this.translate.instant('SUCCESSFUL'),
      message: this.translate.instant('YOUR_PAYMENT_WAS_SUCCESSFULLY')
    };

    const alertModalRef = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.store.dispatch(new SubmitOrderResetStatusAction());
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null });
      }
    });
  }

  private confirmPayment(totalAmount: number, currency: string) {
    const initialState = {
      title: this.translate.instant('CONFIRM'),
      message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_PAY_TOTAL_AMOUNT_PAYABLE_IS', {
        totalAmount: new DecimalProxyPipe(this.translate).transform(totalAmount, '1.2-2'),
        currency: this.translate.instant(currency)
      })
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.store.dispatch(
          new SubmitOrderRequestAction({ cart: this.cartId, additionalNote: this.step2Data.additionalNote })
        );
      }
    });
  }

  get checkDisableStatus() {
    return CheckDisableStatus;
  }
}
