<div class="d-flex justify-content-center">
  <div class="col-12">
    <div class=" border border-grey mobile-verification-form pb-5">
      <p class="text-center">{{ 'YOUR_VERIFICATION_WAS_COMPLETED' | translate }}</p>
      <div class="d-flex justify-content-center text-success pt-3">
        <em class="fa fa-check-circle icon-size"></em>
      </div>
    </div>
    <p class="text-center">{{ 'FOR_MORE_INFORMATION' | translate }}</p>
  </div>
</div>
