import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { BaseService } from './base.service';
var UserListService = /** @class */ (function (_super) {
    tslib_1.__extends(UserListService, _super);
    function UserListService(http, logger) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.logger = logger;
        _this.envService = _this.env.services.users;
        _this.headers = new HttpHeaders(_this.envService.headers);
        return _this;
    }
    UserListService.prototype.getUsers = function (usersRequest) {
        var params = this.getParams(usersRequest);
        return this.http.get(this.getUrl(), {
            headers: this.headers,
            observe: 'response',
            params: params
        });
    };
    return UserListService;
}(BaseService));
export { UserListService };
