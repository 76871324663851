<div class="modal-header">
  <h4 class="modal-title pull-left">{{ 'DUPLICATED_ITEM' | translate }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="col-md" [formGroup]="duplicateItemForm">
    <div class="row">
      {{ 'YOU_ADDED_THIS_ITEM_ALREADY_PLEASE_SPECIFY_YOUR_WANTED_QUANTITY_FOR_THIS_ITEM' | translate }}
    </div>
    <div class="row">
      <label for="amount">{{ 'QUANTITY' | translate }}</label>
      <div class="col-md-8 ml-2">
        <div class="inner-addon right-addon">
          <input
            type="text"
            maxlength="3"
            class="input-quantity form-control form-control-sm"
            [ngClass]="{ 'is-invalid': duplicateItemFormGetter.amount.errors }"
            id="amount"
            appNumbersOnly
            (keyup.enter)="updateItem(barcode)"
            formControlName="amount"
          />
          <div *ngIf="duplicateItemFormGetter.amount.errors" class="invalid-feedback" id="duplicate-input-error">
            <div *ngIf="duplicateItemFormGetter.amount.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    {{ 'CANCEL' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-success"
    [disabled]="duplicateItemFormGetter.amount.errors"
    (click)="updateItem(barcode)"
  >
    {{ 'OK' | translate }}
  </button>
</div>
