import { ErrorResponse } from '../../models/error.model';
import { UserListResponse } from '../../models/user-list.model';

export interface UserListState {
  usersList: {
    response: UserListResponse | null;
    error: ErrorResponse | null;
  };
}

export const initialUserListState: UserListState = {
  usersList: {
    response: {
      content: null,
      page: 0,
      size: 0,
      totalElements: 0,
      first: true,
      totalPages: 0,
      hasContent: false,
      last: true,
      numberOfElements: 0,
      pageable: null,
      empty: true
    },
    error: new ErrorResponse()
  }
};
