import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AlertModalComponent } from '../../layouts';
import { UsersService } from '../../services/users.service';
import { UsersActionTypes, UsersDeleteFailedAction, UsersDeleteSuccessAction, UsersFailedAction, UsersResetPasswordResponseAction, UsersResetPinCodeResponseAction, UsersSuccessAction } from '../actions/users.action';
var UsersEffects = /** @class */ (function () {
    function UsersEffects(actions, usersService, logger, modalService, translate) {
        var _this = this;
        this.actions = actions;
        this.usersService = usersService;
        this.logger = logger;
        this.modalService = modalService;
        this.translate = translate;
        this.UsersAdd$ = this.actions.pipe(ofType(UsersActionTypes.USERS_ADD), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect UsersAddAction: ' + _this.stringify(payload));
            return _this.usersService.addUser(payload).pipe(map(function (resp) {
                resp.updateSuccess = true;
                return new UsersSuccessAction(resp);
            }), catchError(function (err) { return of(new UsersFailedAction(err.error)); }));
        }));
        this.UsersEdit$ = this.actions.pipe(ofType(UsersActionTypes.USERS_EDIT), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect UsersEditAction: ' + _this.stringify(payload));
            return _this.usersService.editUser(payload).pipe(map(function (resp) {
                resp.updateSuccess = true;
                return new UsersSuccessAction(resp);
            }), catchError(function (err) { return of(new UsersFailedAction(err.error)); }));
        }));
        this.UsersGet$ = this.actions.pipe(ofType(UsersActionTypes.USERS_GET), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect UsersGetAction: ' + _this.stringify(payload));
            return _this.usersService.getUser(payload).pipe(switchMap(function (resp) { return of(new UsersSuccessAction(resp)); }), catchError(function (err) {
                var initialState = {
                    title: _this.translate.instant('FAILED'),
                    message: _this.translate.instant(err.error.translateKey)
                };
                _this.bsModalRef = _this.modalService.show(AlertModalComponent, {
                    initialState: initialState
                });
                return of(new UsersFailedAction(err.error));
            }));
        }));
        this.usersDelete$ = this.actions.pipe(ofType(UsersActionTypes.USERS_DELETE), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect UsersDeleteAction: ' + _this.stringify(payload));
            return _this.usersService.deleteUser(payload).pipe(map(function (resp) {
                return new UsersDeleteSuccessAction({
                    statusCode: resp.status.toString(),
                    message: resp.statusText
                });
            }), catchError(function (err) {
                var message = _this.translate.instant(err.error.translateKey);
                if (message === 'ErrorResponse.message') {
                    message = err.error.message;
                }
                var initialState = {
                    title: _this.translate.instant('FAILED'),
                    message: message
                };
                _this.bsModalRef = _this.modalService.show(AlertModalComponent, {
                    initialState: initialState
                });
                return of(new UsersDeleteFailedAction(err.error));
            }));
        }));
        this.usersSuccess$ = this.actions.pipe(ofType(UsersActionTypes.USERS_SUCCESS), tap(function (resp) {
            _this.logger.debug("@Effect " + UsersActionTypes.USERS_SUCCESS + ": " + _this.stringify(resp.payload));
        }));
        this.usersFailed$ = this.actions.pipe(ofType(UsersActionTypes.USERS_FAILED), tap(function (resp) {
            _this.logger.debug("@Effect " + UsersActionTypes.USERS_FAILED + ": " + _this.stringify(resp.payload));
        }));
        this.resetPassword$ = this.actions.pipe(ofType(UsersActionTypes.USERS_RESET_PASSWORD_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect Reset Password Request: ' + _this.stringify(payload));
            return _this.usersService.resetPassword(payload).pipe(map(function (resp) {
                _this.logger.debug('@Effect Reset Password Response: ' + _this.stringify(resp));
                return new UsersResetPasswordResponseAction({ statusCode: resp.status.toString(), message: resp.statusText });
            }), catchError(function (err) {
                return of(new UsersResetPasswordResponseAction({ statusCode: err.status.toString(), message: 'Reset Password Error' }));
            }));
        }));
        this.resetPinCode$ = this.actions.pipe(ofType(UsersActionTypes.USERS_RESET_PIN_CODE_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect Reset Pin Code Request: ' + _this.stringify(payload));
            return _this.usersService.resetPinCode(payload).pipe(map(function (resp) {
                _this.logger.debug('@Effect Reset Pin Code Response: ' + _this.stringify(resp));
                return new UsersResetPinCodeResponseAction({ statusCode: resp.status.toString(), message: resp.statusText });
            }), catchError(function (err) {
                return of(new UsersResetPinCodeResponseAction({ statusCode: err.status.toString(), message: 'Reset Pin Code Error' }));
            }));
        }));
    }
    UsersEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UsersEffects.prototype, "UsersAdd$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UsersEffects.prototype, "UsersEdit$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UsersEffects.prototype, "UsersGet$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UsersEffects.prototype, "usersDelete$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], UsersEffects.prototype, "usersSuccess$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], UsersEffects.prototype, "usersFailed$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UsersEffects.prototype, "resetPassword$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], UsersEffects.prototype, "resetPinCode$", void 0);
    return UsersEffects;
}());
export { UsersEffects };
