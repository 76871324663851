import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { UserInfoState } from '../state/user-info.state';

const selectUserInformation = (state: AppStates) => state.userInfo;

export const selectUserInformationResult = createSelector(
  selectUserInformation,
  (state: UserInfoState) => state
);
