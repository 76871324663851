/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-verification-invalid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./merchant-verification-invalid.component";
var styles_MerchantVerificationInvalidComponent = [i0.styles];
var RenderType_MerchantVerificationInvalidComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantVerificationInvalidComponent, data: {} });
export { RenderType_MerchantVerificationInvalidComponent as RenderType_MerchantVerificationInvalidComponent };
export function View_MerchantVerificationInvalidComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", " border border-grey mobile-verification-form pb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "d-flex justify-content-center text-danger pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "em", [["class", "fa fa-exclamation-circle icon-size"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("YOUR_VERIFICATION_WAS_COMPLETED")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("FOR_MORE_INFORMATION")); _ck(_v, 9, 0, currVal_1); }); }
export function View_MerchantVerificationInvalidComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-verification-invalid", [], null, null, null, View_MerchantVerificationInvalidComponent_0, RenderType_MerchantVerificationInvalidComponent)), i1.ɵdid(1, 114688, null, 0, i3.MerchantVerificationInvalidComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantVerificationInvalidComponentNgFactory = i1.ɵccf("app-merchant-verification-invalid", i3.MerchantVerificationInvalidComponent, View_MerchantVerificationInvalidComponent_Host_0, {}, {}, []);
export { MerchantVerificationInvalidComponentNgFactory as MerchantVerificationInvalidComponentNgFactory };
