import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { map, tap } from 'rxjs/operators';
import { ChildItem } from '../../shared/layouts/modals/child-item/child-item';
import { FullModalComponent } from '../../shared/layouts/modals/full-modal/full-modal.component';
import { CreateCartRequestAction } from '../../shared/store/actions/carts.action';
import { TdStoreListGetAction } from '../../shared/store/actions/td-store-list.action';
import { ResetTdStoreState, TdStoreGetAction } from '../../shared/store/actions/td-store.action';
import { selectClientIdResult } from '../../shared/store/selectors/client-id.selector';
import { selectTdStoreByUserResult } from '../../shared/store/selectors/td-store-list.selector';
import { selectTdStoreResult } from '../../shared/store/selectors/td-store.selector';
import { selectTenantInfoResult } from '../../shared/store/selectors/tenant-info.selector';
import { OrdersComponent } from '../orders/orders.component';
var SummaryComponent = /** @class */ (function () {
    function SummaryComponent(store, translate, modalService, formBuilder, activatedRoute) {
        this.store = store;
        this.translate = translate;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
    }
    SummaryComponent.prototype.ngOnInit = function () {
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.initState();
    };
    SummaryComponent.prototype.initState = function () {
        var _this = this;
        this.storeList$ = this.localStore.pipe(select(selectTdStoreByUserResult));
        this.tenantInfo$ = this.localStore.pipe(select(selectTenantInfoResult));
        this.tdStore$ = this.localStore.pipe(select(selectTdStoreResult));
        this.localStore
            .pipe(select(selectClientIdResult), tap(function (clientId) { return (_this.clientId = clientId); }))
            .subscribe();
        this.tenantInfo$
            .pipe(tap(function (tenantInfo) { return _this.store.dispatch(new TdStoreListGetAction({ merchantNo: tenantInfo.merchant })); }))
            .subscribe();
        this.selectStoreForm = this.formBuilder.group({ store: null });
        this.storeList$
            .pipe(map(function (data) {
            if (data.result.response && data.result.response.content) {
                return data.result.response.content;
            }
        }), tap(function (merchants) {
            if (merchants) {
                _this.storeList = new Array();
                if (_this.isSpecifiedStoreNo) {
                    merchants
                        .filter(function (merchant) { return merchant.no === _this.specifiedStoreNo; })
                        .forEach(function (merchant) { return _this.storeList.push({ name: merchant.name, no: merchant.no }); });
                }
                else {
                    merchants.forEach(function (merchant) { return _this.storeList.push({ name: merchant.name, no: merchant.no }); });
                }
                if (_this.isSingleStore) {
                    var storeNo = _this.storeList[0].no;
                    _this.selectStoreForm.get('store').setValue(storeNo);
                    _this.selectStoreForm.controls.store.disable();
                    _this.selectedStore();
                }
            }
        }))
            .subscribe();
    };
    SummaryComponent.prototype.selectedStore = function () {
        this.store.dispatch(new ResetTdStoreState());
        this.store.dispatch(new TdStoreGetAction(this.selectStoreForm.get('store').value));
    };
    SummaryComponent.prototype.createCart = function () {
        var initialState = {
            title: null,
            childItem: new ChildItem(OrdersComponent, {
                storeNo: this.selectStoreForm.get('store').value,
                title: this.translate.instant('ORDER_PROCESS')
            })
        };
        this.store.dispatch(new CreateCartRequestAction({ storeNo: this.selectStoreForm.get('store').value }));
        this.bsModalRef = this.modalService.show(FullModalComponent, {
            animated: false,
            backdrop: false,
            initialState: initialState
        });
    };
    Object.defineProperty(SummaryComponent.prototype, "isSpecifiedStoreNo", {
        get: function () {
            return this.specifiedStoreNo !== null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SummaryComponent.prototype, "specifiedStoreNo", {
        get: function () {
            return this.activatedRoute.snapshot.paramMap.get('storeNo');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SummaryComponent.prototype, "isSingleStore", {
        get: function () {
            return this.storeList.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    SummaryComponent.prototype.ngOnDestroy = function () { };
    return SummaryComponent;
}());
export { SummaryComponent };
