<div class="container-fluid admin-form">
  <h4>{{ 'VIEW_USER_LIST' | translate }}</h4>
  <form [formGroup]="searchAndFilterForm" class="mt-md-4 mt-sm-2 flex-container flex-column" novalidate>
    <div class="flex-item d-md-flex justify-content-between">
      <div class="form-group">
        <button
          type="button"
          class="btn btn-primary col-md col-sm-12"
          *hasPermission="['user_m']"
          (click)="callAddUser()"
        >
          <em class="fa fa-plus"></em> {{ 'ADD_USER' | translate }}
        </button>
      </div>

      <div class="flex-container flex-fill justify-content-end">
        <div class="search-box flex-item form-group pl-md-2">
          <div class="input-group position-relative">
            <input
              formControlName="searchText"
              type="text"
              class="form-control"
              (keyup.enter)="handleSearchText()"
              [maxLength]="50"
              placeholder="{{ 'ENTER_KEYWORD_SEARCH' | translate }}"
            />
            <em
              class="fa fa-times-circle icon-cross"
              [ngClass]="{ 'd-none': !searchAndFilterForm.value.searchText }"
              (click)="clearSearchText()"
            ></em>
            <div class="input-group-append">
              <button
                class="btn search-button btn-nicon btn-search"
                type="button"
                (click)="handleSearchText()"
              ></button>
            </div>
          </div>
        </div>
        <div class="dropdown flex-item form-group pl-md-2">
          <ng-select
            [searchable]="false"
            id="status"
            [clearable]="false"
            (change)="selectedStatus($event)"
            formControlName="status"
          >
            <ng-option *ngFor="let status of userStatus" [value]="status.value">
              {{ status.label }}
            </ng-option>
          </ng-select>
        </div>
        <div class="text-nowrap form-group pl-md-2">
          <button
            class="btn btn-block advance-filter-button btn-advance-filter "
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="fa nicon icon-filter"></em>
            {{ 'ADVANCE_FILTER' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter, .is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="advance-filter-header clearfix">
                <h6 class="float-left ml-3">{{ 'ADVANCE_FILTER' | translate }}</h6>
                <a class="float-right mr-4" (click)="onClickedOutside($event)">
                  <em class="fa fa-times"></em>
                </a>
              </div>
              <div class="box-dropdown-body">
                <div class="container mt-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="roles">{{ 'ROLE' | translate }}</label>
                        <ng-select
                          [items]="role$ | async"
                          (change)="selectedRoles()"
                          [multiple]="true"
                          [searchable]="false"
                          [closeOnSelect]="false"
                          id="roles"
                          placeholder="{{ 'PLEASE_SELECT' | translate }}"
                          formControlName="selectedRoles"
                          bindValue="code"
                        >
                          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value" *ngFor="let item of items">
                              <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
                              <span class="ng-value-label">{{ item.name | translate }}</span>
                            </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item">
                            <span>{{ item.name | translate }}</span>
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-right">
                <button class="btn btn-outline-primary mr-2" (click)="onClickedOutside($event)">
                  {{ 'CANCEL' | translate }}
                </button>
                <button class="btn btn-primary" (click)="handleAdvanceFilter()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowSearchTag" class="top-search-criteria space-search-criteria">
      <div *ngFor="let role of searchAndFilterForm.get('selectedRoles').value; index as i" class="search-criteria">
        <span>{{ 'ROLE' | translate }} : {{ role | translate }}</span>
        <a class="remove-criteria" (click)="handleRemoveRole(i)">
          <em class="fa fa-times"></em>
        </a>
      </div>
      <div class="d-inline-block">
        <a class="btn clear-btn" (click)="clearAllRoles()">Clear All</a>
      </div>
    </div>
  </form>

  <div class="clearfix">
    <div class="no-result-container" *ngIf="(usersList$ | async).empty">
      <div class="d-flex justify-content-md-center">
        <img width="240" height="240" src="assets/images/not-found.svg" alt="not found" />
      </div>
    </div>
    <div class="table-wrapper">
      <table
        id="datatable"
        class="table table-striped table-bordered"
        cellspacing="0"
        width="100%"
        *ngIf="(usersList$ | async).content && (usersList$ | async).hasContent as user"
      >
        <thead>
          <tr>
            <th>{{ headerRow[0] | translate }}</th>
            <th>{{ headerRow[1] | translate }}</th>
            <th>{{ headerRow[2] | translate }}</th>
            <th>{{ headerRow[3] | translate }}</th>
            <th>{{ headerRow[4] | translate }}</th>
            <th>{{ headerRow[5] | translate }}</th>
            <th class="disabled-sorting" *hasPermission="['user_m']">
              {{ headerRow[6] | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of (usersList$ | async).content" class="pointer">
            <td (click)="callEditUser(user.no, pageMode.REQUEST_VIEW)">{{ user.userCode }}</td>
            <td (click)="callEditUser(user.no, pageMode.REQUEST_VIEW)">{{ user.fullName }}</td>
            <td (click)="callEditUser(user.no, pageMode.REQUEST_VIEW)">{{ user.userName }}</td>
            <td (click)="callEditUser(user.no, pageMode.REQUEST_VIEW)">{{ user.roles[0] | translate }}</td>
            <td
              *ngIf="user.lastSuccessLoginDate != null; else neverLogin"
              (click)="callEditUser(user.no, pageMode.REQUEST_VIEW)"
            >
              {{ user.lastSuccessLoginDate | dateFormat | date: 'medium' | amLocal | amTimeAgo }}
            </td>
            <ng-template #neverLogin>
              <td (click)="callEditUser(user.no, pageMode.REQUEST_VIEW)">{{ 'NEVER_LOGIN' | translate }}</td>
            </ng-template>
            <td (click)="callEditUser(user.no, pageMode.REQUEST_VIEW)">{{ user.status | translate }}</td>
            <td class="action" *hasPermission="['user_m']">
              <em
                class="fa fa-edit pointer-event text-center mx-1"
                (click)="callEditUser(user.no, pageMode.REQUEST_EDIT)"
              ></em>
              <em
                *ngIf="!isOwner(user.roles[0])"
                class="fa fa-trash text-center mx-1 pointer"
                (click)="handleDeleteUser(user.no, user.fullName)"
              ></em>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav aria-label="Page navigation example" class="border-0" *ngIf="!(usersList$ | async).empty">
      <div class="form-group row float-md-left col-sm-6">
        <label for="recordPerPage" class="col-form-label">{{ 'ROW_PER_PAGE' | translate }}</label>
        <div class="col-sm-6">
          <select
            class="custom-select custom-select-sm col-sm-3"
            id="recordPerPage"
            #recordPerPage
            (change)="onRowPerPagesSelected(recordPerPage.value)"
          >
            <option value="20" selected>20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div class="float-right d-flex">
        <div class="page-container">
          <label
            >{{ (currentPage - 1) * listRequest.size + 1 }} {{ 'TO' | translate }}
            {{
              currentPage * listRequest.size > (usersList$ | async).totalElements
                ? (usersList$ | async).totalElements
                : currentPage * listRequest.size
            }}
            {{ 'OF' | translate }} {{ (usersList$ | async).totalElements }} {{ 'ENTRIES' | translate }}
          </label>
        </div>

        <div class="d-inline-block">
          <pagination
            class="justify-content-end pagination-sm text-success"
            [boundaryLinks]="true"
            [totalItems]="(usersList$ | async).totalElements"
            [itemsPerPage]="(usersList$ | async).size"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;"
            [maxSize]="5"
            (pageChanged)="pageChanged($event)"
            [ngModel]="currentPage"
          >
          </pagination>
        </div>
      </div>
    </nav>
  </div>
</div>
