<nav id="navbar" class="navbar navbar-default navbar-expand-lg justify-content-end">
  <div class="form-inline d-sm-inline pr-md-5">
    <ul class="list-inline align-middle d-sm-inline d-md-inline">
      <li class="list-inline-item mx-2 mx-md-3 float-right">
        <app-switch-lang-menu></app-switch-lang-menu>
      </li>
    </ul>
  </div>
</nav>
<div class="col-xs-12 background-gradient d-flex justify-content-center">
  <div class="align-self-center mt-sm-4">
    <img src="assets/images/logo-492x240.png" class="w-50 mx-auto d-block home-logo pr-3" alt="TD Tawandang" />
    <h5 class="text-dark text-center pt-3">{{ 'VERIFICATION_STEP' | translate }}</h5>
    <router-outlet></router-outlet>
  </div>
</div>
