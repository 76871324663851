import { UserActionTypes, UserInfoActions } from '../actions/user-info.action';
import { TenantInfoInitialState, TenantInfoState } from '../state/tenant-info.state';

export function tenantInfoReducers(state = TenantInfoInitialState, action: UserInfoActions): TenantInfoState {
  switch (action.type) {
    case UserActionTypes.USER_TENANT: {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
}
