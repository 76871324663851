import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ItemActionTypeEnum } from '../../../shared/enum/item-action.enum';
import { ProductStatusEnum } from '../../../shared/enum/product-status.enum';
import { ChildItem } from '../../../shared/layouts/modals/child-item/child-item';
import { CustomSizeModalComponent } from '../../../shared/layouts/modals/custom-size-modal/custom-size-modal.component';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { AddItemRequest, CreateCartResponse } from '../../../shared/models/cart.model';
import { Catalog } from '../../../shared/models/catalog.model';
import { ItemModalComponent } from '../../../shared/models/item-modal.component.model';
import { TdStore } from '../../../shared/models/td-store.model';
import {
  AddItemRequestAction,
  OrdersSummaryRequestAction,
  ResetAddItemStatusAction
} from '../../../shared/store/actions/carts.action';
import { CatalogListRequestAction, CatalogListResetAction } from '../../../shared/store/actions/catalog-list.action';
import { selectAddItemResult, selectCreateCartResult } from '../../../shared/store/selectors/cart.selector';
import {
  selectCatalogListLoadedElementResult,
  selectCatalogListResult,
  selectCatalogListTotalResult
} from '../../../shared/store/selectors/catalog-list.selector';
import { selectTdStorePolicyResult } from '../../../shared/store/selectors/td-store.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { CheckDisableStatus } from '../../../shared/utils/check-disable-status';
import { ItemComponent } from '../item/item.component';
import { DuplicateItemComponent } from './duplicate-item/duplicate-item.component';

@Component({
  selector: 'app-orders-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit, OnDestroy {
  public catCode: string;
  public searchCriteria: string;
  public bsModalRef: BsModalRef;
  public catalogListRequest;
  public catalogList$: Observable<Catalog[]>;
  public catalogListTotal$: Observable<number>;
  public catalogListLoadedElement$: Observable<number>;
  public totalItem = 0;
  public cart: CreateCartResponse;
  public stopLoading = false;
  private displaySearchText: string;
  private displayCatText: string;
  private currentBarcode: string;
  private localStore: Observable<any>;

  constructor(
    private readonly modalService: BsModalService,
    private translate: TranslateService,
    private readonly store: Store<AppStates>,
    private toastrService: ToastrService
  ) {
    this.catalogListRequest = {
      searchCriteria: null,
      catalogLV1: null,
      catalogLV2: null,
      offset: 0,
      limit: 10,
      timeZone: null
    };
  }

  dispatchCatalogListAction(catalogRequest) {
    this.store.dispatch(new CatalogListRequestAction(catalogRequest));
  }

  ngOnInit() {
    this.initState();
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.catalogList$ = this.localStore.pipe(select(selectCatalogListResult));
    this.catalogListTotal$ = this.localStore.pipe(select(selectCatalogListTotalResult));
    this.catalogListLoadedElement$ = this.localStore.pipe(select(selectCatalogListLoadedElementResult));

    this.localStore.pipe(select(selectCreateCartResult)).subscribe(cart => {
      this.cart = cart.result.response;
      if (this.cart && this.cart.storeNo) {
        this.store.dispatch(new OrdersSummaryRequestAction({ storeNo: this.cart.storeNo }));
      }
    });

    this.localStore.pipe(select(selectTdStorePolicyResult)).subscribe((tdStore: TdStore) => {
      if (tdStore) {
        this.catalogListRequest.timeZone = tdStore.storeProfile.timezone;
        this.dispatchCatalogListAction(this.catalogListRequest);
      }
    });

    this.localStore.pipe(select(selectAddItemResult)).subscribe(addItem => {
      if (addItem.errorResponse) {
        if (addItem.errorResponse.code === '00002') {
          this.callDuplicateItemModal(addItem.errorResponse.barcode, addItem.errorResponse.qty);
        } else if (['00000', '00001'].includes(addItem.errorResponse.code)) {
          this.showToasterError();
        }
      } else if (addItem.addItemSuccess) {
        if (this.currentBarcode) {
          (document.getElementById(this.currentBarcode + '-list') as HTMLInputElement).value = '1';
        }

        this.currentBarcode = null;
        this.showToaster();
        this.store.dispatch(new OrdersSummaryRequestAction({ storeNo: this.cart.storeNo }));
      }
    });
  }

  onActivate(catCode: string) {
    this.catCode = catCode;

    if (this.catCode) {
      const categoryLevel = this.catCode.substring(0, 4);

      this.catalogListRequest.catalogLV1 = 'CAT1' === categoryLevel ? this.catCode : null;
      this.catalogListRequest.catalogLV2 = 'CAT2' === categoryLevel ? this.catCode : null;

      this.displayCatText = this.translate.instant(`CAT_CODE.${this.catCode}`);

      this.store.dispatch(new CatalogListResetAction());
      this.dispatchCatalogListAction(this.catalogListRequest);
    }
  }

  handleSearchText(searchCriteria: string) {
    this.catCode = null;
    this.displayCatText = this.translate.instant(`CAT_CODE.null`);
    this.catalogListRequest = { catalogLV1: null, catalogLV2: null };

    this.store.dispatch(new CatalogListResetAction());
    this.catalogListRequest.searchCriteria = searchCriteria;
    this.displaySearchText = searchCriteria;
    this.dispatchCatalogListAction(this.catalogListRequest);
  }

  callItemDetails(catalog: Catalog) {
    const initialState: ItemModalComponent = {
      title: null,
      childItem: new ChildItem(ItemComponent, {
        catalog: catalog,
        cart: this.cart,
        title: this.translate.instant('BACK_TO_PREVIOUS_PAGE'),
        isResetQuantity: true,
        step: '1'
      })
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState: initialState
    });
  }

  callDuplicateItemModal(barcode: string, qty: string) {
    const initialState: ItemModalComponent = {
      title: this.translate.instant('DUPLICATED_ITEM'),
      childItem: new ChildItem(DuplicateItemComponent, {
        barcode: barcode,
        qty: qty
      })
    };

    this.bsModalRef = this.modalService.show(CustomSizeModalComponent, {
      animated: true,
      backdrop: true,
      initialState: initialState
    });

    const modal = this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
      modal.unsubscribe();
    });
  }

  onScrollDown() {
    this.catalogListLoadedElement$.subscribe(total => {
      this.totalItem = total;
      this.catalogListRequest.offset = total;
    });
    this.catalogList$.subscribe(catalog => (this.stopLoading = catalog.length === 0));
    this.dispatchCatalogListAction(this.catalogListRequest);
  }

  showToaster() {
    this.toastrService.success(this.translate.instant('YOUR_BASKET_HAS_BEEN_UPDATED'));
    this.store.dispatch(new ResetAddItemStatusAction());
  }

  showToasterError() {
    this.toastrService.error(this.translate.instant('ERROR_CODE.undefined'));
  }

  addItem(barcode: string, qty: string) {
    this.currentBarcode = barcode;

    const addItemRequest: AddItemRequest = {
      barcode: this.currentBarcode,
      qty: qty,
      action: ItemActionTypeEnum.ADD,
      cartId: this.cart.cartId
    };
    if (qty) {
      this.store.dispatch(new AddItemRequestAction(addItemRequest));
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new CatalogListResetAction());
  }

  get productStatus() {
    return ProductStatusEnum;
  }

  get checkDisableStatus() {
    return CheckDisableStatus;
  }
}
