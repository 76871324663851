import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ItemActionTypeEnum } from '../../../shared/enum/item-action.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProductStatusEnum } from '../../../shared/enum/product-status.enum';
import { ChildComponent } from '../../../shared/layouts/modals/child-item/child-component';
import { AddItemRequest, CreateCartResponse } from '../../../shared/models/cart.model';
import { Catalog } from '../../../shared/models/catalog.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { OrderSummary } from '../../../shared/models/orders.model';
import { DecimalProxyPipe } from '../../../shared/pipe/decimal-proxy/decimal-proxy.pipe';
import { PosService } from '../../../shared/services/pos.service';
import { AddItemRequestAction } from '../../../shared/store/actions/carts.action';
import { selectAddItemResult } from '../../../shared/store/selectors/cart.selector';
import { selectOrderSummaryResult } from '../../../shared/store/selectors/order-summary.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { CheckDisableStatus } from '../../../shared/utils/check-disable-status';

@Component({
  selector: 'app-order-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit, OnDestroy, ChildComponent {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { catalog: Catalog; cart: CreateCartResponse; title: string; isResetQuantity: boolean; step: string };

  public itemDetailForm: FormGroup;
  public submitted: boolean;
  public orderSummary: OrderSummary;
  public catalog: Catalog;
  public cart: CreateCartResponse;
  public constInfo: string;

  private currentBarcode: string;
  private localStore: Observable<any>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private pos: PosService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.pos.goto('item');
    this.catalog = new Catalog(this.data.catalog);
    this.cart = this.data.cart;
    this.itemDetailForm = this.formBuilder.group({
      amount: [
        this.data.isResetQuantity ? 1 : this.catalog.qty,
        [Validators.required, Validators.min(1), Validators.max(999)]
      ]
    });

    this.localStore
      .pipe(
        select(selectOrderSummaryResult),
        map(action => action.result.response)
      )
      .subscribe(data => (this.orderSummary = data));

    this.localStore
      .pipe(
        select(selectAddItemResult),
        tap(addItem => {
          if (addItem.addItemSuccess) {
            if (this.currentBarcode && this.data.isResetQuantity) {
              (document.getElementById(this.currentBarcode) as HTMLInputElement).value = '1';
            }
            this.currentBarcode = null;
          }
        })
      )
      .subscribe();

    // Output: สต๊อก 0 ชิ้น | ยอดขายเฉลี่ย 0 ขิ้น/สัปดาห์ | ราคาเฉลี่ย/ชิ้น 0 บาท | ราคาขายต้่อชิ้น 0 บาท | กำไร/ชิ้น 0 บาท
    const retailPricePerUnit =
      this.catalog.articleType !== 'N'
        ? `${new DecimalProxyPipe(this.translate).transform(
            this.catalog.retailPricePerUnit.amount
          )} ${this.translate.instant(this.catalog.retailPricePerUnit.currency)}`
        : '-';

    const profitPerUnit =
      this.catalog.articleType !== 'N'
        ? `${new DecimalProxyPipe(this.translate).transform(
            this.catalog.profitPerUnit.amount
          )} ${this.translate.instant(this.catalog.profitPerUnit.currency)}`
        : '-';

    this.constInfo = [
      `${this.translate.instant('STOCK')} 0 ${this.translate.instant('UNIT.PC')}`,
      `${this.translate.instant('AVERAGE_SALES_WEEKLY', { amount: '0' })}`,
      `${this.translate.instant('AVERAGE_PRICE_PIECE')} ${new DecimalProxyPipe(this.translate).transform(
        this.catalog.wholesalePricePerUnit.amount
      )} ${this.translate.instant(this.catalog.wholesalePricePerUnit.currency)}`,
      `${this.translate.instant('RETAIL_PRICE_PIECE')} ${retailPricePerUnit}`,
      `${this.translate.instant('PROFIT_PIECE')} ${profitPerUnit}`
    ].join(' | ');
  }

  ngOnDestroy(): void {
    this.catalog = null;
  }

  productStatus(status: ProductStatusEnum): Observable<string> {
    return this.translate.get('PRODUCT_STATUS.' + status).pipe();
  }

  onSubmit() {
    this.submitted = true;

    if (this.itemDetailForm.valid) {
      this.currentBarcode = this.catalog.barcode;

      const addItemRequest: AddItemRequest = {
        barcode: this.currentBarcode,
        qty: this.itemDetailForm.controls.amount.value.toString(),
        action: ItemActionTypeEnum.ADD,
        cartId: this.cart.cartId
      };

      this.store.dispatch(new AddItemRequestAction(addItemRequest));
    }
  }

  onCancel() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null });
  }

  onAdd() {
    const newVal = this.itemDetailForm.value.amount >= 999 ? 999 : +this.itemDetailForm.value.amount + 1;
    this.itemDetailForm.controls['amount'].setValue(newVal);
  }

  onReduce() {
    const newVal = this.itemDetailForm.value.amount <= 1 ? 1 : +this.itemDetailForm.value.amount - 1;
    this.itemDetailForm.controls['amount'].setValue(newVal);
  }

  get productTitle() {
    return this.translate.instant('PRODUCT_TITLE', {
      productName: this.catalog.productName,
      unit: this.translate.instant('UNIT.' + this.catalog.unit),
      unitFactor: this.catalog.unitFactor
    });
  }

  get f(): {
    [key: string]: AbstractControl;
  } {
    return this.itemDetailForm.controls;
  }

  get productStatusList() {
    return ProductStatusEnum;
  }

  get checkDisableStatus() {
    return CheckDisableStatus;
  }
}
