import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MerchantVerificationRequestAction } from '../../../shared/store/actions/verification.actions';
import { selectMerchantVerificationResult } from '../../../shared/store/selectors/verification.selector';
var MerchantVerificationComponent = /** @class */ (function () {
    function MerchantVerificationComponent(store, formBuilder, activeRoute, router) {
        this.store = store;
        this.formBuilder = formBuilder;
        this.activeRoute = activeRoute;
        this.router = router;
        this.submitted = false;
        this.merchantId = null;
    }
    MerchantVerificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.merchantVerificationForm = this.formBuilder.group({
            idCardNumber: [null, { validators: [Validators.required, Validators.minLength(13)], updateOn: 'submit' }]
        });
        this.merchantId = this.activeRoute.snapshot.queryParams.m;
        this.merchantVerificationResult$ = this.localStore.pipe(select(selectMerchantVerificationResult));
        this.merchantVerificationResult$.subscribe(function (response) {
            if (response.result && response.result.errorResponse && response.result.errorResponse.code === '04007') {
                _this.f.idCardNumber.setErrors({ invalidId: true });
            }
            else if (response.result && response.result.response && response.result.response.statusCode === '200') {
                _this.router.navigateByUrl('/mer-verify-success');
            }
        });
    };
    MerchantVerificationComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.merchantVerificationForm.valid) {
            var merchantVerification = {
                merchantId: this.merchantId,
                idNumber: this.f.idCardNumber.value
            };
            this.store.dispatch(new MerchantVerificationRequestAction(merchantVerification));
        }
    };
    MerchantVerificationComponent.prototype.clearFormError = function (event) {
        event.preventDefault();
        if (event.key !== 'Enter') {
            this.f.idCardNumber.setErrors(null);
        }
    };
    Object.defineProperty(MerchantVerificationComponent.prototype, "f", {
        get: function () {
            return this.merchantVerificationForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    MerchantVerificationComponent.prototype.ngOnDestroy = function () { };
    return MerchantVerificationComponent;
}());
export { MerchantVerificationComponent };
