import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { TreeItem, TreeviewConfig } from 'ngx-treeview';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { StoreType } from '../../../shared/enum/store.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ChildComponent } from '../../../shared/layouts/modals/child-item/child-component';
import { BaseModalComponent } from '../../../shared/models/base-modal.component.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { TdStoreGroupListResponse, TdStoreList } from '../../../shared/models/td-store-group-list.model';
import { Tenant, UserRequest } from '../../../shared/models/user.model';
import { UsersService } from '../../../shared/services';
import { RolesService } from '../../../shared/services/roles.service';
import { StoresService } from '../../../shared/services/stores.service';
import { TdStoreGroupListGetAction } from '../../../shared/store/actions/td-store-group-list.action';
import { TdStoreListGetAction } from '../../../shared/store/actions/td-store-list.action';
import { TdStoresTreeViewRequestAction } from '../../../shared/store/actions/td-stores-tree-view.action';
import { ResetUserState, UsersAddAction } from '../../../shared/store/actions/users.action';
import { selectUserInfoResult } from '../../../shared/store/selectors/auth-user-info.selector';
import { selectTdStoreGroupListResult } from '../../../shared/store/selectors/td-store-group-list.selector';
import { selectTdStoreListResult } from '../../../shared/store/selectors/td-store-list.selector';
import { selectTdStoresTreeViewResult } from '../../../shared/store/selectors/td-stores-tree-view.selector';
import { selectTenantInfoResult } from '../../../shared/store/selectors/tenant-info.selector';
import { selectUsersResult } from '../../../shared/store/selectors/users.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { UserInfoState } from '../../../shared/store/state/user-info.state';
import { getRoles } from '../../../shared/utils/check-role';

@Component({
  selector: 'app-user-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
  providers: [RolesService, UsersService, StoresService]
})
export class AddComponent implements OnInit, OnDestroy, ChildComponent {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { id: string; title: string };

  public addUserForm: FormGroup;
  public submitted: boolean;

  public tdStoreList$: Observable<Array<TreeItem>>;
  public tdStoreGroupList$: Observable<TdStoreGroupListResponse[]>;
  public tdStoresConfig: TreeviewConfig;
  public bsConfig: BsDatepickerConfig;
  public listOfValue: {};
  public merchantType: StoreType;
  private readonly env = environment;
  private merchantNo: string;
  private tmpElementValue: string[];
  private localStore: Observable<any>;
  public userInfo: UserInfoState;
  public isStoreOwner: boolean;
  public selectedAllStoreInMerchant: boolean;
  public selectedStoreGroup: string[];
  public selectedStore: string[];
  public tdStoreAuthorityList: string[];

  constructor(
    private readonly store: Store<AppStates>,
    private readonly formBuilder: FormBuilder,
    private readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly logger: NGXLogger
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.merchantType = null;
    this.listOfValue = {};
    this.isStoreOwner = false;
    this.selectedAllStoreInMerchant = false;
    this.selectedStoreGroup = [];
    this.selectedStore = [];
    this.initControl();
    this.initState();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetUserState());
  }

  initState() {
    this.localStore.pipe(select(selectUserInfoResult)).subscribe(userInfo => {
      this.userInfo = userInfo;
      this.isStoreOwner = userInfo.roles.includes('STORE_OWNER') || userInfo.roles.includes('STORE_OWNER_PARTNER');

      if (!this.isStoreOwner) {
        this.addUserForm.controls['stores'].setValidators(Validators.required);
      }
    });

    this.localStore.pipe(select(selectTenantInfoResult)).subscribe(tenantInfo => {
      this.merchantNo = tenantInfo.merchant;
      this.tdStoreAuthorityList = tenantInfo.stores;
    });

    // Retrieve TD-store list
    this.localStore
      .pipe(
        select(selectTdStoreListResult),
        tap(val => {
          if (val.result.response) {
            this.merchantType = val.result.response.content[0].merchantType;
          }
        }),
        switchMap(resp => of(resp.result.response))
      )
      .subscribe(val => {
        this.store.dispatch(new TdStoresTreeViewRequestAction(val && val.content));
        this.listOfValue['roles'] = getRoles(this.merchantType, false);
      });

    this.store.dispatch(
      new TdStoreListGetAction({ merchantNo: this.merchantNo, storeNo: this.tdStoreAuthorityList.join(',') })
    );

    if (this.isStoreOwner) {
      this.store.dispatch(new TdStoreGroupListGetAction({ merchantNo: this.merchantNo }));
      this.tdStoreGroupList$ = this.localStore.pipe(
        select(selectTdStoreGroupListResult),
        switchMap(resp => of(resp))
      );
    } else {
      this.tdStoreList$ = this.localStore.pipe(
        select(selectTdStoresTreeViewResult),
        switchMap(resp => of(resp))
      );
    }

    this.localStore
      .pipe(
        select(selectUsersResult),
        map(action => action.result)
      )
      .subscribe(
        next => {
          if (next.response) {
            this.onSuccess(next);
          } else if (next.errorResponse) {
            this.onError(next.errorResponse);
          } else {
            this.logger.debug(next);
          }
        },
        error => this.onError(error),
        () => this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null })
      );
  }

  initControl() {
    this.addUserForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      localFirstName: ['', [Validators.required, Validators.maxLength(100)]],
      localLastName: ['', [Validators.required, Validators.maxLength(100)]],
      mobile: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.email, Validators.maxLength(100)]],
      gender: ['', []],
      active: ['true', []],
      birthDay: ['', []],
      roles: [null, [Validators.required]],
      stores: [null, []]
    });

    // stores: [null, [Validators.required]]

    this.tmpElementValue = new Array<string>();
    this.preventBlock(this.addUserForm.controls, 'firstName', /^[a-z A-Z]*$/);
    this.preventBlock(this.addUserForm.controls, 'lastName', /^[a-z A-Z]*$/);

    this.tdStoresConfig = new TreeviewConfig();
    this.tdStoresConfig.hasAllCheckBox = false;

    this.addUserForm.controls['active'].setValue('true');
    this.addUserForm.controls['active'].disable();
    this.bsConfig = {
      dateInputFormat: this.env.dateFormat,
      maxDate: new Date(),
      showWeekNumbers: false
    } as BsDatepickerConfig;
  }

  setStores(result: string[]) {
    this.addUserForm.controls['stores'].setValue(result);
  }

  onSubmit() {
    let req: UserRequest;

    this.submitted = true;

    if (this.addUserForm.invalid || this.validateStore) {
      if (!this.addUserForm.invalid && this.validateStore) {
        this.showAlert(this.translate.instant('FAILED'), this.translate.instant('PLEASE_SELECT_AT_LEAST_ONE_STORE'));
      }
      return;
    }

    req = new UserRequest(this.addUserForm.value);
    req.tenant = new Tenant();
    req.tenant.merchant = this.merchantNo;
    req.accessAllStores = this.selectedAllStoreInMerchant;
    req.accessStoreGroups = this.selectedStoreGroup;
    req.accessStores = this.isStoreOwner ? this.selectedStore : this.addUserForm.value.stores;
    req.countryCode = this.addUserForm.value.mobile.internationalNumber.split(' ')[0];
    req.mobileNo = this.addUserForm.value.mobile.nationalNumber.replace(/ /g, '');

    if (this.addUserForm.value.birthDay && this.addUserForm.value.birthDay !== '') {
      req.birthDate = moment(this.addUserForm.value.birthDay, environment.dateFormat).format(environment.dateFormat);
    }

    this.store.dispatch(new UsersAddAction(req));
  }

  showAlert(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  get validateStore(): boolean {
    const checkSelectedStore =
      this.selectedAllStoreInMerchant || this.selectedStoreGroup.length > 0 || this.selectedStore.length > 0;
    return this.isStoreOwner && !checkSelectedStore;
  }

  onChangeAll(event: any, storeGroupList: TdStoreGroupListResponse[]) {
    if (event.target.checked) {
      this.selectedAllStoreInMerchant = true;
      this.selectedStoreGroup = [];
      this.selectedStore = [];
      storeGroupList.forEach((storeGroup: TdStoreGroupListResponse) => this.clearSelectedStoreAll(storeGroup));
    } else {
      this.selectedAllStoreInMerchant = false;
      storeGroupList.forEach((storeGroup: TdStoreGroupListResponse) => this.selectedEnableStoreAll(storeGroup));
    }
  }

  onCheckboxChangeGroup(event: any, storeGroup: TdStoreGroupListResponse) {
    if (event.target.checked) {
      this.selectStoreGroup(storeGroup);
    } else {
      this.deSelectStoreGroup(storeGroup);
    }
  }

  onCheckboxChange(event: any, store: TdStoreList) {
    if (event.target.checked) {
      store.selected = true;
      this.selectedStore.push(store.no);
    } else {
      store.selected = false;
      this.removeSelectedStore(store.no);
    }
  }

  selectStoreGroup(storeGroupUser: TdStoreGroupListResponse) {
    storeGroupUser.selected = true;
    this.selectedStoreGroup.push(storeGroupUser.no);
    storeGroupUser.stores.forEach((store: TdStoreList) => {
      store.selected = false;
      store.disabled = true;
      this.removeSelectedStore(store.no);
    });
  }

  deSelectStoreGroup(storeGroupUser: TdStoreGroupListResponse) {
    storeGroupUser.selected = false;
    this.removeSelectedStoreGroup(storeGroupUser.no);
    storeGroupUser.stores.forEach((store: TdStoreList) => {
      store.disabled = false;
    });
  }

  clearSelectedStoreAll(storeGroupUser: TdStoreGroupListResponse) {
    storeGroupUser.selected = false;
    storeGroupUser.disabled = true;
    storeGroupUser.stores.forEach((store: TdStoreList) => {
      store.selected = false;
      store.disabled = true;
    });
  }

  selectedEnableStoreAll(storeGroupUser: TdStoreGroupListResponse) {
    storeGroupUser.disabled = false;
    storeGroupUser.stores.forEach((store: TdStoreList) => {
      store.disabled = false;
    });
  }

  removeSelectedStoreGroup(code: string) {
    this.selectedStoreGroup.splice(this.selectedStoreGroup.findIndex(itemSelected => itemSelected === code), 1);
  }

  removeSelectedStore(code: string) {
    this.selectedStore.splice(this.selectedStore.findIndex(itemSelected => itemSelected === code), 1);
  }

  onCancel() {
    if (this.addUserForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState: initialState,
        notificationType: NotificationTypeEnum.CONFIRM,
        result: null
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null });
    }
  }

  get f(): {
    [key: string]: AbstractControl;
  } {
    return this.addUserForm.controls;
  }

  private preventBlock(controls: any, key: string, regexp: RegExp = /^.*$/) {
    if (!this.tmpElementValue[key]) {
      this.tmpElementValue[key] = '';
    }

    controls[key].valueChanges.pipe(filter(val => val !== this.tmpElementValue[key])).forEach(val => {
      if (regexp.test(val)) {
        this.tmpElementValue[key] = val;
      }
      controls[key].setValue(this.tmpElementValue[key]);
    });
  }

  private onSuccess(resp: any) {
    const initialState: BaseModalComponent = {
      title: this.translate.instant('SUCCESS'),
      message: this.translate.instant('USER_CREATED'),
      routerLink: 'users'
    };

    this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: resp });
    });
    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  private onError(resp: any) {
    if (resp.translateKey) {
      const initialState: BaseModalComponent = {
        title: this.translate.instant('FAILED'),
        message: this.translate.instant(resp.translateKey)
      };

      this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.LOGGING, result: resp });
      });
      this.modalService.show(AlertModalComponent, { initialState });
    }
  }
}
