import { ProductStatusEnum } from '../enum/product-status.enum';
import { Catalog } from '../models/catalog.model';

export class CheckDisableStatus {
  static isDisabled(inputProductStatus: ProductStatusEnum) {
    return [
      ProductStatusEnum.OUT_OF_STOCK,
      ProductStatusEnum.HOLD_BUY_SELL,
      ProductStatusEnum.DELETE,
      ProductStatusEnum.RECALL
    ].includes(inputProductStatus);
  }

  static invalidItemStatus() {
    return [
      ProductStatusEnum.OUT_OF_STOCK,
      ProductStatusEnum.HOLD_BUY_SELL,
      ProductStatusEnum.DELETE,
      ProductStatusEnum.RECALL
    ];
  }

  static isDisablePriceNull(catalog: Catalog): boolean {
    if (
      !catalog.wholesalePricePerUnit ||
      (catalog.articleType !== 'N' && (!catalog.retailPrice || !catalog.profitPerUnit))
    ) {
      return true;
    }
    return false;
  }
}
