import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { DeleteUserState } from '../state/delete-user.state';

const selectDeleteUsers = (state: AppStates) => state.deleteUser;

export const selectDeleteUsersResult = createSelector(
  selectDeleteUsers,
  (state: DeleteUserState) => state
);
