import * as tslib_1 from "tslib";
import { DecimalPipe } from '@angular/common';
import { PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
var DecimalProxyPipe = /** @class */ (function (_super) {
    tslib_1.__extends(DecimalProxyPipe, _super);
    function DecimalProxyPipe(translate) {
        var _this = _super.call(this, translate.currentLang) || this;
        _this.translate = translate;
        return _this;
    }
    DecimalProxyPipe.prototype.transform = function (value, args) {
        return _super.prototype.transform.call(this, value, args);
    };
    return DecimalProxyPipe;
}(DecimalPipe));
export { DecimalProxyPipe };
