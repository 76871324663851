<div class="btn-group pointer" dropdown>
  <a id="button-custom-html" dropdownToggle class="dropdown-toggle" aria-controls="dropdown-custom-html">
    <img src="/assets/images/{{ currentLang }}.png" alt="{{ currentLang }}" />
  </a>
  <ul
    id="dropdown-custom-html"
    *dropdownMenu
    class="dropdown-menu dropdown-menu-right"
    role="menu"
    aria-labelledby="button-custom-html"
  >
    <li role="menuitem" *ngFor="let lang of langList">
      <a class="dropdown-item" (click)="setLang(lang.code)" href="javascript:void(0)"
        ><img src="/assets/images/{{ lang.code }}.png" alt="{{ lang.name }}" />{{ lang.name }}</a
      >
    </li>
  </ul>
</div>
