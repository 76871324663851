import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CatalogsService } from '../../services';
import {
  CatalogListActionTypes,
  CatalogListErrorAction,
  CatalogListRequestAction,
  CatalogListResponseAction
} from '../actions/catalog-list.action';

@Injectable()
export class CatalogListEffects {
  constructor(
    private actions: Actions,
    private catalogService: CatalogsService,
    private readonly router: Router,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  CatalogList: Observable<Action> = this.actions.pipe(
    ofType<CatalogListRequestAction>(CatalogListActionTypes.CATALOG_LIST_REQUEST),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Catalog Request: ' + this.stringify(payload));
      return this.catalogService.getCatalog(payload).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Get Catalogs Response: ' + this.stringify(resp));
          return new CatalogListResponseAction(resp);
        }),
        catchError(err => {
          return of(new CatalogListErrorAction(err.error));
        })
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
