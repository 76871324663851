import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models/error.model';
import { MerchantVerificationRequest, MerchantVerificationResponse } from '../../models/verification.models';

export enum VerificationActionTypes {
  MERCHANT_VERIFICATION_REQUEST = '[Merchant Verification] Merchant Verify Request',
  MERCHANT_VERIFICATION_RESPONSE = '[Merchant Verification] Merchant Verify Response',
  MERCHANT_VERIFICATION_ERROR = '[Merchant Verification] Merchant Verify Error'
}

export class MerchantVerificationRequestAction implements Action {
  readonly type = VerificationActionTypes.MERCHANT_VERIFICATION_REQUEST;

  constructor(public payload: MerchantVerificationRequest) {}
}

export class MerchantVerificationResponseAction implements Action {
  readonly type = VerificationActionTypes.MERCHANT_VERIFICATION_RESPONSE;

  constructor(public payload: MerchantVerificationResponse) {}
}

export class MerchantVerificationErrorAction implements Action {
  readonly type = VerificationActionTypes.MERCHANT_VERIFICATION_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export type VerificationActions =
  | MerchantVerificationRequestAction
  | MerchantVerificationResponseAction
  | MerchantVerificationErrorAction;
