import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { TreeNodeContent } from 'angular-tree-component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryTree } from '../../../../shared/models/category-tree.model';
import { CatalogListMenuRequestAction } from '../../../../shared/store/actions/catalog-list-menu.action';
import { selectCatalogListMenuResult } from '../../../../shared/store/selectors/catalog-list-menu.selector';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-tree-menu',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './tree-menu.component.html',
  styleUrls: ['./tree-menu.component.scss']
})
export class TreeMenuComponent implements OnInit, OnDestroy {
  @Output() catCode = new EventEmitter<string>();
  @ViewChild('th_tree', { static: true }) th_tree;
  @ViewChild('en_tree', { static: true }) en_tree;

  public categoryTree$: Observable<CategoryTree[]>;
  public options: any;
  private currentNode: TreeNodeContent;
  private localStore: Observable<any>;

  constructor(private readonly store: Store<AppStates>, public readonly translate: TranslateService) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.options = {};
    this.initState();
  }

  initState() {
    this.store.dispatch(new CatalogListMenuRequestAction());
    this.categoryTree$ = this.localStore.pipe(
      select(selectCatalogListMenuResult),
      map(resp => this.setCatName(resp))
    );
    this.catCode.emit(null);
  }

  treeInit($event) {
    if (this.currentNode) {
      $event.treeModel.getNodeById(this.currentNode.node.data.id).expand();
      $event.treeModel.getNodeById(this.currentNode.node.data.id).setActiveAndVisible();
    }
  }

  private setCatName(catTree: Array<CategoryTree>): CategoryTree[] {
    return catTree.map(val => {
      if (val.children) {
        val.children = this.setCatName(val.children);
      }

      this.translate.get(`CAT_CODE.${val.code}`).subscribe(translated => {
        val.name = translated;
      });

      return val;
    });
  }

  onActivate($event: TreeNodeContent) {
    this.currentNode = $event;
    this.catCode.emit(this.currentNode.node.data.code);
  }

  onDeactivate(_$event: TreeNodeContent) {
    this.catCode.emit(null);
  }

  ngOnDestroy(): void {}
}
