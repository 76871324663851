import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-verification-error',
  templateUrl: './merchant-verification-error.component.html',
  styleUrls: ['./merchant-verification-error.component.scss']
})
export class MerchantVerificationErrorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
