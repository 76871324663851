import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable, zip } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { TDPageModes } from '../../../../enum/store.enum';
import { MasterData } from '../../../../models/master-data.model';
import { TdStore } from '../../../../models/td-store.model';
import { selectTdStoreResult } from '../../../../store/selectors/td-store.selector';
import { selectUserSelectValueGroupByType } from '../../../../store/selectors/user-select-value.selectors';
import { AppStates } from '../../../../store/state/app.states';
import { BaseTdStoreState } from '../../../../store/state/td-store.state';

@Component({
  selector: 'app-order-policy-schedule',
  templateUrl: './order-policy-schedule.component.html',
  styleUrls: ['./order-policy-schedule.component.scss']
})
export class OrderPolicyScheduleComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() mode: TDPageModes;

  private localStore: Observable<any>;

  private schedulesLimit = 7;
  private orderPolicySelectValue: { [key: string]: Array<MasterData> };
  public orderScheduleDateOptions: MasterData[];

  public tdStore$: Observable<BaseTdStoreState>;
  public userSelectValue$: Observable<{ [key: string]: Array<MasterData> } | null>;

  constructor(private fb: FormBuilder, private readonly store: Store<AppStates>) {}

  get schedules() {
    return this.parentForm.get('schedules') as FormArray;
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.tdStore$ = this.localStore.pipe(
      select(selectTdStoreResult),
      filter(v => v.result.response !== null)
    );

    this.userSelectValue$ = this.localStore.pipe(
      select(selectUserSelectValueGroupByType),
      filter(v => Object.keys(v).length > 0)
    );

    zip(this.tdStore$, this.userSelectValue$)
      .pipe(take(1))
      .subscribe(results => {
        this.orderPolicySelectValue = results[1];
        this.orderScheduleDateOptions = results[1].orderScheduleDate;

        this.addSchedule();
        this.setOrderPolicyScheduleValue(results[0].result.response);

        if (this.mode === TDPageModes.REQUEST_VIEW) {
          this.schedules.disable();
        }
      });
  }

  get createForm() {
    const initialNullRequired = [
      { value: null, disabled: false },
      this.schedules.length === 0 ? Validators.required : null
    ];

    return this.fb.group({
      orderScheduleDate: initialNullRequired,
      orderScheduleTime: initialNullRequired,
      deliverySchedule: initialNullRequired
    });
  }

  addSchedule() {
    if (this.schedules.length > this.schedulesLimit) {
      return;
    }

    this.schedules.push(this.createForm);
  }

  deleteSchedule(index) {
    this.schedules.removeAt(index);
  }

  setOrderPolicyScheduleValue(tdStore: TdStore) {
    const currentData = tdStore && tdStore.orderSchedule;

    if (currentData && currentData.schedules && currentData.schedules.length > 0) {
      currentData.schedules.forEach((field, i) => {
        if (!this.schedules.at(i)) {
          this.addSchedule();
        }

        this.schedules.at(i).patchValue({
          orderScheduleDate: field.orderScheduleDate,
          orderScheduleTime: field.orderScheduleTime,
          deliverySchedule: field.deliverySchedule
        });
      });
    }
  }

  onSelectedScheduleDate(data, rowIndex) {
    if (data.type === 'orderScheduleDate' && rowIndex !== 0) {
      this.updateValidators(rowIndex);
    }
  }

  updateOrderScheduleDateOptions() {
    this.orderScheduleDateOptions = this.orderPolicySelectValue.orderScheduleDate.filter(v => {
      const currentValues = this.schedules.value.map(val => val.orderScheduleDate);
      if (currentValues.indexOf(v.code) === -1) {
        return v;
      }
    });
  }

  updateValidators(rowIndex) {
    const fieldUpdates = ['orderScheduleTime', 'deliverySchedule'];
    for (const field of fieldUpdates) {
      this.schedules
        .at(rowIndex)
        .get(field)
        .setValidators([Validators.required]);
      this.schedules
        .at(rowIndex)
        .get(field)
        .updateValueAndValidity();
    }
  }

  showBtn() {
    return this.schedules.length < this.schedulesLimit && this.checkEditPermission();
  }

  checkEditPermission() {
    // add permission when apply edit mode
    return false;
  }
}
