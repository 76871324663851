import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { StoresService } from '../../services/stores.service';
import { TdStoreActionTypes, TdStoreFailureAction, TdStoreSuccessAction } from '../actions/td-store.action';
var TdStoreEffects = /** @class */ (function () {
    function TdStoreEffects(actions, tdStoresService, router, logger) {
        var _this = this;
        this.actions = actions;
        this.tdStoresService = tdStoresService;
        this.router = router;
        this.logger = logger;
        this.TdStoreGet = this.actions.pipe(ofType(TdStoreActionTypes.TD_STORE), switchMap(function (action) {
            _this.logger.debug('@Effect TdStoreGetAction: ' + _this.stringify(action.payload));
            return _this.tdStoresService.getStore(action.payload).pipe(map(function (resp) {
                return new TdStoreSuccessAction(resp);
            }), catchError(function (err) {
                return of(new TdStoreFailureAction(err.error));
            }));
        }));
        this.TdStoreEdit = this.actions.pipe(ofType(TdStoreActionTypes.TD_STORE_EDIT), switchMap(function (action) {
            _this.logger.debug('@Effect TdStoreEditAction: ' + _this.stringify(action.payload));
            return _this.tdStoresService.editStore(action.payload).pipe(map(function (resp) {
                return new TdStoreSuccessAction(resp);
            }), catchError(function (err) {
                return of(new TdStoreFailureAction(err.error));
            }));
        }));
        this.TdStoresSuccess = this.actions.pipe(ofType(TdStoreActionTypes.TD_STORE_SUCCESS), tap(function (resp) {
            _this.logger.debug('@Effect TdStoreSuccessAction: ' + _this.stringify(resp.payload));
        }));
        this.TdStoresFailure = this.actions.pipe(ofType(TdStoreActionTypes.TD_STORE_FAILURE), tap(function (resp) {
            _this.logger.debug('@Effect TdStoreFailureAction: ' + _this.stringify(resp));
        }));
    }
    TdStoreEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], TdStoreEffects.prototype, "TdStoreGet", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], TdStoreEffects.prototype, "TdStoreEdit", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], TdStoreEffects.prototype, "TdStoresSuccess", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Observable)
    ], TdStoreEffects.prototype, "TdStoresFailure", void 0);
    return TdStoreEffects;
}());
export { TdStoreEffects };
