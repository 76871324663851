import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Android } from '../definitions/android';
import { ClientIdTypeEnum } from '../enum/client-id.enum';
import { selectClientIdResult } from '../store/selectors/client-id.selector';
import { AppStates } from '../store/state/app.states';

@Injectable({
  providedIn: 'root'
})
export class PosService implements OnDestroy {
  private clientId: ClientIdTypeEnum | null;
  private subscriptions: Subscription;

  constructor(private readonly logger: NGXLogger, private readonly store: Store<AppStates>) {
    this.subscriptions = store
      .pipe(
        select(selectClientIdResult),
        tap(clientId => (this.clientId = clientId))
      )
      .subscribe();
  }

  orderComplete(poNumber: string) {
    if (this.isNotPOS) {
      return;
    }

    try {
      this.logger.info('orderComplete', poNumber);
      // @ts-ignore
      Android.orderComplete(poNumber);
    } catch (e) {
      this.logger.error('orderComplete', e);
    }
  }

  goto(pageName: string) {
    if (this.isNotPOS) {
      return;
    }

    try {
      this.logger.info('goto', pageName);
      // @ts-ignore
      Android.goto(pageName);
    } catch (e) {
      this.logger.error('goto', e);
    }
  }

  get isNotPOS(): boolean {
    if (this.clientId !== ClientIdTypeEnum.POS_APPL) {
      this.logger.info('ClientID is not POS_APPL');
    }

    return this.clientId !== ClientIdTypeEnum.POS_APPL;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
