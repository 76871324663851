import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models/error.model';
import { TdStore } from '../../models/td-store.model';

export enum TdStoreActionTypes {
  TD_STORE = '[TdStore] Get',
  TD_STORE_EDIT = '[TdStore] Edit',
  TD_STORE_SUCCESS = '[TdStore] Success',
  TD_STORE_FAILURE = '[TdStore] Failure',
  RESET_TD_STORE_STATE = '[TdStore] Reset TdStore State'
}

export class TdStoreGetAction implements Action {
  readonly type = TdStoreActionTypes.TD_STORE;

  constructor(public payload: string) {}
}

export class TdStoreEditAction implements Action {
  readonly type = TdStoreActionTypes.TD_STORE_EDIT;

  constructor(public payload: TdStore) {}
}

export class TdStoreSuccessAction implements Action {
  readonly type = TdStoreActionTypes.TD_STORE_SUCCESS;

  constructor(public payload: TdStore) {}
}

export class TdStoreFailureAction implements Action {
  readonly type = TdStoreActionTypes.TD_STORE_FAILURE;

  constructor(public payload: ErrorResponse) {}
}

export class ResetTdStoreState implements Action {
  readonly type = TdStoreActionTypes.RESET_TD_STORE_STATE;

  constructor() {}
}

export type TdStoreAction =
  | TdStoreGetAction
  | TdStoreEditAction
  | TdStoreSuccessAction
  | TdStoreFailureAction
  | ResetTdStoreState;
