import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class UserInfoEffects {
  constructor(private actions: Actions, private readonly logger: NGXLogger) {}

  // @Effect()
  // UserInformation: Observable<Action> = this.actions.pipe(
  //   ofType<UserInformationAction>(UserActionTypes.USER_INFO),
  //   map(action => {
  //     this.logger.debug(
  //       '@Effect UserInformationAction: ' + this.stringify(action.payload)
  //     );
  //     return new UserInformationAction(action.payload);
  //   })
  // );

  // private stringify(data: any) {
  //   return JSON.stringify(data);
  // }
}
