import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { BaseRolesState } from '../state/roles.state';

const selectRoles = (state: AppStates) => state.roles;

export const selectRolesResult = createSelector(
  selectRoles,
  (state: BaseRolesState) => state
);
