import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-verification-invalid',
  templateUrl: './merchant-verification-invalid.component.html',
  styleUrls: ['./merchant-verification-invalid.component.scss']
})
export class MerchantVerificationInvalidComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
