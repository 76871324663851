import { Action } from '@ngrx/store';
import { CategoryMenuTree } from '../../models/category-menu-tree.model';
import { ErrorResponse } from '../../models/error.model';

export enum CatalogListMenuActionTypes {
  CATALOG_LIST_MENU_REQUEST = '[Orders] Catalog List Menu Request',
  CATALOG_LIST_MENU = '[Orders] Catalog List Menu',
  CATALOG_LIST_MENU_ERROR = '[Orders] Catalog List Menu Error',
  CATALOG_LIST_MENU_RESET = '[Orders] Catalog List Menu Reset'
}

export class CatalogListMenuRequestAction implements Action {
  readonly type = CatalogListMenuActionTypes.CATALOG_LIST_MENU_REQUEST;

  constructor() {}
}

export class CatalogListMenuResponseAction implements Action {
  readonly type = CatalogListMenuActionTypes.CATALOG_LIST_MENU;

  constructor(public payload: CategoryMenuTree[]) {}
}

export class CatalogListMenuErrorAction implements Action {
  readonly type = CatalogListMenuActionTypes.CATALOG_LIST_MENU_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class CatalogListMenuResetAction implements Action {
  readonly type = CatalogListMenuActionTypes.CATALOG_LIST_MENU_RESET;

  constructor() {}
}
export type CatalogListMenuActions =
  | CatalogListMenuRequestAction
  | CatalogListMenuResponseAction
  | CatalogListMenuErrorAction
  | CatalogListMenuResetAction;
