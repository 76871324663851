import { NGXLogger } from 'ngx-logger';
import * as i0 from "@angular/core";
import * as i1 from "ngx-logger";
var ErrorMissingTranslationHandlerService = /** @class */ (function () {
    function ErrorMissingTranslationHandlerService(logger) {
        this.logger = logger;
    }
    ErrorMissingTranslationHandlerService.prototype.handle = function (params) {
        this.logger.debug('ErrorMissingTranslationHandlerService', params);
        if (params.key.startsWith('ERROR_CODE')) {
            return params.translateService.instant('ERROR_CODE.undefined') + ("[" + params.key + "]");
        }
        return params.key;
    };
    ErrorMissingTranslationHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorMissingTranslationHandlerService_Factory() { return new ErrorMissingTranslationHandlerService(i0.ɵɵinject(i1.NGXLogger)); }, token: ErrorMissingTranslationHandlerService, providedIn: "root" });
    return ErrorMissingTranslationHandlerService;
}());
export { ErrorMissingTranslationHandlerService };
