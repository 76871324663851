import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserListResponse } from '../../models/user-list.model';
import { UserListService } from '../../services';
import {
  UsersActionTypes,
  UsersListErrorAction,
  UsersListRequestAction,
  UsersListResponseAction
} from '../actions/user-list.actions';

@Injectable()
export class UserListEffects {
  constructor(private actions: Actions, private readonly logger: NGXLogger, private usersService: UserListService) {}

  @Effect()
  Users$: Observable<Action> = this.actions.pipe(
    ofType<UsersListRequestAction>(UsersActionTypes.USERS_REQUEST),
    map(action => {
      this.logger.debug('@Effect UsersListResponseAction: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.usersService.getUsers(payload).pipe(
        map((resp: HttpResponse<UserListResponse>) => {
          return new UsersListResponseAction(resp.body);
        }),
        catchError(err => of(new UsersListErrorAction(err.error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
