import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models/error.model';
import { ChangePasswordRequest, ChangePasswordResponse } from '../../models/force-change-password.model';

export enum ForceChangePasswordActionTypes {
  CHANGE_PASSWORD_REQUEST = '[Force Change Password] ChangePassword Request',
  CHANGE_PASSWORD_SUCCESS = '[Force Change Password] ChangePassword Success',
  CHANGE_PASSWORD_FAILED = '[Force Change Password] ChangePassword Failed',
  CHANGE_PASSWORD_RESET = '[Force Change Password] ChangePassword Reset'
}

export class ForceChangePasswordRequestAction implements Action {
  readonly type = ForceChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST;

  constructor(public payload: ChangePasswordRequest) {}
}

export class ForceChangePasswordSuccess implements Action {
  readonly type = ForceChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS;

  constructor(public payload: ChangePasswordResponse) {}
}

export class ForceChangePasswordFailed implements Action {
  readonly type = ForceChangePasswordActionTypes.CHANGE_PASSWORD_FAILED;

  constructor(public payload: ErrorResponse) {}
}

export class ForceChangePasswordReset implements Action {
  readonly type = ForceChangePasswordActionTypes.CHANGE_PASSWORD_RESET;

  constructor() {}
}

export type ForceChangePasswordActions =
  | ForceChangePasswordRequestAction
  | ForceChangePasswordSuccess
  | ForceChangePasswordFailed
  | ForceChangePasswordReset;
