import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TdStore } from '../../models/td-store.model';
import { StoresService } from '../../services/stores.service';
import {
  TdStoreActionTypes,
  TdStoreEditAction,
  TdStoreFailureAction,
  TdStoreGetAction,
  TdStoreSuccessAction
} from '../actions/td-store.action';

@Injectable()
export class TdStoreEffects {
  constructor(
    private actions: Actions,
    private tdStoresService: StoresService,
    private readonly router: Router,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  TdStoreGet: Observable<Action> = this.actions.pipe(
    ofType<TdStoreGetAction>(TdStoreActionTypes.TD_STORE),
    switchMap(action => {
      this.logger.debug('@Effect TdStoreGetAction: ' + this.stringify(action.payload));
      return this.tdStoresService.getStore(action.payload).pipe(
        map((resp: TdStore) => {
          return new TdStoreSuccessAction(resp);
        }),
        catchError(err => {
          return of(new TdStoreFailureAction(err.error));
        })
      );
    })
  );

  @Effect()
  TdStoreEdit: Observable<Action> = this.actions.pipe(
    ofType<TdStoreEditAction>(TdStoreActionTypes.TD_STORE_EDIT),
    switchMap(action => {
      this.logger.debug('@Effect TdStoreEditAction: ' + this.stringify(action.payload));
      return this.tdStoresService.editStore(action.payload).pipe(
        map((resp: TdStore) => {
          return new TdStoreSuccessAction(resp);
        }),
        catchError(err => {
          return of(new TdStoreFailureAction(err.error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  TdStoresSuccess: Observable<Action> = this.actions.pipe(
    ofType(TdStoreActionTypes.TD_STORE_SUCCESS),
    tap((resp: TdStoreSuccessAction) => {
      this.logger.debug('@Effect TdStoreSuccessAction: ' + this.stringify(resp.payload));
    })
  );

  @Effect({ dispatch: false })
  TdStoresFailure: Observable<Action> = this.actions.pipe(
    ofType(TdStoreActionTypes.TD_STORE_FAILURE),
    tap((resp: TdStoreFailureAction) => {
      this.logger.debug('@Effect TdStoreFailureAction: ' + this.stringify(resp));
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
