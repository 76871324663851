import { All, TdStoresTreeViewActionTypes } from '../actions/td-stores-tree-view.action';
import { BaseTdStoresTreeViewState, initialTdStoresTreeViewState } from '../state/td-stores-tree-view.state';

export function tdStoresTreeViewReducers(state = initialTdStoresTreeViewState, action: All): BaseTdStoresTreeViewState {
  switch (action.type) {
    case TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_RESPONSE: {
      return {
        ...state,
        items: action.payload
      };
    }
    case TdStoresTreeViewActionTypes.RESET_TD_STORES_TREE_STATE: {
      return initialTdStoresTreeViewState;
    }
    default: {
      return state;
    }
  }
}
