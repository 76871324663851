import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { TreeItem, TreeviewItem } from 'ngx-treeview';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TdStoreListContent } from '../../models/td-store-list.model';
import { TdStoreSelectObject } from '../../models/td-store-select-object.model';
import {
  TdStoresTreeViewActionTypes,
  TdStoresTreeViewRequestAction,
  TdStoresTreeViewResponseAction,
  TdStoresTreeViewSelectedRequestAction
} from '../actions/td-stores-tree-view.action';

@Injectable()
export class TdStoresTreeViewEffects {
  constructor(
    private actions: Actions,
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly translate: TranslateService
  ) {}

  @Effect()
  create$: Observable<Action> = this.actions.pipe(
    ofType(TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_REQUEST),
    map((action: TdStoresTreeViewRequestAction) => {
      this.logger.debug(`@Effect ${TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_REQUEST}`);

      const rootText = action.payload ? 'ACCESS_TO_ALL_STORES' : 'NO_RESULT_FOUND';

      let treeViewItemRoot: TreeItem = {
        text: this.translate.instant(rootText),
        value: ''
      };

      if (action.payload) {
        const treeViewItem: TreeviewItem[] = action.payload.map((value: TdStoreSelectObject) => {
          const item = new TreeviewItem({
            text: `${value.code}-${value.name}`,
            value: value.no
          });
          item.setCheckedRecursive(false);
          return item;
        });

        treeViewItemRoot = {
          ...treeViewItemRoot,
          ...{
            children: treeViewItem
          }
        };
      }

      return new TdStoresTreeViewResponseAction(new Array(new TreeviewItem(treeViewItemRoot)));
    })
  );

  @Effect()
  checkedCreate$: Observable<Action> = this.actions.pipe(
    ofType(TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_CHECKED_REQUEST),
    map((action: TdStoresTreeViewSelectedRequestAction) => {
      this.logger.debug(`@Effect ${TdStoresTreeViewActionTypes.TD_STORES_TREE_VIEW_CHECKED_REQUEST}`);

      const rootText = action.payload ? 'ACCESS_TO_ALL_STORES' : 'NO_RESULT_FOUND';

      let treeViewItemRoot: TreeItem = {
        text: this.translate.instant(rootText),
        value: '',
        checked: false
      };

      if (action.payload) {
        const treeViewItem: TreeItem[] = action.payload.merchant.map((value: TdStoreListContent) => {
          return {
            text: value.name,
            value: value.no,
            checked: action.payload.stores.includes(value.no)
          };
        });

        treeViewItemRoot = {
          ...treeViewItemRoot,
          ...{
            children: treeViewItem
          }
        };
      }

      return new TdStoresTreeViewResponseAction(new Array(new TreeviewItem(treeViewItemRoot)));
    })
  );

  // TdStoresTreeViewSelectedRequestAction

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
