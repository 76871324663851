import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { BaseTdStoreState } from '../state/td-store.state';

const selectTdStore = (state: AppStates) => state.tdStore;

export const selectTdStoreResult = createSelector(
  selectTdStore,
  (state: BaseTdStoreState) => state
);

export const selectTdStorePolicyResult = createSelector(
  selectTdStore,
  (state: BaseTdStoreState) => state.result.response
);
