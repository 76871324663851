import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textEllipsis'
})
export class TextEllipsisPipe implements PipeTransform {
  transform(value: string, args?: string[]): string {
    const limit = args && args[0] ? args[0] : 35;
    const trail = args && args[1] ? args[1] : '...';
    return value.length > limit ? value.substring(0, Number(limit)) + trail : value;
  }
}
