import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { UserInfoState } from 'src/app/shared/store/state/user-info.state';
import { AuthGuardService } from '../../../shared/services';
import { selectUserInfoResult } from '../../../shared/store/selectors/auth-user-info.selector';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-force-change-password-left',
  templateUrl: './force-change-password-left.component.html',
  styleUrls: ['./force-change-password-left.component.scss']
})
export class ForceChangePasswordLeftComponent implements OnInit, OnDestroy {
  public userInfoResult$: Observable<UserInfoState>;
  private localStore: Observable<any>;

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppStates>,
    private authGuardService: AuthGuardService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.userInfoResult$ = this.localStore.pipe(select(selectUserInfoResult));
  }

  ngOnDestroy(): void {}

  onCancel() {
    this.authGuardService.logOut();
    this.router.navigateByUrl('/login');
  }
}
