import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService {
  public readonly env = environment;
  public envService;

  protected headers: HttpHeaders;

  protected constructor() {}

  loaderHeaders(ms: number = 0): HttpHeaders {
    return new HttpHeaders(this.envService.headers).set('x-frontend-spinner-loader', ms.toString());
  }
  /**
   * Encode an object to HttpParams
   * eg. this.getParams({key:value})
   * @param query  Object for encoding to HttpParams.
   * @returns HttpParams with a query.
   */
  protected getParams(query): HttpParams {
    let params: HttpParams = new HttpParams();

    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach(item => {
            params = params.append(`${key.toString()}[]`, item);
          });
        } else {
          params = params.append(key.toString(), query[key]);
        }
      } else if (query[key] === 0 || query[key] === false) {
        params = params.append(key.toString(), query[key]);
      }
    }

    return params;
  }

  /**
   * Returns the full url of the service.
   * eg. this.getUrl('serviceAppend',{'param1':'Param1','param2':'Param2'}
   * @param serviceUrl  serviceUrl.
   * @param urlParams  Object for replacing all matches in serviceAppend.
   * @returns Full service url
   */
  protected getFullUrl(serviceUrl, urlParams: { [key: string]: string } = null): string {
    console.log('serviceUrl', serviceUrl);
    for (const urlParamsKey in urlParams) {
      if (urlParams[urlParamsKey]) {
        serviceUrl = serviceUrl.replace(new RegExp(`{${urlParamsKey}}`, 'g'), urlParams[urlParamsKey]);
      }
    }

    return this.env.serverUrl + this.envService.url + serviceUrl;
  }

  public getUrl() {
    return this.env.serverUrl + this.envService.url;
  }
}
