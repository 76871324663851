import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
var VerificationService = /** @class */ (function (_super) {
    tslib_1.__extends(VerificationService, _super);
    function VerificationService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.envService = _this.env.services.verification;
        _this.headers = new HttpHeaders(_this.envService.headers);
        return _this;
    }
    VerificationService.prototype.merchantVerification = function (req) {
        var url = this.getFullUrl(this.envService.verifyMobile, {
            merchantId: req.merchantId
        });
        return this.http.put(url, null, { headers: this.headers, params: { idCard: req.idNumber } });
    };
    VerificationService.prototype.getMerchantStatus = function (merchantId, accessToken) {
        this.headers = this.headers.append('Authorization', "Bearer " + accessToken);
        var url = this.getFullUrl(this.envService.status, {
            merchantId: merchantId
        });
        return this.http.get(url, {
            headers: this.headers
        });
    };
    return VerificationService;
}(BaseService));
export { VerificationService };
