import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { TenantInfoState } from '../state/tenant-info.state';

const selectTenantInfo = (state: AppStates) => state.tenantInfo;

export const selectTenantInfoResult = createSelector(
  selectTenantInfo,
  (state: TenantInfoState) => state
);
