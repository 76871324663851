import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { TreeviewConfig } from 'ngx-treeview';
import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ResetPasswordTypeEnum } from '../../../shared/enum/reset-password-type.enum';
import { TDPageModes } from '../../../shared/enum/store.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { Tenant, UserRequest } from '../../../shared/models/user.model';
import { TdStoreGroupListGetAction } from '../../../shared/store/actions/td-store-group-list.action';
import { TdStoreListGetAction } from '../../../shared/store/actions/td-store-list.action';
import { ResetTdStoresTreeViewAction, TdStoresTreeViewSelectedRequestAction } from '../../../shared/store/actions/td-stores-tree-view.action';
import { ResetUserState, UsersEditAction, UsersGetAction, UsersResetPasswordPinCodeResetAction, UsersResetPasswordRequestAction, UsersResetPinCodeRequestAction } from '../../../shared/store/actions/users.action';
import { selectUserInfoResult } from '../../../shared/store/selectors/auth-user-info.selector';
import { selectResetPasswordResult, selectResetPinResult } from '../../../shared/store/selectors/reset-password-pin.selector';
import { selectTdStoreGroupListResult } from '../../../shared/store/selectors/td-store-group-list.selector';
import { selectTdStoreListResult } from '../../../shared/store/selectors/td-store-list.selector';
import { selectTdStoresTreeViewResult } from '../../../shared/store/selectors/td-stores-tree-view.selector';
import { selectTenantInfoResult } from '../../../shared/store/selectors/tenant-info.selector';
import { selectUserSelectValueGroupByType } from '../../../shared/store/selectors/user-select-value.selectors';
import { selectUsersResult } from '../../../shared/store/selectors/users.selector';
import { getRoles, isOwner } from '../../../shared/utils/check-role';
var EditComponent = /** @class */ (function () {
    function EditComponent(store, formBuilder, modalService, translate, logger) {
        this.store = store;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.translate = translate;
        this.logger = logger;
        this.notifyParent = new EventEmitter();
        this.env = environment;
    }
    EditComponent.prototype.ngOnInit = function () {
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.merchantType = null;
        this.listOfValue = {};
        this.isStoreOwner = false;
        this.selectedAllStoreInMerchant = false;
        this.selectedStoreGroup = [];
        this.selectedStore = [];
        this.initState();
        this.initControl();
    };
    EditComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new ResetUserState());
        this.store.dispatch(new UsersResetPasswordPinCodeResetAction());
    };
    EditComponent.prototype.initState = function () {
        var _this = this;
        this.localStore.pipe(select(selectUserInfoResult)).subscribe(function (userInfo) {
            _this.userInfo = userInfo;
            _this.isStoreOwner = userInfo.roles.includes('STORE_OWNER') || userInfo.roles.includes('STORE_OWNER_PARTNER');
            if (!_this.isStoreOwner) {
                _this.editUserForm.controls['stores'].setValidators(Validators.required);
            }
        });
        this.localStore.pipe(select(selectTenantInfoResult)).subscribe(function (tenantInfo) {
            _this.merchantNo = tenantInfo.merchant;
            _this.tdStoreAuthorityList = tenantInfo.stores;
        });
        this.tdStores$ = this.localStore.pipe(select(selectTdStoreListResult), map(function (resp) { return resp.result.response; }), filter(function (value) { return value !== null; }), switchMap(function (resp) { return of(resp); }));
        this.tdStores$.subscribe(function (value) {
            _this.merchantType = value.content[0].merchantType;
            _this.listOfValue['roles'] = getRoles(_this.merchantType, false);
        });
        this.store.dispatch(new TdStoreListGetAction({ merchantNo: this.merchantNo, storeNo: this.tdStoreAuthorityList.join(',') }));
        if (this.isStoreOwner) {
            this.store.dispatch(new TdStoreGroupListGetAction({ merchantNo: this.merchantNo }));
            this.tdStoreGroupList$ = this.localStore.pipe(select(selectTdStoreGroupListResult), switchMap(function (resp) { return of(resp); }));
        }
        else {
            this.localStore
                .pipe(select(selectTdStoresTreeViewResult), switchMap(function (resp) { return of(resp); }))
                .subscribe(function (value) {
                if (_this.data.mode === TDPageModes.REQUEST_VIEW) {
                    value.forEach(function (item) { return (item.disabled = true); });
                }
                _this.tdStoreList = value;
            });
        }
        this.store.dispatch(new UsersGetAction(this.data.id));
        this.users$ = this.localStore.pipe(select(selectUsersResult));
        this.users$.pipe(map(function (action) { return action.result; })).subscribe(function (next) {
            if (next.response && _this.submitted) {
                _this.onSuccess(next);
            }
            else if (next.errorResponse) {
                _this.onError(next.errorResponse);
            }
            else {
                _this.logger.debug(next);
            }
        }, function (error) { return _this.onError(error); }, function () { return _this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null }); });
        this.store.dispatch(new ResetTdStoresTreeViewAction());
        this.localStore
            .pipe(select(selectResetPasswordResult), filter(function (result) { return Boolean(result) && Boolean(result.statusCode); }), tap(function (result) { return _this.resetPasswordEvent(result.statusCode, ResetPasswordTypeEnum.PASSWORD); }))
            .subscribe();
        this.localStore
            .pipe(select(selectResetPinResult), filter(function (result) { return Boolean(result) && Boolean(result.statusCode); }), tap(function (result) { return _this.resetPasswordEvent(result.statusCode, ResetPasswordTypeEnum.PIN); }))
            .subscribe();
        this.userSelectValue$ = this.localStore.pipe(select(selectUserSelectValueGroupByType));
    };
    EditComponent.prototype.initControl = function () {
        var _this = this;
        this.editUserForm = this.formBuilder.group({
            id: '',
            no: '',
            version: ['', Validators.required],
            firstName: ['', [Validators.required, Validators.maxLength(100)]],
            lastName: ['', [Validators.required, Validators.maxLength(100)]],
            localFirstName: ['', [Validators.required, Validators.maxLength(100)]],
            localLastName: ['', [Validators.required, Validators.maxLength(100)]],
            mobile: ['', [Validators.required, Validators.maxLength(100)]],
            email: ['', [Validators.email, Validators.maxLength(100)]],
            gender: ['', []],
            active: ['', []],
            birthDay: ['', []],
            roles: [null, [Validators.required]],
            stores: [null, []]
        });
        this.tdStoresConfig = new TreeviewConfig();
        this.tdStoresConfig.hasAllCheckBox = false;
        this.bsConfig = {
            dateInputFormat: this.env.dateFormat,
            maxDate: new Date(),
            showWeekNumbers: false
        };
        combineLatest([
            this.localStore.pipe(select(selectUserInfoResult), map(function (authUserInfo) { return authUserInfo.userNo; })),
            this.users$.pipe(filter(function (val) { return val.result.response !== null; }), map(function (val) { return val.result.response; }), filter(function (val) { return val !== null && !_this.submitted; }))
        ]).subscribe(function (_a) {
            var loginUserName = _a[0], userResponse = _a[1];
            _this.onBindingFormData(userResponse);
            _this.userResponse = userResponse;
            _this.editUserForm.controls['active'].setValue(userResponse.active.toString());
            _this.editUserForm.controls['active'].disable();
            if (loginUserName === userResponse.userNo) {
                _this.editUserForm.controls['active'].disable();
            }
            else {
                _this.editUserForm.controls['active'].enable();
            }
            if (_this.data.mode === TDPageModes.REQUEST_VIEW) {
                _this.editUserForm.disable();
            }
        });
        if (this.isStoreOwner) {
            combineLatest([
                this.tdStoreGroupList$.pipe(filter(function (val) { return Boolean(val); })),
                this.users$.pipe(filter(function (val) { return val.result.response !== null; }), map(function (val) { return val.result.response; }))
            ]).subscribe(function (_a) {
                var StoreList = _a[0], user = _a[1];
                _this.selectedAllStoreInMerchant = user.accessAllStores;
                _this.selectedStoreGroup = user.accessStoreGroups;
                _this.selectedStore = user.accessStores;
                StoreList.forEach(function (storeGroup) {
                    var selectGroup = _this.selectedStoreGroup.includes(storeGroup.no) && !_this.selectedAllStoreInMerchant;
                    storeGroup.selected = selectGroup;
                    storeGroup.stores.forEach(function (store) {
                        if (!selectGroup) {
                            store.selected = _this.selectedStore.includes(store.no) && !_this.selectedAllStoreInMerchant;
                        }
                    });
                });
                _this.tdStoreGroupList = StoreList.slice();
            });
        }
        else {
            combineLatest([
                this.tdStores$.pipe(map(function (val) { return val.content.filter(Boolean); })),
                this.users$.pipe(filter(function (val) { return val.result.response !== null; }), map(function (val) { return val.result.response.tenant.stores.filter(Boolean); }))
            ]).subscribe(function (_a) {
                var merchant = _a[0], stores = _a[1];
                return _this.store.dispatch(new TdStoresTreeViewSelectedRequestAction({ merchant: merchant, stores: stores }));
            });
        }
    };
    EditComponent.prototype.resetPasswordEvent = function (statusCode, type) {
        if (statusCode === '200') {
            this.alertSuccessResetPasswordPin(type);
        }
        else {
            this.alertFailedResetPasswordPin(type);
        }
    };
    EditComponent.prototype.onBindingFormData = function (data) {
        var _this = this;
        var countryCode = data.countryCode || '';
        var mobileNo = data.mobileNo || '';
        this.userName = data.userName;
        this.editUserForm.controls['mobile'].setValue("" + countryCode + mobileNo);
        this.editUserForm.controls['birthDay'].setValue(data.birthDate);
        this.editUserForm.controls['roles'].enable();
        for (var _i = 0, _a = Object.entries(data).filter(function (_a) {
            var name = _a[0], val = _a[1];
            return val !== null && _this.editUserForm.contains(name);
        }); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (key === 'roles') {
                var role = value[0];
                if (isOwner(role)) {
                    this.editUserForm.controls['roles'].disable();
                    this.listOfValue['roles'] = getRoles(this.merchantType, true);
                }
                this.editUserForm.controls['roles'].setValue(role);
            }
            else if (key !== 'active') {
                this.editUserForm.controls[key].setValue(value);
            }
        }
        // Add validation rules
        this.tmpElementValue = new Array();
        this.preventBlock(this.editUserForm.controls, 'firstName', /^[a-z A-Z]*$/);
        this.preventBlock(this.editUserForm.controls, 'lastName', /^[a-z A-Z]*$/);
    };
    EditComponent.prototype.setStores = function (result) {
        this.editUserForm.controls['stores'].setValue(result);
    };
    EditComponent.prototype.onSubmit = function () {
        var req;
        this.submitted = true;
        if (this.editUserForm.invalid || this.validateStore) {
            if (!this.editUserForm.invalid && this.validateStore) {
                this.showAlert(this.translate.instant('FAILED'), this.translate.instant('PLEASE_SELECT_AT_LEAST_ONE_STORE'));
            }
            return;
        }
        req = new UserRequest(this.editUserForm.getRawValue());
        req.tenant = new Tenant();
        req.tenant.merchant = this.merchantNo;
        req.accessAllStores = this.selectedAllStoreInMerchant;
        req.accessStoreGroups = this.selectedStoreGroup;
        req.accessStores = this.isStoreOwner ? this.selectedStore : this.editUserForm.value.stores;
        req.countryCode = this.editUserForm.value.mobile.internationalNumber.split(' ')[0];
        req.mobileNo = this.editUserForm.value.mobile.nationalNumber.split(' ').join('');
        if (this.editUserForm.value.birthDay && this.editUserForm.value.birthDay !== '') {
            req.birthDate = moment(this.editUserForm.value.birthDay, environment.dateFormat).format(environment.dateFormat);
        }
        this.store.dispatch(new UsersEditAction(req));
    };
    EditComponent.prototype.onCancel = function () {
        if (this.editUserForm.touched) {
            var initialState = {
                title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
                okText: this.translate.instant('STAY_ON_PAGE'),
                cancelText: this.translate.instant('LEAVE'),
                message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
            };
            this.notifyParent.emit({
                initialState: initialState,
                notificationType: NotificationTypeEnum.CONFIRM,
                result: null
            });
        }
        else {
            this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: null });
        }
    };
    EditComponent.prototype.showAlert = function (title, message) {
        var initialState = {
            title: title,
            message: message
        };
        this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
    };
    Object.defineProperty(EditComponent.prototype, "validateStore", {
        get: function () {
            var checkSelectedStore = this.selectedAllStoreInMerchant || this.selectedStoreGroup.length > 0 || this.selectedStore.length > 0;
            return this.isStoreOwner && !checkSelectedStore;
        },
        enumerable: true,
        configurable: true
    });
    EditComponent.prototype.onChangeAll = function (event, storeGroupList) {
        var _this = this;
        if (event.target.checked) {
            this.selectedAllStoreInMerchant = true;
            this.selectedStoreGroup = [];
            this.selectedStore = [];
            storeGroupList.forEach(function (storeGroup) { return _this.clearSelectedStoreAll(storeGroup); });
        }
        else {
            this.selectedAllStoreInMerchant = false;
            storeGroupList.forEach(function (storeGroup) { return _this.selectedEnableStoreAll(storeGroup); });
        }
    };
    EditComponent.prototype.onCheckboxChangeGroup = function (event, storeGroup) {
        if (event.target.checked) {
            this.selectStoreGroup(storeGroup);
        }
        else {
            this.deSelectStoreGroup(storeGroup);
        }
    };
    EditComponent.prototype.onCheckboxChange = function (event, store) {
        if (event.target.checked) {
            store.selected = true;
            this.selectedStore.push(store.no);
        }
        else {
            store.selected = false;
            this.removeSelectedStore(store.no);
        }
    };
    EditComponent.prototype.selectStoreGroup = function (storeGroupUser) {
        var _this = this;
        storeGroupUser.selected = true;
        this.selectedStoreGroup.push(storeGroupUser.no);
        storeGroupUser.stores.forEach(function (store) {
            store.selected = false;
            store.disabled = true;
            _this.removeSelectedStore(store.no);
        });
    };
    EditComponent.prototype.deSelectStoreGroup = function (storeGroupUser) {
        storeGroupUser.selected = false;
        this.removeSelectedStoreGroup(storeGroupUser.no);
        storeGroupUser.stores.forEach(function (store) {
            store.disabled = false;
        });
    };
    EditComponent.prototype.clearSelectedStoreAll = function (storeGroupUser) {
        storeGroupUser.selected = false;
        storeGroupUser.disabled = true;
        storeGroupUser.stores.forEach(function (store) {
            store.selected = false;
            store.disabled = true;
        });
    };
    EditComponent.prototype.selectedEnableStoreAll = function (storeGroupUser) {
        storeGroupUser.disabled = false;
        storeGroupUser.stores.forEach(function (store) {
            store.disabled = false;
        });
    };
    EditComponent.prototype.removeSelectedStoreGroup = function (code) {
        this.selectedStoreGroup.splice(this.selectedStoreGroup.findIndex(function (itemSelected) { return itemSelected === code; }), 1);
    };
    EditComponent.prototype.removeSelectedStore = function (code) {
        this.selectedStore.splice(this.selectedStore.findIndex(function (itemSelected) { return itemSelected === code; }), 1);
    };
    EditComponent.prototype.handleResetPassword = function () {
        if (this.userResponse) {
            this.confirmResetPassword();
        }
    };
    EditComponent.prototype.confirmResetPassword = function () {
        var _this = this;
        var initialState = {
            title: this.translate.instant('RESET_PASSWORD'),
            message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_RESET_PASSWORD', {
                fullName: this.userResponse.firstName + " " + this.userResponse.lastName
            }),
            okText: this.translate.instant('OK')
        };
        var confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState
        });
        confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(function (result) {
            if (result === ModalButtonResponseEnum.OK) {
                // TODO Dispatch reset password
                _this.store.dispatch(new UsersResetPasswordRequestAction({ userName: _this.userName }));
                confirmModalRef.content.action.unsubscribe();
            }
        });
    };
    EditComponent.prototype.handleResetPinCode = function () {
        var _this = this;
        var initialState = {
            title: this.translate.instant('RESET_PIN_CODE'),
            message: this.translate.instant('ARE_YOU_SURE_YOU_WANT_TO_RESET_PIN_CODE', {
                fullName: this.userResponse.firstName + " " + this.userResponse.lastName
            }),
            okText: this.translate.instant('OK')
        };
        var confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState
        });
        confirmModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(function (result) {
            if (result === ModalButtonResponseEnum.OK) {
                // TODO Dispatch reset password
                _this.store.dispatch(new UsersResetPinCodeRequestAction({ userName: _this.userName }));
                confirmModalRef.content.action.unsubscribe();
            }
        });
    };
    EditComponent.prototype.alertSuccessResetPasswordPin = function (type) {
        var initialState = {
            title: this.translate.instant('SUCCESS'),
            message: type === ResetPasswordTypeEnum.PASSWORD
                ? this.translate.instant('PASSWORD_RESET_SUCCESS', { mobileNo: this.userResponse.mobileNo })
                : this.translate.instant('PIN_RESET_SUCCESS', { mobileNo: this.userResponse.mobileNo })
        };
        this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
    };
    EditComponent.prototype.alertFailedResetPasswordPin = function (type) {
        var initialState = {
            title: this.translate.instant('FAILED'),
            message: type === ResetPasswordTypeEnum.PASSWORD
                ? this.translate.instant('PASSWORD_RESET_FAILED')
                : this.translate.instant('PIN_RESET_FAILED')
        };
        this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
    };
    Object.defineProperty(EditComponent.prototype, "f", {
        get: function () {
            return this.editUserForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditComponent.prototype, "pageMode", {
        get: function () {
            return TDPageModes;
        },
        enumerable: true,
        configurable: true
    });
    EditComponent.prototype.preventBlock = function (controls, key, regexp) {
        var _this = this;
        if (regexp === void 0) { regexp = /^.*$/; }
        if (!this.tmpElementValue[key]) {
            this.tmpElementValue[key] = '';
        }
        controls[key].valueChanges.pipe(filter(function (val) { return val !== _this.tmpElementValue[key]; })).forEach(function (val) {
            if (regexp.test(val)) {
                _this.tmpElementValue[key] = val;
            }
            controls[key].setValue(_this.tmpElementValue[key]);
        });
    };
    EditComponent.prototype.onSuccess = function (resp) {
        var _this = this;
        var initialState = {
            title: this.translate.instant('SUCCESS'),
            message: this.translate.instant('USER_UPDATED'),
            routerLink: 'users'
        };
        this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(function () {
            _this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: resp });
        });
        this.modalService.show(AlertModalComponent, {
            initialState: initialState
        });
    };
    EditComponent.prototype.onError = function (resp) {
        var _this = this;
        if (resp.translateKey) {
            var initialState = {
                title: this.translate.instant('FAILED'),
                message: this.translate.instant(resp.translateKey)
            };
            var modal_1 = this.modalService.onHidden.pipe(untilComponentDestroyed(this)).subscribe(function () {
                _this.notifyParent.emit({ notificationType: NotificationTypeEnum.LOGGING, result: resp });
                modal_1.unsubscribe();
            });
            this.modalService.show(AlertModalComponent, { initialState: initialState });
        }
    };
    Object.defineProperty(EditComponent.prototype, "isViewMode", {
        get: function () {
            return this.data.mode === TDPageModes.REQUEST_VIEW;
        },
        enumerable: true,
        configurable: true
    });
    EditComponent.prototype.onTriggerEdit = function () {
        this.data.mode = TDPageModes.REQUEST_EDIT;
        this.data.title = this.translate.instant('EDIT_USER');
        if (!this.isStoreOwner) {
            this.tdStoreList.forEach(function (item) { return (item.disabled = false); });
        }
        this.editUserForm.enable();
    };
    return EditComponent;
}());
export { EditComponent };
