import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { VerificationService } from '../../services/verification.service';
import { MerchantVerificationErrorAction, MerchantVerificationResponseAction, VerificationActionTypes } from '../actions/verification.actions';
var VerificationEffects = /** @class */ (function () {
    function VerificationEffects(actions, logger, verificationService) {
        var _this = this;
        this.actions = actions;
        this.logger = logger;
        this.verificationService = verificationService;
        this.merchantVerification$ = this.actions.pipe(ofType(VerificationActionTypes.MERCHANT_VERIFICATION_REQUEST), map(function (action) { return action.payload; }), switchMap(function (payload) {
            _this.logger.debug('@Effect MerchantVerificationAction: ' + _this.stringify(payload));
            return _this.verificationService.merchantVerification(payload).pipe(map(function () {
                return new MerchantVerificationResponseAction({ statusCode: '200', message: 'OK' });
            }), catchError(function (err) { return of(new MerchantVerificationErrorAction(err.error)); }));
        }));
    }
    VerificationEffects.prototype.stringify = function (data) {
        return JSON.stringify(data);
    };
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], VerificationEffects.prototype, "merchantVerification$", void 0);
    return VerificationEffects;
}());
export { VerificationEffects };
