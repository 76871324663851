import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models/error.model';
import { TdStoreListResponse } from '../../models/td-store-list.model';
import { UrlParams } from '../../models/url-params.model';

export enum TdStoreListActionTypes {
  TD_STORE_LIST = '[TdStore List] Get list',
  TD_STORE_LIST_SUCCESS = '[TdStore List] Success',
  TD_STORE_LIST_FAILURE = '[TdStore List] Failure',
  RESET_TD_STORE_LIST_STATE = '[TdStore List] Reset TdStore List State'
}

export class TdStoreListGetAction implements Action {
  readonly type = TdStoreListActionTypes.TD_STORE_LIST;

  constructor(public payload: UrlParams) {}
}

export class TdStoreListSuccessAction implements Action {
  readonly type = TdStoreListActionTypes.TD_STORE_LIST_SUCCESS;

  constructor(public payload: TdStoreListResponse) {}
}

export class TdStoreListFailureAction implements Action {
  readonly type = TdStoreListActionTypes.TD_STORE_LIST_FAILURE;

  constructor(public payload: ErrorResponse) {}
}

export class ResetTdStoreListAction implements Action {
  readonly type = TdStoreListActionTypes.RESET_TD_STORE_LIST_STATE;

  constructor() {}
}

export type TdStoreListAction =
  | TdStoreListGetAction
  | TdStoreListSuccessAction
  | TdStoreListFailureAction
  | ResetTdStoreListAction;
