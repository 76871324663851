import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CategoryMenuTree } from '../../models/category-menu-tree.model';
import { ErrorResponse } from '../../models/error.model';
import { CatalogListMenuActions, CatalogListMenuActionTypes } from '../actions/catalog-list-menu.action';

export interface CategoryMenuTreeState extends EntityState<CategoryMenuTree> {
  isLoading: boolean;
  result?: {
    response: Array<CategoryMenuTree> | null;
    errorResponse: ErrorResponse | null;
  };
}

export const adapter: EntityAdapter<CategoryMenuTree> = createEntityAdapter<CategoryMenuTree>({
  selectId: (catalog: CategoryMenuTree) => catalog.code
});

export const initialCatalogListMenuState: CategoryMenuTreeState = adapter.getInitialState({
  isLoading: false
});

export function catalogListMenuReducers(
  state = initialCatalogListMenuState,
  action: CatalogListMenuActions
): CategoryMenuTreeState {
  switch (action.type) {
    case CatalogListMenuActionTypes.CATALOG_LIST_MENU: {
      action.payload.unshift({ code: 'null' });
      return adapter.upsertMany(action.payload, state);
    }
    case CatalogListMenuActionTypes.CATALOG_LIST_MENU_ERROR: {
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    }
    case CatalogListMenuActionTypes.CATALOG_LIST_MENU_RESET: {
      return adapter.removeAll(state);
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
