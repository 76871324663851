import { Action } from '@ngrx/store';
import { Menu } from '../../models/menu.model';

export enum MenuActionTypes {
  MenuRequested = '[Menu] User Menu Requested',
  MenuLoaded = '[Menu] User Menu Loaded'
}

export class MenuRequestedAction implements Action {
  readonly type = MenuActionTypes.MenuRequested;
  constructor(public payload: string[]) {}
}

export class MenuLoadedAction implements Action {
  readonly type = MenuActionTypes.MenuLoaded;
  constructor(public payload: Menu[]) {}
}

export type MenuActions = MenuRequestedAction | MenuLoadedAction;
