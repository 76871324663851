import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { UserListRequest } from '../models/user-list.model';
import { BaseService } from './base.service';

@Injectable()
export class UserListService extends BaseService {
  constructor(private http: HttpClient, private readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.users;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getUsers<T>(usersRequest: UserListRequest): Observable<HttpResponse<T>> {
    const params = this.getParams(usersRequest);
    return this.http.get<T>(this.getUrl(), {
      headers: this.headers,
      observe: 'response',
      params: params
    });
  }
}
