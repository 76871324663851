import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { zip } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { TDPageModes } from '../../../../enum/store.enum';
import { selectTdStoreResult } from '../../../../store/selectors/td-store.selector';
import { selectUserSelectValueGroupByType } from '../../../../store/selectors/user-select-value.selectors';
var OrderPolicyScheduleComponent = /** @class */ (function () {
    function OrderPolicyScheduleComponent(fb, store) {
        this.fb = fb;
        this.store = store;
        this.schedulesLimit = 7;
    }
    Object.defineProperty(OrderPolicyScheduleComponent.prototype, "schedules", {
        get: function () {
            return this.parentForm.get('schedules');
        },
        enumerable: true,
        configurable: true
    });
    OrderPolicyScheduleComponent.prototype.ngOnDestroy = function () { };
    OrderPolicyScheduleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.localStore = this.store.pipe(untilComponentDestroyed(this));
        this.tdStore$ = this.localStore.pipe(select(selectTdStoreResult), filter(function (v) { return v.result.response !== null; }));
        this.userSelectValue$ = this.localStore.pipe(select(selectUserSelectValueGroupByType), filter(function (v) { return Object.keys(v).length > 0; }));
        zip(this.tdStore$, this.userSelectValue$)
            .pipe(take(1))
            .subscribe(function (results) {
            _this.orderPolicySelectValue = results[1];
            _this.orderScheduleDateOptions = results[1].orderScheduleDate;
            _this.addSchedule();
            _this.setOrderPolicyScheduleValue(results[0].result.response);
            if (_this.mode === TDPageModes.REQUEST_VIEW) {
                _this.schedules.disable();
            }
        });
    };
    Object.defineProperty(OrderPolicyScheduleComponent.prototype, "createForm", {
        get: function () {
            var initialNullRequired = [
                { value: null, disabled: false },
                this.schedules.length === 0 ? Validators.required : null
            ];
            return this.fb.group({
                orderScheduleDate: initialNullRequired,
                orderScheduleTime: initialNullRequired,
                deliverySchedule: initialNullRequired
            });
        },
        enumerable: true,
        configurable: true
    });
    OrderPolicyScheduleComponent.prototype.addSchedule = function () {
        if (this.schedules.length > this.schedulesLimit) {
            return;
        }
        this.schedules.push(this.createForm);
    };
    OrderPolicyScheduleComponent.prototype.deleteSchedule = function (index) {
        this.schedules.removeAt(index);
    };
    OrderPolicyScheduleComponent.prototype.setOrderPolicyScheduleValue = function (tdStore) {
        var _this = this;
        var currentData = tdStore && tdStore.orderSchedule;
        if (currentData && currentData.schedules && currentData.schedules.length > 0) {
            currentData.schedules.forEach(function (field, i) {
                if (!_this.schedules.at(i)) {
                    _this.addSchedule();
                }
                _this.schedules.at(i).patchValue({
                    orderScheduleDate: field.orderScheduleDate,
                    orderScheduleTime: field.orderScheduleTime,
                    deliverySchedule: field.deliverySchedule
                });
            });
        }
    };
    OrderPolicyScheduleComponent.prototype.onSelectedScheduleDate = function (data, rowIndex) {
        if (data.type === 'orderScheduleDate' && rowIndex !== 0) {
            this.updateValidators(rowIndex);
        }
    };
    OrderPolicyScheduleComponent.prototype.updateOrderScheduleDateOptions = function () {
        var _this = this;
        this.orderScheduleDateOptions = this.orderPolicySelectValue.orderScheduleDate.filter(function (v) {
            var currentValues = _this.schedules.value.map(function (val) { return val.orderScheduleDate; });
            if (currentValues.indexOf(v.code) === -1) {
                return v;
            }
        });
    };
    OrderPolicyScheduleComponent.prototype.updateValidators = function (rowIndex) {
        var fieldUpdates = ['orderScheduleTime', 'deliverySchedule'];
        for (var _i = 0, fieldUpdates_1 = fieldUpdates; _i < fieldUpdates_1.length; _i++) {
            var field = fieldUpdates_1[_i];
            this.schedules
                .at(rowIndex)
                .get(field)
                .setValidators([Validators.required]);
            this.schedules
                .at(rowIndex)
                .get(field)
                .updateValueAndValidity();
        }
    };
    OrderPolicyScheduleComponent.prototype.showBtn = function () {
        return this.schedules.length < this.schedulesLimit && this.checkEditPermission();
    };
    OrderPolicyScheduleComponent.prototype.checkEditPermission = function () {
        // add permission when apply edit mode
        return false;
    };
    return OrderPolicyScheduleComponent;
}());
export { OrderPolicyScheduleComponent };
