import { ElementRef, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
var HasPermissionDirective = /** @class */ (function () {
    function HasPermissionDirective(element, templateRef, viewContainer, store) {
        this.element = element;
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.store = store;
        this.permissions = [];
        this.logicalOp = 'OR';
        this.isHidden = true;
    }
    HasPermissionDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.authoritiesSubscription = this.store
            .select(function (state) { return state.userInfo.authorities; })
            .subscribe(function (authorities) {
            _this.userAuthorities = authorities;
            _this.updateView();
        });
    };
    HasPermissionDirective.prototype.ngOnDestroy = function () {
        this.authoritiesSubscription.unsubscribe();
    };
    Object.defineProperty(HasPermissionDirective.prototype, "hasPermission", {
        set: function (val) {
            this.permissions = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HasPermissionDirective.prototype, "hasPermissionOp", {
        set: function (permOp) {
            this.logicalOp = permOp;
        },
        enumerable: true,
        configurable: true
    });
    HasPermissionDirective.prototype.updateView = function () {
        if (this.checkPermission()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        }
        else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    };
    HasPermissionDirective.prototype.checkPermission = function () {
        var hasPermission = false;
        if (this.userAuthorities) {
            var _loop_1 = function (checkPermission) {
                var permissionFound = this_1.userAuthorities.find(function (x) { return x.toUpperCase() === checkPermission.toUpperCase(); });
                if (permissionFound) {
                    hasPermission = true;
                    if (this_1.logicalOp === 'OR') {
                        return "break";
                    }
                }
                else {
                    hasPermission = false;
                    if (this_1.logicalOp === 'AND') {
                        return "break";
                    }
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = this.permissions; _i < _a.length; _i++) {
                var checkPermission = _a[_i];
                var state_1 = _loop_1(checkPermission);
                if (state_1 === "break")
                    break;
            }
        }
        return hasPermission;
    };
    return HasPermissionDirective;
}());
export { HasPermissionDirective };
