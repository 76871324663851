export enum TDPageModes {
  REQUEST_VIEW = 'REQUEST_VIEW',
  REQUEST_EDIT = 'REQUEST_EDIT'
}

export enum StoreType {
  STORE_MODEL = 'STORE_MODEL',
  EARLIER_PARTNER = 'EARLIER_PARTNER',
  PARTNER_MODEL = 'PARTNER_MODEL'
}
