import { TenantInformation } from '../../models/tenant-information.model';

export class TenantInfoState extends TenantInformation {}

export const TenantInfoInitialState: TenantInfoState = {
  merchant: '',
  sites: [],
  groups: [],
  stores: []
};
